/**
* ko
*/
export default {
    bannerName: '빠른거래 센터',
    bannerInfo: '자금 안전 신속 간편한 P2P거래',
    buyAmount: '구매수량',
    rujinAmount: '입금수량',
    tradeRate: '환율',
    paymentAmount: '결제금액',
    rujinjine: '입금금액',
    tradeFee: '수수료',
    patternofPayment: '결제방식 선택',
    sellersChoice: '판매자 선택',
    sellersSearch: '판매자 검색',
    countDown: '카운트다운',
    orderNo: '주문번호',
    remittanceAmount: '결제금액',
    clientProceedsWay: '판매자 수금방식',
    name: '성명',
    bankAccount: '계좌번호',
    bankName: '은행명',
    weChat: 'wechat pay',
    alipay: 'alipay',
    getQr: '결제QR코드 확인',
    buyNow: '구매하기',
    cancelBtn: '취소',
    scheduleStatus: '진행상태',
    sellName: '거래대상',
    buyUser: '입금인',
    remittanceNow: '송금완료(송금후 클릭)',
    contactPlatform: '관리자 연결',
    subAppeal: '중재요청',
    toPay: '송금완료(송금후 클릭)',
    openIm: '판매자 문의',
    verifyProceeds: '수금확인',
    cancelOrder: '주문취소',
    WECHATPAY: 'wechatpay',
    ALIPAY: 'alipay',
    BANK_CARD: '은행계좌이체',
    rules: {
        payTypeId: '결제방식 선택',
        searchKey: '판매자 선택',
        appealDesc: '중재 내용을 입력 하십시오'
    },
    message: {
        searchUser: '매칭되는 판매자가 없습니다. ',
        success: '실행성공',
        mkOrder: '주문성공!  판매자에게 송금 준비 하십시오.',
        orderCancel: '주문취소 성공',
    },
    confirm: {
        hint: '알림',
        info: '송금완료 확인',
        cancelInfo: '현재 주문 취소',
        rejectInfo: '현재 주문 거부',
        confirmInfo: `판매자의 계좌정보에 따라 송금 하셨습니까?<br/> 악의적 클릭은 계정 동결 될수 있습니다. `,
        confirmBtn: '송금완료후 클릭',
        cancelBtn: '미송금',
        confirm: '확인',
        cancel: '취소',
    }
}