/**
* ko
*/
export default {
    bannerName: '입금 센터',
    bannerInfo: '자금 안전 신속 편리한 P2P거래   ',
    countDown: '카운트다운',
    orderNo: '주문번호',
    tradeTye: '입금',
    price: '입금금액',
    possessor: '입금수량',
    sellInfo: '판매자정보',
    payeeUser: '예금주',
    quantity: '입금수량',
    payTypeId: '결제방식 선택',
    addressHint: '* 구매후 USDT 받을 지갑주소 ',
    address: '지갑주소',
    bank: '은행명',
    payType: '결제방식',
    WeChat: 'wechatpay',
    Alipay: 'alipay',
    bankNo: '은행계좌',
    remittance: '입금완료 (입금후 클릭)',
    canelOrder: '주문 취소',
    subBtn: '입금하기',
    cancel: '취소',
    // 2
    Step1: {
        label: 'Step1 : 구매 수량과 금액을 확인 하십시오',
        tradeAmount: '입금수량:',
        currencyAmount: '입금금액:',
        orderNo: '주문번호:'
    },
    Step2: {
        label: 'Step2 : 입금자 성명을 입력 하십시오',
    },
    Step3: {
        label: 'Step3 : 본인 휴대폰번호를 입력 하십시오',
    },
    agreement: {
        txt1: '이용약관',
        txt2: ' 확인후 동의。',
    },
    rules: {
        price: '구매금액을 입력 하십시오',
        quantity: '구매수량을 입력 하십시오',
        payTypeId: '결제방식을 선택 하십시오',
        userName: '입금인 성명을 입력 하십시오',
        tel: '본인 휴대폰번호를 입력 하십시오',
    },
    message: {
        agreement: '이용약관 확인후 동의'
    },
    tooltipList: {
        txt1: '판매자의 계좌정보에 따라 송금 하셨습니까?',
        txt2: '악의적 클릭은 계정 동결 될수 있습니다',
    },
    confirm: {
        hint: '주문취소 ?',
        title: '알림',
        subBtn: '확인',
        cancelBtn: '취소',
    },
    warnInfo: [
        {
            title: "주의사항",
            values: [
                `1. 매번 거래는 플랫폼이 자동으로 판매자를 매칭 합니다 ,판매자의 계좌정보도 매번 다를수가 있으니 판매자 계좌정보에 따라 송금하시길 바랍니다.`,
                `2. 이용자가 USDT 구매 요청 동시 판매자의 USDT는 동결처리 되므로 안전하게 거래를 할수 있습니다.`,
                `3.송금시 비고란을 공란으로 하십시오！(예：가상화폐、USDT、비트코인、외환거래 등 절때 금지)판매자가 거래를 거절할수 있습니다.`,
                `4.이용자가 계좌정보 오류로 잘못 입금한 금액은 플랫폼에서 책임지지 않습니다.`,
                `5.반드시 등록된 본인 은행계좌로 구매하셔야 합니다. 그렇지 않으면 구매한 USDT가 동결 되여 찾을수 없습니다.`,
                `6. 운영시간 ：9：00-21：00，운영시간이외 거래는 판매자의 처리가 늦을수 있습니다.`,
            ],
        },
    ],
    warnInfo3: [
        {
            title: "주의사항",
            values: [
                `1. 매번 거래는 플랫폼이 자동으로 판매자를 매칭 합니다 ,판매자의 계좌정보도 매번 다를수가 있으니 판매자 계좌정보에 따라 송금하시길 바랍니다.`,
                `2. 이용자가 USDT 구매 요청 동시 판매자의 USDT는 동결처리 되므로 안전하게 거래를 할수 있습니다.`,
                `3. 송금시 비고란을 공란으로 하십시오！(예：가상화폐、USDT、비트코인、외환거래 등 절때 금지)판매자가 거래를 거절할수 있습니다.`,
                `4. 이용자가 계좌정보 오류로 잘못 입금한 금액은 플랫폼에서 책임지지 않습니다.`,
                `5. 반드시 등록된 본인 은행계좌로 구매하셔야 합니다. 그렇지 않으면 구매한 USDT가 동결 되여 찾을수 없습니다.`,
                `6. 운영시간 ：9：00-21：00，운영시간이외 거래는 판매자의 처리가 늦을수 있습니다.`
            ],
        },
    ],
};
