/**
* en
*/
export default {
    bannerName: 'Asset',
    bannerInfo: 'Safe, Fast, Convenient',
    header: {
        title: 'Asset details',
        item1: {
            name: 'Available amount'
        },
        item2: {
            name: 'Freeze amount'
        },
        btms: {
            recharge: 'Recharge',
            rechargeRecord: 'Recharge records',
            withdraw: 'Withdrawal',
            withdrawRecord: 'Withdrawal records',
        }
    },
    content: {
        changeTypeShow: 'Type',
        currency: 'Currency types',
        changeAmount: 'Quantity',
        createTime: 'Time',
        withdrawalQuantity: 'Withdrawal quantity',
        address: 'Withdrawal address',
        rechargeQuantity: 'Recharge quantity',
        rechargeAddress: 'Recharge address',
    },
    extractdDialog: {
        title: 'Withdrawal',
        chainId: 'Transfer network',
        address: 'Withdrawal address',
        amount: 'Withdrawal quantity',
        serviceCharge: 'Handling fee 0-10',
        toTheAccount: 'Received quantity',
        tradePass: 'Transaction password',
        fundPassword: 'Fund password',
        subBtn: 'Confirm',
        withdraw: 'Withdrawal',
        warnTitle: 'Kind reminder',
        warninfo1: 'The minimum withdrawal quantity is: 100USDT.',
        warninfo2: 'To ensure account security, we will manually review any changes in your account security policy or password changes.',
        warninfo3: 'Please be patient and wait for the staff to contact you by phone or email.',
        warninfo4: 'Please make sure to ensure the security of your computer and browser to prevent information from being tampered with or leaked.',
        rules: {
            chainId: "Please enter the transfer network",
            address: "Please enter the withdrawal address",
            amount:'Please enter the transfer quantity',
            serviceCharge: "Please enter the handling fee",
            toTheAccount: "Please enter the received quantity",
            tradePass:'Please enter the fund password to confirm withdrawal',
            fundPassword:'Confirm fund password',
            balance:'The withdrawal quantity cannot be greater than the remaining amount',
            isNaN:'Please enter Arabic numerals throughout the text with a minimum value of 100',
        },
        subsuccess:'Operation successful',
    },
    rechargeDialog: {
        title: 'Recharge',
        rechargeAddress: 'Recharge address',
        warnTitle: 'Kind reminder',
        warninfo1: 'Please do not recharge any non USDT assets to the above address, otherwise the assets will not be recoverable.',
        warninfo2: 'Minimum reset amount: 1USDT. Recharges below the minimum amount will not be credited to the account and cannot be refunded.',
        warninfo3: 'Your recharge address will not change frequently and can be recharged repeatedly. If there are any changes, we will try our best to notify you through website announcements or emails.',
        warninfo4: 'Please make sure to ensure the security of your computer and browser to prevent information from being tampered with or leaked.',
    },
    changeTypeShows: {
        RECHARGE: 'Recharge',
        BUY: 'Buy',
        SELL: 'Sell',
        WITHDRAW: 'Withdrawal',
        TRANSFER_IN: 'Transfer in',
        TRANSFER_OUT: 'Transfer out',
        COMMISSION: 'Commission',
    }
};