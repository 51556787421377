import { render, staticRenderFns } from "./headers.vue?vue&type=template&id=3b197203&scoped=true"
import script from "./headers.vue?vue&type=script&lang=js"
export * from "./headers.vue?vue&type=script&lang=js"
import style0 from "./headers.vue?vue&type=style&index=0&id=3b197203&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b197203",
  null
  
)

export default component.exports