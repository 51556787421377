<template>
  <div>
    <SmallBanners
      :title="$t('userInfo.bannerName')"
      :info="$t('userInfo.bannerInfo')"
    />
    <div class="content">
      <div class="header">
        <div class="header-title">
          <img src="../../assets/userInfo/ID.png" alt="" />
          <div class="header-title-info">
            <div class="header-title-info-name">{{ userInfo.loginName }}</div>
            <div class="header-title-info-ID">UID:{{ userInfo.userId }}</div>
          </div>
        </div>
        <div class="header-info">
          <div class="header-info-item">
            <div class="header-info-item-primary">
              <span class="primary">{{ userInfo.loginName }}</span>
            </div>
            <div class="header-info-item-second">
              UID : {{ userInfo.userId }}
            </div>
          </div>
          <div class="header-info-item">
            <div class="header-info-item-primary">
              <span class="primary"
                >{{ userInfo.buyCount + userInfo.buyCount }} ({{
                  userInfo.buyCount
                }}/{{ userInfo.buyCount }})</span
              >
              <span class="second">{{
                $t("userInfo.header.item2.lebel")
              }}</span>
            </div>
            <div class="header-info-item-second">
              {{ $t("userInfo.header.item2.info") }}
            </div>
          </div>
          <div class="header-info-item">
            <div class="header-info-item-primary">
              <span class="primary">0.0</span>
              <span class="second">{{
                $t("userInfo.header.item3.lebel")
              }}</span>
            </div>
            <div class="header-info-item-second">
              {{ $t("userInfo.header.item3.info") }}
            </div>
          </div>
          <div class="header-info-item">
            <div class="header-info-item-primary">
              <span class="primary">{{ userInfo.dayCount }}</span>
              <span class="second">{{
                $t("userInfo.header.item4.lebel")
              }}</span>
            </div>
            <div class="header-info-item-second">
              {{ $t("userInfo.header.item4.info") }}
            </div>
          </div>
          <div class="header-info-item">
            <div class="header-info-item-primary">
              <span class="primary">{{ userInfo.dayRate }}</span>
              <span class="second">{{
                $t("userInfo.header.item5.lebel")
              }}</span>
            </div>
            <div class="header-info-item-second">
              {{ $t("userInfo.header.item5.info") }}
            </div>
          </div>
        </div>
      </div>
      <div class="userInfo">
        <div class="userInfo-personal">
          <div class="userInfo-personal-title">
            {{ $t("userInfo.userInfo.title") }}
          </div>
          <div class="userInfo-personal-item">
            <div class="userInfo-personal-item-name">
              {{ $t("userInfo.userInfo.name") }}
            </div>
            <div class="userInfo-personal-item-info">
              {{ userInfo.loginName }}
            </div>
          </div>
          <div class="userInfo-personal-item">
            <div class="userInfo-personal-item-name">
              {{ $t("userInfo.userInfo.code") }}
            </div>
            <div class="userInfo-personal-item-info">{{ userInfo.userId }}</div>
          </div>
          <div class="userInfo-personal-item">
            <div class="userInfo-personal-item-name">
              {{ $t("userInfo.userInfo.nationality") }}
            </div>
            <div class="userInfo-personal-item-info">
              {{ $t(`nationality.${userInfo.countryName}`) }}
            </div>
          </div>
          <div class="userInfo-personal-item">
            <div class="userInfo-personal-item-name">
              {{ $t("userInfo.userInfo.recordDate") }}
            </div>
            <div class="userInfo-personal-item-info">
              {{ userInfo.createTime }}
            </div>
          </div>
        </div>
        <div class="userInfo-account">
          <div class="userInfo-account-title">
            {{ $t("userInfo.accountInfo.title") }}
          </div>
          <div class="userInfo-account-item">
            <div class="userInfo-account-item-name">
              {{ $t("userInfo.accountInfo.password") }}
            </div>
            <div class="userInfo-account-item-info">******</div>
            <div class="userInfo-account-item-option">
              <i
                class="el-icon-edit"
                @click="openDialog('resetLoginPwdDialog')"
              />
            </div>
          </div>
          <div class="userInfo-account-item">
            <div class="userInfo-account-item-name">
              {{ $t("userInfo.accountInfo.transactionPin") }}
            </div>
            <div class="userInfo-account-item-info">******</div>
            <div class="userInfo-account-item-option">
              <i
                class="el-icon-edit"
                @click="openDialog('resetTransactionPwdDialog')"
              />
            </div>
          </div>
          <div class="userInfo-account-item" v-if="$store.state.common.i18n !== 'ko'">
            <div class="userInfo-account-item-name">
              {{ $t("userInfo.accountInfo.authentication.label") }}
            </div>
            <div class="userInfo-account-item-info colors">
              {{
                $t(
                  `userInfo.accountInfo.authentication.info${userInfo.certState}`
                )
              }}
            </div>
            <div class="userInfo-account-item-option">
              <el-button
                type="primary"
                size="mini"
                v-if="userInfo.certState == 0 || userInfo.certState == 3"
                @click="toPath('authentication')"
                >{{
                  $t(
                    `userInfo.accountInfo.authentication.button${userInfo.certState}`
                  )
                }}</el-button
              >
              <span
                v-if="userInfo.certState == 2"
                class="userInfo-account-item-option-color"
              >
                {{
                  $t(
                    `userInfo.accountInfo.authentication.button${userInfo.certState}`
                  )
                }}
              </span>
              <span
                v-if="userInfo.certState == 4"
                class="userInfo-account-item-option-color"
              >
                <i class="el-icon-circle-check" />
                {{
                  $t(
                    `userInfo.accountInfo.authentication.button${userInfo.certState}`
                  )
                }}
              </span>
            </div>
          </div>
          <div class="userInfo-account-item">
            <div class="userInfo-account-item-name">
              {{ $t("userInfo.accountInfo.phone.label") }}
            </div>
            <div class="userInfo-account-item-info">
              <span v-if="userInfo.tel" class="colors">
                {{ $t("userInfo.accountInfo.phone.info") }}{{ userInfo.tel }}
              </span>
              <span v-else>
                {{ $t("userInfo.accountInfo.phone.wrong") }}
              </span>
            </div>

            <div class="userInfo-account-item-option">
              <i
                class="el-icon-edit"
                v-if="userInfo.tel"
                @click="openDialog('phoneDialog', true)"
              />
              <el-button
                v-else
                type="primary"
                size="mini"
                @click="openDialog('phoneDialog')"
                >{{ $t("userInfo.accountInfo.phone.button") }}</el-button
              >
            </div>
          </div>
          <div class="userInfo-account-item">
            <div class="userInfo-account-item-name">
              {{ $t("userInfo.accountInfo.mail.label") }}
            </div>
            <div class="userInfo-account-item-info">
              <span v-if="userInfo.email" class="colors">
                {{ $t("userInfo.accountInfo.mail.info") }}{{ userInfo.email }}
              </span>
              <span v-else>
                {{ $t("userInfo.accountInfo.mail.wrong") }}
              </span>
            </div>
            <div
              class="userInfo-account-item-option"
              @click="openDialog('emailDialog', true)"
            >
              <i class="el-icon-edit" v-if="userInfo.email" />
              <el-button
                v-else
                type="primary"
                size="mini"
                @click="openDialog('emailDialog')"
                >{{ $t("userInfo.accountInfo.mail.button") }}</el-button
              >
            </div>
          </div>
        </div>
        <div class="userInfo-pay">
          <div class="userInfo-pay-title">
            <span>{{ $t("userInfo.accountInfo.paymentProcedure.title") }}</span>
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('addBankAccountDialog')"
              >{{
                $t("userInfo.accountInfo.paymentProcedure.titleBnt")
              }}</el-button
            >
          </div>
          <div
            class="userInfo-pay-item"
            v-for="(item, index) in userInfo.payBankList"
            :key="item.id"
          >
            <div class="userInfo-pay-item-name">
              {{ $t(`common.bankList.${item.payNameCode}`) }}
            </div>
            <div class="userInfo-pay-item-info colors">
              {{
                $t(
                  `userInfo.accountInfo.paymentProcedure.paytypeState${item.paytypeState}`
                )
              }}
              :
              {{ item.paytypeName }}
              <span v-if="item.paytypeId != 3 && item.paytypeId != 4">
                {{ item.paytypeNo }}
              </span>
            </div>
            <div class="userInfo-pay-item-option">
              <i
                class="el-icon-edit"
                @click="openDialog('resetBankAccountDialog', true, item)"
              />
              <el-switch
                v-model="item.paytypeOpen"
                active-text="ON"
                inactive-text="OFF"
                active-color="#019393"
                inactive-color="#313131"
                active-value="1"
                inactive-value="0"
                @change="paytypeOpenChange(item, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PhoneDialog ref="phoneDialog" @diaLogCallBack="diaLogCallBack" />
    <EmailDialog ref="emailDialog" @diaLogCallBack="diaLogCallBack" />
    <ResetLoginPwdDialog
      ref="resetLoginPwdDialog"
      @diaLogCallBack="diaLogCallBack"
    />
    <ResetTransactionPwdDialog
      ref="resetTransactionPwdDialog"
      @diaLogCallBack="diaLogCallBack"
    />
    <AddBankAccountDialog
      ref="addBankAccountDialog"
      @diaLogCallBack="diaLogCallBack"
    />
    <ResetBankAccountDialog
      ref="resetBankAccountDialog"
      @diaLogCallBack="diaLogCallBack"
    />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import PhoneDialog from "./components/phoneDialog";
import EmailDialog from "./components/emailDialog";
import ResetLoginPwdDialog from "./components/resetLoginPwdDialog";
import ResetTransactionPwdDialog from "./components/resetTransactionPwdDialog";
import AddBankAccountDialog from "./components/addBankAccountDialog";
import ResetBankAccountDialog from "./components/resetBankAccountDialog";
import { myInfo, updateState } from "@/api/userInfo";
export default {
  name: "userInfo",
  components: {
    PhoneDialog,
    EmailDialog,
    ResetLoginPwdDialog,
    ResetTransactionPwdDialog,
    AddBankAccountDialog,
    ResetBankAccountDialog,
  },
  data() {
    return {
      value: "",
      userInfo: {
        userId: "",
        buyCount: "",
        dayCount: "",
        dayRate: "",
        loginName: "",
        userId: "",
        countryName: "",
        createTime: "",
        certState: "",
        tel: "",
        email: "",
        payBankList: [],
      },
    };
  },
  created() {},
  mounted() {
    this.getUserInfo();
  },
  methods: {
    tooltipBack(flag, isCancel) {
      if (isCancel) return;
      if (flag == "authentication") {
        if (this.userInfo.certState == 0 || this.userInfo.certState == 4) {
          this.$router.push("/authentication");
        }
      }
    },
    // 查询个人信息
    getUserInfo() {
      myInfo().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.$store.dispatch("userInfo/setUserInfo", res.data);
          if (res.data.certState == 0 || res.data.certState == 3) {
            this.authentication("mounted");
          }
        }
      });
    },
    paytypeOpenChange(item, index) {
      let data = new FormData();
      data.append("id", item.id);
      data.append("paytypeOpen", item.paytypeOpen);
      updateState(data).then((res) => {
        if (res.code == 200) {
          this.getUserInfo();
        }
      });
    },
    // 弹窗关闭回调
    diaLogCallBack(value) {
      this.getUserInfo();
    },
    // 打开弹窗
    openDialog(refName, type, item) {
      let data = this.userInfo;
      /*if (refName == "addBankAccountDialog" && this.userInfo.certState != 4) {
        this.authentication();
        return;
      }*/
      this.$refs[refName].opendialog(this.userInfo, type, item);
    },
    toPath(path) {
      this.$router.push("/" + path);
      sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    },
    // 判断你是否认证
    authentication(type) {
      /*this.$refs['TooltipPop'].openDialog('authentication', false, {
        type: "affirm",
        info: [this.userInfo.certState == 2
          ? this.$t("userInfo.accountInfo.confirm.authenticationInfo2")
          : this.$t("userInfo.accountInfo.confirm.authenticationInfo1"),],
      });*/
    },
  },
  computed: {}
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 5vw;
}
.header {
  padding: 1vw 0;
  margin-top: -5vw;
  .header-title {
    padding: 10px 0;
    display: flex;
    align-items: center;
    img {
      width: 42px;
      height: 42px;
      margin-right: 1vw;
    }
    .header-title-info {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .header-info {
    height: 6vw;
    box-sizing: border-box;
    background: #019393;
    border-radius: 5px;
    padding: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-info-item {
      width: 25%;
      text-align: center;
      border-right: 1px solid #74bdbd;
      .header-info-item-primary {
        .primary {
          font-size: 1.66667vw;
          font-weight: bold;
        }
        .second {
          font-size: 1.66667vw;
          font-weight: bold;
        }
      }
      .header-info-item-second {
        font-size: 0.72917vw;
      }
    }
    .header-info-item:first-child {
      width: 30%;
      text-align: left;
    }
    .header-info-item:last-child {
      width: 20%;
      text-align: right;
      border: none;
    }
  }
}
.userInfo {
  background: #fff;
  .userInfo-personal {
    margin-top: 4.16667vw;
    width: 100%;
    .userInfo-personal-title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 1.51042vw;
      border-bottom: 1px solid #ededed;
    }
    .userInfo-personal-item {
      width: 100%;
      line-height: 50px;
      padding-left: 1vw;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      border-bottom: 1px solid #ededed;
      .userInfo-personal-item-name {
        width: 16.14583vw;
        font-weight: bold;
      }
    }
  }
  .userInfo-account {
    width: 100%;
    margin-top: 4.16667vw;
    .userInfo-account-title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 1.51042vw;
      border-bottom: 1px solid #ededed;
    }
    .userInfo-account-item {
      width: 100%;
      line-height: 50px;
      padding-left: 1vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px solid #ededed;
      .colors {
        color: #019393;
      }
      .userInfo-account-item-name {
        width: 16.14583vw;
        font-weight: bold;
      }
      .userInfo-account-item-info {
        flex: 1;
        font-weight: bold;
      }
      .userInfo-account-item-option {
        min-width: 10vw;
        text-align: right;
        .el-icon-edit {
          font-size: 1.14583vw;
          margin-right: 1vw;
          cursor: pointer;
        }
        .el-icon-edit:hover {
          color: #74bdbd;
        }
        .el-icon-edit:active {
          color: #019393;
        }
        &-color {
          color: #019393;
        }
      }
    }
  }
  .userInfo-pay {
    width: 100%;
    margin-top: 4.16667vw;
    .userInfo-pay-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 1.51042vw;
      border-bottom: 1px solid #ededed;
    }
    .userInfo-pay-item {
      width: 100%;
      line-height: 50px;
      padding-left: 1vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px solid #ededed;
      .colors {
        color: #019393;
      }
      .userInfo-pay-item-name {
        width: 16.14583vw;
        font-weight: bold;
      }
      .userInfo-pay-item-info {
        flex: 1;
        font-weight: bold;
      }
      .userInfo-pay-item-option {
        width: 10vw;
        text-align: right;
        .el-icon-edit {
          font-size: 1.14583vw;
          margin-right: 1vw;
          cursor: pointer;
        }
        .el-icon-edit:hover {
          color: #74bdbd;
        }
        .el-icon-edit:active {
          color: #019393;
        }
      }
    }
  }
}
::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
/*关闭时文字位置设置*/
::v-deep .el-switch__label--left {
  z-index: 1;
  left: 22px;
}
/*显示文字*/
::v-deep .el-switch__label.is-active {
  display: block;
}
::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 55px !important;
}
</style>
