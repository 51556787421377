<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="42vw"
      class="roll-dialog"
    >
      <div class="title">{{ $t(`transfer.addTransferDialog.title`) }}</div>
      <el-table :data="tableData" ref="table" class="tabel" v-loading="loading">
        <el-table-column
          show-overflow-tooltip
          :label="$t(`transfer.addTransferDialog.friendName`)"
        >
          <template slot-scope="scope">
            <div class="tabel-item color">
              <el-checkbox
                v-model="scope.row.checkbox"
                :true-label="scope.row.id"
                >{{ scope.row.friendName }}</el-checkbox
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="friendAddress"
          :label="$t(`transfer.addTransferDialog.friendAddress`)"
          align="center"
        >
          <template slot-scope="scope">
            <div class="tabel-item color">
              {{ scope.row.friendAddress }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :page="page"
        :size="size"
        :total="total"
        @callbackPage="callbackPage"
      />
      <div class="dialog-footer">
        <el-button type="primary" class="btn" @click="subData">{{
          $t(`transfer.addTransferDialog.subBtn`)
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { relationshipList } from "@/api/transfer";
export default {
  name: "addBatchDialog",
  data() {
    return {
      loading: false,
      dialogVisible: false,
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      backList: [],
      parentList:[]
    };
  },
  methods: {
    init() {
      let data = new FormData();
      data.append("page", this.page);
      data.append("pageSize", this.size);
      this.loading = true;
      relationshipList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = [];
          let mapList = this.parentList.map((i) => i.id);
          res.data.list.forEach((i) => {
            this.tableData.push({
              ...i,
              checkbox: mapList.indexOf(i.id) >= 0 ? i.id : "",
            });
          });
          this.page = res.data.pageNum;
          this.size = res.data.pageSize;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    //分页返回
    callbackPage({ page, size }) {
      this.page = page;
      this.size = size;
      this.tableDataCheck();
      this.init();
    },
    subData() {
      this.tableDataCheck()
      this.handleClose();
    },
    tableDataCheck() {
      let map = this.parentList.map(i => i.id)
      this.tableData.forEach((i) => {
        if (i.checkbox && map.indexOf(i.id) < 0) {
          this.parentList.push({
            ...i,
            address: i.friendAddress,
          });
        }
      });
    },
    opendialog(userInfo, list) {
      this.page = 1
      this.size = 10
      this.total = 0
      this.dialogVisible = !this.dialogVisible;
      this.parentList = list
      this.init();
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("dialogBack", this.parentList, false);
      this.parentList = []
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  color: #019393;
  font-size: 1.25vw;
  font-weight: bold;
}
.tabel {
  margin-top: 1vw;
  position: relative;
  .tabel-item {
    width: 100%;
    // height: 40px;
    // line-height: 40px;
  }
  .color {
    color: #313131 !important;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin: 2.08333vw 0;
}
.btn {
  width: 25.52083vw;
  background: #313131;
  border: 1px solid #313131;
  color: #ffffff;
}
.btn:hover {
  border: 1px solid #9e9e9e !important;
}
.btn:active {
  border: 1px solid #313131 !important;
}
// --------------------
// 修改头部样式
::v-deep .el-table .el-table__header th {
  background: #019393;
  color: #ffffff !important;
  box-sizing: border-box;
}
::v-deep .el-table__header {
  border-radius: 5px !important;
  overflow: hidden !important;
}
// 修改title背景
::v-deep .el-table .el-table__header th .cell {
  background: #019393;
  text-align: center;
  transform: translateX(-0.5vw);
}
// 修改row样式
::v-deep .el-table .cell {
  width: calc(100%);
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #ededed;
}
//--------------------------------
// 修改checkbox样式
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  color: #313131 !important;
  border: 1px solid #313131 !important;
  background: #313131 !important;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border: 1px solid #313131 !important;
}
::v-deep .el-checkbox__label {
  color: #313131 !important;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
