/**
* zh_CN
*/
export default {
    bannerName: '注册会员',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    phoneRegister: '手机号注册',
    emailRegister: '邮箱注册',
    mustFillIn: '基本设置',
    setPsd: '设置密码',
    userAgreement: '用户协议',
    signInBtn: '注册会员',
    formLabel: {
        countryCode: '国籍',
        loginName: '用户ID',
        phoneNumber: '手机号',
        mail: '邮箱',
        phoneVerificationCode: '手机验证码',
        mailVerificationCode: '邮箱验证码',
        sendCode: '发送验证码',
        newCode: '重新发送',
        password: '登录密码',
        notarizePassword: '确认登录密码',
        tradePassword: '交易密码',
        notarizeTradePassword: '确认交易密码',
        inviteId: '邀请码 ',
        userAgreementBtn: '用户协议',
        userAgreementInfo: '请确认后点击同意',
    },
    rules: {
        countryCode: '请选择国籍',
        loginName: '请输入用户ID',
        phoneNumber: '请输入手机号',
        mail: '请输入邮箱',
        verificationCode: '请输入验证码',
        password: '请输入登录密码',
        verifyPassword: '请确认登录密码',
        verifyPasswordError: '登录密码不一致',
        tradePassword: '请输入交易密码',
        verifyTradePassword: '请确认交易密码',
        verifyTradePasswordError: '交易密码不一致',
        inviteId: '请输入邀请码',
        userAgreement: '请先同意用户协议',
    },
    success:'操作成功'
};
