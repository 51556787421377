import store from "@/store";
/**
 * 下拉回显
 */
const selectionEcho = (val, dom, list) => {
    for (let index in list) {
        let obj = list[index];
        if (obj.label == val) {
            dom.setAttribute(
                "style",
                `
            background: url(${obj.src}) no-repeat; 
            background-size:22px 24px;
            background-position: 24px 0% ;
            height: 24px;
            padding-left:71px;
            `
            );
        }
        if (obj.currency == val) {
            dom.setAttribute(
                "style",
                `
            background: url(${obj.src}) no-repeat; 
            background-size: 26px 28px !important;
            background-position: 22px 0%  !important;
            height: 28px !important;
            padding-left:71px;
            `
            );
        }
    }
}
/**
 * 金额格式化
 */
const numberFormatting = (number) => {
    // 转为字符串，并按照.拆分
    const arr = (number + '').split('.');
    // 整数部分再拆分
    const int = arr[0].split('');
    // 小数部分
    const fraction = arr[1] || '';
    // 返回的变量
    let r = '';
    int.reverse().forEach(function (v, i) {
        // 非第一位并且是位值是3的倍数，添加“,”
        if (i !== 0 && i % 3 === 0) {
            r = v + ',' + r;
        } else {
            // 正常添加字符
            r = v + r;
        }
    });
    // 整数部分和小数部分拼接
    return r + (!!fraction ? '.' + fraction : '');
}
/**
 * 查询验证码图片
 *
 * @author 流转地址
 */
function codeImage(value) {
    let blob = new Blob([value], {
        type: "application/vnd.ms-excel;charset=utf-8",
    });
    let url = JSON.stringify(window.URL.createObjectURL(blob));
    let find = url.lastIndexOf('/')
}
/**
 * 判断登录
 *
 * @author 判断登录
 */
function isOnline(value) {
    return store.state.userInfo.userInfo && store.state.userInfo.userInfo.userId?true:false
}

export default {
    selectionEcho,
    numberFormatting,
    codeImage,
    isOnline
}