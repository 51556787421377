/**
* ko
*/
export default {
    copy: {
        title: '알림',
        info: '복사성공',
        button: '확인',
    },
    bankList: {
        PAY_TYPE_KRW_BANK: '한국(KRW)은행계좌',
        PAY_TYPE_CNY_BANK: '중국(CNY)은행계좌',
        PAY_TYPE_CNY_WECHATPAY: '(CNY)Wechatpay',
        PAY_TYPE_CNY_ALIPAY: '(CNY)Alipay',
        PAY_TYPE_JPY_BANK: '일본(JPY)은행계좌',
        PAY_TYPE_HKD_BANK: '홍콩(HKD)은행계좌',
        PAY_TYPE_VND_BANK: '베트남(VND)은행계좌',
        PAY_TYPE_PHP_BANK: '(PHP)은행계좌',
        PAY_TYPE_MYR_BANK: '말레이시아(MYR)은행계좌',
        PAY_TYPE_GBP_BANK: '영국(GBP)은행계좌',
        PAY_TYPE_EUR_BANK: '유로(EUR)은행계좌',
        PAY_TYPE_USD_BANK: '미국(USD)은행계좌'
    },
    minBankList: {
        BANK_CARD: '은행계좌이체',
        WECHATPAY: 'wechatpay',
        ALIPAY: 'alipay',
    },
    tooltipPop: {
        success: '성공',
        error: '오류',
        affirm: '확인'
    }
}
