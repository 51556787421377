/**
* zh_CN
*/
export default {
    bannerName: '个人中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    header: {
        item1: {
            lebel: '用户',
            info: ''
        },
        item2: {
            lebel: '次',
            info: '总成单（买/卖）'
        },
        item3: {
            lebel: '分钟',
            info: '平均放行'
        },
        item4: {
            lebel: '次',
            info: '1日成单'
        },
        item5: {
            lebel: '%',
            info: '1日完成率'
        }
    },
    userInfo: {
        title: '个人信息',
        name: '姓名',
        code: '用户编号',
        nationality: '国家',
        recordDate: '注册日期',
    },
    accountInfo: {
        title: '设置账号',
        password: '登录密码',
        passwordDialog: {
            diaLog: {
                title: '修改登录密码',
                oldPassword: '原登录密码',
                newPassword: '新登录密码',
                verifyNewPassword: '确认新登录密码',
                subBtn: '修改',
                rules: {
                    oldPassword: '请输入原登录密码',
                    newPassword: '请输入新登录密码',
                    verifyNewPassword: '请确认新登录密码',
                    verifyNewPasswordError: '输入的新密码不一致,请确认',
                },
            },
        },
        transactionPin: '交易密码',
        transactionPinDialog: {
            diaLog: {
                title: '修改交易密码',
                oldPassword: '原交易密码',
                newPassword: '新交易密码',
                verifyNewPassword: '确认新交易密码',
                subBtn: '修改',
                rules: {
                    oldPassword: '请输入原交易密码',
                    newPassword: '请输入新交易密码',
                    verifyNewPassword: '请确认新交易密码',
                    verifyNewPasswordError: '输入的新密码不一致,请确认',
                },
            },
        },
        authentication: {
            label: '实名认证',
            info0: '请提交实名认证资料',
            info2: '已提交实名认证材料',
            info3: '请提交实名认证资料',
            info4: '已认证',
            button0: '申请实名认证',
            button2: '请耐心等待管理员审批',
            button3: '申请实名认证',
            button4: '已认证',
        },
        phone: {
            label: '手机',
            info: '已认证 : ',
            wrong: '未绑定',
            button: '绑定手机',
            diaLog: {
                title: '绑定手机号',
                resetTitle: '修改绑定手机号',
                phone: '手机号码',
                code: '验证码',
                codeBtn: '发送验证码',
                newCodeBtn: '发送验证码',
                subBtn: '提交',
                rules: {
                    phone: '请输入手机号码',
                    code: '请输入验证码',
                }
            },
        },
        mail: {
            label: '邮箱',
            info: '已认证 : ',
            wrong: '未绑定',
            button: '绑定邮箱',
            diaLog: {
                title: '绑定邮箱',
                resetTitle: '修改绑定邮箱',
                email: '邮箱地址',
                code: '验证码',
                codeBtn: '发送验证码',
                newCodeBtn: '发送验证码',
                subBtn: '提交',
                rules: {
                    email: '请输入邮箱地址',
                    code: '请输入验证码',
                }
            },
        },
        paymentProcedure: {
            title: '选择支付方式',
            titleBnt: '添加银行账号',
            label: '银行账号',
            paytypeState: '未认证',
            paytypeState1: '审核中',
            paytypeState2: '未通过',
            paytypeState3: '通过',
            diaLog: {
                title: '添加银行账号',
                realName: '姓名',
                bankName: '银行',
                paytypeId: '账户类型',
                paytypeName: '收款人',
                paytypeNo: '银行账号',
                paytypeNoWECHATPAY: '微信账号',
                subBtn: '确定',
                rules: {
                    realName: '请输入姓名',
                    bankName: '请输入银行名称',
                    paytypeId: '请选择支付方式',
                    paytypeName: '请输入收款人',
                    paytypeNo: '请输入银行账号',
                    paytypeNoWECHATPAY: '请输入微信账号',
                    collectionImg: '请上传收款二维码',
                },
            },
            editDialog: {
                title: '修改收款方式',
                realName: '姓名',
                paytypeState: '帐号状态',
                paytypeState1: '审核中',
                paytypeState2: '未通过',
                paytypeState3: '通过',
                paytypeId: '收款方式',
                paytypeName: '收款人',
                bankName: '银行',
                paytypeNo: '银行账号',
                paytypeNoWECHATPAY: '微信账号',
                subBtn: '确认',
                deleteBtn: '删除',
                rules: {
                    realName: '请输入姓名',
                    paytypeState: '请选择账号状态',
                    bankName: '请输入银行名称',
                    paytypeId: '请选择支付方式',
                    paytypeName: '请输入收款人',
                    paytypeNo: '请输入银行账号',
                    paytypeNoWECHATPAY: '请输入微信账号',
                    collectionImg: '请上传收款二维码',
                },
            }
        },
        confirm: {
            authenticationTitle: '提示',
            authenticationInfo1: '暂无认证信息,是否前往认证?',
            authenticationInfo2: '认证信息审核中,请等待',
            confirmBtn: '确定',
            cancelBtn: '取消',
        }
    }
};
