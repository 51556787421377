<template>
  <div>
    <SmallBanners :title="$t('transactionOrder.bannerName.pendingOrder')" :info="$t('transactionOrder.bannerInfo')"/>
    <Content />
  </div>
</template>

<script>
import Content from './index.vue'
export default {
  name: "",
  components: {Content},
  data() {
    return {

    };
  },
  created() {},
  mounted() {},
  methods: {

  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.layoutContent {
  padding: 0 210px;
}
</style>
