/**
* zh_CN
*/
export default {
    bannerName: 'Operation successful',
    bannerInfo: 'Safe, Fast, Convenient',
    title: 'Real name authentication',
    titleInfo: '*To ensure transaction security, please patiently complete the following information',
    certType: 'Document type ',
    realName: 'Name',
    dateOfBirth: 'Date Of Birth  (1900-00-00)',
    certImage: 'Please upload ID photos',
    info: {
        title: 'matters needing attention:',
        item1: '1. Please make sure to submit a photo taken on the front of the original ID card.',
        item2: '2. Due to factors such as light reflection, shaking, and regional shearing, it may be difficult to accurately identify an ID card.',
        item3: '3. Severely damaged ID cards may be rejected',
    },
    subBtn: 'Submit',
    idTypes: {
        item1: 'ID card',
        item2: 'passport',
        item3: "Driver's license",
        item4: 'Foreigner Registration Certificate',
    },
    rules: {
        certType: 'Please select the type of document',
        realName: 'Please enter your name',
        dateOfBirth: 'Please select the date of birth',
        certImage: 'Please upload ID photos',
    }
};