import api from '@/utils/request'
// 查询交易兑换数量
export function getRate(data) {
    return api.post('/api/v1/exchange/rate', data)
}
// 下单前check
export function checkState(data) {
    return api.post('/api/v1/exchange/checkState', data)
}
// 搜索可接单用户
export function searchAcceptor(data) {
    return api.post('/api/v1/exchange/searchAcceptor', data)
}
// 下单
export function mkOrder(data) {
    return api.post('/api/v1/exchange/mkOrder', data)
}
// 取消订单
export function cancel(data) {
    return api.post('/api/v1/exchange/cancel', data)
}
// 确认已付款
export function confirmPaid(data) {
    return api.post('/api/v1/exchange/confirmPaid', data)
}
// 确认收款放币
export function confirmReceipt(data) {
    return api.post('/api/v1/exchange/confirmReceipt', data)
}
// 订单申诉
export function appeal(data) {
    return api.post('/api/v1/exchange/appeal', data)
}
// 商家拒绝订单
export function reject(data) {
    return api.post('/api/v1/exchange/reject', data)
}
// 订单详情情况
export function orderInfo(data) {
    return api.get('/api/v1/order/info?id=' + data.orderNo)
}