<template>
  <div>
    <el-button
      type="text"
      class="box"
      @click="toPath('/estate')"
      v-if="!isOnline"
    >
      <img src="@/assets/headeTooltip/head1.png" alt="" />
      <div class="box-tip-angle" />
      <div class="box-tip">
        <div class="box-tip-angle-item">
          {{ $t("headeTooltip.property") }}
        </div>
      </div>
    </el-button>
    <el-button type="text" class="box" v-if="!isOnline" @click="toPath('/IM')">
      <el-badge :value="unReadCount">
        <img src="@/assets/headeTooltip/head2.png" alt="" />
      </el-badge>
        <div class="box-tip-angle" />
        <div class="box-tip">
          <div class="box-tip-angle-item">
            {{ $t("headeTooltip.customerService") }}
          </div>
        </div>
    </el-button>
    <el-button
      type="text"
      class="box"
      @click="toPath('/userInfo')"
      v-if="!isOnline"
    >
      <img src="@/assets/headeTooltip/head3.png" alt="" />
      <div class="box-tip-angle" />
      <div class="box-tip">
        <div class="box-tip-angle-item">
          {{ $t("headeTooltip.personalCenter") }}
        </div>
      </div>
    </el-button>
    <el-button type="text" class="box">
      <img src="@/assets/headeTooltip/head4.png" alt="" />
      <div class="box-tip-angle" />
      <div class="box-tip">
        <div class="box-tip-angle-item" @click="setLang('ko')">
          한&nbsp;국&nbsp;어
        </div>
        <div class="box-tip-angle-item" @click="setLang('zh_CN')">
          中&nbsp;国&nbsp;语
        </div>
        <div class="box-tip-angle-item" @click="setLang('en')">English</div>
      </div>
    </el-button>
  </div>
</template>

<script>
import obj from "@/utils/common.js";
export default {
  name: "HeadeTooltip",
  components: {},
  data() {
    return {
      unReadCount: 0,
    };
  },
  watch: {
    "$store.state.common.unReadCount"(newVal, oldVal) {
      console.log("IM__watch__unReadCount", newVal);
      this.unReadCount = newVal;
    },

    deep: true,
    immediate: true,
  },
  created() {},
  mounted() {},
  methods: {
    toPath(path) {
      if (path == "/IM") {
        console.log(1111)
        this.$store.dispatch("common/setIM", { bool: null,accId:this.$store.state.userInfo.userInfo.serviceAccId });
        return;
      }
      if (this.$route.path == path) {
        location.reload();
      } else {
        this.$router.push(path);
      }
    },
    async setLang(val) {
      this.$i18n.locale = val;
      this.$store.dispatch("common/setI18n", val);
      this.$store.dispatch("common/setNationalitys", obj.nationality);
      // await this.IM.destroyIm();
      // await this.IM.createIm();
    },
  },
  computed: {
    isOnline() {
      return this.$store.state.userInfo.userInfo &&
        this.$store.state.userInfo.userInfo.userId
        ? false
        : true;
    },
  },
};
</script>
<style scoped lang='scss'>
.box {
  position: relative;
  display: inline-block;
  height: 100%;
  line-height: 100%;
  margin-right: 1vw;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
  .box-tip-angle {
    visibility: hidden;
    width: 10px;
    height: 10px;
    position: absolute;
    left: calc(50% - 5px);
    bottom: -5px;
    background: #019393;
    transform: rotate(180deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
  }
  .box-tip {
    min-width: 30px;
    visibility: hidden;
    font-size: 14px;
    color: #ffffff;
    background: #019393;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    z-index: 99;
    .box-tip-angle-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      padding: 16px;
      //强制内容不换行
      white-space: nowrap;
    }
    .box-tip-angle-item:hover {
      background: #00a7a7;
    }
  }
}
.box:hover {
  .box-tip-angle {
    visibility: initial;
  }
  .box-tip {
    visibility: initial;
  }
}
</style>
