
const state = {
    i18n: navigator.language == 'zh-CN' ? 'zh_CN' : 'ko',
    // 获取交易币对
    getSymbol: [],
    // 获取所有法币信息
    getAllCurrency: [],
    // 获取所有币种信息
    getAllCoin: [],
    // 国家集合
    nationalitys: [],
    IM: false,
    // 需要联系得IM用户对象
    imData: null,
    // IM未读数
    unReadCount: 0
}
const mutations = {
    // 更新IM 未读数量
    setUnReadCount(state, num) {
        state.unReadCount = num
    },
    // 打开IM
    setIM(state, data) {
        // console.log('打开IM', bool, state.IM,)
        state.IM = data.bool === true || data.bool === false ? data.bool : !state.IM
        // console.log('打开IM', bool, state.IM)
        if (data.accId) {
            state.imData = data
        }
    },
    // 设置i18n状态
    async setI18n(state, val) {
        state.i18n = val
        localStorage.setItem('lang', val)
    },
    // 获取交易币对
    getSymbol(state, data) {
        state.getSymbol = data
    },
    // 获取所有法币信息
    getAllCurrency(state, data) {
        state.getAllCurrency = data
    },
    // 获取所有币种信息
    getAllCoin(state, list) {
        state.getAllCoin = list
    },
    // 国家集合动态缓存
    setNationalitys(state, list) {
        state.nationalitys = []
        list.forEach(i => {
            state.nationalitys.push({
                ...i,
                label: state.i18n == 'zh_CN' ? i.cLabel : i.eLabel
            })
        })
    },
}
const actions = {
    // 更新IM 未读数量
    setUnReadCount({ commit }, num) {
        commit('setUnReadCount', num)
    },
    // 打开IM
    setIM({ commit }, data) {
        console.log('IM__openIM', data)
        commit('setIM', data)
    },
    // 设置i18n状态
    setI18n({ commit }, val) {
        commit('setI18n', val)
    },
    // 获取交易币对
    getSymbol({ commit }, data) {
        commit('getSymbol', data)
    },
    // 获取所有法币信息
    getAllCurrency({ commit }, data) {
        commit('getAllCurrency', data)
    },
    // 获取所有币种信息
    getAllCoin({ commit }, list) {
        commit('getAllCoin', list)
    },
    // 国家集合动态缓存
    setNationalitys({ commit }, list) {
        commit('setNationalitys', list)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}