/**
* en
*/
export default {
    bannerName: 'Quick Trading Center',
    bannerInfo: 'Safe, Fast, Convenient',
    sellAmount: 'Sales quantity',
    chujinAmount: 'Out amount',
    tradeRate: 'exchange rate',
    proceedsAmount: 'Amount Collected ',
    chujinjine: 'Withdrawal amount',
    tradeFee: 'Handling fees',
    selectPaymentTerm: 'Choose payment method',
    payTypeName: 'payment method ',
    name: 'Name',
    bankAccount: 'Bank account number',
    bankName:'Bank name',
    WeChat: 'WeChat payment',
    Alipay: 'Alipay payment',
    getQr: 'View payment code',
    selectBuyer: 'Select buyer',
    searchBuyer: 'Search for buyers',
    transactionPwd: '交易密码',
    sellNow: 'Sell Now',
    orderNo: 'Order number',
    scheduleStatus: 'Progress status',
    sellName: 'Trading partner',
    sellUser: 'Cashier',
    sellAddress: 'Cash withdrawal address',
    cancelOrder: 'Cancel order',
    rejectOrder: 'Reject',
    verifyProceeds: 'Confirm receipt',
    chujin: 'Confirm',
    toPay: 'Go pay',
    subAppeal: 'Submit an appeal',
    cancelBtn: 'Cancel',
    contactPlatform: 'Contact platform',
    openIm: 'Contact buyer',
    appealDescLabel: 'Reason for appeal',
    confirmBtn: 'Confirm',
    cancel: 'Cancel',
    rules: {
        payTypeId: 'Please select the payment method',
        searchKey: 'Please select a buyer',
        tradePassword: 'Please enter the transaction password',
        appealDesc: 'Please enter the appeal information'
    },
    message: {
        searchUser:'No buyer matches',
        success: 'Operation successful',
        mkOrder: "Order successful! Please wait for the buyer's remittance.",
        orderCancel: 'Order cancelled',
    },
    confirm: {
        hint: 'Tip',
        cancelInfo: 'Cancel current order',
        rejectInfo: 'Reject current order',
        confirmInfo: 'Confirm receipt',
        confirm: 'Confirm',
        cancel: 'Cancel',
    }
}