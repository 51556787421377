<template>
  <div>
    <SmallBanners
      :title="
        clientData
          ? $t('pendingOrders.clientBannerName')
          : $t('pendingOrders.bannerName')
      "
      :info="$t('pendingOrders.bannerInfo')"
    />
    <div class="content">
      <div class="content-head">
        <div class="content-head-btnbox">
          <el-button
            v-if="!clientData"
            type="primary"
            class="content-head-button"
            :plain="formData.orderType != 1"
            @click="transactionTypeChange('1')"
            >{{ $t("pendingOrders.buyBtn") }}</el-button
          >
        </div>
        <div class="content-head-btnbox">
          <el-button
            type="primary"
            class="content-head-button"
            :plain="formData.orderType != 2"
            @click="transactionTypeChange('2')"
            >{{ $t("pendingOrders.sellBtn") }}</el-button
          >
        </div>
      </div>
      <el-form :model="formData" :rules="rules" ref="formData" class="formData">
        <el-form-item
          prop="price"
          ref="elFormItem1"
          class="formData-item formData-itemBorder"
        >
          <el-input
            disabled
            v-model="formData.price"
            class="content-form-area-box-input"
          >
            <template slot="prepend">{{
              formData.orderType == 1
                ? $t("pendingOrders.buyLabelPrice")
                : $t("pendingOrders.sellLabelPrice")
            }}</template>
            <template slot="append">
              <el-select
                ref="currencyType"
                v-model="formData.currency"
                placeholder=""
                @change="selectChege(formData.currency)"
              >
                <el-option
                  v-for="item in currencySelectList"
                  :key="item.id"
                  :value="item.currency"
                >
                  <img
                    :src="item.src"
                    style="
                      width: 20px;
                      height: 20px;
                      vertical-align: text-bottom;
                    "
                  />
                  {{ item.currency }}
                </el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="amount"
          ref=""
          class="formData-item formData-itemBorder"
        >
          <GalaxyNumberInput
            v-model="formData.amount"
            :prepend="
               formData.orderType == 1
                ? $t('pendingOrders.buyLabelAmount')
                : $t('pendingOrders.sellLabelAmount')
            "
            :orderType="formData.orderType"
            :append="'pendingOrders'"
            class="content-form-area-box-input"
          />
        </el-form-item>
        <div class="formData-forBox">
          <div class="formData-item-btns">
            <el-button type="info" class="btn info" @click="subData">{{
              formData.orderType == 1
                ? $t("pendingOrders.newBuyBtn")
                : $t("pendingOrders.newSellBtn")
            }}</el-button>
          </div>
        </div>
        <div class="formData-forBox">
          <div class="formData-itemTitle">
            <span>{{ $t("pendingOrders.switchType") }}</span>
            <span>
              <span>
                {{
                  formData.orderType == 1
                    ? $t("pendingOrders.switchTipBuy")
                    : $t("pendingOrders.switchTipSell")
                }}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                {{ $t("pendingOrders.switchTip") }}
              </span>
            </span>
          </div>
          <div v-for="t in 2" :key="t">
            <div
              class="formData-item-option"
              v-for="(item, index) in currencySwitchList"
              :key="item.currency"
              v-if="
                (t == 1 && index == 0) ||
                (t == 2 && index != 0 && currencySwitchType)
              "
            >
              <div class="formData-item-option-col" @click="showCard">
                <i class="el-icon-arrow-down" v-if="index == 0" />
                <div class="ml" v-else></div>
                <img :src="item.src" alt="" />
                <span class="label">{{ item.currency }}</span>
              </div>
              <div class="formData-item-option-col">
                <el-switch
                  v-model="item.orderType"
                  active-text="ON"
                  inactive-text="OFF"
                  active-color="#019393"
                  inactive-color="#313131"
                  @change="switchChange(item.orderType, item, index)"
                >
                </el-switch>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <PageInfoTXT :infoList="infoList" />
    </div>
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import {
  orderTalkSetInfo,
  updateTradeLimit,
  currencyOnOff,
} from "@/api/pendingOrders";
import { baseURL } from "@/utils/baseURL";
import GalaxyNumberInput from "@/components/galaxyNumberInput.vue";
import obj from "@/utils/common";
export default {
  name: "",
  components: { GalaxyNumberInput },
  data() {
    const formCheck = (rule, value, callback) => {
      if (value || value == 0) {
        callback();
      } else {
        return callback(
          new Error(
            this.$t(
              `pendingOrders.rules.${
                this.formData.orderType == 1 ? "buy" : "sell"
              }${rule.field}`
            )
          )
        );
      }
    };
    return {
      toPath: false,
      beExtant: null,
      clientData: null,
      formData: {
        orderType: 1,
        price: "",
        currency: "KRW",
        amount: "",
      },
      // 设置信息
      rules: {
        price: [{ required: true, validator: formCheck, trigger: "blur" }],
        amount: [{ required: true, validator: formCheck, trigger: "blur" }],
      },
      currencySwitchType: false,
      currencySwitchList: [],
      currencySelectList: obj.nationality,
      infoList: this.$t("pendingOrders.warnInfo"),
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.infoList = this.$t("pendingOrders.warnInfo");
    },
  },
  async created() {
    if (sessionStorage.getItem("clientData")) {
      this.clientData = JSON.parse(sessionStorage.getItem("clientData"));
      this.formData.orderType = 2;
    }
    await this.init();
    await this.selectionEcho(
      this.formData.currency,
      "currencyType",
      this.currencySelectList
    );
  },
  destroyed() {
    sessionStorage.removeItem("clientData");
  },
  methods: {
    tooltipBack(flag, isCancel) {
      if (flag && !isCancel) {
        this.toPath = true;
        this.$router.push(flag);
      }
    },
    init() {
      orderTalkSetInfo().then((res) => {
        if (res.code == 200) {
          this.formData.amount =
            this.formData.orderType == 1
              ? res.data.buyLimit
              : res.data.sellLimit;
          this.info = res.data;
          this.currencySwitchList = [];
          this.currencySelectList = [];
          res.data.userTalkSetVoList.forEach((i) => {
            this.currencySwitchList.push({
              ...i,
              src: baseURL + i.currencyLogo,
              currency: i.currencyName,
              orderType:
                this.formData.orderType == 1
                  ? i.inStatus == 1
                    ? true
                    : false
                  : i.outStatus == 1
                  ? true
                  : false,
            });
          });
          res.data.currencyPriceList.forEach((i) => {
            if (i.symbol == "KRW") {
              if (this.formData.orderType == 1) {
                this.formData.price = i.sellPriceShow;
              } else {
                this.formData.price = i.buyPriceShow;
              }
            }
            this.currencySelectList.push({
              ...i,
              src: baseURL + i.logo,
              label: i.symbol,
              currency: i.symbol,
            });
          });
          this.beExtant = res.data.amount;
          if (this.clientData && this.clientData.amount) {
            this.formData.amount += this.clientData.amount;
          }
          this.$forceUpdate();
        }
      });
    },
    subData() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let data = {
            type: this.formData.orderType,
            amount: this.formData.amount,
          };
          updateTradeLimit(data).then((res) => {
            if (res.code == 200) {
              this.init();
              this.beExtant = this.formData.amount;
              this.clientData = null;
              sessionStorage.removeItem("clientData");
              this.$tooltipPop.open(null, true, {
                type: "success",
                info: [this.$t("pendingOrders.message.success")],
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 滑块 开关
    switchChange(value, item, index) {
      if (this.formData.orderType == 1) {
        this.currencySwitchList[index].inStatus = value ? 1 : 0;
      } else {
        this.currencySwitchList[index].outStatus = value ? 1 : 0;
      }
      this.$forceUpdate();
      let data = {
        type: this.formData.orderType,
        id: item.id,
        onOff: value ? 1 : 0,
      };
      currencyOnOff(data).then((res) => {
        if (res.code == 200) {
          this.$tooltipPop.open(null, true, {
            type: "success",
            info: [this.$t("pendingOrders.message.success")],
          });
        }
      });
    },
    selectChege(value) {
      this.selectionEcho(
        this.formData.currency,
        "currencyType",
        this.currencySelectList
      );
      this.currencySelectList.forEach((i) => {
        if (value == i.symbol) {
          this.formData.price =
            this.formData.orderType == 1 ? i.sellPriceShow : i.buyPriceShow;
        }
      });
    },
    showCard() {
      this.currencySwitchType = !this.currencySwitchType;
    },
    //切换买卖状态
    transactionTypeChange(type) {
      this.formData.orderType = type;
      this.init();
    },
    // 设置下拉选回显图片
    selectionEcho(val, refName, list) {
      let dom = this.$refs[refName].$el.children[0].children[0];
      this.utils.selectionEcho(val, dom, list);
    },
  },
  computed: {},
  beforeRouteLeave(to, from, next) {
    if (this.toPath) {
      next();
      return;
    }
    if (
      this.beExtant !== this.formData.amount &&
      this.clientData &&
      this.clientData.amount
    ) {
      this.$refs['TooltipPop'].openDialog(to.path, false, {
        type: "affirm",
        info: [this.$t("pendingOrders.confirm.authenticationInfo")],
      });
      return;
    } else {
      next();
    }
  },
  destroyed() {
    sessionStorage.removeItem("clientData");
  },
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6vw;
  .content-head {
    width: 100%;
    margin-bottom: 3.541667vw;
    .content-head-btnbox {
      display: inline-block;
      .content-head-button {
        margin-right: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .formData {
    width: 100%;
    position: relative;
    .formData-forBox {
      width: 100%;
      cursor: pointer;
      .formData-item-option {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1vw;
        border: 1px solid #dcdfe6;
        border-radius: 5px;
        position: relative;
        .formData-item-option-col {
          padding: 0 1.5vw;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
          cursor: pointer;
          .ml {
            width: 14px;
            display: inline-block;
          }
          .label {
            margin-left: 44px;
          }
          img {
            width: 24px;
            height: 24px;
            margin: 0 0.5vw;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .formData-item-option-col:first-child {
          flex: 1;
        }
        .formData-item-option-col:last-child {
          width: 100px;
        }
      }
      .formData-itemTitle {
        width: 100%;
        height: 0.9375vw;
        color: #019393;
        display: flex;
        justify-content: space-between;
        padding: 0 1vw;
        box-sizing: border-box;
        transform: translateY(-30%);
      }
      .formData-item-btns {
        margin-bottom: 2vw;
        display: flex;
        justify-content: space-between;
        .btn {
          width: 100%;
        }
        .info {
          background: #000000;
        }
        .info:hover {
          background: rgba(112, 112, 112, 0.98);
        }
        .info:active {
          background: #000000;
        }
      }
    }
  }
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
// --------------------
//修改input样式
::v-deep .el-form-item__content {
  height: 40px !important;
  line-height: 40px !important;
}
// input左侧插槽
::v-deep .el-input-group__prepend {
  width: 18vw !important;
  min-width: 230px !important;
  text-align: center !important;
  color: #000000 !important;
}
// input 右侧插槽
::v-deep .el-input-group__append {
  width: 12vw !important;
  background: #ffffff !important;
  box-sizing: border-box !important;
  .el-select {
    line-height: 40px !important;
  }
  .el-icon-edit:hover {
    color: #019393;
  }
}
::v-deep .el-input__inner {
  cursor: pointer !important;
  width: 100% !important;
}
::v-deep .el-select__caret,
.el-input__icon,
.el-icon-arrow-up {
  cursor: pointer !important;
  width: 100% !important;
}
// --------------------
// 滑块
::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
/*关闭时文字位置设置*/
::v-deep .el-switch__label--left {
  z-index: 1;
  left: 22px;
}
/*显示文字*/
::v-deep .el-switch__label.is-active {
  display: block;
}
::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 55px !important;
}
</style>
