
<template>
  <el-popover ref="myPopover" placement="right" trigger="click">
    <div class="searchBox-pop" id="searchBox">
      <el-form ref="data" :model="data">
        <el-form-item class="searchBox-pop-formItem">
          <div class="searchBox-pop-formItemBox">
            <el-date-picker
              v-model="data.start"
              class="searchBox-pop-formItem-date"
              type="date"
              :placeholder="$t(`searchDialog.srartDate`)"
            >
            </el-date-picker>
            <span>~</span>
            <el-date-picker
              v-model="data.end"
              class="searchBox-pop-formItem-date"
              type="date"
              :placeholder="$t(`searchDialog.endDate`)"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <!-- default -->
        <template v-if="type == 'default'">
          <el-form-item class="searchBox-pop-formItem">
            <div class="searchBox-pop-formItemBox">
              <el-input
                class="searchBox-pop-formItem-input"
                :placeholder="$t(`searchDialog.orderNo`)"
                v-model="data.orderNo"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="searchBox-pop-formItem">
            <div class="searchBox-pop-formItemBox">
              <el-input
                class="searchBox-pop-formItem-input"
                :placeholder="$t(`searchDialog.currencyName`)"
                v-model="data.name"
              ></el-input>
            </div>
          </el-form-item>
        </template>
        <template v-if="type == 'estate'">
          <el-form-item class="searchBox-pop-formItem">
            <div class="searchBox-pop-formItemBox">
              <el-select
                class="searchBox-pop-formItem-input"
                v-model="data.changeType"
                :placeholder="$t(`searchDialog.changeType`)"
              >
                <el-option
                  v-for="item in types"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                />
              </el-select>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <div class="">
        <el-button
          class="searchBox-pop-btn"
          @click="subForm"
          type="primary"
          id="searchSearchBtn"
          >{{ $t(`searchDialog.searchBtn`) }}</el-button
        >
      </div>
      <div class="">
        <el-button
          class="searchBox-pop-btn mt"
          id="searchCanselBtn"
          plain
          @click="canselBtn"
          >{{ $t(`searchDialog.canselBtn`) }}</el-button
        >
      </div>
    </div>

    <div class="searchBox" slot="reference">
      <img
        class="searchImg"
        src="@/assets/transactionOrder/search.png"
        alt=""
        id="popoverSearch"
        @click="popoverSearch('1')"
      />
    </div>
  </el-popover>
</template>

<script>
import moment from "moment";
export default {
  name: "searchPop",
  components: {},
  props: {
    type: {
      type: String,
      default: () => {
        return "default";
      },
    },
  },
  data() {
    return {
      data: {
        start: undefined,
        end: undefined,
        orderNo: "",
        name: "",
        changeType: "",
      },
      types: [
        {
          label: this.$t(`estate.changeTypeShows.RECHARGE`),
          value: "RECHARGE",
          id:'1'
        },
        {
          label: this.$t(`estate.changeTypeShows.BUY`),
          value: "BUY",
          id:'2'
        },
        {
          label: this.$t(`estate.changeTypeShows.SELL`),
          value: "SELL",
          id:'3'
        },
        {
          label: this.$t(`estate.changeTypeShows.WITHDRAW`),
          value: "WITHDRAW",
          id:'4'
        },
        {
          label: this.$t(`estate.changeTypeShows.TRANSFER_IN`),
          value: "TRANSFER_IN",
          id:'5'
        },
        {
          label: this.$t(`estate.changeTypeShows.TRANSFER_OUT`),
          value: "TRANSFER_OUT",
          id:'6'
        },
        {
          label: this.$t(`estate.changeTypeShows.COMMISSION`),
          value: "COMMISSION",
          id:'7'
        },
      ],
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      let list = this.types;
      this.types.forEach((i) => {
        i = {
          ...i,
          label: this.$t(`estate.changeTypeShows.${i.value}`),
        };
      });
    },
    deep: true,
    immediate: true,
  },
  methods: {
    // 查询
    subForm() {
      let data = {
        ...this.data,
      };
      if (this.data.start) {
        data.start= moment(this.data.start).format("YYYY-MM-DD")
      }
      if (this.data.end) {
        data.end= moment(this.data.end).format("YYYY-MM-DD")
      }
      this.$emit("popoverSearch", data);
      this.canselBtn();
    },
    //点击控制弹框展示/关闭
    popoverSearch(val) {
      this.resetData();
    },
    canselBtn() {
      this.resetData();
      document.body.click();
    },
    resetData() {
      this.data = {
        start: undefined,
        end: undefined,
        orderNo: "",
        name: "",
        changeType: "",
      };
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.searchImg {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.searchBox {
  margin-top: 20px;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
}
.searchBox-pop {
  width: 334px;
  .searchBox-pop-formItem {
    width: 100%;
    .searchBox-pop-formItemBox {
      display: flex;
      justify-content: space-between;
      span {
        padding: 0 10px;
      }
      .searchBox-pop-formItem-date {
        width: 45%;
      }
      .searchBox-pop-formItem-input {
        width: 100%;
      }
    }
  }
  .searchBox-pop-btn {
    width: 100%;
  }
  .mt {
    margin-top: 20px;
  }
}
</style>
