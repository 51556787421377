<template>
  <div>
    <SmallBanners :title="$t('clientSell.bannerName')" :info="$t('clientSell.bannerInfo')" />
    <Sell1 v-if="pageType == 1" @pageChange="pageChange" />
    <Sell2 v-if="pageType == 2" @pageChange="pageChange" />
  </div>
</template>

<script>
import Sell1 from "./sell1.vue";
import Sell2 from "./sell2.vue";
export default {
  components: { Sell1, Sell2 },
  data() {
    return {
      pageType: 1
    };
  },
  methods: {
    pageChange(type) {
      this.pageType = type;
    },
  },
  destroyed() {
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("checkResData");
  },
};
</script>

<style>
</style>