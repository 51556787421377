/**
* ko
*/
export default {
    bannerName: '대리상 센터',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    header: {
        invite: '초대사용자 수량',
        allEarnings: '총수익',
        monthEarnings: '월수익',
        dayEarnings: '어제수익',
    },
    tabel: {
        loginName: '계정ID',
        transactionAmount: '거래금액',
        earningsAmount: '수익금액',
        createTime: '등록시간',
    }
}