/**
* ko
*/
export default {
    bannerName: '입출금내역',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    header: {
        inviterUserNumber: '초대사용자 수량',
        totalRevenue: '총수익',
        monthlyIncome: '월수익',
        btns: {
            buyBtn: '입금 ',
            sellBtn: '출금 ',
        }
    },
    content: {
        tabelBtns: {
            all: '전부',
            buyAamount: '입금중',
            sellamount: '출금신청',
            overtime: '취소',
            arbitration: '중재중',
            reject: '거절',
            accomplish: '완성',
        },
        tabel: {
            orderNo: '주문번호',
            status: '상태',
            buyAmount: '지불금액',
            sellAmount: '출금금액',
            buyUser: '입금인',
            sellUser: '출금인',
            tradeRate: '환율',
            buyNum: '입금수량',
            sellNum: '출금수량',
            transactionObject: '거래대상',
            creationTime: '시간',
            options: '실행',
            search: '조회',
        },
    }
};
