<template>
  <div class="loginBody">
    <div class="contentBox">
      <div class="contentBox-header">
        <img src="@/assets/brands.png" alt="" />
        <span class="contentBox-header-name">Umarket</span>
      </div>
      <!-- 登录 -->
      <template v-if="$route.path == '/logIn'">
        <div class="contentBox-form">
          <el-form
            :model="formData"
            :rules="logInRules"
            ref="formData"
            label-width="0px"
          >
            <el-form-item prop="loginName">
              <el-input
                :placeholder="$t('logInAndResetPasswords.loginName')"
                v-model="formData.loginName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                show-password
                :placeholder="$t('logInAndResetPasswords.password')"
                v-model="formData.password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="validateCode">
              <el-input
                :placeholder="$t('logInAndResetPasswords.validateCode')"
                class="contentBox-form-input"
                v-model="formData.validateCode"
              ></el-input>
              <img
                src=""
                ref="captchaImage"
                class="contentBox-form-codeImg"
                @click="getCodeImgUrl"
                alt=""
              />
            </el-form-item>
            <div class="interval" />
          </el-form>
          <div>
            <el-button
              type="primary"
              class="contentBox-resetBtn"
              ref="logInSubBtn"
              @click="formDataFun()"
              >{{ $t("logInAndResetPasswords.logInBtn") }}</el-button
            >
          </div>
          <div>
            <el-link :underline="false" @click="toResetPwd">{{
              $t("logInAndResetPasswords.forgetThePassword")
            }}</el-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login";
export default {
  name: "logIn",
  components: {},
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        return callback(
          new Error(this.$t("logInAndResetPasswords.logInRules." + rule.field))
        );
      }
    };
    return {
      formData: {
        loginName: "",
        password: "",
        validateCode: "",
      },
      logInRules: {
        loginName: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        validateCode: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["formData"].resetFields();
    },
  },

  created() {},
  mounted() {
    this.getCodeImgUrl();
    this.keydown(this);
    this.$refs["formData"].resetFields();
  },
  methods: {
    // 获取图片验证码
    getCodeImgUrl() {
      let time = setTimeout(() => {
        let url = "/api/v1/captcha/captchaImage?type=math&s=" + Math.random();
        this.$refs["captchaImage"].src = url;
        clearTimeout(time);
      }, 1);
    },
    // 登录提交
    formDataFun() {
      this.$nextTick(() => {
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            let data = this.formData;
            login(data).then((res) => {
              if (res.code == 200) {
                this.$store.dispatch("userInfo/setUserInfo", res.data);
                sessionStorage.setItem("userType", res.data.userType);
                if (res.data.certState == 0 || res.data.certState == 3) {
                  sessionStorage.setItem("logIn", "true");
                }
                this.$router.push({
                  path: "/",
                });
                this.$refs["formData"].resetFields();
              }
            });
          } else {
            return false;
          }
        });
      });
    },
    toResetPwd() {
      this.$nextTick(() => {
        window.open("/resetPasswords", "_blank");
      });
    },
    keydown() {
      let that = this;
      //所有键盘按下触发
      document.addEventListener(
        "keydown",
        function (e) {
          var event = e || window.event; // e:非IE浏览器使用，window.event是IE浏览器使用
          if (event.keyCode == 13) {
            that.formDataFun();
          }
        },
        false
      );
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.loginBody {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/login/logInBG.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.contentBox {
  width: 40vw;
  background: #eaecf0;
  border-radius: 1.5625vw;
  padding: 6.14583vw 6.51042vw;
  margin: auto;
  .contentBox-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.92708vw;
    img {
      width: 2.60416vw;
      height: 2.60416vw;
      margin-right: 0.88542vw;
    }
    .contentBox-header-name {
      font-size: 2.39583vw;
      font-weight: bold;
    }
  }
  .contentBox-option {
    display: flex;
    justify-content: center;
    margin-bottom: 5vw;
    .content-head-button {
      width: 11.45833vw;
    }
  }
  .contentBox-form {
    justify-content: space-between;
    .contentBox-form-input {
      width: 75%;
    }
    .contentBox-form-sendCode {
      width: calc(25% - 8px);
      margin-left: 8px;
      text-align: center;
    }
    .contentBox-form-codeImg {
      width: calc(25% - 8px);
      height: 36px;
      position: absolute;
      top: 2px;
      right: 0;
      cursor: pointer;
    }
  }
  .contentBox-resetBtn {
    margin-bottom: 1vw;
    width: 100%;
  }
}
.interval {
  height: 2.65625vw;
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
</style>
