<template>
  <div>
    <div :class="$style.container">
      <!-- IMUIKIT 相关内容 -->
      <!-- <div :class="$style.header">
        <div :class="$style.search" ref="search" />
        <div :class="$style.add" ref="add" />
        <div :class="$style.close" ref="close" @click.stop="logOut">
          <i class="el-icon-close"></i>
        </div>
      </div> -->
      <div :class="$style.content">
        <div :class="$style.left">
          <div :class="$style['avatar-icon']" ref="avatar" />
          <div
            :class="{
              [$style['chat-icon']]: true,
              [$style.active]: model === 'chat',
            }"
            @click="() => (model = 'chat')"
          >
            <i
              :class="{
                [$style['iconfont']]: true,
                iconfont: true,
                'icon-message_fill_light': true,
              }"
            />
            <div :class="$style['icon-label']">
              {{ $t("im.conversation") }}
            </div>
          </div>
          <div v-if="false"
            :class="{
              [$style['contact-icon']]: true,
              [$style.active]: model === 'contact',
            }"
            @click="() => (model = 'contact')"
          >
            <i
              :class="{
                [$style['iconfont']]: true,
                iconfont: true,
                'icon-tongxunlu1': true,
              }"
            />
            <div :class="$style['icon-label']">{{ $t("im.addressBook") }}</div>
          </div>
           <div :class="$style['logout-icon']" v-if="false" @click="logOut">
            <i
              :class="{
                [$style['iconfont']]: true,
                iconfont: true,
                'icon-logout': true,
              }"
            />
            <div :class="$style['icon-label']">退出</div>
          </div>
        </div>
        <div :class="$style.right" v-show="model === 'chat'">
          <div :class="$style['right-list']" ref="conversation" />
          <div :class="$style['right-content']" ref="chat" />
        </div>
        <div :class="$style.right" v-show="model === 'contact'">
          <div :class="$style['right-list']" ref="contactList" />
          <div :class="$style['right-content']" ref="contactInfo" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ConversationContainer, // 会话列表组件
  ChatContainer, // 聊天（会话消息）组件
  AddContainer, // 搜索——添加按钮组件
  SearchContainer, // 搜索——搜索组件
  ContactListContainer, // 通讯录——通讯录导航组件
  ContactInfoContainer, // 通讯录——通讯录详情组件，包含好友列表、群组列表以及黑名单列表
  MyAvatarContainer, // 用户资料组件
} from "@xkit-yx/im-kit-ui";
import { compile } from "jsx-web-compiler";
import "@xkit-yx/im-kit-ui/es/style/css";
import "./iconfont.css";
import { autorun } from "mobx";

export default {
  name: "IM",
  props: {
    accId: {
      default: "",
    },
  },
  data() {
    return {
      model: "chat",
      time: null,
      timeNum: 0,
    };
  },
  watch: {
    deep: true,
    immediate: true,
  },
  async mounted() {
    await this.render();
  },
  methods: {
    render() {
      // this.$uikit.render(
      //   SearchContainer,
      //   {
      //     onClickChat: () => {
      //       this.model = "chat";
      //     },
      //   },
      //   this.$refs.search
      // );
      // this.$uikit.render(
      //   AddContainer,
      //   {
      //     onClickChat: () => {
      //       this.model = "chat";
      //     },
      //   },
      //   this.$refs.add
      // );
      console.log('MyAvatarContainer11111',this.$uikit)
      this.$uikit.render(MyAvatarContainer, null, this.$refs.avatar);
      this.$uikit.render(ConversationContainer, {}, this.$refs.conversation);
      this.$uikit.render(
        ChatContainer,
        {
          teamSettingActions: [],
          p2pSettingActions: [],
          // 以下是自定义渲染，用 compile 函数包裹 html 就可以了，注意 class 要写成 className
          // 安装并引入： import { compile } from "jsx-web-compiler";
          renderHeader:(options) => {

          },
          // 空状态
          renderEmpty: () =>
            compile(`
              <div className='ChatContainerInfo'>
                <img className='chatImg'  style={{width:'100px',height: '100px',position: 'absolute',top: '200px',left: '580px',}} src='${
                  this.img
                }'/>
                <div style={{width:'200px',position: 'absolute',top: '300px',left: '540px',fontSize: '20px',}}>Welcome to Umarket</div>
              </div>
          `),
          // 发送信息输入提示
          renderP2pInputPlaceHolder: (options) => {
            if (window.__xkit_store__) {
              const { store, nim } = window.__xkit_store__;
              return (
                this.$t("im.sendInfo") +
                store.uiStore.getAppellation({ account: options.to }) +
                "..."
              );
            } else {
              return this.$t("im.sendInfo") + "...";
            }
          },
        },
        this.$refs.chat
      );
      this.$uikit.render(
        ContactListContainer,
        {
          renderCustomContact: (contactType) => {
            console.log(
              "==========renderCustomContact===========",
              contactType
            );
            if (contactType === "groupList") {
              return compile(`<div></div>`);
            } else {
              return null;
            }
          },
        },
        this.$refs.contactList
      );
      this.$uikit.render(
        ContactInfoContainer,
        {
          afterSendMsgClick: () => {
            this.model = "chat";
          },
          onGroupItemClick: () => {
            this.model = "chat";
          },
          renderEmpty: () =>
            compile(`
              <div className='ChatContainerInfo'>
                <img className='chatImg'  style={{width:'100px',height: '100px',position: 'absolute',top: '200px',left: '595px',}} src='${
                  this.img
                }'/>
                <div style={{width:'160px',position: 'absolute',top: '300px',left: '555px',fontSize: '20px',whiteSpace: 'nowrap',textAlian: 'center'}}>${this.$t(
                  "im.info"
                )}</div>
              </div>
          `),
        },
        this.$refs.contactInfo
      );
      autorun(() => {
        const store = window.__xkit_store__.store;
        let unReadCount = store.uiStore.sessionUnread || 0;
        this.$store.dispatch("common/setUnReadCount", unReadCount);
      });
      this.init();
    },
    init() {
      if (!this.$store.state.common.IM || !this.accId) return;
      console.log("IM__accid", this.accId);
      const { store, nim } = window.__xkit_store__;
      let time = setTimeout(() => {
        clearTimeout(time);
        if (store) {
          let sessionData = null;
          store.uiStore.sessionList.forEach((i) => {
            if (i.to == this.accId) {
              sessionData = i;
            }
          });
          // 如果没有需要的对话 则插入
          if (!sessionData && this.accId) {
            store.sessionStore.insertSessionActive("p2p", this.accId, true);
          }
          // 打开对话
          let sessIonTime = setTimeout(() => {
            store.uiStore.selectedSession = "p2p-" + this.accId;
            clearTimeout(sessIonTime);
          }, 0);
        } else {
          this.init();
        }
      }, 1000);
    },
    logOut() {
      this.$store.dispatch("common/setIM", { bool: null, accId: null });
    },
  },
  computed: {
    img() {
      return require("../../assets/im/brands.png");
    },
  },
};
</script>

<style module scoped>
.chat-action-wrap{
  display: none !important;
}
.container {
  width: 960px;
  height: 540px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px;
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  background: #fff;
}

.header {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e8e8e8;
}
.search {
  width: 50%;
}

.add {
  margin-left: 20px;
  display: none;
}
.close {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 18px;
}
.close:hover {
  cursor: pointer;
  color: #019393;
}

.content {
  width: 100%;
  height: 538px;
  display: flex;
  position: relative;
}

.left {
  min-width: 60px;
  border-right: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 8px;
}
.conversation {
  text-align: center;
}

.avatar-icon {
  margin: 20px 0 25px 0;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
}

.iconfont {
  font-size: 24px;
}

.chat-icon,
.contact-icon {
  margin: 0 0 25px 0;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.active {
  color: #2a6bf2;
}

.logout-icon {
  position: absolute;
  bottom: 10px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.icon-label {
  font-size: 12px;
  text-align: center;
}

.right {
  flex: 1;
  display: flex;
}

.right-list {
  width: 200px;
  border-right: 1px solid #e8e8e8;
}

.right-content {
  flex: 1;
}
/* .ant-alert {
  display: none !important;
}
.ant-alert-warning {
  display: none !important;
}
.ant-alert-banner {
  display: none !important;
}
.ant-alert {
  display: none !important;
}
.ant-alert {
  display: none !important;
}
.ant-alert {
  display: none !important;
}
.ant-alert {
  display: none !important;
} */
/* .chat-message-list-stranger-noti.ant-alert{
  display: none;
} */
</style>
