/**
* zh_CN
*/
export default {
  bannerName: '出入金订单',
  bannerInfo: '资金安全, 快捷, 方便 随时交易',
  header: {
    inviterUserNumber: '邀请用户数量',
    totalRevenue: '总收益',
    monthlyIncome: '月收益',
    btns: {
      buyBtn: '入金',
      sellBtn: '出金',
    }
  },
  content: {
    tabelBtns: {
      all: '全部',
      buyAamount: '入金中',
      sellamount: '出金申请',
      overtime: '已取消',
      arbitration: '仲裁中',
      reject: '已驳回',
      accomplish: '已完成',
    },
    tabel: {
      orderNo: '订单号',
      status: '状态',
      buyAmount: '支付金额',
      sellAmount: '出金金额',
      buyUser: '入金人',
      sellUser: '出金人',
      tradeRate: '汇率',
      buyNum: '入金数量',
      sellNum: '出金数量',
      transactionObject: '交易对象',
      creationTime: '时间',
      options: '操作',
      search: '查看',
    },
  }
};