<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="41.66667vw"
      :before-close="handleClose"
    >
      <div class="title">
        {{
          diaLogType
            ? $t("userInfo.accountInfo.phone.diaLog.resetTitle")
            : $t("userInfo.accountInfo.phone.diaLog.title")
        }}
      </div>
      <div class="content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          class="formData"
          label-position="left"
        >
          <el-form-item
            :label="$t('userInfo.accountInfo.phone.diaLog.phone')"
            prop="phone"
          >
            <el-input
              v-model="formData.phone"
              placeholder=""
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="$t('userInfo.accountInfo.phone.diaLog.code')"
            prop="code"
          >
            <el-input
              v-model="formData.code"
              placeholder=""
              class="formData-inputCode"
            />
            <div class="formData-sendCodeBox">
              <el-button
                type="primary"
                class="formData-sendCode"
                :disabled="this.sendCodecountDown != 120"
                @click="sendCode"
                >{{
                  this.sendCodecountDown != 120
                    ? `${$t("userInfo.accountInfo.phone.diaLog.newCodeBtn")}(${
                        this.sendCodecountDown
                      })`
                    : $t("userInfo.accountInfo.phone.diaLog.codeBtn")
                }}</el-button
              >
            </div>
          </el-form-item>
          <div class="formData-subBtn">
            <el-button
              type="primary"
              class="formData-subBtn-btn"
              @click="submitForm"
              >{{ $t("userInfo.accountInfo.phone.diaLog.subBtn") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sendPhoneCode } from "@/api/common";
import { setPhone } from "@/api/userInfo";
export default {
  name: "",
  data() { 
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        return callback(
          new Error(
            this.$t("userInfo.accountInfo.phone.diaLog.rules." + rule.field)
          )
        );
      }
    };
    return {
      dialogVisible: false,
      sendCodecountDown: 120,
      sendCodeTime: null,
      parentInfo: null,
      diaLogType: false,
      formData: {
        phone: "",
        code: "",
      },
      rules: {
        phone: { required: true,validator: formRulesCheck, trigger: "blur" },
        code: { required: true, validator: formRulesCheck, trigger: "blur" },
      },
    };
  },
  methods: {
    opendialog(userInfo, type) {
      this.diaLogType = type;
      this.parentInfo = userInfo;
      this.dialogVisible = !this.dialogVisible;
    },
    submitForm() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let data = new FormData();
          data.append("code", this.formData.code);
          data.append("actionType", 3);
          data.append("phone", this.formData.phone);
          setPhone(data).then((res) => {
            if (res.code == 200) {
              this.dialogVisible = false;
              this.$emit("diaLogCallBack", null);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      this.$refs["formData"].validateField(["phone"], (valid, vv) => {
        if (!valid) {
          let data = {
            actionType: 3, // 1:注册验证码，2：找回密码发送验证码,3:个人中心绑定手机号
            countryCode: this.parentInfo.countryCode,
            phoneNumber: this.formData.phone,
          };
          sendPhoneCode(data).then((res) => {
            this.startTime();
          });
        }
      });
    },
    startTime() {
      this.sendCodeTime = setInterval(() => {
        this.sendCodecountDown -= 1;
        if (this.sendCodecountDown == 0) {
          clearInterval(this.sendCodeTime);
          this.sendCodecountDown = 120;
        }
      }, 1000);
    },
    handleClose(done) {
      done();
      this.resetData();
    },
    resetData() {
      this.$refs["formData"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  font-size: 1.30208vw;
  font-weight: bold;
  margin-top: -1.5625vw;
}
.content {
  padding-top: 1vw;
  .formData {
    .formData-input {
      width: calc(100% - 120px);
    }
    .formData-inputCode {
      width: calc(100% - 120px - 20%);
    }
    .formData-sendCodeBox {
      width: calc(20% - 1vw);
      margin-left: 1vw;
      display: inline-block;
      .formData-sendCode {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .formData-subBtn {
      width: 100%;
      margin-top: 2.23958vw;
      // margin-bottom: 1.6vw;
      display: flex;
      justify-content: center;
      &-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
::v-deep .el-form-item__label {
  width: 120px !important;
  padding-left: 1vw !important;
  font-weight: bold !important;
  background: #f8f8f8 !important;
}
::v-deep .el-input__inner {
  border: none !important;
  background: #f8f8f8 !important;
}
::v-deep .el-dialog__body {
  // padding-top: 0 !important;
}
</style>
