/**
* zh_CN
*/
export default {
    sellScheduleStatusStart0: '等待买家付款',
    buyScheduleStatusStart0: '按照卖家收款信息汇款,汇款好点击已汇款。',
    sellScheduleStatusStart1: '订单已取消',
    buyScheduleStatusStart1: '订单已取消',
    sellScheduleStatusStart2: '买家已付款',
    chujinScheduleStatusStart2: '等待您确认出金',
    buyScheduleStatusStart2: '等待卖家放币',
    sellScheduleStatusStart3: '订单已完成',
    buyScheduleStatusStart3: '已完成',
    sellScheduleStatusStart4: '申诉中',
    buyScheduleStatusStart4: '申诉中',
    sellScheduleStatusStart5: '申诉驳回',
    buyScheduleStatusStart5: '申诉驳回',
    sellScheduleStatusStart6: '已拒绝',
    buyScheduleStatusStart6: '已拒绝',
    UNPAID_PAYMENTS: '待收款',
    NO_PAY: '未支付',
    CANCELED: '已取消',
    PENDING_PAYMENT: '确认收款',
    PAID_PAYMENTS: '待收USDT',
    SUCCESS: '已完成',
    IN_APPEAL: '申诉中',
    REJECTED: '已拒绝',
    APPLY_WITHDRAW: '出金申请',
    AWAIT_SEND_COIN: '待放币',
    AWAIT_RECEIVE_COIN: '待收USDT',
    APPLY_DEPOSIT: '入金申请',
}