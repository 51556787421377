/**
* ko
*/
export default {
    bannerName: '자산',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    header: {
        title: '자산내역',
        item1: {
            name: '보유금액'
        },
        item2: {
            name: '동결금액'
        },
        btms: {
            recharge: '충전',
            rechargeRecord: '충전내역',
            withdraw: '출금',
            withdrawRecord: '출금내역',
        }
    },
    content: {
        changeTypeShow: '거래유형',
        currency: '화폐종류',
        changeAmount: '수량',
        createTime: '시간',
        withdrawalQuantity: '출금수량',
        address: '출금주소',
        rechargeQuantity: '충전수량',
        rechargeAddress: '충전주소',
    },
    extractdDialog: {
        title: '출금',
        chainId: '체인이름',
        address: '출금주소',
        amount: '출금수량',
        serviceCharge: '수수료 0-10',
        toTheAccount: '입금수량',
        tradePass: '결제 비밀번호 입력.！',
        fundPassword: '결제 비밀번호 확인',
        subBtn: '확인',
        withdraw: '출금',
        warnTitle: '알림',
        warninfo1: '최소 출금가능 수량  : ',
        warninfo2: '자금의 안전을 보장하기 위해 사용자의 계좌변경 비밀번호 변경시 당사는 관리자가 심사 할 것입니다. ',
        warninfo3: '문자 또는 메일로 사용자에게 연락 할 때까지 기다려주십시오.',
        warninfo4: '정보가 변조되거나 유출되지 않도록 컴퓨터와 브라우저가 안전한지 확인하십시오.',
        rules: {
            chainId: "체인이름을 입력 하십시오",
            address: "출금주소를 입력 하십시오",
            amount: '전송수량을 입력 하십시오',
            serviceCharge: " 수수료       ",
            toTheAccount: "입금수량을 입력 하십시오",
            tradePass: '결제 비밀번호 입력.！',
            fundPassword: '결제 비밀번호 확인',
            balance: '출금수량이 보유금액보다 클수 없습니다',
            isNaN: '숫자를 입력하십시오. 최소값은 ',
        },
        subsuccess: '실행성공',
    },
    rechargeDialog: {
        chainId: '체인이름',
        title: '충전',
        rechargeAddress: '충전주소',
        warnTitle: '알림',
        warninfo1: '위 지갑주소에 USDT가 아닌 다른 자산을 입금하지 마십시오. 자산을 되찾을 수 없습니다.',
        warninfo2: '최소 입금액 10USDT, 최소 입금액 미만의 입금액은 계좌에 입금되지 않으며 환불이 불가능합니다.',
        warninfo3: '사용자의 충전주소는 자주 바뀌지 않으며 중복 충전을 할수 있으며 변경이 있으면 문자 혹은 메일로 알려 드립니다.',
        warninfo4: '정보가 변조되거나 유출되지 않도록 컴퓨터와 브라우저가 안전한지 확인하십시오.',
    },
    changeTypeShows: {
        RECHARGE: '충전',
        BUY: '매입',
        SELL: '매도',
        WITHDRAW: '출금',
        TRANSFER_IN: '입금',
        TRANSFER_OUT: '출금',
        COMMISSION: '수수료',
    }
};