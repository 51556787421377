import { IMUIKit } from "@xkit-yx/im-kit-ui";
import RootStore from '@xkit-yx/im-store'
import { NimKitCoreFactory, NimKitCoreTypes } from '@xkit-yx/core-kit'
import Vue from "vue";
import $store from "@/store";
import localeConfig from '@/utils/localeConfig'
const createIm = (falg) => {
    console.log('IM__falg__初始化调用', falg)
    if (falg == 'reset') {
        destroyIm(falg)
        return
    }
    if (Vue.prototype.$uikit) {
        return
    }
    if (
        // 条件判断是否初始化建立连接
        $store.state.userInfo.userInfo &&
        $store.state.userInfo.userInfo.accId &&
        $store.state.userInfo.userInfo.accToken) {
        console.log("IM__初始化前", Vue.prototype.$uikit)
        console.log("IM__初始化前", window.__xkit_store__)
        const initOptions = {
            appkey: "88c51e70524ce164a7c3465ac9321e33", // 请填写你的appkey
            account: $store.state.userInfo.userInfo.accId, // 请填写你的account
            token: $store.state.userInfo.userInfo.accToken, // 请填写你的token
            needReconnect: true, //是否需要自动重连，默认为 true
            // -------------------------------------------------------
            needReconnect: true, // 是否开启断网自动重连，非必传
            debug: false, // 是否开启日志，将其打印到console。集成开发阶段建议打开。
            onconnect: onConnect, // 成功回调
            onwillreconnect: onWillReconnect, //断开
            ondisconnect: onDisconnect, //SDK异常回调 // 跳转登录
            onerror: onError, //错误回调
        };
        const localOptions = {
            // 单聊消息是否显示已读未读 默认 false
            p2pMsgReceiptVisible: true,
            // 群聊消息是否显示已读未读 默认 false
            teamMsgReceiptVisible: true,
            // 是否显示在线离线状态 默认 true
            loginStateVisible: false,
            // 添加好友模式，默认需要验证
            allowTransferTeamOwner: false,
            // 添加好友模式，默认需要验证
            addFriendNeedVerify: true,
            // 是否需要@消息 默认 true
            needMention: true,
            // p2p会话列表消息已读未读，默认 false
            p2pMsgReceiptVisible: true,
        };
        console.log('IM__common.i18n', $store.state.common.i18n)
        // 全局挂载实例
        Vue.prototype.$uikit = new IMUIKit({
            initOptions,
            singleton: true,
            sdkVersion: 1,
            localOptions,
            localeConfig: localeConfig[`${$store.state.common.i18n}`]
        });
        if (Vue.prototype.$uikit) {
            console.log('IM__初始化成功',falg, Vue.prototype.$uikit)
            return falg||falg == 'resetIM' || falg == 'init' || falg == 'reset' ? true : false
        }
        function onConnect() {
            console.log("IM__onConnect连接成功");
        }
        function onWillReconnect(obj) {
            // 此时说明 SDK 已经断开连接, 请开发者在界面上提示用户连接已断开, 而且正在重新建立连接
            console.log("IM__即将重连");
            console.log(obj.retryCount);
            console.log(obj.duration);
        }
        function onDisconnect(error) {
            // 此时说明 SDK 处于断开状态, 开发者此时应该根据错误码提示相应的错误信息, 并且跳转到登录页面
            console.log("IM__丢失连接");
            console.log(error);
            if (error) {
                switch (error.code) {
                    // 账号或者密码错误, 请跳转到登录页面并提示错误
                    case 302:
                        break;
                    // 重复登录, 已经在其它端登录了, 请跳转到登录页面并提示错误
                    case 417:
                        break;
                    // 被踢, 请提示错误后跳转到登录页面
                    case "kicked":
                        break;
                    default:
                        break;
                }
            }
        }
        function onError(error) {
            console.log(error);
        }
    }
}
//手动调用 nim.disconnect 方法或 nim.destroy 方法后，会直接触发 SDK 实例的 ondisconnect 回调函数，但此时长连接并未真正销毁。
//只有在 done 回调函数触发的时候，才能保证长连接真正销毁（onclose 状态）
const destroyIm = (flag) => {
    console.log('IM_destroyIm__前__flag', flag)
    if (!window.__xkit_store__) {
        return
    }
    // 手动释放 uikit 实例内存  
    window.__xkit_store__.store.destroy();
    window.__xkit_store__.nim.destroy();
    // 这里根据 sdkVersion 传入 1 或 2
    const NIM = NimKitCoreFactory(1)
    RootStore.ins = undefined
    NIM.ins = undefined
    window.__xkit_store__ = undefined
    Vue.prototype.$uikit = undefined
    console.log('IM__卸载成功', Vue.prototype.$uikit)
    console.log('IM__卸载成功', window.__xkit_store__)
    if (flag) {
        createIm('resetIM')
    }
}
// 设置默认会话
const setDefaultSession = (sessionId) => {
    const { store, nim } = window.__xkit_store__;
    store.uiStore.selectSession(sessionId).then(res => {
        console.log('IM__设置默认会话成功')
    })
}
// 断开连接
const exitIm = () => {
    const { store, nim } = window.__xkit_store__;
    nim.disconnect().then(() => {
        console.log("IM__断开连接成功");
        // location.reload()
    });
}
//该方法必须在调用exitIm方法登出 IM 后，才能调用。
const connectIm = () => {
    const { store, nim } = window.__xkit_store__;
    nim.connect().then(() => {
        console.log("IM__连接成功");
    });
}

// 
const init = () => {

}
export default {
    createIm,
    setDefaultSession,
    destroyIm,
    exitIm,
    connectIm,
    init,
}