<template>
  <div class="footerInfo">
    <div v-for="(i, ind) in infoList" :key="ind" class="footerInfo-item">
      <div class="footerInfo-name">{{ i.title }}</div>
      <div class="footerInfo-value">
        <div v-for="(ival, iind) in i.values" :key="iind">
          {{ ival }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    infoList: {
      type: Array,
        required: true,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style scoped lang='scss'>
.footerInfo {
  margin-top: 4vw;
  width: 100%;
  min-height: 100px;
  padding: 1.5vw 1.5vw;
  box-sizing: border-box;
  background: #f2f2f2;
  font-size: 14px;
  .footerInfo-item {
    padding-bottom: 14px;
    .footerInfo-name {
      color: #019393;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .footerInfo-value {
      div {
        line-height: 1.6vw;
      }
    }
  }
  .footerInfo-item:first-child {
    margin-top: 0;
  }
}
</style>
