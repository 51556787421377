<template>
  <div id="app">
    <layout />
    <IMDialog ref="IMDialog" />
  </div>
</template>
<script>
import layout from "@/layout/index.vue";
import IMDialog from "@/views/IM";
export default {
  components: { layout, IMDialog },
  mounted() {
    console.log("window.innerWidthwindow.innerWidthwindow.innerWidth",window.innerWidth)
    if(this.isMobile()) {
      if (this.$route.name === '入金') {
        window.location.href = "https://www.umarket.cc/h5/#/pages/client/buy?shopId="+this.$route.params.shopId
      }else if (this.$route.name === '出金') {
        window.location.href = "https://www.umarket.cc/h5/#/pages/client/sell?shopId="+this.$route.params.shopId
      }else {
        window.location.href = 'https://www.umarket.cc/h5/'
      }
    }
  },
  methods: {

    isMobile() {
      // 判断是否为移动设备
      return (
          typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上一般存在
          navigator.userAgent.indexOf('IEMobile') !== -1 || // 判断是否为Windows Phone
          navigator.userAgent.indexOf('iPhone') !== -1 || // 判断是否为iPhone
          navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1 || // 判断是否为Android手机
          navigator.userAgent.indexOf('BlackBerry') !== -1 || // 判断是否为BlackBerry
          navigator.userAgent.indexOf('Opera Mini') !== -1 // 判断是否为Opera Mini浏览器
      );
    }
  }
};
</script>

<style lang="scss">
/* 全局CSS文件或main.js中的样式标签 */
@font-face {
  font-family: "myFont";
  src: url("./assets/font/Pretendard-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-user-select: none;
  // -ms-user-select: none;
  // -moz-user-select: none;
  // -khtml-user-select: none;
  // user-select: none;
}
// 隐藏浏览器滚动条
html {
  overflow: -moz-hidden-unscrollable;
  height: 100%;
}
body {
  -ms-overflow-style: none;
  overflow: auto;
  box-sizing: border-box;
  // overflow-x: hidden;
  margin: 0;
  padding: 0;
  background: #ffffff;
  color: #313131;
}
body::-webkit-scrollbar {
  display: none;
}
.chat-message-list-stranger-noti{
  display:none !important;
}
</style>
