/**
* zh_CN
*/
export default {
    bannerName: '마이페이지',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    title: '본인인증',
    titleInfo: '*안전한 거래를 위하여 아래 필수 사항을 작성하여 주십시오 ',
    certType: '신분증유형',
    realName: '성명',
    dateOfBirth: '생년월일 (1900-00-00)',
    certImage: '신분증 이미지 업로드',
    info: {
        title: '주의사항:',
        item1: '1.반드시 신분증 원본 앞면이 촬영된 사진을  제출하십시오.',
        item2: '2..빛 반사, 흔들림, 영역 잘림 등으로 정확한 신분증 인식이 어려울 수 있습니다.',
        item3: '3. 훼손이 심한 신분증은 거절될 수도 있습니다.',
    },
    subBtn: '제출',
    idTypes: {
        item1: '주민등록증',
        item2: '여권',
        item3: '운전면허증',
        item4: '외국인등록증',
    },
    rules: {
        certType: '신분증 유형을 선택 하십시오',
        realName: '성명을 입력 하십시오',
        dateOfBirth: '생년월일을 선택 하십시오',
        certImage: '신분증 이미지 업로드 하십시오',
    }
};