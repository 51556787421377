/**
* zh_CN
*/
export default {
    bannerName: '快捷交易中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    buyBtn: '购买',
    buyPrice: '购买金额',
    buyAmount: '购买数量',
    buyAmountPlaceholder: '一次购买范围最少10usdt ~ 最多7000usdt',
    sellBtn: '出售',
    sellPrice: '出售金额',
    sellAmount: '出售数量',
    subBuybtn: '购买',
    subSellbtn: '出售',
    placeholder:'请输入内容',
    rules: {
        buyPrice: '请输入购买价格',
        buyAmount: '请输入购买数量',
        sellPrice: '请输入出售价格',
        sellAmount: '请输入出售数量',
        type: '请选择类型'
    },
    buyWarnInfo: [
        {
            title: "注意事项",
            values: [
                `1. 每次交易都是由平台自动配对卖方。卖方的账户信息每次都可能不一样，请根据卖方账户信息汇款。`,
                `2. 用户要求购买USDT，同时卖方的USDT将被冻结，可以安全交易。`,
                `3. 汇款时请把备注栏打空。(绝对禁止虚拟货币，USDT，比特币，外汇交易)卖方可以拒绝交易。`,
                `4. 用户因账户信息错误而存入错误的金额，平台不负责。`,
                `5. 必须用登记的本人银行账户购买。否则你购买的USDT会被冻结，无法提币。`,
                `6. 运营时间- 9:00~21:00运营时间以外的交易，卖方可能会延迟处理。`,
            ],
        },
    ],
    sellWarnInfo: [
        {
            title: "注意事项",
            values: [
                `1. 每次交易平台都会自动匹配买家。`,
                `2. 用户请求出售USDT时，用户的USDT将被冻结处理，确认收款后，USDT将存入购买者账户。`,
                `3. 安全交易保障系统启动，卖方和买方之间的交易，平台保证100%的安全。`,
                `4. 运营时间：9:00-21:00运营时间以外的交易可能会推迟买家的汇款处理。`,
            ],
        },
    ],
}