<template>
  <div>
    <SmallBanners
      :title="$t('agency.bannerName')"
      :info="$t('agency.bannerInfo')"
    />
    <div class="content">
      <div class="content-head-cardBox">
        <div class="content-head-cardBox-card">
          <div class="content-head-cardBox-card-title">
            {{ $t("agency.header.invite") }}
          </div>
          <div class="content-head-cardBox-card-value">
            {{ userInfo.inviteCountShow }}
          </div>
        </div>
        <div class="content-head-cardBox-card">
          <div class="content-head-cardBox-card-title">
            {{ $t("agency.header.allEarnings") }}
          </div>
          <div class="content-head-cardBox-card-value">
            {{ userInfo.totalIncomeShow }}
          </div>
        </div>
        <div class="content-head-cardBox-card">
          <div class="content-head-cardBox-card-title">
            {{ $t("agency.header.monthEarnings") }}
          </div>
          <div class="content-head-cardBox-card-value">
            {{ userInfo.lastMonthIncomeShow }}
          </div>
        </div>
        <div class="content-head-cardBox-card">
          <div class="content-head-cardBox-card-title">
            {{ $t("agency.header.dayEarnings") }}
          </div>
          <div class="content-head-cardBox-card-value">
            {{ userInfo.yesterdayIncomeShow }}
          </div>
        </div>
      </div>
      <div class="tabel">
        <el-table :data="tableData" ref="table" v-loading="loading">
          <el-table-column
            show-overflow-tooltip
            prop="loginName"
            :label="$t('agency.tabel.loginName')"
            align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="orderAmountShow"
            :label="$t('agency.tabel.transactionAmount')"
            align="center"
          >
            <template slot-scope="scope">
              <div class="tabel-item">
                <span class="tabel-item-money">
                  {{ scope.row.orderAmountShow }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="incomeShow"
            :label="$t('agency.tabel.earningsAmount')"
            align="center"
          >
            <template slot-scope="scope">
              <div class="tabel-item">
                <span class="tabel-item-money">
                  {{ scope.row.incomeShow }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="createTime"
            :label="$t('agency.tabel.createTime')"
            align="center"
          />
        </el-table>
        <Pagination
          :page="page"
          :size="size"
          :total="total"
          @callbackPage="callbackPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { inviteList, agentInfo } from "@/api/agency";
export default {
  name: "",
  components: {},
  data() {
    return {
      loading: false,
      orderType: 1,
      tagType: "",
      page: 1,
      size: 10,
      total: 80,
      visible: true,
      userInfo: {},
      tableData: [],
    };
  },
  created() {
    this.init();
    this.getUserinfo();
  },
  mounted() {},
  methods: {
    init() {
      this.tableData = [];
      let data = {
        page: this.page,
        pageSize: this.size,
      };
      this.loading = true;
      inviteList(data).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.tableData = res.data.list;
            this.page = res.data.pageNum;
            this.size = res.data.pageSize;
            this.total = res.data.total;
            this.loading = false;
          });
        }
      });
    },
    getUserinfo() {
      agentInfo().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
        }
      });
    },
    //分页返回
    callbackPage({ page, size }) {
      this.page = page;
      this.size = size;
      this.init();
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 8.4375vw;
  .content-head-cardBox {
    display: flex;
    justify-content: space-between;
    .content-head-cardBox-card {
      width: 14.84375vw;
      height: 4.16666vw;
      border: 1px solid #019393;
      color: #ffffff;
      border-radius: 5px;
      font-size: 1.09375vw;
      margin-right: 1vw;
      .content-head-cardBox-card-title {
        background: #019393;
        width: 100%;
        height: 2.08333vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .content-head-cardBox-card-value {
        color: #313131;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.08333vw;
      }
    }
    .content-head-cardBox-card:last-child {
      margin-right: 0;
    }
  }
  .tabel {
    margin-top: 4.27083vw;
    .table-item {
      width: 100%;
      height: 100%;
    }
    .tabel-item {
      color: #019393;
      .tabel-item-money {
        font-weight: bold;
      }
    }
    .tanel-falg {
      width: 1.5vw;
      height: 1.5vw;
      transform: translateY(25%);
    }
  }
}
// --------------------
// 修改头部样式
::v-deep .el-table .el-table__header th {
  background: #019393 !important;
  color: #ffffff !important;
}
// // 隐藏tabel底部伪元素
// ::v-deep .el-table::before {
//   height: 0px !important;
// }
// // 隐藏tabel表头边框线
// ::v-deep .el-table__cell {
//   /* 去除上边框 */
//   border: none !important;
// }
// // 隐藏tabel单元格边框线
// ::v-deep .el-table__row > td {
//   /* 去除表格线 */
//   border: none !important;
// }
::v-deep .el-table__header {
  border-radius: 0.26042vw !important;
  overflow: hidden !important;
}
</style>
