<template>
  <div class="loginBody">
    <div class="contentBox">
      <div class="contentBox-header">
        <img src="@/assets/brands.png" alt="" />
        <span class="contentBox-header-name">Umarket</span>
      </div>
      <div class="contentBox-option">
        <el-button
          type="primary"
          class="content-head-button"
          :plain="resetType != 0"
          @click="resetTypeChange('0')"
          >{{ $t("logInAndResetPasswords.phoneReset") }}</el-button
        >
        <el-button
          type="primary"
          class="content-head-button"
          :plain="resetType != 1"
          @click="resetTypeChange('1')"
          >{{ $t("logInAndResetPasswords.mailReset") }}</el-button
        >
      </div>
      <div class="contentBox-form">
        <el-form
          :model="resetData"
          :rules="resetRules"
          ref="resetData"
          label-width="0px"
        >
          <el-form-item prop="username">
            <el-input
              :placeholder="$t('logInAndResetPasswords.username')"
              v-model="resetData.username"
            ></el-input>
          </el-form-item>
          <!-- 选择国家 手机区号 -->
          <el-form-item
            v-if="resetType == 0"
            :prop="resetType == 0 ? 'countryCode' : ''"
          >
            <el-select
              ref="currencyType"
              v-model="resetData.countryCode"
              filterable
              class="contentBox-form-select"
              :placeholder="$t('logInAndResetPasswords.countryCode')"
              @change="
                (val) => changeSelection(resetData.countryCode, 'currencyType')
              "
            >
              <el-option
                v-for="item in currencyTypes"
                :key="item.id"
                :value="item.label"
              >
                <img
                  :src="item.src"
                  style="width: 20px; height: 20px; vertical-align: text-bottom"
                />
                {{ item.label }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="resetType == 0"
            :prop="resetType == 0 ? 'phonenumber' : ''"
          >
            <el-input
              :placeholder="$t('logInAndResetPasswords.phonenumber')"
              v-model="resetData.phonenumber"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="resetType == 1"
            :prop="resetType == 1 ? 'email' : ''"
          >
            <el-input
              :placeholder="$t('logInAndResetPasswords.email')"
              v-model="resetData.email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              :placeholder="$t('logInAndResetPasswords.code')"
              class="contentBox-form-input"
              v-model="resetData.code"
            ></el-input>
            <el-button
              type="primary"
              class="contentBox-form-sendCode"
              :disabled="sendCodecountDown != 300"
              @click="sendCode"
              >{{
                sendCodecountDown != 300
                  ? `${$t("logInAndResetPasswords.codeBtn1")}${$t(
                      "logInAndResetPasswords.codeBtn1"
                    )}(${sendCodecountDown})`
                  : $t("logInAndResetPasswords.codeBtn2")
              }}</el-button
            >
          </el-form-item>
          <div class="interval" />
          <el-form-item prop="newpassword">
            <el-input
              style="width: 0px; height: 0px"
              v-model="value"
            ></el-input>
            <el-input
              show-password
              :placeholder="$t('logInAndResetPasswords.newpassword')"
              v-model="resetData.newpassword"
            ></el-input>
          </el-form-item>
          <el-form-item prop="verifyPassword">
            <el-input
              style="width: 0px; height: 0px"
              v-model="value"
            ></el-input>
            <el-input
              show-password
              :placeholder="$t('logInAndResetPasswords.verifyPassword')"
              v-model="resetData.verifyPassword"
            ></el-input>
          </el-form-item>
          <div class="interval" />
        </el-form>
        <el-button
          type="primary"
          class="contentBox-resetBtn"
          @click="resetDataFun"
          >{{ $t("logInAndResetPasswords.resetBtn") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { phoneForgetPassword, emailForgetPassword } from "@/api/login";
import { sendPhoneCode, sendMailCode } from "@/api/common";
import obj from "../../utils/common.js";
export default {
  name: "LogInAndResetPasswords",
  components: {},
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        if (
          rule.field == "verifyPassword" &&
          this.resetData.verifyPassword != this.resetData.newpassword
        ) {
          callback(
            new Error(
              this.$t(
                "logInAndResetPasswords.logInRules." + rule.field + "Error"
              )
            )
          );
          return;
        }
        callback();
      } else {
        return callback(
          new Error(this.$t("logInAndResetPasswords.logInRules." + rule.field))
        );
      }
    };
    return {
      formType: 0, //0:登录,1:重置密码
      resetType: 0, //0:手机,1:邮箱
      sendCodecountDown: 300,
      sendCodeTime: null,
      sendCodeFlag: true, // 发送验证码单独校验状态
      codeImg: "",
      resetData: {
        username: "",
        phonenumber: "",
        countryCode: "",
        email: "",
        code: "",
        newpassword: "",
        verifyPassword: "",
      },
      resetRules: {
        username: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        phonenumber: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        countryCode: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        email: [{ required: true, validator: formRulesCheck, trigger: "blur" }],
        code: [{ required: true, validator: formRulesCheck, trigger: "blur" }],
        newpassword: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        verifyPassword: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
      },
      currencyTypes: obj.nationality,
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["resetData"].resetFields();
      this.currencyTypes = this.$store.state.common.nationalitys;
      this.$refs["currencyType"].$el.children[0].children[0].setAttribute(
        "style",
        `background: url(""}) no-repeat;`
      );
    },
  },

  created() {},
  mounted() {},
  methods: {
    //重置密码提交
    resetDataFun() {
      this.$refs["resetData"].validate((valid) => {
        if (valid) {
          let data = new FormData();
          data.append("username", this.resetData.username);
          data.append("phonenumber", this.resetData.phonenumber);
          data.append("email", this.resetData.email);
          data.append("code", this.resetData.code);
          data.append("newpassword", this.resetData.newpassword);
          if (this.resetType == 0) {
            phoneForgetPassword(data).then((res) => {
              if (res.code == 200) {
                this.$tooltipPop.open(null, true, {
                  type: "success",
                  info: [this.$t("logInAndResetPasswords.message.success")],
                });
                let time = setTimeout(() => {
                  clearTimeout(time);
                  this.$router.push("/logIn");
                }, 3000);
              }
            });
          } else {
            emailForgetPassword(data).then((res) => {
              if (res.code == 200) {
                this.$tooltipPop.open(null, true, {
                  type: "success",
                  info: [this.$t("logInAndResetPasswords.message.success")],
                });
                let time = setTimeout(() => {
                  clearTimeout(time);
                  this.$router.push("/logIn");
                }, 3000);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      this.sendCodeFlag = true;
      this.$refs["resetData"].validateField(
        ["username", "phonenumber", "countryCode"],
        (valid, vv) => {
          if (valid) {
            this.sendCodeFlag = false;
          }
        }
      );
      if (this.sendCodeFlag == false) return;
      if (this.resetType == 0) {
        let data = {
          actionType: 2, // 1:注册验证码，2：找回密码发送验证码,3:个人中心绑定手机号
          countryCode: this.countryCodeChewck(this.resetData.countryCode),
          phoneNumber: this.resetData.phonenumber,
        };
        sendPhoneCode(data).then((res) => {
          if (res.code == 200) {
            this.startTime();
          }
        });
      } else {
        let data = {
          actionType: 2, // 1:注册验证码，2：找回密码发送验证码,3:个人中心绑定手机号
          mail: this.resetData.email,
        };
        sendMailCode(data).then((res) => {
          if (res.code == 200) {
            this.startTime();
          }
        });
      }
    },
    startTime() {
      this.sendCodeTime = setInterval(() => {
        this.sendCodecountDown -= 1;
        if (this.sendCodecountDown == 0) {
          clearInterval(this.sendCodeTime);
          this.sendCodecountDown = 300;
        }
      }, 1000);
    },
    // 重置方式切换
    resetTypeChange(type) {
      this.resetData.code = "";
      this.resetType = type;
      this.sendCodecountDown = 300;
      clearInterval(this.sendCodeTime);
      this.$refs["resetData"].resetFields();
    },
    // 选择国籍
    changeSelection(val, refName) {
      for (let index in this.currencyTypes) {
        let obj = this.currencyTypes[index];
        if (obj.label == val) {
          this.$refs[refName].$el.children[0].children[0].setAttribute(
            "style",
            `
            background: url(${obj.src}) no-repeat; 
            background-size:20px 20px !important;
            background-position: 20px 50%;
            background-size:43%;
            padding-left:45px;
            `
          );
        }
      }
    },
  },
  computed: {
    countryCodeChewck() {
      return (val) => {
        if (val) {
          let item = this.currencyTypes.find(
            (i) => (i.label || i.eLabel || i.cLabel) == val
          );
          let flag = item.countryCode;
          return flag;
        }
      };
    },
  },
};
</script>
<style scoped lang='scss'>
.loginBody {
  width: 100vw;
  min-height: calc(100vh - 5vw);
  background: url("../../assets/login/logInBG.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vw 0 5vw 0;
  box-sizing: border-box;
}
.contentBox {
  width: 40vw;
  background: #eaecf0;
  border-radius: 1.5625vw;
  padding: 6.14583vw 6.51042vw;
  margin: auto;
  .contentBox-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.92708vw;
    img {
      width: 2.60416vw;
      height: 2.60416vw;
      margin-right: 0.88542vw;
    }
    .contentBox-header-name {
      font-size: 2.39583vw;
      font-weight: bold;
    }
  }
  .contentBox-option {
    display: flex;
    justify-content: center;
    margin-bottom: 5vw;
    .content-head-button {
      width: 11.45833vw;
    }
  }
  .contentBox-form {
    justify-content: space-between;
    .contentBox-form-select {
      width: 100%;
      background: #ffffff !important;
    }
    .contentBox-form-input {
      width: 65%;
    }
    .contentBox-form-sendCode {
      width: calc(35% - 8px);
      margin-left: 8px;
      text-align: center;
    }
    .contentBox-form-codeImg {
      width: calc(35% - 8px);
      height: 36px;
      position: absolute;
      top: 2px;
      right: 0;
      cursor: pointer;
    }
  }
  .contentBox-resetBtn {
    margin-bottom: 1vw;
    width: 100%;
  }
}
.interval {
  height: 2.65625vw;
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
</style>
