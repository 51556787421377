/**
* ko
*/
export default {
    bannerName: '구매/판매신청',
    clientBannerName: '판매신청',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    buyBtn: '구매신청',
    sellBtn: '판매신청',
    buyLabelPrice: '구매가격',
    sellLabelPrice: '판매가격',
    buyLabelAmount: '구매신청 수량',
    sellLabelAmount: '판매신청 수량',
    newSellBtn: '판매신청하기 ',
    newBuyBtn: '구매신청하기 ',
    switchType: '화폐종류',
    switchTipBuy: '구매신청후 접수버튼을 열어 주십시오.',
    switchTipSell: '판매신청후 접수버튼을 열어 주십시오.',
    switchTip: '주문접수 ON/OFF',
    rules: {
        price: '      ',
        buyamount: '구매신청 수량을 입력하십오',
        sellamount: '판매신청 수량을 입력 하십시오',
    },
    message: {
        success: '실행성공',
    },
    confirm: {
        authenticationTitle: '알림',
        authenticationInfo: '테이터가 저장되지 않았습니다. 이동 하시겠습니까?',
        confirmBtn: '네 ',
        cancelBtn: '아니요',
    },
    warnInfo: [
        {
            title: "구매/판매신청 방법",
            values: [
                `1. 고객님이 구매/판매 하고 싶은 최대 수량을 입력하고 대기 하시면 설정한 최대 수량 이내의 거래를 플랫폼 AI 자동매칭프로그램이 구매자와판매자를 자동으로 매칭하여 드립니다.`,
                `2. 구매/판매신청은  빠른거래 판매/구매 보다 저렴한 가격에 USDT를 구매하고 높은 가격에 USDT를 판매 할수 있습니다.`
            ],
        },
        {
            title: "이용방법",
            values: [
                `1. 접수버튼 ON/OFF 기능으로 수시로 구매/판매를 조정 할수 있습니다.`,
                `2. 구매/판매금액을 개인사정에 맞게  자유롭게 설정 할수 있습니다. `,
            ],
        },

    ],
}
