/**
* zh_CN
*/
export default {
  PARAMETER_ERROR: '参数错误',
  SEND_CODE_FREQUENT: '验证码发送频繁',
  LOGIN_NAME_HAS_BEEN_REGISTERED: '用户名已经存在',
  PHONE_NUMBER_HAS_BEEN_REGISTERED: '手机号码已被注册',
  EMAIL_HAS_BEEN_REGISTERED: '邮箱已被注册',
  INVALID_INVITE_CODE: '无效的邀请码',
  VALIDATE_CODE_ERROR: '无效的验证码',
  INVALID_VERIFICATION_CODE: '无效的验证码',
  LOGIN_NAME_OR_PASSWORD_ERROR: '用户名或密码错误',
  PHONE_NUMBER_ERROR: '手机号码错误',
  EMAIL_ERROR: '邮箱错误',
  CURRENCY_CODE_ERROR: '币种代码错误',
  NEED_AUTH_PASS_FIRST_ERROR: '请先进行实名认证并等待管理员审核通过',
  NEED_BIND_BANK_CARD_AND_APPROVE: '请先添加银行卡，通过审核并打开后，才能交易',
  ORDER_MIN_NUM_ERROR: '不满足最低交易数量',
  ORDER_MAX_NUM_ERROR: '超过最大交易数量',
  ORDER_STATUS_CANNOT_BE_OPERATE: '订单状态不允许此此操作',
  NO_OPERATION_PERMISSION: '无操作权限',
  NO_OPERATION_PERMISSION: '无操作权限',
  SHOP_ID_NOT_EXIST: '商户不存在',
  CLIENT_NO_SELLER_ERROR: '没有能提供服务的卖家',
  CLIENT_NO_BUYER_ERROR: '没有能提供服务的买家',
  ORDER_NO_EXPIRE: '订单号已过期',
  ORDER_NO_ERROR: '订单号不存在',
  SHOP_BALANCE_NOT_ENOUGH: '商户余额不足',
  INVALID_UMARKET_ADDRESS: '无效的umarket地址',
  NO_REGISTER: '未注册',
  SYSTEM_CONFIGURATION_ERROR: '系统配置错误',
  BAD_REQUEST: '错误的请求',
  READDRESS_INVALID: '充值地址无效',
  ADDRESS_INVALID: '无效的地址',
  PHONE_NUMBER_HAS_ALREADY_BEEN_LINKED_TO_AN_ACCOUNT: '手机号码已被其他账户绑定',
  VERIFICATION_CODE_ERROR: '验证码错误',
  EMAIL_HAS_ALREADY_BEEN_LINKED_TO_AN_ACCOUNT: '邮箱已被其他账户绑定',
  CERTIFICATION_IN_PROGRESS: '实名认证正在审核',
  PASSED: '已通过实名认证',
  ADDRESS_DOES_NOT_EXIST: '地址不存在',
  REPEATEDLY_ADDING_FRIENDS: '不可重复添加相同地址',
  OLD_PASSWORD_ERROR: '旧密码错误',
  OLD_TRADE_PASSWORD_ERROR: '旧交易密码错误',
  BALANCE_NOT_ENOUGH: '余额不足',
  USER_NOT_PRESENT: '用戶不存在',
  TOKEN_ERROR: '登录超时',
  TOKEN_ERROR: '交易密码不正确',
  INVALID_ADDRESS: '无效的地址',
  ONLY_ADD_UMARKET_MEMBER_ADDRESS: '只能添加Umarket地址',
  BALANCE_NOT_ENOUGH: '余额不足',
  USER_NOT_PRESENT: '用戶不存在',
  TRADE_PASSWORD_ERROR: '交易密码不正',
  INVALID_ADDRESS: '无效的地址',
  NOT_AGENT: '不是代理商用户',
  ACCOUNT_HAS_BEEN_FROZEN: "账户已被冻结"
};
