/**
* en
*/
export default {
    bannerName: 'Deposit center',
    bannerInfo: 'Safe, Fast, Convenient',
    countDown: 'Countdown',
    orderNo: 'Order number',
    tradeTye: 'Deposit',
    price: 'Deposit amount',
    possessor: 'Deposit amount',
    sellInfo: 'Seller Information',
    payeeUser: 'Payee',
    quantity: 'Deposit amount',
    payTypeId: 'Choose payment method',
    addressHint: '* The wallet address to receive USDT after purchase',
    address: 'Wallet address',
    bank: 'Bank',
    payType: 'Payment method',
    WeChat: 'WeChat payment',
    Alipay: 'Alipay payment',
    bankNo: 'Bank account number',
    remittance: 'Remittance has been made (click on the remittance button)',
    canelOrder: 'Cancel order',
    subBtn: 'Immediate deposit',
    cancel: 'Cancel',
    // 2
    Step1: {
        label: 'Step1 : Please confirm the purchase quantity and amount',
        tradeAmount: 'Deposit amount:',
        currencyAmount: 'Deposit amount:',
        orderNo: 'Order number:'
    },
    Step2: {
        label: 'Step2 : Please enter the name of the remitter',
    },
    Step3: {
        label: 'Step3 : Please enter your phone number',
    },
    agreement: {
        txt1: 'User Agreement',
        txt2: 'Please confirm and click agree.',
    },
    rules: {
        price: 'Please enter the purchase amount',
        quantity: 'Please enter the purchase quantity',
        payTypeId: 'Please select payment method',
        userName: 'Please enter the name of the remitter',
        tel: 'Please enter your phone number',
    },
    message: {
        agreement: 'Please confirm the user agreement and click agree'
    },
    tooltipList: {
        txt1: "Did you make the remittance based on the seller's account information?",
        txt2: 'Malicious clicking on an account may result in it being frozen.',
    },
    confirm: {
        hint: 'Are you sure you want to cancel the order?',
        title: 'Tip',
        subBtn: 'Confirm',
        cancelBtn: 'Cancel',
    },
    warnInfo: [
        {
            title: "reminders",
            values: [
                `1. Each time the transaction, the platform will automatically match the seller. The seller's account information can be different every time, so please remit according to the seller's account information.`,
                `2. Coins purchased by users will be deposited directly from the seller to the third party platform.`,
                `3. The platform will not be responsible for the amount wrongly deposited by the user due to an account information error.`,
                `4. Please remit to your account for the safety of your funds.`,
                `5. Hours of Operation - 9:00 to 21:00, transactions outside of operating hours may be late for processing by the seller.`,
                `6. To prevent money laundering, in some cases the USDT you purchase may be transferable or withdrawn after 24 hours.`,
                `7. Please leave the comments blank when sending! (for example, virtual currency, USDT, Bitcoin, foreign exchange transactions, etc.) may be prohibited by the seller to decline the transaction.`,
            ],
        },
    ],
    warnInfo3: [
        {
            title: "reminders",
            values: [
                `1.The platform does not support automatic money transfers. Please remit to the seller directly from your bank account.`,
                `2.The seller's USDT deposit is frozen, so you may rest assured and remit money.`,
                `3.Please do not fill in the ETH, USDT, BTC and other information on the remittance. Failure to do so may result in the seller rejecting the transaction.`,
            ],
        },
    ],
};