/**
* en
*/
export default {
  userAgreement: 'User Agreement',
  privacyProtection: 'Privacy Policy',
  announcementCenter: 'Notice',
  customerCenter: 'Service Center',
  FAQ: 'FAQs',
  beginnerGuide: "Beginner's Guide",
  announcement: {
    agreement: 'User Agreement',
    privacyAgreement: 'Privacy Policy',
    announcement: 'Notice',
    customerCenter: 'Service Center',
    FAQ: 'FAQs',
    rookieGuide: "Beginner's Guide",
    bannerInfo: 'Safe, Fast, Convenient',
    title: 'Title',
    userName: 'Contacts',
    userPhone: 'Telephone',
    userEmail: 'Email',
    content: 'Content',
    sendBtn: 'Send',
    message: {
      success: 'Operation successful',
    },
    rules: {
      title: 'Please enter a title',
      userName: 'Please enter the contact person',
      userPhone: 'Please enter phone number',
      userEmail: 'Please enter your email address',
      content: 'Please enter the content',
    }
  }
};