
<template>
  <div class="headersBody">
    <div class="option-box">
      <div class="option-box-left">
        <img
          class="option-box-left-img"
          src="@/assets/brands.png"
          @click="toPath('/')"
        />
        <span class="option-box-left-name" @click="toPath('/')"> Umarket </span>
        <!-- 展示判断 token存在显示 -->
        <!-- ▼ -->
        <template v-if="!isOnline">
          <el-button
            type="text"
            v-for="item in leftOptions"
            :key="item.name"
            v-if="item.userType && item.certState == 4"
            class="option-box-left-item"
          >
            <span
              :class="pathCheck(item) ? 'highlight' : ''"
              @click="toPath(item.to)"
            >
              {{ item.name
              }}<span class="option-box-left-item-icon" v-if="item.children"
                >▼</span
              >
            </span>
            <div class="box-tip-angle" v-if="item.key == 'tradingOrder'" />
            <div class="box-tip" v-if="item.key == 'tradingOrder'">
              <template>
                <div
                  class="box-tip-angle-item"
                  v-for="(child, cind) in item.children"
                  :key="cind"
                  @click.stop="toPath(child.to)"
                >
                  {{ child.name }}
                </div>
              </template>
            </div>
          </el-button>
        </template>
      </div>
      <div class="option-box-right">
        <el-link
          :underline="false"
          class="mr size"
          v-if="isOnline"
          @click="toPath('/logIn')"
          >{{ $t("headers.login") }}</el-link
        >
        <el-button
          type="primary"
          class="mr option-box-right-btn"
          size="mini"
          v-if="isOnline"
          @click="toPath('/signIn')"
          >{{ $t("headers.registerMember") }}</el-button
        >
        <HeadeTooltip />
        <el-button
          type="primary"
          class="option-box-right-btn"
          v-if="!isOnline"
          @click="logOut"
          size="mini"
          >{{ $t("headers.logout") }}</el-button
        >
      </div>
    </div>
    <!-- <div class="info-box">
      <div class="flow-box">这是一段文字描述样例！</div>
    </div> -->
    <LogInDialog ref="LogInDialog" />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
    <div class="IMbox">
      <IM v-if="uikitInit" />
    </div>
  </div>
</template>

<script>
import { logout } from "@/api/common";
import IMDialog from "@/views/IM";
import IM from "@/views/IM/IM.vue";
export default {
  name: "headers",
  components: { IMDialog, IM },
  props: {},
  data() {
    return {
      token: null,
      leftOptions: [],
      uikitInit: false, // 挂载隐藏的 IM 试图组件 // 使过去store 实例
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      console.log("IM__语言切换");
      // this.uikitInit = false;
      // this.initRender('reset');
      // let time = setTimeout(() => {tooltipType: "affirm"tooltipType: "affirm"
      //   clearTimeout(time);
      //   this.uikitInit = this.$uikit ? true : false;
      //   this.$forceUpdate();
      //   console.log("IM__uikitInit_watch", this.uikitInit);
      // }, 0);
      this.navbarBtns();
    },
    "$store.state.userInfo.userInfo"(newVal, oldVal) {
      this.navbarBtns();
    },
    $route(to, from) {
      this.$store.dispatch("common/setIM", { bool: false, accId: null });
      if (to.path == "/logIn") return;
      if (!this.$uikit) {
        this.initRender("init");
      } else if (!window.__xkit_store__) {
        this.uikitInit = true;
      } else {
        return;
      }
    },
    deep: true,
    immediate: true,
  },
  mounted() {
    // 浏览器刷新初始化 IM
    this.initRender("init");
    console.log("IM__uikitInit_mounted", this.uikitInit);
    this.$store.dispatch("common/setIM", { bool: false, accId: null });
    this.navbarBtns();
  },
  methods: {
    tooltipBack(flag, isCancel) {
      if (flag == "logOut" && !isCancel) {
        logout().then((res) => {
          if (res.code == 200) {
            // this.$tooltipPop.open(null, true, {
            //   type: "success",
            //   info: [this.$t("headers.success")],
            // });
            this.uikitInit = false;
            this.IM.destroyIm();
            this.$store.dispatch("userInfo/setUserInfo", null);
            let path = this.$route.path;
            if (!path.includes("client/sell") && !path.includes("client/buy")) {
              this.toPath({
                path: "/logIn",
              });
            }
          }
        });
      }
    },
    initRender(type = "") {
      this.uikitInit = this.IM.createIm(type);
    },
    toPath(path) {
      let page =
        this.$route.path.includes("client/sell") ||
        this.$route.path.includes("client/buy");
      if (
        path == "/logIn" &&
        page &&
        (!this.$store.state.userInfo.userInfo ||
          !this.$store.state.userInfo.userInfo.userId)
      ) {
        this.$refs["LogInDialog"].openDialog();
      } else {
        if (this.$route.path == path) {
          location.reload();
        } else {
          this.$router.push(path);
        }
      }
    },
    logOut() {
      this.$refs['TooltipPop'].openDialog('logOut', false, {
        type: "affirm",
        info: [this.$t("headers.logoutDiaLog.info")],
      });
    },
    navbarBtns() {
      if (
        this.$store.state.userInfo.userInfo &&
        this.$store.state.userInfo.userInfo.userType
      ) {
        console.log(' this.$store.state.userInfo.userInfo',this.$store.state.userInfo.userInfo)
        let userType = this.$store.state.userInfo.userInfo.userType;
        let certState = this.$store.state.userInfo.userInfo.certState;
        let list = [
          {
            name: this.$t("headers.quickTrade"),
            to: "/transaction",
            userType: true,
            certState
          },
          {
            name: this.$t("headers.buyOrSell"),
            to: "/pendingOrders",
            userType: true,
            certState
          },
          {
            name: this.$t("headers.tradingOrderName"),
            key: "tradingOrder",
            to: "",
            userType: true,
            certState,
            children: [
              {
                name: this.$t("headers.tradingOrder.quickTrade"),
                to: "/shortcutOrder",
              },
              {
                name: this.$t("headers.tradingOrder.pendingOrder"),
                to: "/pendingOrder",
              },
            ],
          },
          {
            name: this.$t("headers.transfer"),
            to: "/transfer",
            userType: true,
            certState
          },
          {
            name: this.$t("headers.orderManagement"),
            to: "/orderManagement",
            userType: userType == 201 ? true : false,
            certState
          },
          {
            name: this.$t("headers.agency"),
            to: "/agency",
            userType: userType == 202 ? true : false,
            certState: 4
          },
        ];
        this.$nextTick(() => {
          this.$set(this, "leftOptions", list);
          this.$forceUpdate();
        });
      }
    },
  },
  computed: {
    pathCheck() {
      return (item) => {
        let flag = false;
        let path = this.$route.path;
        if (item.children && item.children.length >= 1) {
          let ind = item.children.map((i) => i.to).indexOf(path);
          if (ind >= 0) {
            flag = true;
          }
        } else {
          if (path == item.to) {
            flag = true;
          }
        }
        return flag;
      };
    },
    isOnline() {
      return this.$store.state.userInfo.userInfo &&
        this.$store.state.userInfo.userInfo.userId
        ? false
        : true;
    },
  },
};
</script>
<style scoped lang='scss'>
.IMbox {
  position: fixed;
  width: 400px;
  height: 300px;
  top: 50px;
  left: 0px;
  overflow: hidden;
  visibility: hidden;
}
.headersBody {
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  background: #ffffff;
  .option-box {
    height: 48px;
    line-height: 48px;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    .option-box-left {
      width: 65%;
      height: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      .option-box-left-img {
        width: 36px;
        height: 36px;
        margin-right: 9px;
        cursor: pointer;
      }
      .option-box-left-name {
        font-size: 24px;
        font-weight: bold;
        margin-right: 24px;
        cursor: pointer;
      }
      .option-box-left-item {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        margin-right: 0.6vw;
        color: #313131;
        cursor: pointer;
        .option-box-left-item-icon {
          font-size: 10px;
          margin-left: 2px;
        }
        .box-tip-angle {
          visibility: hidden;
          width: 10px;
          height: 10px;
          position: absolute;
          left: calc(50% - 5px);
          bottom: -5px;
          background: #019393;
          transform: rotate(180deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
        }
        .box-tip {
          min-width: 30px;
          visibility: hidden;
          font-size: 14px;
          color: #ffffff;
          background: #019393;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          z-index: 99;
          .box-tip-angle-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            padding: 16px;
            //强制内容不换行
            white-space: nowrap;
          }
          .box-tip-angle-item:hover {
            background: #00a7a7;
          }
        }
      }
      .option-box-left-item:hover {
        color: #019393;
        .box-tip-angle {
          visibility: initial;
        }
        .box-tip {
          visibility: initial;
        }
      }
    }
    .option-box-right {
      width: 35%;
      height: 100%;
      text-align: flex-end;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .mr {
        margin-right: 1vw;
      }
      .size {
        font-size: 14px;
      }
      .option-box-right-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  .info-box {
    width: 100vw;
    height: 20px;
    line-height: 20px;
    background: #eeeeee;
    padding-left: 4px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 1px 0px 0px rgb(213, 213, 213);
    .flow-box {
      position: absolute;
      animation-name: flow;
      white-space: nowrap;
      animation: flow 60s infinite; /* 设置动画为2秒钟的时间，并无限循环 */
      animation-timing-function: linear;
      animation-play-state: normal !important;
    }
    .flow-box:hover {
      animation-play-state: paused !important;
    }
    @keyframes flow {
      0% {
        right: 0;
        transform: translateX(100%);
      }
      100% {
        right: 100%;
        transform: translateX(-100%);
      }
    }
  }
  .info-box:hover {
    cursor: pointer;
  }
}
.highlight {
  color: #019393;
}
</style>
