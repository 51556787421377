<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="41.66667vw"
      :before-close="handleClose"
    >
      <div class="title">
        {{ $t("userInfo.accountInfo.passwordDialog.diaLog.title") }}
      </div>
      <div class="content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          class="formData"
          label-position="left"
        >
          <el-form-item
            :label="
              $t('userInfo.accountInfo.passwordDialog.diaLog.oldPassword')
            "
            prop="oldPassword"
          >
            <el-input
              v-model="formData.oldPassword"
              placeholder=""
              show-password
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="
              $t('userInfo.accountInfo.passwordDialog.diaLog.newPassword')
            "
            prop="newPassword"
          >
            <el-input
              v-model="formData.newPassword"
              placeholder=""
              show-password
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="
              $t('userInfo.accountInfo.passwordDialog.diaLog.verifyNewPassword')
            "
            prop="verifyNewPassword"
          >
            <el-input
              v-model="formData.verifyNewPassword"
              placeholder=""
              show-password
              class="formData-input"
            />
          </el-form-item>
          <div class="formData-subBtn">
            <el-button
              type="primary"
              class="formData-subBtn-btn"
              @click="submitForm"
              >{{
                $t("userInfo.accountInfo.passwordDialog.diaLog.subBtn")
              }}</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { passwordLogin } from "@/api/userInfo";
export default {
  name: "",
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        if (
          rule.field == "verifyNewPassword" &&
          this.formData.verifyNewPassword != this.formData.newPassword
        ) {
          return callback(
            new Error(
              this.$t(
                "userInfo.accountInfo.passwordDialog.diaLog.rules." +
                  rule.field +
                  "Error"
              )
            )
          );
        }
        callback();
      } else {
        return callback(
          new Error(
            this.$t(
              "userInfo.accountInfo.passwordDialog.diaLog.rules." + rule.field
            )
          )
        );
      }
    };
    return {
      dialogVisible: false,
      parentInfo: "",
      formData: {
        oldPassword: "",
        newPassword: "",
        verifyNewPassword: "",
      },
      rules: {
        oldPassword: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        newPassword: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        verifyNewPassword: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    opendialog(userInfo) {
      this.parentInfo = userInfo;
      this.dialogVisible = !this.dialogVisible;
    },
    submitForm() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let data = new FormData();
          data.append("oldPassword", this.formData.oldPassword);
          data.append("newPassword", this.formData.newPassword);
          passwordLogin(data).then((res) => {
            if (res.code == 200) {
              this.dialogVisible = false
              this.$emit('diaLogCallBack',null)
            }
          })
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      done();
      this.resetData();
    },
    resetData() {
      this.$refs["formData"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  font-size: 1.30208vw;
  font-weight: bold;
  margin-top: -1.5625vw;
}
.content {
  padding-top: 1vw;
  .formData {
    .formData-input {
      width: calc(100% - 200px);
    }
    .formData-subBtn {
      width: 100%;
      margin-top: 2.23958vw;
      // margin-bottom: 1.6vw;
      display: flex;
      justify-content: center;
      &-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
::v-deep .el-form-item__label {
  width: 200px !important;
  padding-left: 1vw !important;
  font-weight: bold !important;
  background: #f8f8f8 !important;
}
::v-deep .el-input__inner {
  border: none !important;
  background: #f8f8f8 !important;
}
::v-deep .el-dialog__body {
  // padding-top: 0 !important;
}
</style>
