/**
* ko
*/
export default {
  srartDate: "시작일",
  endDate: "종료일",
  orderNo: "주문전호",
  currencyName: "화폐명",
  searchBtn: "검색",
  canselBtn: "취소",
  changeType: '지금유형을 선택 하십시오.'
};