// 引入
import QR from './QRDialog.vue'
import LogInDialog from './logInDialog'
import PageInfoTXT from './pageInfoTXT'
import PhoneInternation from './phoneInternation'
import SearchDialog from './searchDialog'
import TooltipPop from './tooltipPop'
import UploadImg from './uploadImg'
import UploadFile from './uploadFile'
import Pagination from './pagination'
import SmallBanners from './smallBanners'
import HeadeTooltip from './headeTooltip'
import Footers from './footers'
import Headers from './headers'
export default {
  install(Vue) {
  // 注册全局组件
  Vue.component('QR', QR),
  Vue.component('LogInDialog', LogInDialog),
  Vue.component('PageInfoTXT', PageInfoTXT),
  Vue.component('PhoneInternation', PhoneInternation),
  Vue.component('SearchDialog', SearchDialog),
  Vue.component('TooltipPop', TooltipPop),
  Vue.component('UploadImg', UploadImg),
  Vue.component('Pagination', Pagination),
  Vue.component('SmallBanners', SmallBanners),
  Vue.component('HeadeTooltip', HeadeTooltip),
  Vue.component('Footers', Footers),
  Vue.component('Headers', Headers)
  }
}
