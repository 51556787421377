/**
* ko
*/
export default {
    quickTrade: '빠른거래',
    buyOrSell: '구매/판매신청',
    tradingOrderName: '거래내역',
    tradingOrder: {
        quickTrade: '빠른거래 내역',
        pendingOrder: '구매/판매신청 내역',
    },
    transfer: '전송',
    orderManagement: '입출금내역',
    agency: '대리상 센터',
    login: '로그인',
    registerMember: '회원가입',
    headeTooltip: {
        property: '자산',
        IM: '고객사연결',
        personalCenter: '마이페이지',
        language: 'Language',
    },
    logout: '로그아웃',
    success: '로그아웃',
    logoutDiaLog: {
        title: '알림',
        info: '로그아웃 하시겠습니까?',
        confirmBtn: '확인',
        cancelBtn: '취소',
    }
};
