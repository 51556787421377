import Vue from 'vue'
// 多语言
import i18n from '@/lang'
// 引入ElementUI组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './element-variables.scss'
// 设置语言
Vue.use(ElementUI, {
	i18n: (key, value) => {
		return i18n.t(key, value) //i18n国际化
	}
});

//utils全局引入
import utils from "./utils/index";
import nim from "./utils/IM";
Vue.prototype.utils = utils;
Vue.prototype.IM = nim;

import App from './App.vue'
import router from './router'
import store from './store'
import vuex from 'vuex'
//注册全局组件
import Components from './components'
Vue.use(Components)
//关闭Vue的生产提示
Vue.config.productionTip = false


window.vm = new Vue({
	el: '#app',
	i18n, //i18n国际化
	vuex,
	store,
	router,
	render: (h) => h(App),
});

// 白名单
const whites = [
	'/',
	'/logIn',
	'/signIn',
	'/resetPasswords',
	'/agreement',
	'/privacyAgreement',
	'/announcement',
	'/customerCenter',
	'/FAQ',
	'/rookieGuide',
	'/client/sell',
	'/client/buy',
]
// 商户
const merchant = [
	'/orderManagement',
]
// 代理商
const agency = [
	'/agency',
]

// 路由守卫
router.beforeEach((to, from, next) => {
	console.log('store.state',to.path)
	if ((store.state.common.i18n === 'ko' || store.state.common.i18n === 'en' || !store.state.common.i18n) && to.path === '/authentication') {
		next('/authenticationKR')
		return
	}
	if (whites.indexOf(to.path) >= 0) {
		next()
		return
	}
	if (!store.state.userInfo.userInfo?.userId) {
		next('/logIn')
		return
	}
	if (merchant.indexOf(to.path) >= 0) {
		if (store.state.userInfo.userInfo.userType == 201) {
			next()
		} else {
			next('/')
		}
		return
	}
	if (agency.indexOf(to.path) >= 0) {
		if (store.state.userInfo.userInfo.userType == 202) {
			next()
		} else {
			next('/')
		}
		return
	}
	next()
})


// 解决跳转到详情页，滚动条不在顶部问题
router.afterEach((to, from, next) => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;

	// 兼容IE
	window.scrollTo(0, 0);
});

