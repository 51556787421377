/**
* zh_CN
*/
export default {
    bannerName: '传送',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    header: {
        title: '可用金额',
        info: '只能传送Umarket地址',
        form: {
            address: '传送地址',
            amount: '传送数量',
        },
        btns: {
            onceTransfer: '立即传送',
            batch: '批量传送',
            friend: '好友列表',
        },
    },
    content: {
        addTransfer: '添加传送人',
        addFriend: '添加好友',
        tabel: {
            type: '类型',
            amount: '传送数量',
            friendName: '姓名',
            address: '钱包地址',
            createTime: '时间',
            options: '操作',
            delete: '删除',
        },
        convey: {
            CONVEY_OUT: '转出',
            CONVEY_IN: '转入'
        },
        searchInput: '输入姓名',
        searchBtn: '搜索',
        footerBtn: '立即批量传送',
    },
    addTransferDialog: {
        title: '添加传送人',
        friendName: '姓名',
        friendAddress: '钱包地址',
        subBtn: '立即添加',
    },
    addFriendDialog: {
        title: '添加好友',
        friendName: '姓    名',
        friendAddress: '钱包地址',
        subBtn: '立即添加',
        rules: {
            friendName: '请输入姓名',
            friendAddress: '请输入钱包地址',
        }
    },
    success:'操作成功'
}
