/**
 *国籍集合定义
 *
 */
const nationality = [
    {
        id: "1",
        symbol: "₩",
        currency: "KRW",
        label: "Korea",
        cLabel: "韩国",
        eLabel: "Korea",
        code: "ROK",
        countryCode:82,
        src: require("@/assets/nationality/dh_kr.png"),
    },
    {
        id: "2",
        symbol: "￥",
        currency: "CNY",
        label: "China",
        cLabel: "中国",
        eLabel: 'China',
        code: "CHN",
        countryCode:86,
        src: require("@/assets/nationality/dh_cn.png"),
    },
    // {
    //     id: "3",
    //     symbol: "€",
    //     currency: "EUR",
    //     label: "EU",
    //     cLabel: "欧盟",
    //     eLabel: "EU",
    //     code: "EU",
    //     countryCode:'',
    //     src: require("@/assets/nationality/dh_eur.png"),
    // },
    {
        id: "8",
        symbol: "HK$",
        currency: "HKD",
        label: "Thailand",
        cLabel: "香港",
        eLabel: "Thailand",
        code: "HKD",
        countryCode:852,
        src: require("@/assets/nationality/dh_hkd.png"),
    },
    {
        id: "4",
        symbol: "$",
        currency: "USD",
        label: "USA",
        cLabel: "美国",
        eLabel: "USA",
        code: "USA",
        countryCode:1,
        src: require("@/assets/nationality/dh_usa.png"),
    },
    {
        id: "5",
        symbol: "J ¥",
        currency: "JPY",
        label: "Japan",
        cLabel: "日本",
        eLabel: "Japan",
        code: "JPN",
        countryCode:81,
        src: require("@/assets/nationality/dh_jp.png"),
    },
    {
        id: "6",
        symbol: "RM",
        currency: "MYR",
        label: "Malaysia",
        cLabel: "马来西亚",
        eLabel: "Malaysia",
        code: "MYS",
        countryCode:60,
        src: require("@/assets/nationality/dh_myr.png"),
    },
    {
        id: "7",
        symbol: "₱",
        currency: "PHP",
        label: "Philippines",
        cLabel: "菲律宾",
        eLabel: "Philippines",
        code: "PH",
        countryCode:63,
        src: require("@/assets/nationality/dh_php.png"),
    },
    {
        id: "9",
        symbol: "₫",
        currency: "VND",
        label: "Vietnam",
        cLabel: "越南",
        eLabel: "Vietnam",
        code: "VN",
        countryCode:84,
        src: require("@/assets/nationality/dh_vn.png"),
    },
]
/**
 *币种集合定义
 */
const currency = [
    {
        id: "USDT",
        label: "USDT",
        src: require("@/assets/transaction/currency.png"),
    }
]
export default {
    nationality,
    currency,
}
