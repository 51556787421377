/**
* en
*/
export default {
  srartDate: "Start date",
  endDate: "End date",
  orderNo: "Order number",
  currencyName: "Currency name",
  searchBtn: "Query",
  canselBtn: "Cancel",
  changeType: 'Please select an asset type'
};