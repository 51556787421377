/**
* en
*/
export default {
  quickTrade: 'Quick transactions',
  buyOrSell: 'Purchase/Sale Request',
  tradingOrderName: 'Transaction order',
  tradingOrder: {
    quickTrade: 'Quick transaction order',
    pendingOrder: 'Hanging order transaction order',
  },
  transfer: 'Transfer',
  orderManagement: 'Deposit/Withdrawal Order',
  agency: 'Agent Center',
  login: 'Log in',
  registerMember: 'Register',
  headeTooltip: {
    property: 'Asset',
    IM: 'Contact service',
    personalCenter: 'Personal Center',
    language: 'Language',
  },
  logout: 'Log out',
  success:'Logged out',
  logoutDiaLog: {
    title: 'Tip',
    info: 'Login is about to exit, do you want to continue?',
    confirmBtn: 'Confirm',
    cancelBtn: 'Cancel',
  }
};