import api from '@/utils/request'
// 用户协议和隐私条款
export function notice(code) {
    return api.get('/api/v1/noticeMsg/notice/' + code)
}
// 公告中心，常见问题，新手指南列表查询
export function announcement(data) {
    return api.get(`/api/v1/noticeMsg/notice/announcement?noticeType=${data.noticeType}&page=${data.page}&pageSize=${data.size}`)
}
// 根据ID查询文章内容
export function announcementByID(id) {
    return api.get(`/api/v1/noticeMsg/notice/announcementByID?id=${id}`)
}
// 提交客服中心数据
export function feedBack(data) {
    return api.post('/api/v1/noticeMsg/notice/feedBack', data)
}