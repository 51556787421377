/**
* en
*/
export default {
    bannerName: 'Agent Center',
    bannerInfo: 'Safe, Fast, Convenient',
    header: {
        invite:'Number of invited users',
        allEarnings:'Total revenue',
        monthEarnings:'Monthly income',
        dayEarnings:"Yesterday's earnings",
    },
    tabel:{
        loginName:'Account ID',
        transactionAmount:'Transaction amount',
        earningsAmount:'Revenue amount',
        createTime:'Registration time',
    }
}