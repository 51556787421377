import api from '@/utils/request'

// 获取交易币对
export function getSymbol(data) {
    return api.get('/api/v1/common/getSymbol')
}
// 获取所有法币信息
export function getAllCurrency(data) {
    return api.get('/api/v1/common/getAllCurrency')
}
// 获取所有币种信息
export function getAllCoin(data) {
    return api.get('/api/v1/common/getAllCoin')
}
// 获取图形验证码
export function captchaImage(data) {
    return api.get('/api/v1/captcha/captchaImage?type=math')
}
// 获取个人信息
export function myInfo(data) {
    return api.get('/api/v1/user/myInfo')
}
// 登录
export function login(data) {
    return api.post('/api/v1/user/login', data)
}
// 忘记密码--手机找回
export function phoneForgetPassword(data) {
    return api.post('/api/v1/common/phoneForgetPassword', data)
}
// 忘记密码--邮箱找回
export function emailForgetPassword(data) {
    return api.post('/api/v1/common/emailForgetPassword', data)
}