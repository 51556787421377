<template>
  <el-input
    class="galaxy-num-input"
    v-model="displayValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="
      orderType == 1
        ? $t('pendingOrders.rules.buyamount')
        : $t('pendingOrders.rules.sellamount')
    "
    @change="changeInput"
    @blur="onBlur"
    @focus="focus"
  >
    <template slot="prepend" v-if="prependValue == ''">{{ prepend }}</template>
    <template slot="append" v-if="append == 'pendingOrders'">

      <i class="el-icon-edit edit-icon"></i>
      <el-select
        disabled
        ref="currencyText"
        v-model="appendValue"
        placeholder=""
        selectBefore
        @change="(val) => selectionEcho(val, 'currencyText', currency)"
      >
        <el-option v-for="item in currency" :key="item.id" :value="item.label">
          <img
            :src="item.src"
            style="
              width: 24px !important;
              height: 24px !important;
              vertical-align: text-bottom;
            "
           alt=""/>
          {{ item.label }}
        </el-option>
      </el-select>
    </template>
  </el-input>
</template>
<script>
import obj from "@/utils/common.js";
export default {
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    min: {
      type: Number,
      default: -999999999999999,
    },

    max: {
      type: Number,
      default: 999999999999999,
    },
    precision: {
      type: Number,
      default: 2,
    },
    size: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    prepend: {
      type: String,
      default: "",
    },
    append: {
      type: String,
      default: "",
    },
    orderType: {
      type: Number,
    },
  },

  data() {
    return {
      displayValue: undefined,
      prependValue: "",
      appendValue: "USDT",
      currency: obj.currency,
    };
  },

  watch: {
    value() {
      this.displayValue = this.format(this.value);
    },
    prependValue() {
      this.displayValue = this.format(this.prepend);
    },
  },

  mounted() {
    this.displayValue = this.format(this.value);
    this.selectionEcho(this.appendValue, "currencyText", this.currency);
  },

  methods: {
    format(val) {
      if (this.checkRates(val)) {
        return Number(this.th2Num(val))
          .toFixed(this.precision)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "";
      }
    },

    checkRates(str) {
      if (str === null || str === undefined) {
        return false;
      } else {
        let numStr = str.toString().trim().replace(/,/g, "");
        var re = /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/;
        return re.test(numStr);
      }
    },

    th2Num(str) {
      if (str === null || str === undefined || str === "") {
        return 0;
      } else {
        return Number(str.toString().trim().replace(/,/g, ""));
      }
    },

    onBlur() {
      this.changeInput(this.displayValue);
    },

    changeInput(val) {
      console.log(val, "hhhhhhhhhhhhhhhhh");
      const { min, max } = this.$props;
      let num = Number(val.trim().replace(/,/g, ""));
      if (!this.checkRates(val)) {
        this.displayValue = "";
      } else {
        if (num < min) {
          num = min;
        }
        if (num > max) {
          num = max;
        }
      }
      this.displayValue = this.format(num);
      this.$emit("input", Number(num.toFixed(this.precision)));
      this.$emit("change", Number(num.toFixed(this.precision)));
    },

    focus() {
      this.$emit("focus");
    },
    // 设置下拉选回显图片
    selectionEcho(val, refName, list) {
      let dom = this.$refs[refName].$el.children[0].children[0];
      this.utils.selectionEcho(val, dom, list);
    },
  },
};
</script>
<style lang="scss" scoped>
.galaxy-num-input /deep/ .el-input__inner {
  text-align: left !important;
}

.edit-icon {
  font-size: 20px;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
