/**
* zh_CN
*/
export default {
  banner: {
    title: 'USDT P2P 交易平台',
    info: '资金安全, 快捷, 方便 随时交易',
  },
  tableTitle: '今日汇率',
  tableLabel: {
    symbol: '货币种类',
    tradingUnit: '交易单位',
    buyingPrice: '购买价格',
    sellingPrice: '出售价格',
  },
  reasonTitle: '选择Umarket 理由',
  reasonItem: {
    item1: '快捷支付, 结算, 交易系统',
    item2: '随时查看出入金功能',
    item3: '安全 快捷 方便 转账 & 出金',
  },
  confirm: {
    authenticationTitle: '提示',
    authenticationInfo: '暂无认证信息,是否前往认证?',
    confirmBtn: '确定',
    cancelBtn: '取消',
    authToSell: "请进行本人认证后进行交易"
  }
};
