<template>
  <div class="body">
    <div class="banner">
      <div class="banner-title">
        {{ $t("footer.announcement.customerCenter") }}
      </div>
      <div class="banner-info">{{ $t("footer.announcement.bannerInfo") }}</div>
      <img src="@/assets/brands.png" class="banner-ime" alt="" />
    </div>
    <div class="content" v-loading="loading">
      <el-form
        :model="formData"
        label-position="left"
        hide-required-asterisk
        :rules="rules"
        label-width="68px"
        ref="formData"
      >
        <el-form-item :label="$t('footer.announcement.title')" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('footer.announcement.userName')"
          prop="userName"
        >
          <el-input v-model="formData.userName" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('footer.announcement.userPhone')"
          prop="userPhone"
        >
          <el-input v-model="formData.userPhone" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('footer.announcement.userEmail')"
          prop="userEmail"
        >
          <el-input v-model="formData.userEmail" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item :label="$t('footer.announcement.content')" prop="content">
          <el-input
            type="textarea"
            :autosize="{ minRows: 12 }"
            v-model="formData.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <div class="toPath">
          <div class="toPath-item">
            <!-- <img src="@/assets/customerCenter/453a624d017900001.png" alt="" />
            <span>umarket</span> -->
          </div>
          <div class="toPath-item">
            <!-- <img src="@/assets/customerCenter/@umarketkoera.png" alt="" />
            <span>@umarketkoera</span> -->
          </div>
        </div>
        <div>
          <el-button type="primary" class="btn" @click="subForm">{{
            $t("footer.announcement.sendBtn")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { feedBack } from "@/api/announcement";
export default {
  name: "agreement",
  components: {},
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        return callback(
          new Error(this.$t("footer.announcement.rules." + rule.field))
        );
      }
    };
    return {
      tooltipList: [],
      formData: {
        userName: "",
        userPhone: "",
        userEmail: "",
        title: "",
        content: "",
        loading: false,
      },
      rules: {
        title: [{ required: true, validator: formRulesCheck, trigger: "blur" }],
        userName: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        userPhone: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        userEmail: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        content: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["formData"].validate((valid) => {});
    },
  },
  methods: {
    subForm() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          feedBack(this.formData).then((res) => {
            if (res.code == 200) {
              this.$nextTick(() => {
                this.$refs["formData"].resetFields();
                this.loading = false;
                this.$tooltipPop.open(null, true, {
                  type: "success",
                  info: [this.$t("footer.announcement.message.success")],
                });
              });
            }
          });
        }
      });
    },
    toPath(path) {},
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.body {
  width: 100vw;
  box-sizing: border-box;
  margin-left: -22vw;
  margin-top: 48px;
}
.banner {
  width: 100%;
  height: 10.41667vw;
  padding: 0 18.69792vw;
  box-sizing: border-box;
  background: linear-gradient(0deg, #f7f7f7, #fafafa);
  position: relative;
  .banner-title {
    font-size: 2.60417vw;
    font-weight: bold;
    margin-bottom: 1.25vw;
  }
  .banner-info {
    font-size: 0.9375vw;
  }
  .banner-ime {
    width: 10.26042vw;
    height: 10.98958vw;
    position: absolute;
    top: 1.82292vw;
    left: 77.5969vw;
  }
}
.content {
  padding: 0 18.69792vw;
  padding-top: 5.36458vw;
  padding-bottom: 6.71875vw;
  .inputWidth {
    width: 50%;
  }
  .footer {
    margin-top: 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 7.08333vw;
    .toPath {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      .toPath-item {
        margin-right: 5vw;
        line-height: 100%;
        position: relative;
        img {
          width: 3.75vw;
          height: 3.75vw;
          margin-right: 1vw;
        }
        span {
          font-size: 1.04167vw;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .btn {
      width: 10.41667vw;
    }
  }
}
::v-deep .el-input__inner {
  background: #ededed !important;
}
::v-deep .el-textarea__inner {
  background: #ededed !important;
}
</style>
