/**
* zh_CN
*/
export default {
    copy: {
        title: '提示',
        info: '复制成功',
        button: '确定',
    },
    bankList: {
        PAY_TYPE_KRW_BANK: '韩国(KRW)银行账号',
        PAY_TYPE_CNY_BANK: '中国(CNY)银行账号',
        PAY_TYPE_CNY_WECHATPAY: '(CNY)微信支付',
        PAY_TYPE_CNY_ALIPAY: '(CNY)支付宝',
        PAY_TYPE_JPY_BANK: '日本(JPY)银行账号',
        PAY_TYPE_HKD_BANK: '香港(HKD)银行账号',
        PAY_TYPE_VND_BANK: '越南(VND)银行账号',
        PAY_TYPE_PHP_BANK: '菲律宾(PHP)银行账号',
        PAY_TYPE_MYR_BANK: '马来西亚(MYR)银行账号',
        PAY_TYPE_GBP_BANK: '英国(GBP)银行账号',
        PAY_TYPE_EUR_BANK: '欧盟(EUR)银行账号',
        PAY_TYPE_USD_BANK: '美国(USD)银行账号'
    },
    minBankList: {
        BANK_CARD: '银行转账',
        WECHATPAY: '微信支付',
        ALIPAY: '支付宝支付',
    },
    tooltipPop:{
        success:'成功',
        error:'错误',
        affirm:'确认'
    }
}
