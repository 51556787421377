/**
* en
*/
export default {
  KOREA: '한국',
  CHINA: '중국',
  EU: '유럽',
  BRITAIN: '영국',
  JAPAN: '일본',
  MALAYSIA: '말레시아',
  PHILIPPINES: '필리핀',
  THAILAND: '태국',
  VIETNAM: '베트남',
};