/**
* zh_CN
*/
export default {
  quickTrade: '快捷交易',
  buyOrSell: '购买/出售申请',
  tradingOrderName: '交易订单',
  tradingOrder: {
    quickTrade: '快捷交易订单',
    pendingOrder: '购买/出售申请订单',
  },
  transfer: '传送',
  orderManagement: '出入金管理',
  agency: '代理商中心',
  login: '登录',
  registerMember: '注册会员',
  headeTooltip: {
    property: '资产',
    IM: '联系客服',
    personalCenter: '个人中心',
    language: '语言',
  },
  logout: '退出',
  success:'登录已退出',
  logoutDiaLog: {
    title: '提示',
    info: '登录即将退出,是否继续?',
    confirmBtn: '确定',
    cancelBtn: '取消',
  }
};