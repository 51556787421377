/**
* ko
*/
import im from './ko/IM'
import requestCode from "./ko/requestCode";
import common from "./ko/common";
import orderStatus from "./ko/orderStatus";
import headers from "./ko/components/headers";
import headeTooltip from "./ko/components/headeTooltip";
import footer from "./ko/components/footer";
import searchDialog from "./ko/components/searchDialog";
import signIn from "./ko/signIn";
import loginHome from "./ko/loginHome";
import logInAndResetPasswords from "./ko/loginHome/logInAndResetPasswords";
import userInfo from "./ko/userInfo";
import authentication from "./ko/userInfo/authentication";
import nationality from "./ko/nationality";
import estate from "./ko/estate";
import transfer from "./ko/transfer";
import agency from "./ko/agency";
import orderManagement from "./ko/orderManagement";
import transaction from "./ko/transaction";
import transactionSell from "./ko/transaction/sell";
import transactionBuy from "./ko/transaction/buy";
import pendingOrders from "./ko/pendingOrders";
import transactionOrder from "./ko/transactionOrder";
import clientBuy from './ko/client/buy'
import clientSell from './ko/client/sell'
export default {
  im,
  requestCode,
  common,
  orderStatus,
  headers,
  headeTooltip,
  footer,
  searchDialog,
  signIn,
  loginHome,
  logInAndResetPasswords,
  userInfo,
  authentication,
  nationality,
  estate,
  transfer,
  agency,
  orderManagement,
  transaction,
  transactionSell,
  transactionBuy,
  pendingOrders,
  transactionOrder,
  clientBuy,
  clientSell,
};