/**
* zh_CN
*/
export default {
    bannerName: '个人中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    title: '实名认证',
    titleInfo: '*为保证交易安全，请您耐心完善以下信息',
    certType: '证件类型',
    realName: '姓名',
    dateOfBirth: '出生年月日 (1900-00-00)',
    certImage: '请上传证件照片',
    info: {
        title: '注意事项:',
        item1: '1. 请务必提交身份证原件正面拍摄的照片。',
        item2: '2. 由于光线反射、晃动、区域剪切等原因，可能很难准确识别身份证。',
        item3: '3. 严重损坏的身份证可能会被拒绝',
    },
    subBtn: '提交',
    idTypes: {
        item1: '身份证',
        item2: '护照',
        item3: '驾驶证',
        item4: '外国人登记证',
    },
    rules: {
        certType: '请选择证件类型',
        realName: '请输入姓名',
        dateOfBirth: '请选择出生年月日',
        certImage: '请上传证件照片',
    }
};