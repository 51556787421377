/**
* zh_CN
*/
export default {
    bannerName: '资产',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    header: {
        title: '资产明细',
        item1: {
            name: '可用金额'
        },
        item2: {
            name: '冻结金额'
        },
        btms: {
            recharge: '充值',
            rechargeRecord: '充值记录',
            withdraw: '提现',
            withdrawRecord: '提现记录',
        }
    },
    content: {
        changeTypeShow: '类型',
        currency: '货币种类',
        changeAmount: '数量',
        createTime: '时间',
        withdrawalQuantity: '提现数量',
        address: '提现地址',
        rechargeQuantity: '充值数量',
        rechargeAddress: '充值地址',
    },
    extractdDialog: {
        title: '提现',
        chainId: '转账网络',
        address: '提现地址',
        amount: '提现数量',
        serviceCharge: '手续费 0-10',
        toTheAccount: '到账数量',
        tradePass: '请输入资金密码确认提现！',
        fundPassword: '确认资金密码',
        subBtn: '确定',
        withdraw: '提现',
        warnTitle: '温馨提示',
        warninfo1: '最小提现数量为 : ',
        warninfo2: '为保证账号安全,当您账户安全策略变更、密码修改、我们会对体现进行人工审核',
        warninfo3: '请耐心等待工作人员电话或邮件联系',
        warninfo4: '请务必确认电脑及浏览器的安全,防止信息被篡改或泄露',
        rules: {
            chainId: "请输入转账网络",
            address: "请输入提现地址",
            amount:'请输入转账数量',
            serviceCharge: "请输入手续费",
            toTheAccount: "请输入到账数量",
            tradePass:'请输入资金密码确认提现',
            fundPassword:'确认资金密码',
            balance:'提现数量不能大于剩余金额',
            isNaN:'请全文输入阿拉伯数字且最小值为',
        },
        subsuccess:'操作成功',
    },
    rechargeDialog: {
        chainId: '充值网络',
        title: '充值',
        rechargeAddress: '充值地址',
        warnTitle: '温馨提示',
        warninfo1: '请勿向上述地址充值任何非USDT资产, 否则资产将不可找回。',
        warninfo2: '最小重置金额 : 1USDT, 小于最小金额的充值将不会上账目无法退回。',
        warninfo3: '您的充值地址不会经常改变,可以重复充值如有更改,我们会尽量通过网站公告或邮件通知您。',
        warninfo4: '请务必确认电脑及浏览器的安全,防止信息被篡改或泄露。',
    },
    changeTypeShows: {
        RECHARGE: '充值',
        BUY: '买入',
        SELL: '卖出',
        WITHDRAW: '提现',
        TRANSFER_IN: '转入',
        TRANSFER_OUT: '转出',
        COMMISSION: '佣金',
    }
};