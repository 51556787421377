/**
* en
*/
export default {
    bannerName: 'Quick Trading Center',
    bannerInfo: 'Safe, Fast, Convenient',
    buyAmount: 'Purchase quantity',
    rujinAmount: 'Deposit amount',
    tradeRate: 'Exchange rate',
    paymentAmount: 'Payment amount',
    rujinjine: 'Deposit amount',
    tradeFee: 'Handling fees',
    patternofPayment: 'Please select payment method',
    sellersChoice: 'Select seller',
    sellersSearch: 'Search for sellers',
    countDown: 'Countdown',
    orderNo: 'Order number',
    remittanceAmount: 'Payment amount',
    clientProceedsWay: 'Customer payment method',
    name: 'Name',
    bankAccount: 'Bank account number',
    bankName:'Bank name',
    WeChat: 'WeChat payment',
    Alipay: 'Alipay payment',
    getQr: 'View payment code',
    buyNow: 'Buy Now',
    cancelBtn: 'Cancel',
    scheduleStatus: 'Progress status',
    sellName: 'Trading partner',
    buyUser: 'Deposit recipient',
    remittanceNow: 'Immediate remittance',
    contactPlatform: 'Contact platform',
    subAppeal: 'Submit an appeal',
    toPay: 'Go pay',
    openIm: 'Contact the seller',
    verifyProceeds: 'Confirm receipt',
    cancelOrder: 'Cancel order',
    WECHATPAY: 'WeChat payment',
    ALIPAY: 'Alipay',
    BANK_CARD: 'Bank transfer',
    rules: {
        payTypeId: 'Please select payment method',
        searchKey: 'Please select a seller',
        appealDesc: 'Please enter the appeal information'
    },
    message: {
        searchUser:'No match to the seller',
        success: 'Operation successful',
        mkOrder: 'Order successful! Please prepare to remit to the seller.',
        orderCancel: 'Order cancelled',
    },
    confirm: {
        hint: 'Tip',
        info: 'Confirm payment has been made',
        cancelInfo: 'Cancel current order',
        rejectInfo: 'Reject current order',
        confirmInfo: 'Confirmed remittance',
        confirmBtn: 'Click after payment',
        cancelBtn: 'Unpaid',
        confirm: 'Confirm',
        cancel: 'Cancel',
    }
}