var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.left},[_c('div',{ref:"avatar",class:_vm.$style['avatar-icon']}),_c('div',{class:{
            [_vm.$style['chat-icon']]: true,
            [_vm.$style.active]: _vm.model === 'chat',
          },on:{"click":() => (_vm.model = 'chat')}},[_c('i',{class:{
              [_vm.$style['iconfont']]: true,
              iconfont: true,
              'icon-message_fill_light': true,
            }}),_c('div',{class:_vm.$style['icon-label']},[_vm._v(" "+_vm._s(_vm.$t("im.conversation"))+" ")])]),(false)?_c('div',{class:{
            [_vm.$style['contact-icon']]: true,
            [_vm.$style.active]: _vm.model === 'contact',
          },on:{"click":() => (_vm.model = 'contact')}},[_c('i',{class:{
              [_vm.$style['iconfont']]: true,
              iconfont: true,
              'icon-tongxunlu1': true,
            }}),_c('div',{class:_vm.$style['icon-label']},[_vm._v(_vm._s(_vm.$t("im.addressBook")))])]):_vm._e(),(false)?_c('div',{class:_vm.$style['logout-icon'],on:{"click":_vm.logOut}},[_c('i',{class:{
              [_vm.$style['iconfont']]: true,
              iconfont: true,
              'icon-logout': true,
            }}),_c('div',{class:_vm.$style['icon-label']},[_vm._v("退出")])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.model === 'chat'),expression:"model === 'chat'"}],class:_vm.$style.right},[_c('div',{ref:"conversation",class:_vm.$style['right-list']}),_c('div',{ref:"chat",class:_vm.$style['right-content']})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.model === 'contact'),expression:"model === 'contact'"}],class:_vm.$style.right},[_c('div',{ref:"contactList",class:_vm.$style['right-list']}),_c('div',{ref:"contactInfo",class:_vm.$style['right-content']})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }