/**
* ko
*/
export default {
    bannerName: '마이페이지',
    bannerInfo: '자금 안전 신속 편리한 P2P거래 ',
    header: {
        item1: {
            lebel: '사용자',
            info: ''
        },
        item2: {
            lebel: '번',
            info: '총주문（구매/판매）'
        },
        item3: {
            lebel: '분',
            info: '평균처리'
        },
        item4: {
            lebel: '번',
            info: '1일 완성주문'
        },
        item5: {
            lebel: '%',
            info: '1일 완성율'
        }
    },
    userInfo: {
        title: '개인정보',
        name: '성명',
        code: '사용자번호',
        nationality: '국가',
        recordDate: '가입일',
    },
    accountInfo: {
        title: '계정설정',
        password: '로그인 비밀번호',
        passwordDialog: {
            diaLog: {
                title: '로그인 비밀번호 변경',
                oldPassword: '기존 로그인 비밀번호',
                newPassword: '새 로그인 비밀번호',
                verifyNewPassword: '새 로그인 비밀번호 확인',
                subBtn: '변경',
                rules: {
                    oldPassword: '기존 로그인 비밀번호 입력 ',
                    newPassword: '새 로그인 비밀번호 입력 ',
                    verifyNewPassword: '새 로그인 비밀번호 확인 ',
                    verifyNewPasswordError: '새 로그인 비밀번호가 일치 하지 않습니다',
                },
            },
        },
        transactionPin: '거래 비밀번호',
        transactionPinDialog: {
            diaLog: {
                title: '거래 비밀번호 변경',
                oldPassword: '기존 거래 비밀번호 ',
                newPassword: '새 거래 비밀번호',
                verifyNewPassword: '새 거래 비밀번호 확인',
                subBtn: '변경',
                rules: {
                    oldPassword: '기존 로그인 비밀번호 입력 ',
                    newPassword: '새 로그인 비밀번호 입력',
                    verifyNewPassword: '새 로그인 비밀번호 확인',
                    verifyNewPasswordError: '새 로그인 비밀번호가 일치 하지 않습니다',
                },
            },
        },
        authentication: {
            label: '본인인증',
            info0: '본인인증 자료를 제출 하십시오',
            info2: '본인인증 자료 제출 완료',
            info3: '본인인증 자료를 제출 하십시오',
            info4: '인증완료 ',
            button0: '본인인증 신청',
            button2: '관리자의 심사를 기다려 주십시오',
            button3: '본인인증 신청',
            button4: '인증완료',
        },
        phone: {
            label: '휴대폰',
            info: '인증완료  : ',
            wrong: '미연동',
            button: '휴대폰연동',
            diaLog: {
                title: '연동된 휴대폰번호',
                resetTitle: '연동된 휴대폰번호 변경',
                phone: '휴대폰번호',
                code: '인증코드',
                codeBtn: '인증코드 발송',
                newCodeBtn: '인증코드 발송',
                subBtn: '제출',
                rules: {
                    phone: '휴대폰번호를 입력 하십시오',
                    code: '인증코드를 입력 하십시오',
                }
            },
        },
        mail: {
            label: '메일',
            info: '인증완료 : ',
            wrong: '미연동',
            button: '메일연동',
            diaLog: {
                title: '메일연동',
                resetTitle: '연동된메일 변경',
                email: '메일주소',
                code: '인증코드',
                codeBtn: '인증코드 발송',
                newCodeBtn: '인증코드 발송',
                subBtn: '제출',
                rules: {
                    email: '메일주소 입력 하십시오',
                    code: '인증코드를 입력 하십시오',
                }
            },
        },
        paymentProcedure: {
            title: '결제방식 선택',
            titleBnt: '은행계좌 추가',
            label: '은행계좌',
            paytypeState: '미인증',
            paytypeState1: '심사중',
            paytypeState2: '미승인',
            paytypeState3: '승인완료',
            diaLog: {
                title: '은행계좌 추가',
                realName: '성명',
                bankName: '은행',
                paytypeId: '계좌유형',
                paytypeName: '예금주',
                paytypeNo: '은행계좌번호',
                paytypeNoWECHATPAY: 'wechatpay',
                subBtn: '확인',
                rules: {
                    realName: '성명을 입력 하십시오',
                    bankName: '은행명을 입력 하십시오',
                    paytypeId: '결제방식 선택',
                    paytypeName: '예금주 성명을 입력 하십시오',
                    paytypeNo: '은행계좌번호를 입력 하십시오',
                    paytypeNoWECHATPAY: 'wechatpay계정을 입력 하십시오',
                    collectionImg: 'QR코드 업로드',
                },
            },
            editDialog: {
                title: '결제방식 변경',
                realName: '성명',
                paytypeState: '계좌상태',
                paytypeState1: '심사중',
                paytypeState2: '미승인',
                paytypeState3: '승인완료',
                paytypeId: '수금방식',
                paytypeName: '예금주',
                bankName: '은행',
                paytypeNo: '은행계좌',
                paytypeNoWECHATPAY: 'wechatpay',
                subBtn: '확인',
                deleteBtn: '삭제',
                rules: {
                    realName: '성명을 입력 하십시오',
                    paytypeState: '     ',
                    bankName: '은행명을 입력 하십시오',
                    paytypeId: '결제방식을 선택 하십시오',
                    paytypeName: '예금주 성명을 입력 하십시오',
                    paytypeNo: '은행계좌번호를 입력 하십시오',
                    paytypeNoWECHATPAY: 'wechatpay계정을 입력 하십시오',
                    collectionImg: 'QR코드를 업로드 하십시오',
                },
            }
        },
        confirm: {
            authenticationTitle: '알림',
            authenticationInfo1: '인증자료가 없습니다. 계속 인증 하시겠습니까?',
            authenticationInfo2: '인증자료 심사중 잠시만 기다려 주십시오',
            confirmBtn: '확인',
            cancelBtn: '취소',
        }
    }
};
