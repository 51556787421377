/**
* zh_CN
*/
export default {
    sellScheduleStatusStart0: '구매자 결제 대기중 입니다.',
    buyScheduleStatusStart0: '판매자 수금방식에 따라 송금 하십시오.',
    sellScheduleStatusStart1: '주문 취소 되였습니다.',
    buyScheduleStatusStart1: '주문 취소 되였습니다.',
    sellScheduleStatusStart2: '구매자 송금 완료 되였습니다.',
    chujinScheduleStatusStart2: '출금 승인 대기중 입니다.',
    buyScheduleStatusStart2: '판매자 USDT 전송 대기중 입니다.',
    sellScheduleStatusStart3: '주문이 완성 되였습니다',
    buyScheduleStatusStart3: '완성',
    sellScheduleStatusStart4: '중재중',
    buyScheduleStatusStart4: '중재중',
    sellScheduleStatusStart5: '중재거절',
    buyScheduleStatusStart5: '중재거절',
    sellScheduleStatusStart6: '거절',
    buyScheduleStatusStart6: '거절',
    UNPAID_PAYMENTS: '미수금 ',
    NO_PAY: '미지불',
    CANCELED: '취소',
    PENDING_PAYMENT: '수금확인',
    PAID_PAYMENTS: '미수금USDT',
    SUCCESS: '완성',
    IN_APPEAL: '중재중',
    REJECTED: '거절',
    APPLY_WITHDRAW: '출금신청',
    AWAIT_SEND_COIN: '대기환 화폐',
    AWAIT_RECEIVE_COIN: '미수금USDT',
    APPLY_DEPOSIT: '입금신청',
}