<template>
  <div class="content">
    <el-form :model="formData" :rules="rules" ref="formData" class="formData">
      <el-form-item class="formData-item">
        <div class="formData-item-label">
          {{ $t("clientSell.Step1.label") }}
        </div>
        <div class="formData-item-value">
          <div class="formData-item-value-item">
            <div class="formData-item-value-item-name">
              {{ $t("clientSell.Step1.tradeAmount") }}
            </div>
            <div class="formData-item-value-item-value">
              {{ utils.numberFormatting(checkResData.tradeAmount) }}USDT
            </div>
          </div>
          <div class="formData-item-value-item">
            <div class="formData-item-value-item-name">
              {{ $t("clientSell.Step1.currencyAmount") }}
            </div>
            <div class="formData-item-value-item-value">
              {{ utils.numberFormatting(checkResData.currencyAmount)
              }}{{ checkResData.symbol }}
            </div>
          </div>
          <div class="formData-item-value-item">
            <div class="formData-item-value-item-name">
              {{ $t("clientSell.Step1.orderNo") }}
            </div>
            <div class="formData-item-value-item-value">
              {{ checkResData.orderNo }}
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="userName" class="formData-item">
        <div class="formData-item-label">
          {{ $t("clientSell.Step2.label") }}
        </div>
        <el-input v-model="formData.userName"></el-input>
      </el-form-item>
      <el-form-item prop="address" class="formData-item">
        <div class="formData-item-label">
          {{ $t("clientSell.Step3.label") }}
        </div>
        <el-input
          v-model="formData.address"
          :placeholder="$t('clientSell.Step3.placeholder')"
          class="formData-item-input"
        />
        <el-button
          type="info"
          class="formData-item-btn"
          @click="exportAddress(false)"
          >{{ $t("clientSell.exportAddress") }}</el-button
        >
      </el-form-item>
      <el-form-item class="formData-item">
        <div class="formData-item-option">
          <el-checkbox
            v-model="formData.checkbox"
            size="medium"
            class="formData-item-checkbox"
          />
          <span
            @click="toAgreement()"
            class="formData-item-describe formData-item-agreement"
            >{{ $t("clientSell.agreement.txt1") }}</span
          >
          <span class="formData-item-describe">{{
            $t("clientSell.agreement.txt2")
          }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="footerBox">
      <el-button type="info" class="sub" @click="submit">{{
        $t("clientSell.sellBtn")
      }}</el-button
      ><el-button type="info" plain class="cancel" @click="goBack">{{
        $t("clientSell.cancelBntn")
      }}</el-button>
    </div>
    <LogInDialog ref="LogInDialog" @logInBack="exportAddress" />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import { sell } from "@/api/client";
import { assetInfo } from "@/api/estate";
export default {
  name: "",
  components: {},
  data() {
    const formDataCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        return callback(new Error(this.$t(`clientSell.rules.` + rule.field)));
      }
    };
    return {
      formData: {
        userName: "",
        address: "",
        checkbox: false,
      },
      shopId: "",
      userInfo: null,
      checkResData: {
        tradeAmount: "",
        tradeAmount: "",
        orderNo: "",
      },
      rules: {
        userName: [
          {
            required: true,
            validator: formDataCheck,
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            validator: formDataCheck,
            trigger: "blur",
          },
        ],
        checkbox: [
          {
            required: true,
            validator: formDataCheck,
            trigger: "chenge",
          },
        ],
      },
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$nextTick(() => {
        this.$refs["formData"].resetFields();
      });
    },
  },
  mounted() {
    this.shopId = this.$route.params.shopId;
    if (sessionStorage.getItem("formData")) {
      this.formData = Object.assign(
        this.formData,
        JSON.parse(sessionStorage.getItem("formData"))
      );
    }
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }
    if (sessionStorage.getItem("checkResData")) {
      this.checkResData = JSON.parse(sessionStorage.getItem("checkResData"));
    }
  },
  methods: {
    // 获取地址
    exportAddress(type) {
      let isLogIn =
        this.$store.state.userInfo.userInfo &&
        this.$store.state.userInfo.userInfo.userId;
      if (isLogIn) {
        assetInfo().then((res) => {
          if (res.code == 200) {
            this.formData.address = res.data.address;
            this.$refs["formData"].validateField(["address"], (valid, vv) => {
              if (!valid) {
                console.log("校验成功");
              }
            });
          }
        });
        if (type && this.formData.checkbox) {
          this.submit(type);
        }
      } else {
        this.$refs["LogInDialog"].openDialog();
      }
    },
    // 返回上一步
    goBack() {
      this.$emit("pageChange", 1);
    },
    // 提交
    submit(type) {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (!this.formData.checkbox) {
            this.$tooltipPop.open(null, true, {
              type: "error",
              info: [this.$t(`clientSell.message.agreement`)],
            });
            return;
          }
          let data = {
            shopId: this.shopId,
            orderNo: this.userInfo.orderNo,
            tradeTye: this.formData.tradeTye,
            computeMode: this.formData.computeMode,
            currencyCode: this.formData.currencyCode,
            inputAmount: this.formData.inputAmount,
            userName: this.formData.userName,
            address: this.formData.address,
          };
          sell(data).then((res) => {
            if (res.code == 200) {
              this.$refs['TooltipPop'].openDialog("", false, {
                type: "affirm",
                info: [
                  this.$t("clientSell.tooltipList.txt1"),
                  this.$t("clientSell.tooltipList.txt2"),
                ],
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    toAgreement() {
      window.open("/agreement", "_blank");
    },
    tooltipBack(flag, isCancel) {
      if (
        this.$store.state.userInfo.userInfo &&
        this.$store.state.userInfo.userInfo.userId
      ) {
        let data = {
          orderNo: this.checkResData.orderNo,
          amount: this.checkResData.tradeAmount,
          isClient: true,
          orderType: "2",
        };
        sessionStorage.setItem("clientData", JSON.stringify(data));
        this.$router.push("/pendingOrders");
      } else {
        this.$emit("pageChange", 1);
      }
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6.77083vw;
  .formData {
    .formData-item {
      .formData-item-label {
        width: 31.66667vw;
        height: 2.08333vw;
        background: #019393;
        font-size: 0.9375vw;
        display: flex;
        align-items: center;
        color: #ffffff;
        padding: 0.5vw 1vw;
        border-radius: 5px 5px 5px 5px;
        margin-bottom: 1.35417vw;
        box-sizing: border-box;
      }
      .formData-item-value {
        .formData-item-value-item {
          width: 100%;
          font-size: 0.937vw;
          line-height: 1vw;
          margin-bottom: 1vw;
          display: flex;
          justify-content: flex-start;
          padding-left: 1vw;
          .formData-item-value-item-name {
            width: 5vw;
            display: flex;
            justify-content: space-between;
            margin-right: 1vw;
          }
          .formData-item-value-item-value {
            font-size: 1vw;
            font-weight: bold;
          }
        }
      }
      .formData-item-input {
        width: calc(100% - 12.30208vw);
        margin-right: 1vw;
      }
      .formData-item-btn {
        width: 11.30208vw;
        background: #000000;
        border: 1px solid #313131;
      }
      .formData-item-btn:hover {
        background: rgba(112, 112, 112, 0.98);
      }
      .formData-item-btn:active {
        background: #313131 !important;
      }
      .formData-item-option {
        width: 100%;
        .formData-item-checkbox {
          margin-right: 18px;
        }
        .formData-item-describe {
          font-size: 0.9375vw;
        }
        .formData-item-agreement {
          cursor: pointer;
          border-bottom: 1px solid #cccccc;
        }
        .formData-item-agreement:hover {
          color: #019393;
          border-bottom: 1px solid #019393;
        }
      }
    }
  }
  .footerBox {
    display: flex;
    justify-content: space-between;
    .sub {
      width: 42.03125vw;
      margin-right: 1vw;
      background: #000000;
    }
    .sub:hover {
      background: rgba(112, 112, 112, 0.98);
    }
    .sub:active {
      background: #313131 !important;
    }
    .cancel {
      width: 19.42708vw;
      color: #000000;
      background: #ffffff !important;
    }
  }
}
::v-deep .el-input__inner {
  background: #f8f8f8;
  border: none;
}
</style>
