/**
* ko
*/
export default {
  userAgreement: '이용약관',
  privacyProtection: '개인정보보호정책',
  announcementCenter: '공지사항',
  customerCenter: '고객센터',
  FAQ: 'FAQ',
  beginnerGuide: '이용가이드',
  announcement: {
      agreement: '이용약관',
      announcement: '공지사항',
      customerCenter: '고객센터',
      FAQ: 'FAQ',
      privacyAgreement: '개인정보보호정책',
      rookieGuide: '이용가이드',
      bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
      title: '제목',
      userName: '연락인',
      userPhone: '전화',
      userEmail: '메일',
      content: '내용',
      sendBtn: '발송',
      message: {
        success: '실행성공',
      },
      rules: {
          title: '제목을 입력 하십시오',
          userName: '연락인을 입력 하십시오',
          userPhone: '전화번호를 입력 하십시오',
          userEmail: '메일주소를 입력 하십시오',
          content: '내용을 입력 하십시오',
      }
  }
};