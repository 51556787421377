<template>
  <div class="body">
    <div class="banner">
      <div class="banner-title">{{$t('footer.announcement.FAQ')}}</div>
      <div class="banner-info">{{$t('footer.announcement.bannerInfo')}}</div>
      <img src="@/assets/brands.png" class="banner-ime" alt="" />
    </div>
    <div class="content" v-loading="loading">
      <el-collapse
        @change="handleChange(item)"
        v-for="(item, index) in activeNames"
        :key="item.noticeId"
      >
        <el-collapse-item :name="index">
          <template slot="title">
            <div class="collapse-title">
              <div class="collapse-title-name">
                <div class="collapse-title-name-num">{{ index + 1 }}</div>
                <div class="collapse-title-name-name">
                  {{ item.noticeTitle }}
                </div>
              </div>
              <div class="collapse-title-date">{{ item.createTime }}</div>
            </div>
          </template>
          <div v-html="item.noticeContent"></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <Pagination
      :page="page"
      :size="size"
      :total="total"
      @callbackPage="callbackPage"
    />
  </div>
</template>

<script>
import { announcement, announcementByID } from "@/api/announcement";
export default {
  name: "agreement",
  components: {},
  data() {
    return {
      activeNames: [],
      page: 1,
      size: 10,
      total: 0,
      loading:false
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let data = {
        noticeType: "4", //4,5,6 (常见问题，新手指南，公告中心)
        page: this.page,
        size: this.size,
      };
      this.loading = true
      announcement(data).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.activeNames = [...res.data.list];
            this.page = res.data.pages;
            this.size = res.data.pageSize;
            this.total = res.data.total;
            this.loading = false;
          });
        }
      });
    },
    handleChange(item) {},
    // 分页回调
    callbackPage({ page, size, total }) {
      this.page = page;
      this.size = size;
      this.init();
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.body {
  width: 100vw;
  box-sizing: border-box;
  margin-left: -22vw;
  margin-top: 48px;
}
.banner {
  width: 100%;
  height: 10.41667vw;
  padding: 0 18.69792vw;
  box-sizing: border-box;
  background: linear-gradient(0deg, #f7f7f7, #fafafa);
  position: relative;
  .banner-title {
    font-size: 2.60417vw;
    font-weight: bold;
    margin-bottom: 1.25vw;
  }
  .banner-info {
    font-size: 0.9375vw;
  }
  .banner-ime {
    width: 10.26042vw;
    height: 10.98958vw;
    position: absolute;
    top: 1.82292vw;
    left: 77.5969vw;
  }
}
.content {
  padding: 0 18.69792vw;
  padding-top: 5.36458vw;
  padding-bottom: 6.71875vw;
  .collapse-title {
    width: 100%;
    height: 3.125vw;
    display: flex;
    justify-content: space-between;
    .collapse-title-name {
      display: flex;
      justify-content: flex-start;
      .collapse-title-name-num {
        width: 3.125vw;
        height: 3.125vw;
        line-height: 3.125vw;
        font-size: 1.30208vw;
        color: #ffffff;
        background: #019393;
        display: flex;
        justify-content: center;
        margin-right: 1.77083vw;
      }
      .collapse-title-name-name {
        font-size: 1.04167vw;
      }
    }
    .collapse-title-date {
      font-size: 0.78125vw;
    }
  }
}
::v-deep .el-collapse-item__header {
  height: 5.26042vw !important;
  padding: 1.09375vw 0;
}
::v-deep .el-collapse-item__content {
  background: #ededed !important;
  padding: 1.875vw 1.4583vw 1.875vw 4.8958vw;
}
::v-deep .el-icon-arrow-right:before {
  content: none;
}
</style>
