import api from '@/utils/request'
// 发送短信验证码
export function sendPhoneCode(data) {
    return api.post('/api/v1/common/sendPhoneCode', data)
}

// 发送邮箱验证码
export function sendMailCode(data) {
    return api.post('/api/v1/common/sendMailCode', data)
}
// 退出登录
export function logout(data) {
    return api.get('/api/v1/user/logout')
}
// 上传文件
export function upload(data) {
    return api.post('/api/v1/user/upload',data)
}