/**
* en
*/
export default {
    bannerName: 'Quick Trading Center',
    bannerInfo: 'Safe, Fast, Convenient',
    buyBtn: 'Buy',
    buyPrice: 'Purchase price',
    buyAmount: 'Purchase quantity',
    sellBtn: 'Sell',
    sellPrice: 'Selling price',
    sellAmount: 'Sales quantity',
    subBuybtn: 'Buy',
    subSellbtn: 'Sell',
    placeholder:'Please enter content',
    rules: {
        buyPrice: 'Please enter the purchase price',
        buyAmount: 'Please enter the purchase quantity',
        sellPrice: 'Please enter the selling price',
        sellAmount: 'Please enter the sales quantity',
        type:'Please select the type'
    },
    warnInfo: [
        {
            title: "reminders",
            values: [
                `1. Each transaction is automatically matched to the seller by the platform. The seller's account information may be different from time to time, please remit according to the seller's account information.`,
                `2. User purchase request USDT at the same time seller's USDT is frozen, so you can trade safely.`,
                `3. Please leave the comments blank when sending money. Absolute no transactions (e.g. virtual currency, USDT, Bitcoin, foreign exchange, etc.) allow the seller to refuse the transaction.`,
                `4. The platform will not be responsible for the amount wrongly deposited by the user due to the error of account information.`,
                `5. Must be purchased with registered bank account. Otherwise, the USDT you purchased will be frozen and you will not be able to withdraw coins.`,
                `6. Hours of Operation - Transactions outside the 9:00 to 21:00 hours of operation may be delayed by the seller.`,
            ],
        },
    ],
    sellWarnInfo: [
        {
            title: "reminders",
            values: [
                `1. The platform automatically matches buyers with each transaction.`,
                `2. When the user requests to sell USDT, the user's USDT will be frozen and processed, and the USDT will be deposited into the buyer's account after the payment is confirmed.`,
                `3. The security transaction guarantee system is launched, and the transaction between the seller and the buyer is guaranteed 100% security by the platform.`,
                `4. Operating hours: 9:00-21:00 Transactions outside operating hours may delay the processing of the buyer's remittance.`,
            ],
        },
    ],
}