<template>
  <div class="body" v-loading="loading">
    <SmallBanners
      :title="$t('signIn.bannerName')"
      :info="$t('signIn.bannerInfo')"
    />
    <div class="content">
      <div class="header">
        <div class="header-btnbox">
          <el-button
            type="primary"
            class="header-button"
            :plain="formData.registerType != 1"
            @click="signInTypeChange(1)"
            >{{ $t("signIn.phoneRegister") }}</el-button
          >
        </div>
        <!-- <div class="header-btnbox">
          <el-button
            type="primary"
            class="header-button"
            :plain="formData.registerType != 2"
            @click="signInTypeChange(2)"
            >{{ $t("signIn.emailRegister") }}</el-button
          >
        </div> -->
      </div>
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-position="left"
        :hide-required-asterisk="true"
      >
        <div class="content-form-area">
          <div class="content-form-area-warn">
            {{ $t("signIn.mustFillIn") }}
          </div>
          <div class="content-form-area-box">
            <el-form-item prop="countryCode">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.countryCode") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-select
                ref="currencyType"
                v-model="formData.countryCode"
                filterable
                placeholder=""
                class="content-form-area-box-input"
                @change="
                  (val) => changeSelection(formData.countryCode, 'currencyType')
                "
              >
                <el-option
                  v-for="item in currencyTypes"
                  :key="item.id"
                  :value="item.label"
                >
                  <img
                    :src="item.src"
                    style="
                      width: 20px;
                      height: 20px;
                      vertical-align: text-bottom;
                    "
                  />
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-form-area-box" @click="$refs.focus1.focus()">
            <el-form-item prop="loginName">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.loginName") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                  ref="focus1"
                v-model="formData.loginName"
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" v-if="formData.registerType == 1" @click="$refs.focus2.focus()">
            <el-form-item
              :prop="formData.registerType == 1 ? 'phoneNumber' : ''"
            >
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.phoneNumber") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                  ref="focus2"
                v-model="formData.phoneNumber"
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" v-if="formData.registerType == 2" @click="$refs.focus2.focus()">
            <el-form-item :prop="formData.registerType == 2 ? 'mail' : ''">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.mail") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                  ref="focus3"
                v-model="formData.mail"
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" @click="$refs.focus4.focus()">
            <el-form-item prop="verificationCode">
              <span slot="label" class="content-form-area-box-label">
                <span>{{
                  formData.registerType == 1
                    ? $t("signIn.formLabel.phoneVerificationCode")
                    : $t("signIn.formLabel.mailVerificationCode")
                }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                type="text"
                ref="focus4"
                v-model="formData.verificationCode"
                class="content-form-area-box-verificationCode"
              ></el-input>
              <div class="content-form-area-box-sendCodeBox">
                <el-button
                  type="primary"
                  class="content-form-area-box-sendCode"
                  :disabled="this.sendCodecountDown != 120"
                  @click="sendCode"
                  >{{
                    this.sendCodecountDown != 120
                      ? `${$t("signIn.formLabel.newCode")}(${
                          this.sendCodecountDown
                        })`
                      : $t("signIn.formLabel.sendCode")
                  }}</el-button
                >
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="content-form-area">
          <div class="content-form-area-warn">{{ $t("signIn.setPsd") }}</div>
          <div class="content-form-area-box" @click="$refs.focus5.focus()">
            <el-form-item prop="password">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.password") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                v-model="formData.password"
                ref="focus5"
                show-password
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" @click="$refs.focus6.focus()">
            <el-form-item prop="verifyPassword">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.notarizePassword") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                  ref="focus6"
                v-model="formData.verifyPassword"
                show-password
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" @click="$refs.focus7.focus()">
            <el-form-item prop="tradePassword">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.tradePassword") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                v-model="formData.tradePassword"
                show-password
                ref="focus7"
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" @click="$refs.focus8.focus()">
            <el-form-item prop="verifyTradePassword">
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.notarizeTradePassword") }}</span>
                <span class="content-form-area-box-label-flag">*</span>
              </span>
              <el-input
                v-model="formData.verifyTradePassword"
                show-password
                ref="focus8"
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-form-area-box" @click="$refs.focus9.focus()">
            <el-form-item>
              <span slot="label" class="content-form-area-box-label">
                <span>{{ $t("signIn.formLabel.inviteId") }}</span>
                <!-- <span class="content-form-area-box-label-flag">*</span> -->
              </span>
              <el-input
                v-model="formData.inviteId"
                ref="focus9"
                class="content-form-area-box-input"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="content-form-area">
          <div class="content-form-area-warn">
            {{ $t("signIn.userAgreement") }}
          </div>
          <div class="content-form-area-box content-form-area-agreement">
            <el-checkbox
              v-model="formData.userAgreement"
              class="content-form-area-box-checkbox"
            />
            <span
              @click="toAgreement(e)"
              class="content-form-area-box-describe content-form-area-box-agreement"
              >{{ $t("signIn.formLabel.userAgreementBtn") }}</span
            >
            <span class="content-form-area-box-describe">{{
              $t("signIn.formLabel.userAgreementInfo")
            }}</span>
          </div>
        </div>
      </el-form>
      <el-button
        type="primary"
        class="content-signIn"
        @click="userAgreementOpen"
        >{{ $t("signIn.signInBtn") }}</el-button
      >
    </div>
    <TooltipPop ref="TooltipPop" @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import { sendPhoneCode, sendMailCode } from "@/api/common";
import { register } from "@/api/signIn";
import obj from "../../utils/common.js";
export default {
  name: "",
  components: {},
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        if (
          rule.field == "verifyPassword" &&
          this.formData.verifyPassword != this.formData.password
        ) {
          callback(new Error(this.$t("signIn.rules." + rule.field + "Error")));
          return;
        }
        if (
          rule.field == "verifyTradePassword" &&
          this.formData.verifyTradePassword != this.formData.tradePassword
        ) {
          callback(new Error(this.$t("signIn.rules." + rule.field + "Error")));
          return;
        }
        callback();
      } else {
        return callback(new Error(this.$t("signIn.rules." + rule.field)));
      }
    };
    return {
      loading: false,
      sendCodeTime: null,
      sendCodecountDown: 120,
      formData: {
        registerType: 1, // 注册方式类型 1：手机号码注册，2：邮箱注册
        countryCode: "", // 国家代码 82：韩国，86：中国
        mail: "",
        loginName: "",
        phoneNumber: "",
        verificationCode: "",
        password: "",
        verifyPassword: "",
        tradePassword: "",
        verifyTradePassword: "",
        inviteId: "",
        userType: "200", // 200:普通用户，201企业用户，202:代理商
        userAgreement: false,
      },
      rules: {
        countryCode: [
          { required: true, validator: formRulesCheck, trigger: "change" },
        ],
        loginName: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        phoneNumber: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        mail: [{ required: true, validator: formRulesCheck, trigger: "blur" }],
        verificationCode: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        password: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        verifyPassword: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        tradePassword: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        verifyTradePassword: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
        inviteId: [
          { required: true, validator: formRulesCheck, trigger: "blur" },
        ],
      },
      currencyTypes: obj.nationality
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["formData"].resetFields();
      this.currencyTypes = this.$store.state.common.nationalitys;
      this.$refs["currencyType"].$el.children[0].children[0].setAttribute(
        "style",
        `background: url(""}) no-repeat;`
      );
    },
  },
  created() {},
  mounted() {
    let lables = Object.keys(this.formData);
    lables.forEach((i) => {
      this.formData[i];
      if (i == "countryCode" && this.formData[i]) {
        this.changeSelection(this.formData[i], "currencyType");
      }
    });
  },
  methods: {
    tooltipBack(flag, isCancel) {
      if (flag == "userAgreementOpen" && !isCancel) {
        this.formData.userAgreement = true;
        this.$nextTick(() => {
          this.subForm();
        });
      }
      if (flag == "subForm" && !isCancel) {
        this.$router.push("/login");
      }
    },
    // 跳转预览用户协议
    toAgreement() {
      window.open("/agreement", "_blank");
    },
    // 注册会员
    subForm() {
      this.loading = true;
      let data = { ...this.formData };
      data.countryCode = this.countryCodeChewck(this.formData.countryCode);
      register(data).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          this.$refs["TooltipPop"].openDialog("subForm", false, {
            type: "affirm",
            info: [this.$t("signIn.success")],
          });
          setTimeout(()=>{
            this.$router.push("/logIn")
          },2000)
        }
      }).finally(()=>{
        this.loading = false
      });
    },
    // 注册会员
    userAgreementOpen() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.formData.userAgreement == false) {
            this.$refs["TooltipPop"].openDialog("userAgreementOpen", false, {
              type: "affirm",
              info: [this.$t("signIn.rules.userAgreement")],
            });
            return;
          } else {
            this.subForm();
          }
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      this.$refs["formData"].validateField(["countryCode"], (valid, vv) => {
        if (!valid) {
          if (this.formData.registerType == 1) {
            if (this.formData.phoneNumber) {
              let data = {
                actionType: 1, // 1:注册验证码，2：找回密码发送验证码,3:个人中心绑定手机号
                countryCode: this.countryCodeChewck(this.formData.countryCode),
                phoneNumber: this.formData.phoneNumber,
              };
              sendPhoneCode(data).then((res) => {
                if (res.code == 200) {
                  this.startTime();
                }
              });
            } else {
              this.$tooltipPop.open(null, true, {
                type: "error",
                info: [this.$t("signIn.rules.phoneNumber")],
              });
            }
          } else {
            if (this.formData.mail) {
              let data = {
                actionType: 1, // 1:注册验证码，2：找回密码发送验证码,3:个人中心绑定手机号
                countryCode: this.countryCodeChewck(this.formData.countryCode),
                mail: this.formData.mail,
              };
              sendMailCode(data).then((res) => {
                if (res.code == 200) {
                  this.startTime();
                }
              });
            } else {
              this.$tooltipPop.open(null, true, {
                type: "error",
                info: [this.$t("signIn.rules.mail")],
              });
            }
          }
        }
      });
    },
    startTime() {
      this.sendCodeTime = setInterval(() => {
        this.sendCodecountDown -= 1;
        if (this.sendCodecountDown == 0) {
          clearInterval(this.sendCodeTime);
          this.sendCodecountDown = 120;
        }
      }, 1000);
    },
    // 切换注册方式
    signInTypeChange(type) {
      this.$refs["formData"].resetFields();
      this.formData.verificationCode = "";
      this.formData.registerType = type;
      this.$refs["currencyType"].$el.children[0].children[0].setAttribute(
        "style",
        `background: url(""}) no-repeat;`
      );
    },
    // 选择国籍
    changeSelection(val, refName) {
      for (let index in this.currencyTypes) {
        let obj = this.currencyTypes[index];
        if (obj.label == val) {
          this.$refs[refName].$el.children[0].children[0].setAttribute(
            "style",
            `
            background: url(${obj.src}) no-repeat;
            background-size:20px 20px !important;
            background-position: 20px 50%;
            background-size:43%;
            padding-left:45px;
            `
          );
        }
      }
    },
  },
  computed: {
    countryCodeChewck() {
      return (val) => {
        let item = this.currencyTypes.find(
          (i) => (i.label || i.eLabel || i.cLabel) == val
        );
        let flag = item.countryCode;
        return flag;
      };
    },
  },
};
</script>
<style scoped lang='scss'>
::v-deep .el-form-item__content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content {
  padding-bottom: 5vw;
  .header {
    width: 100%;
    margin-bottom: 3.541667vw;
    .header-btnbox {
      display: inline-block;
      margin-right: 1vw;
      .header-button {
        //width: 8.85417vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .content-form-area {
    margin-bottom: 4.16667vw;
    .content-form-area-warn {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 1.92709vw;
    }
    .content-form-area-box {
      // border-top: 1px solid #ededed;
      padding: 0 20px;
      background: #f2f2f2;
      margin-bottom: 30px;
      border-radius: 12px;
      ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
      ::v-deep .el-input__inner {
        border: none;
        background: #f2f2f2;
        text-align: right;
        height: 60px;
      }
      .content-form-area-box-label {
        font-weight: 600;
        .content-form-area-box-label-flag {
          color: #019393;
          margin-left: 4px;
        }
      }
      .content-form-area-box-input {
        width: 100%;
      }
      .content-form-area-box-verificationCode {
        width: 100%;
        margin-right: 10px;
      }
      .content-form-area-box-sendCodeBox {
        width: 9.29167vw;
        display: inline-block;
        .content-form-area-box-sendCode {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .content-form-area-box-checkbox {
        margin-right: 18px;
      }
      .content-form-area-box-agreement {
        cursor: pointer;
        // border-bottom: 1px solid #cccccc;
      }
      .content-form-area-box-agreement:hover {
        color: #019393;
        border-bottom: 1px solid #019393;
      }
    }
    .content-form-area-box:last-child {
      // border-bottom: 1px solid #ededed;
      position: relative;
    }
    .content-form-area-agreement {
      padding: 20px;
    }
  }
}
.content-signIn {
  width: 10.41667vw;
  margin-left: 26.04167vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-select .el-input--suffix .el-input__suffix {
  right: 0;
  i {
    padding-left: 0;
  }
}
// ::v-deep .el-form-item {
//   margin-bottom: 10px !important;
// }
// ::v-deep .el-form-item__error {
//   bottom: 0;
//   transform: translateY(-200%);
// }
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
::v-deep .el-form-item__label {
  width: 17.44792vw !important;
}
::v-deep .el-form-item__error {
  width: 17.44792vw !important;
  margin-left: 17.44792vw !important;
}
::v-deep .el-checkbox__inner {
  transform: scale(1.5) !important;
}
</style>
