<template>
  <div>
    <el-upload
      class="upload-demo"
      ref="upload"
      action=""
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-change="beforeChange"
      :file-list="fileList"
      :auto-upload="false"
    >
      <el-button slot="trigger" size="small"
        ><i class="el-icon-upload2" />选取文件</el-button
      >
      <span style="margin-left: 10px" v-if="fileList.length == 0"
        >未选取任何文件</span
      >

      <div slot="tip" class="el-upload__tip"></div>
    </el-upload>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="uploadFile">下载模板</el-button>
      <el-button type="success" @click="handleSubmit">确定导入</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "uploadFile",
  components: {},
  props: {
    limit: "",
  },
  data() {
    return {
      fileList: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    uploadFun() {
      let data = new FormData();
      this.fileList.forEach((i) => {
        data.append("files", i.raw);
      });
      data.append("jsonParam", JSON.stringify(submitData));
      batchOrderDeal(data)
        .then((res) => {
          if (res.code == "0000") {
            this.goBack();
          } else {
            this.$tooltipPop.open(null, true, {
              type: "error",
              info: [res.msg],
            });
          }
        })
        .catch((res) => {
          this.$tooltipPop.open(null, true, {
            type: "error",
            info: [res.msg],
          });
        });
    },
    //上传文件
    beforeChange(file) {
      this.fileList.push(file);
    }, //删除
    handleRemove(file, fileList) {
      this.fileList = fileList;
    }, //删除前
    beforeRemove(file, fileList) {},
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
</style>
