import axios from "axios";
import { Message } from 'element-ui'
import { baseURL } from '@/utils/baseURL'
import { getToken } from './auth'
import $store from "@/store";
import Vue from "vue";
let token = getToken()
const instance = axios.create({
    baseURL: '',
    timeout: 100 * 1000,
})
// 请求拦截器
// 1.请求拦截器，在发请求之前
instance.interceptors.request.use(config => {
    config.headers['Authorization'] = token
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['lang'] = $store.state.common.i18n
    return config
},
    error => {
        Promise.reject(error)
    })
// 响应拦截器
instance.interceptors.response.use(response => {
    if (response.status === 404) {
        Toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true
        });
    } else if (response.status === 500) {
        Toast({
            message: '内部服务器错误',
            duration: 1500,
            forbidClick: true
        });
    } else {
        if (response.data.code != 200) {
            if (response.data.msg == 'TOKEN_ERROR') {
                $store.dispatch("userInfo/setUserInfo", null);
                window.vm._router.push('/logIn')
            } else {
                Vue.$tooltipPop.open(null, true, { type: 'error', info: [window.vm.$t("requestCode." + response.data.msg)] });
            }
        }
    }
    return Promise.resolve(response.data);
},
    error => {
        if (error.response.status) {
            return Promise.reject(error.response);
        }
    })

// GET 请求
export function get(url, params) {
    return instance.get(url, { params });
}

// POST 请求
export function post(url, data) {
    return instance.post(url, JSON.stringify({ ...data }));
}
// 对外暴露前面创建的构造方法
export default instance
