<template>
  <div class="body">
    <div class="banner">
      <div class="banner-title">{{ $t("footer.announcement.agreement") }}</div>
      <div class="banner-info">{{ $t("footer.announcement.bannerInfo") }}</div>
      <img src="@/assets/brands.png" class="banner-ime" alt="" />
    </div>
    <div class="content" v-loading="loading" ref="content" v-html="html"></div>
  </div>
</template>

<script>
import { notice } from "@/api/announcement";
export default {
  name: "agreement",
  components: {},
  data() {
    return {
      html: "",
      loading: false,
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      notice("user_agreement").then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.html = res.data.notice;
            this.loading = false;
          });
        }
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.body {
  width: 100vw;
  box-sizing: border-box;
  margin-left: -22vw;
  margin-top: 48px;
}
.banner {
  width: 100%;
  height: 10.41667vw;
  padding: 0 18.69792vw;
  box-sizing: border-box;
  background: linear-gradient(0deg, #f7f7f7, #fafafa);
  position: relative;
  .banner-title {
    font-size: 2.60417vw;
    font-weight: bold;
    margin-bottom: 1.25vw;
  }
  .banner-info {
    font-size: 0.9375vw;
  }
  .banner-ime {
    width: 10.26042vw;
    height: 10.98958vw;
    position: absolute;
    top: 1.82292vw;
    left: 77.5969vw;
  }
}
.content {
  padding: 0 18.69792vw;
  padding-top: 5.36458vw;
  padding-bottom: 6.71875vw;
}
</style>
