import api from '@/utils/request'
// 获取挂单交易设置信息
export function orderTalkSetInfo(data) {
    return api.get('/api/v1/user/orderTalkSet/info')
}
// 更新交易限制
export function updateTradeLimit(data) {
    return api.post('/api/v1/user/orderTalkSet/updateTradeLimit', data)
}
// 接单开关
export function currencyOnOff(data) {
    return api.post('/api/v1/user/orderTalkSet/currencyOnOff', data)
}