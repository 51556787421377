<template>
  <div class="body">
    <SmallBanners
      :title="$t('estate.bannerName')"
      :info="$t('estate.bannerInfo')"
    />
    <div class="content">
      <div class="header">
        <div class="header-record">
          <div class="header-record-name">{{ $t(`estate.header.title`) }}</div>
          <div class="header-record-BtnBox">
            <el-button
              type="info"
              class="header-record-BtnBox-btn"
              :class="
                tableType == 'withdraw' ? 'header-record-BtnBox-current' : ''
              "
              plain
              @click="tableSwitchover('withdraw')"
              >{{ $t(`estate.header.btms.withdrawRecord`) }}</el-button
            >
            <el-button
              type="info"
              class="header-record-BtnBox-btn"
              :class="
                tableType == 'recharge' ? 'header-record-BtnBox-current' : ''
              "
              plain
              @click="tableSwitchover('recharge')"
              >{{ $t(`estate.header.btms.rechargeRecord`) }}</el-button
            >
          </div>
        </div>
        <div class="header-existing">
          <div class="header-existing-info">
            <div class="header-existing-info-item mr">
              <div class="header-existing-info-item-left">
                {{ $t(`estate.header.item1.name`) }}
              </div>
              <div class="header-existing-info-item-right ar">
                <span class="money mr">{{ userInfo.balanceShow }}</span>
                <span>USDT</span>
              </div>
            </div>
            <div class="header-existing-info-item">
              <div class="header-existing-info-item-left">
                {{ $t(`estate.header.item2.name`) }}
              </div>
              <div class="header-existing-info-item-right ar">
                <span class="money mr">{{ userInfo.freezeAmountShow }}</span>
                <span>USDT</span>
              </div>
            </div>
          </div>
          <div class="header-existing-BtnBox">
            <el-button
              type="info"
              class="header-existing-BtnBox-btn"
              @click="opendialog('WithdrawDialog', userInfo)"
              >{{ $t(`estate.header.btms.withdraw`) }}</el-button
            >
            <el-button
              type="info"
              class="header-existing-BtnBox-btn"
              @click="opendialog('RechargeDialog', userInfo)"
              >{{ $t(`estate.header.btms.recharge`) }}</el-button
            >
          </div>
        </div>
      </div>
      <div class="tableBox" v-loading="loading">
        <template v-if="tableType == ''">
          <el-table :data="tableData" ref="table" :cell-style="rowStyle">
            <el-table-column
              show-overflow-tooltip
              prop="changeTypeShow"
              :label="$t(`estate.content.changeTypeShow`)"
              align="center"
            >
              <template slot-scope="scope">
                {{ $t(`estate.changeTypeShows.${scope.row.changeTypeShow}`) }}
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t(`estate.content.currency`)"
              align="center"
            >
              <template> USDT </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="changeAmountShow"
              :label="$t(`estate.content.changeAmount`)"
              align="center"
            />
            <el-table-column
              show-overflow-tooltip
              prop="createTime"
              :label="$t(`estate.content.createTime`)"
              align="center"
            >
              <template slot-scope="scope">
                {{ timeCheck(scope.row.timestamp) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="tableType == 'withdraw'">
          <el-table :data="tableData" ref="table" :cell-style="rowStyle">
            <el-table-column
              show-overflow-tooltip
              prop="totalAmountShow"
              :label="$t(`estate.content.withdrawalQuantity`)"
              align="center"
            />
            <el-table-column
              show-overflow-tooltip
              prop="address"
              :label="$t(`estate.content.address`)"
              align="center"
            />
            <el-table-column
              show-overflow-tooltip
              prop="createTime"
              :label="$t(`estate.content.createTime`)"
              align="center"
            >
              <template slot-scope="scope">
                {{ timeCheck(scope.row.timestamp) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="tableType == 'recharge'">
          <el-table :data="tableData" ref="table" :cell-style="rowStyle">
            <el-table-column
              show-overflow-tooltip
              prop="amountShow"
              :label="$t(`estate.content.rechargeQuantity`)"
              align="center"
            />
            <el-table-column
              show-overflow-tooltip
              prop="address"
              :label="$t(`estate.content.rechargeAddress`)"
              align="center"
            />
            <el-table-column
              show-overflow-tooltip
              prop="createTime"
              :label="$t(`estate.content.createTime`)"
              align="center"
            >
              <template slot-scope="scope">
                {{ timeCheck(scope.row.timestamp) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
        <SearchDialog @popoverSearch="popoverSearch" type="estate" />
        <Pagination
          :page="page"
          :size="size"
          :total="total"
          @callbackPage="callbackPage"
        />
      </div>
    </div>
    <RechargeDialog ref="RechargeDialog" @dialogBack="dialogBack" />
    <WithdrawDialog ref="WithdrawDialog" :txMin="this.userInfo.txMin" @dialogBack="dialogBack" />
  </div>
</template>

<script>
import {
  assetInfo,
  assetChangelist,
  withdrawList,
  rechargeList,
} from "@/api/estate";
import RechargeDialog from "./components/rechargeDialog";
import WithdrawDialog from "./components/withdrawDialog";
import moment from "moment";
export default {
  name: "estate",
  components: { RechargeDialog, WithdrawDialog },
  data() {
    return {
      loading: false,
      tableType: "", //'':默认列表, recharge:充值记录,withdraw:提现记录
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      userInfo: {
        balanceShow: "",
        freezeAmountShow: "",
      },
      searchData: {},
    };
  },
  created() {
    // 查询个人资产
    assetInfo().then((res) => {
      if (res.code == 200) {
        this.userInfo = res.data;
        console.log('this.txMin',this.userInfo)
      }
    });
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      //在数据加载完，重新渲染表格
      this.$refs["table"].doLayout();
    });
  },
  methods: {
    // 查询列表
    init(searchData) {
      let data = {
        page: this.page,
        pageSize: this.size,
      };
      if (searchData && searchData.start) {
        data.beginTime = searchData.start
      }
      if (searchData && searchData.end) {
        data.endTime = searchData.end
      }
      if (searchData && searchData.changeType) {
        data.changeType = searchData.changeType
      }
      this.loading = true;
      assetChangelist(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.setPage(res);
          this.loading = false;
        }
      });
    },
    callbackPage({ page, size, total }) {
      this.page = page;
      this.size = size;
      if (this.tableType != "") {
        this.tableSwitchover(this.tableType);
      } else {
        this.init();
      }
    },
    // 打开弹窗
    opendialog(refName, userInfo) {
      this.$refs[refName].opendialog(userInfo);
    },
    dialogBack() {
      this.callbackPage();
    },
    //table切换
    tableSwitchover(value, isSearch) {
      if (!isSearch) {
        if (this.tableType == value) {
          this.tableType = "";
        } else {
          this.tableType = value;
        }
      }
      let data = {
        page: this.page,
        pageSize: this.size,
      };
      if (this.tableType == "recharge") {
        rechargeList(data).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.setPage(res);
          }
        });
      } else if (this.tableType == "withdraw") {
        withdrawList(data).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.setPage(res);
          }
        });
      } else {
        this.init();
      }
    },
    // 修改单元格样式
    rowStyle({ row, column, rowIndex, columnIndex }) {
      let str = "";
      if (
        column.label == this.$t(`estate.content.changeAmountShow`) ||
        column.label == this.$t(`estate.content.rechargeQuantity`) ||
        column.label == this.$t(`estate.content.amount`)
      ) {
        str = str + "color:#019393;";
      }
      if (
        column.label == this.$t(`estate.content.rechargeQuantity`) ||
        column.label == this.$t(`estate.content.extractAddress`)
      ) {
        str = str + "width:60%;";
      }
      return str;
    },
    setPage(res) {
      this.page = res.data.pageNum;
      this.size = res.data.pageSize;
      this.total = res.data.total;
      this.$forceUpdate();
    },
    popoverSearch(data) {
      this.tableType = ''
      this.searchData = data;
      this.init(data);
    },
  },
  computed: {
    timeCheck() {
      return (time) => {
        return moment(time).format("YYYY-MM-DD H:mm:ss");
      };
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 4vw;
  .header {
    width: 100%;
    background: #eeeeee;
    padding: 1vw;
    box-sizing: border-box;
    margin-bottom: 1vw;
    .header-record {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5vw;
      .header-record-name {
        width: 60%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.30208vw;
        font-weight: bold;
      }
      .header-record-BtnBox {
        width: 40%;
        text-align: right;
        .header-record-BtnBox-btn {
          width: 7.5vw;
          text-align: center;
          background: #ffffff !important;
          border: 1px solid #313131;
          color: #313131 !important;
        }
        .header-record-BtnBox-btn:active {
          border: 1px solid #000000;
        }
        .header-record-BtnBox-btn:hover {
          background: rgba(231, 231, 231, 0.98) !important;
          color: #000000 !important;
        }
        .header-record-BtnBox-current {
          background: #ffffff !important;
          border: 1px solid #019393;
          color: #019393 !important;
        }
        .header-record-BtnBox-current:active {
          border: 1px solid #019393 !important;
        }
        .header-record-BtnBox-current:hover {
          background: rgba(231, 231, 231, 0.98) !important;
          border: 1px solid #019393;
          color: #019393 !important;
        }
      }
    }
    .header-existing {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .header-existing-info {
        width: 60%;
        display: flex;
        justify-content: flex-start;
        .mr {
          margin-right: 0.5vw;
        }
        .header-existing-info-item {
          //width: 15.625vw;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #ffffff;
          background: #019393;
          border-radius: 5px;
          padding: 0 1.3541vw;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          //强制内容不换行
          //white-space: nowrap;
          //隐藏超出内容
          //overflow: hidden;
          //超出内容变成省略号
          //text-overflow: ellipsis;
          .ar {
            text-align: right;
          }
          .mr {
            margin-right: 10px;
          }
          .money {
            color: #ffff00;
          }
          .header-existing-info-item-left {
            min-width: 75px;
          }
          .header-existing-info-item-right {
            flex: 1;
          }
        }
      }
      .header-existing-BtnBox-btn {
        width: 7.5vw;
      }
      .header-existing-BtnBox-btn:active {
        color: rgba(61, 58, 58, 0.98);
        background: rgba(94, 94, 94, 0.98) !important;
      }
    }
  }
}

// 修改头部样式
::v-deep .el-table .el-table__header th {
  background: #019393 !important;
  color: #ffffff !important;
}
// // 隐藏tabel底部伪元素
// ::v-deep .el-table::before {
//   height: 0px !important;
// }
// // 隐藏tabel表头边框线
// ::v-deep .el-table__cell {
//   /* 去除上边框 */
//   border: none !important;
// }

// // 隐藏tabel单元格边框线
// ::v-deep .el-table__row > td {
//   /* 去除表格线 */
//   border: none !important;
// }
::v-deep .el-table__header {
  border-radius: 0.26042vw !important;
  overflow: hidden !important;
}
// 修改按钮样式 ↓
::v-deep .header-existing-BtnBox-btn {
  background: #313131 !important;
  color: #ffffff !important;
}
::v-deep .header-existing-BtnBox-btn:active {
  background: rgba(141, 141, 141, 0.98) !important;
  color: #ffffff !important;
}
::v-deep .header-existing-BtnBox-btn:hover {
  background: rgba(70, 70, 70, 0.98) !important;
  color: #ffffff !important;
}
</style>
