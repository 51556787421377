/**
* en
*/
export default {
    copy: {
        title: 'Tip',
        info: 'Copy successful',
        button: 'Confirm',
    },
    bankList: {
        PAY_TYPE_KRW_BANK: 'South Korea(KRW)Bank account number',
        PAY_TYPE_CNY_BANK: 'China(CNY)Bank account number',
        PAY_TYPE_CNY_WECHATPAY: '(CNY)WeChat payment',
        PAY_TYPE_CNY_ALIPAY: '(CNY)Alipay payment',
        PAY_TYPE_JPY_BANK: 'Japan(JPY)Bank account number',
        PAY_TYPE_HKD_BANK: 'Hong Kong(HKD)Bank account number',
        PAY_TYPE_VND_BANK: 'Vietnam(VND)Bank account number',
        PAY_TYPE_PHP_BANK: 'Philippines(PHP)Bank account number',
        PAY_TYPE_MYR_BANK: 'Malaysia(MYR)Bank account number',
        PAY_TYPE_GBP_BANK: 'Britain(GBP)Bank account number',
        PAY_TYPE_EUR_BANK: 'Europe(EUR)Bank account number',
        PAY_TYPE_USD_BANK: 'USA(USD)Bank account number'
    },
    minBankList: {
        BANK_CARD: 'Bank transfer',
        WECHATPAY: 'WeChat payment',
        ALIPAY: 'Alipay payment',
    },
    tooltipPop:{
        success:'Cuccess',
        error:'Error',
        affirm:'Confirm'
    }
}
