<template>
  <div class="body" v-if="pageTotal > 1">
    <div class="content">
      <el-link :underline="false" @click="prev('1')" :disabled="current == 1"
        ><i class="el-icon-d-arrow-left icons"
      /></el-link>
      <el-link :underline="false" @click="prev" :disabled="current == 1"
        ><i class="el-icon-arrow-left icons"
      /></el-link>

      <el-link
        v-for="item in btns"
        :key="item"
        :underline="false"
        class="btns"
        :class="item == current ? 'flagBtn' : ''"
        @click="nowadayChange(item)"
        >{{ item }}</el-link
      >

      <el-link :underline="false" @click="next" :disabled="current == pageTotal"
        ><i class="el-icon-arrow-right icons"
      /></el-link>
      <el-link
        :underline="false"
        @click="next('1')"
        :disabled="current == pageTotal"
        ><i class="el-icon-d-arrow-right icons"
      /></el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  components: {},
  props: {
    // 页码
    page: {
      type: Number,
      required: true,
      default: 0,
    },
    // 数量
    size: {
      type: Number,
      required: true,
      default: 0,
    },
    // 总数
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      btns: [],
      pageTotal: 0, //总页数
      currentSize: 10, //每页几条
      current: 1, // 当前第几页
      accumulation: 0, //计数 逢10  +1或者-1 用于计算当前分页计算
      time: false, // 防抖
    };
  },
  watch: {
    total() {
      this.pageTotalCheck();
      this.countNum();
    },
    page() {
      this.current = this.page;
      this.pageTotalCheck();
      this.countNum();
    },
    size() {
      this.currentSize = this.size;
      this.pageTotalCheck();
      this.countNum();
    },
    deep: true,
    immediate: true,
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.size && this.total) {
        this.pageTotalCheck();
        this.countNum();
      }
    });
  },
  methods: {
    // 上翻
    prev(flag) {
      if (this.time) return;
      this.time = true;
      let timeOut = setTimeout(() => {
        clearTimeout(timeOut);
        this.time = false;
        if (flag == 1) {
          this.current = 1;
          this.accumulation = 0;
          this.countNum("prev");
        } else {
          if (this.current > this.btns[0]) {
            this.current = this.current - 1;
          }
          if (this.accumulation > 0) {
            this.current = this.current - 1;
            this.accumulation = this.accumulation - 1;
          }
        }
        this.$forceUpdate();
        this.callback();
      }, 200);
    },
    // 下翻
    next(flag) {
      if (this.time) return;
      this.time = true;
      let timeOut = setTimeout(() => {
        clearTimeout(timeOut);
        this.time = false;
        if (flag == 1) {
          this.current = this.pageTotal;
          this.accumulation = Number(parseInt(this.pageTotal / 10));
          this.countNum("next");
        } else {
          if (this.current < this.btns[this.btns.length - 1]) {
            this.current = this.current + 1;
          }
          if (this.current < this.pageTotal) {
            this.current = this.current + 1;
            this.accumulation = this.accumulation + 1;
          }
        }
        this.$forceUpdate();
        this.callback();
      }, 200);
    },
    // 计算总数
    countNum() {
      this.$nextTick(() => {
        let arr = [];
        for (var i = 1; i <= this.pageTotal; i++) {
          arr.push(i);
        }
        if (arr.length <= 10) {
          this.btns = arr;
        } else {
          let num = this.accumulation * 10;
          this.btns = [];
          let maxNum = num + 10;
          arr.forEach((i) => {
            if (i > num && i <= maxNum) {
              this.btns.push(i);
            }
          });
        }
      });
    },
    // 返回函数
    callback() {
      this.$emit("callbackPage", {
        page: this.current,
        size: this.currentSize,
        total: this.total,
      });
    },
    // 指向跳转
    nowadayChange(item) {
      this.$nextTick(() => {
        this.current = item;
        this.callback();
      });
    },
    // 计算总页数
    pageTotalCheck() {
      this.$nextTick(() => {
        if (this.total == 0) {
          this.pageTotal = 0;
        } else {
          let num = this.total / this.size;
          this.pageTotal = Math.ceil(num);
        }
      });
    },
  },
  computed: {
    totalChack() {
      return this.total / this.size;
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  text-align: center;
  margin-top: 2vw;
  font-size: 0.9375vw;
}
.icons {
  width: 1.5vw;
  font-size: 1.2vw;
  font-weight: bold;
}
.icons:hover {
  color: #019393;
}
.btns {
  width: 2vw;
  text-align: center;
  font-size: 0.88541vw;
}
.btns:hover {
  color: #019393;
}
.flagBtn {
  font-weight: bold;
  color: #019393;
}
</style>
