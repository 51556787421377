/**
* zh_CN
*/
export default {
    bannerName: '入金中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    countDown: '倒计时',
    orderNo: '订单号',
    tradeTye: '入金',
    price: '入金金额',
    possessor: '入金数量',
    sellInfo: '卖家信息',
    payeeUser: '收款人',
    quantity: '入金数量',
    payTypeId: '选择支付方式',
    addressHint: '* 购买后要接收USDT的钱包地址',
    address: '钱包地址',
    bank: '银行',
    payType: '支付方式',
    WeChat: '微信支付',
    Alipay: '支付宝支付',
    bankNo: '银行账号',
    remittance: '已汇款 (汇款好点击)',
    canelOrder: '取消订单',
    subBtn: '立即入金',
    cancel: '取消',
    // 2
    Step1: {
        label: 'Step1 : 请确认购买数量和购买金额',
        tradeAmount: '入金数量:',
        currencyAmount: '入金金额:',
        orderNo: '订 单 号:'
    },
    Step2: {
        label: 'Step2 : 请输入汇款人姓名',
    },
    Step3: {
        label: 'Step3 : 请输入本人手机号码',
    },
    agreement: {
        txt1: '用户协议',
        txt2: '请确认后点击同意。',
    },
    rules: {
        price: '请输入购买金额',
        quantity: '请输入购买数量',
        payTypeId: '请选择支付方式',
        userName: '请输入汇款人姓名',
        tel: '请输入本人手机号码',
    },
    message: {
        agreement: '请确认用户协议后点击同意'
    },
    tooltipList: {
        txt1: '您是根据卖家的账户信息进行汇款的吗？',
        txt2: '恶意点击账户可能会被冻结。',
    },
    confirm: {
        hint: '确定取消订单?',
        title: '提示',
        subBtn: '确定',
        cancelBtn: '取消',
    },
    warnInfo: [
        {
            title: "注意事项",
            values: [
                `1. 每次交易都是由平台自动配对卖方。卖方的账户信息每次都有可能不同，请根据卖方的账户信息汇款。`,
                `2. 用户购买的货币从卖方直接汇入第三方平台。`,
                `3. 用户因账户信息错误而存入错误的金额，平台不负责。`,
                `4. 为了资金安全，请汇入本人账户。`,
                `5. 运营时间- 9:00 ~ 21:00，运营时间以外的交易，卖方处理可能会晚。`,
                `6. 为了防止洗钱，您购买的USDT可能在24小时后转账或取款。`,
                `7. 汇款时请空格!(虚拟货币，USDT，比特币，外汇交易等禁止)卖方可以拒绝交易。`,
            ],
        },
    ],
    warnInfo3: [
        {
            title: "注意事项",
            values: [
                `1.平台不支持自动转账。请直接用您的银行账户汇款给卖方。`,
                `2.卖方的USDT保证金已被冻结，请放心汇款。`,
                `3.汇款时不要填写ETH, USDT, BTC及其他信息。否则卖方可能拒绝交易。`,
            ],
        },
    ],
};
