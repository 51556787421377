/**
* en
*/
export default {
    // 登录
    loginName: 'User ID',
    password:'Login password',
    validateCode:'Verification code',
    logInBtn: 'Login',
    forgetThePassword: 'Forgot password',
    // 忘记密码
    phoneReset:'Reset with phone number',
    mailReset:'Reset with email',
    username: 'User ID',
    countryCode: 'Nationality',
    phonenumber: 'Bind phone number',
    email: 'Bind email',
    code:'Verification code',
    codeBtn1: 'Resend',
    codeBtn2: 'Send code',
    newpassword:'New login password',
    verifyPassword:'Confirm new login password',
    resetBtn: 'Reset',
    message: {
      success: 'Success',
    },
    logInRules:{
        // 登录
        loginName:'Please enter the user ID',
        password:'Please enter your login password',
        validateCode:'Please enter the verification code',
        // 忘记密码
        username:'Please enter the user ID',
        countryCode: 'Please select nationality',
        phonenumber: "Please enter the bound phone number",
        email: "Please enter the bound email address",
        code: "Please enter the verification code",
        newpassword: "Please enter a new password",
        verifyPassword: "Please confirm the new password",
        verifyPasswordError: "The new password you entered does not match. Please confirm",
    }
};