/**
* ko
*/
export default {
    banner: {
        title: 'USDT P2P 거래 플랫폼',
        info: '자금 안전 신속 편리한 P2P거래  ',
    },
    tableTitle: '오늘 환율',
    tableLabel: {
        symbol: '화폐종류',
        tradingUnit: '거래단위',
        buyingPrice: '구매가격',
        sellingPrice: '판매가격',
    },
    reasonTitle: 'Umarket 선택하는 이유',
    reasonItem: {
        item1: '편리한 결제 시스템 연결',
        item2: '언제든지 입금 & 출금 체크 가능',
        item3: '안전 신속 편리한 USDT 구매 & 판매',
    },
    confirm: {
        authenticationTitle: '알림',
        authenticationInfo: '본인인증 신청<br/>계속 진행하시겠습니까?',
        confirmBtn: '확인',
        cancelBtn: '취소',
        authToSell: "휴대폰버전으로 실명인증을 진행하여 주십시오."
    }
};
