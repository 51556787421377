
<template>
  <div class="homeBody">
    <div class="brand-box">
      <img src="@/assets/brands.png" class="brand-box-img" alt="" />
      <div class="brand-box-info">
        <div class="brand-box-info-title">
          {{ $t("loginHome.banner.title") }}
        </div>
        <div class="brand-box-info-info">{{ $t("loginHome.banner.info") }}</div>
      </div>
    </div>
    <div class="content-box">
      <div class="content-box-reminder">
        <div class="content-box-reminder-title">
          {{ $t("loginHome.tableTitle") }}
        </div>
        <div class="tabel">
          <el-table
            :data="tabelCheck(allCurrency)"
            ref="table"
            v-loading="loading"
          >
            <el-table-column align="center" width="100">
              <template #header>
                <div class="tabelSelect" @click="showList">
                  <span v-if="showListType">▲</span>
                  <span v-else>▼</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="table-item">
                  <img
                    :src="imgUrl(scope.row.logo)"
                    alt=""
                    class="tanel-falg"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('loginHome.tableLabel.symbol')"
              align="center"
            >
              <template slot-scope="scope">
                <span>USDT</span>
                <span>&nbsp;/</span>
                <span>
                  {{ scope.row.symbol }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('loginHome.tableLabel.tradingUnit')"
              align="center"
            >
              1
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('loginHome.tableLabel.buyingPrice')"
              align="center"
            >
              <template slot-scope="scope">
                {{ numCheck(scope.row.buyPrice) }}
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('loginHome.tableLabel.sellingPrice')"
              align="center"
            >
              <template slot-scope="scope">
                {{ numCheck(scope.row.sellPrice) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-box-reason">
        <div class="content-box-reason-title">
          {{ $t("loginHome.reasonTitle") }}
        </div>
        <div class="content-box-reason-list">
          <div class="content-box-reason-list-item">
            <img
              src="@/assets/login/login_icon1.png"
              class="content-box-reason-list-item-img"
              alt=""
            />
            <div class="content-box-reason-list-item-info">
              {{ $t("loginHome.reasonItem.item1") }}
            </div>
          </div>
          <div class="content-box-reason-list-item">
            <img
              src="@/assets/login/login_icon2.png"
              class="content-box-reason-list-item-img"
              alt=""
            />
            <div class="content-box-reason-list-item-info">
              {{ $t("loginHome.reasonItem.item2") }}
            </div>
          </div>
          <div class="content-box-reason-list-item">
            <img
              src="@/assets/login/login_icon3.png"
              class="content-box-reason-list-item-img"
              alt=""
            />
            <div class="content-box-reason-list-item-info">
              {{ $t("loginHome.reasonItem.item3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import { baseURL } from "@/utils/baseURL";
import { getSymbol, getAllCurrency, getAllCoin, myInfo } from "@/api/login";
import ResetBankAccountDialog from "@/views/userInfo/components/resetBankAccountDialog.vue";
export default {
  name: "",
  components: {ResetBankAccountDialog},
  data() {
    return {
      tooltipList: [],
      loading: false,
      showListType: false,
      // 法币信息
      allCurrency: [],
      attestationType: "",
    };
  },

  created() {
    this.getSymbol();
    this.getAllCurrency();
    this.getAllCoin();
  },
  mounted() {
    if (sessionStorage.getItem("logIn") == "true") {
      this.openConfirm();
    }
  },
  methods: {
    showList() {
      console.log("showList");
      this.showListType = !this.showListType;
    },
    tooltipBack(falg, isCancel) {
      console.log('isCancel',falg)
      console.log('isCancel',isCancel)
      if (falg == "openConfirm" && !isCancel) {
        this.$router.push("/authentication");
      }
      if (falg == "openConfirm" && isCancel) {
        sessionStorage.setItem("logIn", "");
      }
    },
    openConfirm() {
      // 判断实名认证
      /*this.$tooltipPop.open(null, true, {
        type: "affirm",
        info: [this.$t("loginHome.confirm.authenticationInfo")],
      });*/
      this.$refs['TooltipPop'].openDialog("openConfirm", false, {
        type: "affirm",
        info: [this.$t("loginHome.confirm.authToSell")],
      });
    },
    // 获取交易币对
    getSymbol() {
      getSymbol().then((res) => {
        this.$store.dispatch("common/getSymbol", res.data);
      });
    },
    // 获取所有法币信息
    getAllCurrency() {
      this.loading = true;
      getAllCurrency().then((res) => {
        this.allCurrency = res.data;
        this.$store.dispatch("common/getAllCurrency", res.data);
        this.loading = false;
      });
    },
    // 获取所有币种信息
    getAllCoin() {
      getAllCoin().then((res) => {
        this.$store.dispatch("common/getAllCoin", res.data);
      });
    },
  },
  computed: {
    tabelCheck() {
      return (tabelList) => {
        let list = [];
        let flag = "KRW";
        if (tabelList && tabelList.length > 0) {
          if (window.localStorage.getItem("lang") == "zh_CN") {
            flag = "CNY";
          }
          tabelList.forEach((i) => {
            if (this.showListType) {
              list.push(i);
            } else {
              if (i.symbol == flag) {
                list.push(i);
              }
            }
          });
        }
        return list;
      };
    },
    numCheck() {
      return (num) => {
        return this.utils.numberFormatting(num);
      };
    },
    imgUrl() {
      return (img) => {
        return baseURL + img;
      };
    },
  },
  destroyed() {
    sessionStorage.removeItem("logIn");
  },
};
</script>
<style scoped lang='scss'>
.brand-box {
  width: 100vw;
  height: 24vw;
  position: relative;
  background: #f7f7f7;
  .brand-box-img {
    position: absolute;
    width: 16vw;
    height: 17vw;
    top: 56%;
    right: 18.85417vw;
    transform: translateY(-50%);
  }
  .brand-box-info {
    min-width: 34.21875vw;
    position: absolute;
    top: 50%;
    left: 18.90625vw;
    transform: translateY(-50%);
    .brand-box-info-title {
      font-size: 3vw;
      font-weight: bold;
    }
    .brand-box-info-info {
      font-size: 1.5vw;
      margin-top: 1vw;
    }
  }
}
.content-box {
  box-sizing: border-box;
  width: 100vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .content-box-reminder {
    width: 56.6042vw;
    margin-bottom: 5vw;
    .content-box-reminder-title {
      font-size: 2.08333vw;
      font-weight: bold;
      margin-bottom: 0.78125vw;
      text-align: center;
    }
    .tabel {
      .tabelSelect {
        cursor: pointer;
      }
      .table-item {
        width: 100%;
        height: 100%;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 36px;
          height: 36px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .content-box-reason {
    width: 56.6042vw;
    .content-box-reason-title {
      font-size: 2.08333vw;
      font-weight: bold;
      margin-bottom: 0.78125vw;
      text-align: center;
    }
    .content-box-reason-list {
      width: 100%;
      height: 15.625vw;
      display: flex;
      justify-content: space-between;
      .content-box-reason-list-item {
        width: 19.79167vw;
        height: 100%;
        display: inline-block;
        background: #f7f7f7;
        text-align: center;
        .content-box-reason-list-item-img {
          width: 6.61458vw;
          height: 6.61458vw;
          margin-top: 2.86458vw;
          margin-bottom: 3.17708vw;
        }
        .content-box-reason-list-item-info {
          font-size: 0.9375vw;
        }
      }
    }
  }
}
// --------------------
// 修改头部样式
::v-deep .el-table .el-table__header th {
  background: #019393 !important;
  color: #ffffff !important;
}
::v-deep .el-table__header {
  border-radius: 0.26042vw !important;
  overflow: hidden !important;
}
// 修改row >>> cell样式
::v-deep .el-table .cell {
  font-weight: bold;
  font-size: 0.9375vw;
}
</style>
