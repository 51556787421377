/**
* en
*/
export default {
  PARAMETER_ERROR: 'Parameter error',
  SEND_CODE_FREQUENT: 'Frequent sending of verification codes',
  LOGIN_NAME_HAS_BEEN_REGISTERED: 'The username already exists',
  PHONE_NUMBER_HAS_BEEN_REGISTERED: 'The phone number has been registered',
  EMAIL_HAS_BEEN_REGISTERED: 'The email has been registered',
  INVALID_INVITE_CODE: 'Invalid invitation code',
  VALIDATE_CODE_ERROR: 'Invalid verification code',
  INVALID_VERIFICATION_CODE: 'Invalid verification code',
  LOGIN_NAME_OR_PASSWORD_ERROR: 'Incorrect username or password',
  PHONE_NUMBER_ERROR: 'Mobile phone number error',
  EMAIL_ERROR: 'Email error',
  CURRENCY_CODE_ERROR: 'Currency code error',
  NEED_AUTH_PASS_FIRST_ERROR: "Please conduct real name authentication first and wait for the administrator's approval",
  NEED_BIND_BANK_CARD_AND_APPROVE: 'Please add your bank card first, review and open it before proceeding with the transaction',
  ORDER_MIN_NUM_ERROR: 'Not meeting the minimum transaction quantity',
  ORDER_MAX_NUM_ERROR: 'Exceeding maximum transaction quantity',
  ORDER_STATUS_CANNOT_BE_OPERATE: 'Order status does not allow this operation',
  NO_OPERATION_PERMISSION: 'No operation permission',
  SHOP_ID_NOT_EXIST: 'Merchant does not exist',
  CLIENT_NO_SELLER_ERROR: 'There are no sellers who can provide services',
  CLIENT_NO_BUYER_ERROR: 'There are no buyers who can provide services',
  ORDER_NO_EXPIRE: 'Order number has expired',
  ORDER_NO_ERROR: 'Order number does not exist',
  SHOP_BALANCE_NOT_ENOUGH: 'Insufficient merchant balance',
  INVALID_UMARKET_ADDRESS: 'Invalid umarket address',
  NO_REGISTER: 'Unregistered',
  SYSTEM_CONFIGURATION_ERROR: 'System configuration error',
  BAD_REQUEST: 'Wrong request',
  READDRESS_INVALID: 'Invalid recharge address',
  ADDRESS_INVALID: 'Invalid address',
  PHONE_NUMBER_HAS_ALREADY_BEEN_LINKED_TO_AN_ACCOUNT: 'The phone number has been bound to another account',
  VERIFICATION_CODE_ERROR: 'Verification code error',
  EMAIL_HAS_ALREADY_BEEN_LINKED_TO_AN_ACCOUNT: 'The email has been bound to another account',
  CERTIFICATION_IN_PROGRESS: 'Real name authentication is under review',
  PASSED: 'Real name authentication has been passed',
  ADDRESS_DOES_NOT_EXIST: 'The address does not exist',
  REPEATEDLY_ADDING_FRIENDS: 'Cannot add the same address repeatedly',
  OLD_PASSWORD_ERROR: 'Old password error',
  OLD_TRADE_PASSWORD_ERROR: 'Old transaction password error',
  BALANCE_NOT_ENOUGH: 'Insufficient balance',
  USER_NOT_PRESENT: 'User does not exist',
  TOKEN_ERROR: 'Login timeout ',
  INVALID_ADDRESS: 'Invalid address',
  ONLY_ADD_UMARKET_MEMBER_ADDRESS: 'Only Umarket addresses can be added',
  TRADE_PASSWORD_ERROR: 'Transaction password incorrect',
  NOT_AGENT: 'Not an agent user',
  ACCOUNT_HAS_BEEN_FROZEN: "Account has been frozen. Please contact customer service"
};
