<template>
  <div>
    <SmallBanners
      :title="$t('userInfo.bannerName')"
      :info="$t('userInfo.bannerInfo')"
    />
    <div class="content">
      <div class="header">
        <div class="header-title">
          <img src="../../assets/userInfo/ID.png" alt="" />
          <div class="header-title-info">
            <div class="header-title-info-name">{{ userInfo.loginName }}</div>
            <div class="header-title-info-ID">UID:{{ userInfo.userId }}</div>
          </div>
        </div>
        <div class="header-info">
          <div class="header-info-title">{{ $t("authentication.title") }}</div>
          <div class="header-info-info">
            {{ $t("authentication.titleInfo") }}
          </div>
        </div>
      </div>
      <div class="registerInfo">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          class="formData"
        >
          <el-form-item label="" prop="certType">
            <div class="formData-label">
              {{ $t("authentication.certType") }}
            </div>
            <el-select
              v-model="formData.certType"
              placeholder=""
              class="formData-input"
            >
              <el-option
                v-for="item in 4"
                :key="item"
                :label="$t(`authentication.idTypes.item${item}`)"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="realName">
            <div class="formData-label">
              {{ $t("authentication.realName") }}
            </div>
            <el-input v-model="formData.realName" class="formData-input" />
          </el-form-item>
<!--          <el-form-item label="" prop="dateOfBirth">
            <div class="formData-label">
              {{ $t("authentication.dateOfBirth") }}
            </div>
            <el-date-picker
              v-model="formData.dateOfBirth"
              type="date"
              placeholder=""
              class="formData-input"
            >
            </el-date-picker>
          </el-form-item>-->
          <el-form-item label="" prop="certImage">
            <div class="formData-label">
              {{ $t("authentication.certImage") }}
            </div>
            <!-- .el-upload--picture-card -->
            <div class="formData-upload">
              <UploadImg :limit="1" @backUpload="backUpload" />
            </div>
          </el-form-item>
          <div class="formData-prompt">
            <div class="formData-prompt-title">
              {{ $t("authentication.info.title") }}
            </div>
            <div class="formData-prompt-text">
              {{ $t("authentication.info.item1") }}
            </div>
            <div class="formData-prompt-text">
              {{ $t("authentication.info.item2") }}
            </div>
            <div class="formData-prompt-text">
              {{ $t("authentication.info.item3") }}
            </div>
          </div>
          <div class="formData-subBtn">
            <el-button
              type="primary"
              class="formData-subBtn-btn"
              @click="submitForm"
              >{{ $t("authentication.subBtn") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { realNameAuthentication } from "@/api/userInfo";
export default {
  name: "authentication",
  components: {},
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (rule.field == "certImage") {
        if (value.length >= 1 && value) {
          callback();
        } else {
          return callback(
            new Error(this.$t("authentication.rules." + rule.field))
          );
        }
      } else {
        if (value) {
          callback();
        } else {
          return callback(
            new Error(this.$t("authentication.rules." + rule.field))
          );
        }
      }
    };
    return {
      userInfo: null,
      formData: {
        certType: "", //认证类型 1：身份证 2：护照
        realName: "",
        dateOfBirth: void 0,
        certImage: [],
      },
      rules: {
        certType: {
          required: true,
          validator: formRulesCheck,
          trigger: "change",
        },
        realName: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        dateOfBirth: {
          required: true,
          validator: formRulesCheck,
          trigger: "change",
        },
        certImage: {
          required: true,
          validator: formRulesCheck,
          trigger: "change",
        },
      },
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["formData"].resetFields();
    },
  },
  created() {
    this.userInfo = this.$store.state.userInfo.userInfo;
  },
  mounted() {},
  methods: {
    backUpload({ certImage, file, type, resUrl }) {
      if (type == "change") {
        this.formData.certImage.push(resUrl);
      }
      if (type == "success" || type == "remove") {
        this.formData.certImage = resUrl;
      }
      this.$refs["formData"].validateField(["certImage"], (valid, vv) => {});
    },
    submitForm() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let data = {
            ...this.formData,
            certImage: this.formData.certImage,
          };
          realNameAuthentication(data).then((res) => {
            if (res.code == 200) {
              this.$router.push("/userInfo");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.header {
  padding: 1vw 0;
  margin-top: -5vw;
  .header-title {
    padding: 10px 0;
    display: flex;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
      margin-right: 1vw;
    }
    .header-title-info {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .header-info {
    .header-info-title {
      font-size: 34px;
      font-weight: bold;
      color: #019393;
    }
    .header-info-info {
      margin-top: 1.14583vw;
      font-weight: 0.83333vw;
    }
  }
}
.registerInfo {
  margin-top: 1vw;
  padding-bottom: 5vw;
  .formData {
    .formData-label {
      // font-size: 0.9375vw;
      padding-left: 1vw;
      font-weight: bold;
    }
    .formData-input {
      width: 50%;
    }
    .formData-upload {
      height: 152px;
      max-height: 152px;
      overflow: hidden;
    }
  }
  .formData-prompt {
    // font-size: 0.9375vw;
    .formData-prompt-title {
      font-weight: bold;
    }
  }
  .formData-subBtn {
    width: 100%;
    margin-top: 2.23958vw;
    display: flex;
    justify-content: center;
    &-btn {
      width: 10.41667vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.uploadIcon ::v-deep .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}
</style>
