/**
* zh_CN
*/
import im from './zh_CN/IM'
import requestCode from "./zh_CN/requestCode";
import common from "./zh_CN/common";
import orderStatus from "./zh_CN/orderStatus";
import headers from "./zh_CN/components/headers";
import headeTooltip from "./zh_CN/components/headeTooltip";
import footer from "./zh_CN/components/footer";
import searchDialog from "./zh_CN/components/searchDialog";
import signIn from './zh_CN/signIn'
import loginHome from './zh_CN/loginHome'
import logInAndResetPasswords from "./zh_CN/loginHome/logInAndResetPasswords";
import userInfo from "./zh_CN/userInfo";
import authentication from "./zh_CN/userInfo/authentication";
import nationality from "./zh_CN/nationality";
import estate from "./zh_CN/estate";
import transfer from "./zh_CN/transfer";
import agency from "./zh_CN/agency";
import orderManagement from "./zh_CN/orderManagement";
import transaction from "./zh_CN/transaction";
import transactionSell from "./zh_CN/transaction/sell";
import transactionBuy from "./zh_CN/transaction/buy";
import pendingOrders from "./zh_CN/pendingOrders";
import transactionOrder from "./zh_CN/transactionOrder";
import clientBuy from './zh_CN/client/buy'
import clientSell from './zh_CN/client/sell'
export default {
  im,//IM
  requestCode,//错误码
  common,//公共
  orderStatus,//订单状态
  headers,
  headeTooltip,
  footer,
  searchDialog,
  signIn,
  loginHome,
  logInAndResetPasswords,
  userInfo,
  authentication,
  nationality,
  estate,
  transfer,
  agency,
  orderManagement,
  transaction,
  transactionSell,
  transactionBuy,
  pendingOrders,
  transactionOrder,
  clientBuy,
  clientSell,
};