/**
* zh_CN
*/
export default {
    bannerName: '出金中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    tradeTye: '出金',
    price: '出金金额',
    quantity: '出金数量',
    sellBtn: '立即出金',
    cancelBntn: '取消',
    Step1: {
        label: 'Step1 : 请确认出金数量和出金金额',
        tradeAmount: '出金数量:',
        currencyAmount: '出金金额:',
        orderNo: '订 单 号:'
    },
    Step2: {
        label: 'Step2 : 请输入出金者使用的ID或姓名',
    },
    Step3: {
        label: 'Step3 : 请输入Umarket钱包地址',
        placeholder:'请点击“导出地址”自动生成地址'
    },
    exportAddress: '导出地址',
    agreement: {
        txt1: '用户协议',
        txt2: '请确认后点击同意。',
    },
    tooltipList: {
        txt1: '出金申请已完成。',
        txt2: '请等待商家审核。',
    },
    rules: {
        price: '请输入出金金额',
        quantity: '请输入出售数量',
        userName: '请输入出金者使用的ID或姓名',
        address: '请输入Umarket钱包地址',
        checkbox: '请确认用户协议后点击同意',
    },
    message: {
        agreement: '请确认用户协议后点击同意'
    },
    warnInfo: [
        {
            title: "注意事项",
            values: [
                `1. 每次交易都是由平台自动配对卖方。卖方的账户信息每次都有可能不同，请根据卖方的账户信息汇款。`,
                `2. 用户购买的货币从卖方直接汇入第三方平台。`,
                `3. 用户因账户信息错误而存入错误的金额，平台不负责。`,
                `4. 为了资金安全，请汇入本人账户。`,
                `5. 运营时间- 9:00 ~ 21:00，运营时间以外的交易，卖方处理可能会晚。`,
                `6. 为了防止洗钱，您购买的USDT可能在24小时后转账或取款。`,
                `7. 汇款时请空格!(虚拟货币，USDT，比特币，外汇交易等禁止)卖方可以拒绝交易。`,
            ],
        },
    ]
};
