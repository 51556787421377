<template>
  <div>
    <SmallBanners
      :title="$t('transactionBuy.bannerName')"
      :info="$t('transactionBuy.bannerInfo')"
    />
    <div class="content">
      <div class="content-head">
        <div class="content-head-title">
          <span class="content-head-title-name">{{
            orderData.isJieDanRen
              ? $t(`transactionBuy.buyAmount`)
              : orderData.orderType == 3
              ? $t("transactionBuy.rujinAmount")
              : $t(`transactionBuy.buyAmount`)
          }}</span>
          <span class="content-head-title-num">{{
            orderData.orderAmountShow
          }}</span>
          <span class="content-head-title-type">USDT</span>
        </div>
        <div class="content-head-info">
          <div class="content-head-info-item">
            <span class="content-head-info-item-name"
              >{{ $t("transactionBuy.orderNo") }}:</span
            >
            <span class="content-head-info-item-info">#{{ orderData.id }}</span>
          </div>
        </div>
      </div>
      <div class="content-paymentInfo">
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            {{ $t("transactionBuy.tradeRate") }}:
          </div>
          <div class="content-paymentInfo-item-value">
            {{ orderData.priceShow }}USDT/{{ orderData.symbol }}
          </div>
        </div>
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            {{
              orderData.isJieDanRen
                ? $t("transactionBuy.paymentAmount")
                : orderData.orderType == 3
                ? $t("transactionBuy.rujinjine")
                : $t("transactionBuy.paymentAmount")
            }}:
          </div>
          <div class="content-paymentInfo-item-value">
            {{ orderData.currencyAmountShow }}{{ orderData.symbol }}
          </div>
        </div>
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            {{ $t("transactionBuy.tradeFee") }}:
          </div>
          <div class="content-paymentInfo-item-value">
            {{ orderData.feeShow }}USDT
          </div>
        </div>
        <div
          class="content-paymentInfo-item"
          style="background: none"
          v-if="orderData.orderStatus == 0 || orderData.orderStatus == 2"
        >
          <div class="content-paymentInfo-item-reminder">
            <div class="content-paymentInfo-item-reminder-btnBox">
              <el-button
                type="primary"
                class="content-paymentInfo-item-reminder-btnBox-btn"
                plain
                >{{ $t("transactionBuy.clientProceedsWay") }}</el-button
              >
            </div>
            <div class="content-paymentInfo-item-reminder-value">
              <div class="content-paymentInfo-item-reminder-value-item">
                <div class="content-paymentInfo-item-reminder-value-item-name">
                  {{ $t("transactionBuy.name") }}:
                </div>
                <div class="content-paymentInfo-item-reminder-value-item-value">
                  {{ orderData.otherSideName }}
                </div>
              </div>
              <div class="content-paymentInfo-item-reminder-value-item">
                <div class="content-paymentInfo-item-reminder-value-item-name">
                  {{
                    $t(
                      orderData.payTypeId == 3
                        ? "transactionBuy.WeChat"
                        : orderData.payTypeId == 4
                        ? "transactionBuy.Alipay"
                        : "transactionBuy.bankAccount"
                    )
                  }}:
                </div>
                <div class="content-paymentInfo-item-reminder-value-item-value">
                  <span
                    v-if="orderData.payTypeId != 3 && orderData.payTypeId != 4"
                    >{{ orderData.bankNumber }}</span
                  >
                  <span
                    class="content-paymentInfo-item-reminder-value-item-getImg"
                    @click="getQR"
                    v-else
                    >{{ $t("transactionBuy.getQr") }}</span
                  >
                </div>
              </div>
              <div class="content-paymentInfo-item-reminder-value-item">
                <div class="content-paymentInfo-item-reminder-value-item-name">
                  {{ $t("transactionBuy.bankName") }}:
                </div>
                <div class="content-paymentInfo-item-reminder-value-item-value">
                  {{ orderData.bankName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            <template v-if="orderData.orderType == 3 && !orderData.isJieDanRen">
              {{ $t("transactionBuy.buyUser") }}:
            </template>
            <template v-else> {{ $t("transactionBuy.sellName") }}: </template>
          </div>
          <div class="content-paymentInfo-item-value">
            <template v-if="orderData.orderType == 3 && !orderData.isJieDanRen">
              {{ orderData.payUserName }} &nbsp;&nbsp;
              <!-- {{ orderData.payUserTel }}&nbsp;&nbsp; -->
            </template>
            <template v-else>
              {{ orderData.otherSideName }}
            </template>
          </div>
        </div>
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-reminder">
            <span class="name">{{ $t(`transactionBuy.scheduleStatus`) }}:</span>
            <span class="value">{{
              $t(`orderStatus.buyScheduleStatusStart${orderData.orderStatus}`)
            }}</span>
          </div>
        </div>
      </div>
      <!-- -----------------------------是发起人---------------------- -->
      <template v-if="!orderData.isJieDanRen">
        <template v-if="orderData.orderStatus == 0">
          <div class="footer">
            <!-- 已付款（付款好点击） -->
            <el-button
              v-if="orderData.orderType == 1"
              type="primary"
              @click="forceUpdate()"
              class="footer-left btn"
              >{{ $t(`transactionBuy.toPay`) }}</el-button
            >
            <!-- 取消订单 -->
            <el-button
              :type="orderData.orderType == 3 ? 'primary' : 'info'"
              class="btn"
              :class="orderData.orderType == 3 ? 'footer-left' : ''"
              @click="cancelOrder()"
              >{{ $t(`transactionBuy.cancelOrder`) }}</el-button
            >
            <!-- <el-button
              type="info"
              class="openIm"
              v-if="orderData.orderType == 3"
              style="visibility: hidden"
              >占位</el-button
            > -->
          </div>
        </template>
        <template v-if="orderData.orderStatus == 2">
          <div class="footer">
            <!-- 提交申诉 -->
            <el-button
              type="primary"
              class="footer-left btn"
              @click="openAppeal()"
              >{{ $t(`transactionBuy.subAppeal`) }}</el-button
            >
            <!-- 联系卖家 -->
            <el-button
              type="primary"
              class="footer-left"
              @click="openIM(orderData.accId)"
              plain
              >{{ $t(`transactionBuy.openIm`) }}</el-button
            >
          </div>
          <div class="footer">
            <!-- 联系平台 -->
            <el-button
              type="info"
              @click="openIM(orderData.serviceAccId)"
              class="contactPlatform infoBtn"
              >{{ $t("transactionBuy.contactPlatform") }}</el-button
            >
          </div>
        </template>
        <!-- -----------------------------联系平台---------------------- -->
        <div class="footer" v-if="orderData.orderStatus != 2">
          <el-button
            type="info"
            @click="openIM(orderData.serviceAccId)"
            class="contactPlatform infoBtn"
            >{{ $t("transactionBuy.contactPlatform") }}</el-button
          >
          <!-- 联系卖家 -->
          <el-button
            type="primary"
            @click="openIM(orderData.accId)"
            class="openIm"
            plain
            >{{ $t(`transactionBuy.openIm`) }}</el-button
          >
        </div>
      </template>
      <!-- -----------------------------是接单人---------------------- -->
      <template v-else>
        <template v-if="orderData.orderStatus == 0">
          <div class="footer">
            <!-- 取消订单 -->
            <el-button
              type="primary"
              class="footer-left"
              @click="cancelOrder()"
              >{{ $t(`transactionBuy.cancelOrder`) }}</el-button
            >
            <!-- 联系卖家 -->
            <el-button
              type="primary"
              @click="openIM(orderData.accId)"
              class="footer-left"
              plain
              >{{ $t(`transactionBuy.openIm`) }}</el-button
            >
          </div>
          <div class="footer">
            <!-- 联系平台 -->
            <el-button
              type="info"
              @click="openIM(orderData.serviceAccId)"
              class="contactPlatform infoBtn"
              >{{ $t("transactionBuy.contactPlatform") }}</el-button
            >
          </div>
        </template>
        <template v-if="orderData.orderStatus == 2">
          <div class="footer">
            <!-- 提交申诉 -->
            <el-button
              type="primary"
              @click="openAppeal()"
              class="footer-left"
              >{{ $t("transactionBuy.subAppeal") }}</el-button
            >
            <!-- 联系卖家 -->
            <el-button
              type="primary"
              @click="openIM(orderData.accId)"
              class="footer-right"
              plain
              >{{ $t(`transactionBuy.openIm`) }}</el-button
            >
          </div>
        </template>
        <!-- -----------------------------联系平台---------------------- -->
        <div class="footer" v-if="orderData.orderStatus != 0">
          <el-button
            type="info"
            @click="openIM(orderData.serviceAccId)"
            class="contactPlatform infoBtn"
            >{{ $t("transactionBuy.contactPlatform") }}</el-button
          >
          <!-- 联系卖家 -->
          <el-button
            type="primary"
            @click="openIM(orderData.accId)"
            class="openIm"
            plain
            v-if="orderData.orderStatus != 2"
            >{{ $t(`transactionBuy.openIm`) }}</el-button
          >
        </div>
      </template>
    </div>
    <QR ref="QR" />
    <AppealDialog ref="AppealDialog" @appealBack="appealBack" />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import { orderInfo, cancel, confirmReceipt, appeal } from "@/api/transaction";
import AppealDialog from "@/views/transaction/components/appealDialog.vue";
export default {
  name: "",
  components: { AppealDialog },
  data() {
    return {
      orderData: {},
      orderNo: "",
    };
  },
  created() {
    this.orderNo = this.$route.query.orderNo;
    this.init();
  },
  mounted() {},
  methods: {
    tooltipBack(flag, isCancel) {
      if (flag == "cancelOrder" && !isCancel) {
        cancel({ orderId: this.orderData.id }).then((res) => {
          if (res.code == 200) {
            this.init();
            clearInterval(this.countDownTime);
            this.countDown = "00:00";
          }
        });
      }
    },
    init() {
      let data = {
        orderNo: this.orderNo,
      };
      orderInfo(data).then((res) => {
        if (res.code == 200) {
          this.orderData = {
            ...res.data,
          };
        }
      });
    },
    // 已付款（付款好点击） 刷新当前页面
    forceUpdate() {
      this.$router.go(0);
    },
    // 取消订单
    cancelOrder() {
      this.$refs['TooltipPop'].openDialog("cancelOrder", false, {
        type: "affirm",
        info: [this.$t("transactionBuy.confirm.cancelInfo")],
      });
    },
    getQR() {
      let data = {
        type: 1,
        url: this.orderData.bankName,
        amountShow: this.orderData.currencyAmountShow,
        symbol: this.orderData.symbol,
      };
      this.$refs["QR"].openDialog(data);
    },
    openAppeal() {
      this.$refs["AppealDialog"].openDialog();
    },
    appealBack(value) {
      let data = {
        orderId: this.orderData.id,
        appealDesc: value.appealDesc,
      };
      appeal(data).then((res) => {
        if (res.code == 200) {
          this.init();
        }
      });
    },
    openIM(id) {
      this.$store.dispatch("common/setIM", { bool: null, accId: id });
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6vw;
  .content-head {
    width: 100%;
    background: #eeeeee;
    padding: 1.35417vw;
    box-sizing: border-box;
    margin-bottom: 2.44792vw;
    .content-head-title {
      font-size: 1.82292vw;
      .content-head-title-name {
        font-weight: bold;
        color: #019393;
        margin-right: 1vw;
      }
      .content-head-title-num {
        font-size: 2.6042vw;
        font-weight: bold;
      }
    }
    .content-head-info {
      display: flex;
      justify-content: flex-start;
      margin-top: 1.2vw;
      .content-head-info-item {
        width: 15.625vw;
        height: 40px;
        line-height: 40px;
        background: #019393;
        color: #ffffff;
        margin-right: 1vw;
        border-radius: 5px;
        padding-left: 1vw;
        box-sizing: border-box;
        display: flex;
        font-size: 0.9375vw;
        span {
          margin-right: 0.5vw;
        }
        .color {
          color: #ffff00;
        }
      }
    }
  }
  .content-paymentInfo {
    .content-paymentInfo-item {
      height: 2.08333vw;
      min-height: 40px;
      line-height: 2.08333vw;
      padding-left: 1vw;
      margin-bottom: 1vw;
      font-size: 14px;
      color: #7d7d7d;
      border-radius: 5px;
      box-sizing: border-box;
      background: #f8f8f8;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .content-paymentInfo-item-name {
        width: calc(5vw + 2px);
        display: flex;
        justify-content: space-between;
        margin-right: 1vw;
        font-weight: bold;
        div {
          display: inline-block;
          width: 23%;
        }
        .col {
          width: 33.3%;
          text-align: center;
        }
        .col:first-child {
          text-align: left;
        }
        .col:last-child {
          text-align: right;
        }
      }
      .content-paymentInfo-item-value {
        width: calc(100% - 7vw);
      }
      .content-paymentInfo-item-reminder {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .content-paymentInfo-item-reminder-btnBox {
          width: 15.625vw;
          margin-right: 1vw;
          display: flex;
          justify-content: center;
          align-items: center;
          .content-paymentInfo-item-reminder-btnBox-btn {
            width: 100%;
            font-weight: bold;
          }
        }
        .content-paymentInfo-item-reminder-value {
          flex: 1;
          background: #f8f8f8;
          padding-left: 1vw;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .content-paymentInfo-item-reminder-value-item {
            display: flex;
            justify-content: flex-start;
            margin-right: 2vw;
            .content-paymentInfo-item-reminder-value-item-name {
              font-weight: bold;
              margin-right: 0.5vw;
            }
            .content-paymentInfo-item-reminder-value-item-getImg:hover {
              color: #007c7c;
            }
          }
          .content-paymentInfo-item-reminder-value-item {
            cursor: pointer;
          }
        }
      }
      &-reminder {
        width: 100%;
        font-size: 0.83333vw;
        display: flex;
        justify-content: flex-start;
        .name {
          color: #019393;
          width: 8%;
          font-weight: bold;
        }
        .value {
          color: #019393;
        }
      }
    }
    .content-paymentInfo-item:last-child {
      background: none;
      line-height: 1.3vw;
      background: none;
    }
  }
  .footer {
    margin-top: 1.92708vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .footer-left {
      flex: 1;
    }
    .footer-right {
      width: 30%;
    }
    .openIm {
      width: calc(50%);
    }
    .contactPlatform {
      flex: 1;
      background: #313131;
      color: #ffffff;
    }
  }
  .infoBtn {
    background: #313131;
    color: #ffffff;
  }
}
::v-deep .infoBtn:hover {
  background: #808080 !important;
}
::v-deep .infoBtn:active {
  background: #313131 !important;
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
::v-deep .is-plain:active {
  background: #f5f5f5 !important;
  color: #007c7c !important;
}
</style>
