
<template>
  <div class="layoutbody">
    <Headers></Headers>
    <router-view
      v-if="
        $route.path == '/' ||
        $route.path == '/logIn' ||
        $route.path == '/resetPasswords' ||
        $route.path == '/authenticationKR'
      "
    />
    <div class="layoutContent" v-else>
      <router-view />
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
export default {
  name: "layout",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style lang='scss'>
.layoutContent {
  width: calc(100vw - 18px);
  min-height: calc(100vh - 80px);
  box-sizing: border-box;
  padding: 0 19vw;
}
// // 设置输入框disabled 状态 字体颜色
// .el-input.is-disabled .el-input__inner,.el-textarea__inner {
//   color: #000;
//   $placeholder: #000;
// }
// // 设置输入框placeholder字体颜色
// .el-textarea__inner,
// .el-input__inner {
//   $placeholder: #000;
//   &::placeholder {
//     color: $placeholder !important;
//   }
//   &::-webkit-input-placeholder {
//     /* WebKit browsers 适配谷歌 */
//     color: $placeholder !important;
//   }
//   &:-moz-placeholder {
//     /* Mozilla Firefox 4 to 18 适配火狐 */
//     color: $placeholder !important;
//   }
//   &::-moz-placeholder {
//     /* Mozilla Firefox 19+ 适配火狐 */
//     color: $placeholder !important;
//   }
//   &:-ms-input-placeholder {
//     /* Internet Explorer 10+  适配ie*/
//     color: $placeholder !important;
//   }
// }
::v-deep .el-dialog__body {
  padding: 0 !important;
  box-sizing: border-box !important;
}
</style>
