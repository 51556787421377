const zh_CN = {
    // common
    saveText: "保存",
    setText: "设置",
    saveSuccessText: "保存成功",
    saveFailedText: "保存失败",
    addFriendSuccessText: "添加好友成功",
    applyFriendSuccessText: "申请添加好友成功",
    addFriendFailedText: "添加好友失败",
    applyFriendFailedText: "申请添加好友失败",
    okText: "确定",
    cancelText: "取消",
    deleteText: "删除",
    recallText: "撤回",
    you: "你",
    deleteFriendText: "删除好友",
    confirmDeleteText: "确定删除？",
    confirmDeleteFriendText: "确定删除好友？",
    deleteFriendSuccessText: "删除好友成功",
    deleteFriendFailedText: "删除好友失败",
    blackText: "拉黑好友",
    removeBlackText: "解除拉黑",
    blackSuccessText: "拉黑成功",
    blackFailedText: "拉黑失败",
    removeBlackSuccessText: "解除拉黑成功",
    removeBlackFailedText: "解除拉黑失败",
    maxSelectedText: "最多只能选择",
    selectedText: "已选",
    friendsText: "位好友",
    strangerText: "位陌生人",
    emailErrorText: "邮箱格式不正确",
    uploadLimitText: "图片或文件大小最大支持",
    uploadLimitUnit: "M",
    uploadImgFailedText: "上传图片失败",
    accountText: "账号",
    nickText: "昵称",
    genderText: "性别",
    phoneText: "手机",
    emailText: "邮箱",
    signText: "签名",
    accountPlaceholder: "请输入账号",
    teamIdPlaceholder: "请输入群组 ID",
    nickPlaceholder: "请输入昵称",
    genderPlaceholder: "请选择性别",
    phonePlaceholder: "请输入手机号",
    emailPlaceholder: "请输入邮箱",
    signPlaceholder: "请输入签名",
    searchInputPlaceholder: "搜索好友或群组",
    man: "男",
    woman: "女",
    unknow: "未知",
    welcomeText: "欢迎使用云信",
    notSupportMessageText: "暂不支持该消息",
    applyTeamText: "申请入群",
    rejectText: "拒绝",
    acceptText: "同意",
    inviteTeamText: "邀请您加入群组",
    applyFriendText: "添加您为好友",
    acceptResultText: "已同意",
    rejectResultText: "已拒绝",
    beRejectResultText: "拒绝了好友申请",
    passResultText: "通过了好友申请",
    rejectTeamInviteText: "拒绝了群邀请",
    updateTeamAvatar: "更新了群头像",
    updateTeamName: "更新群名称为",
    updateTeamIntro: "更新了群介绍",
    updateTeamInviteMode: "更新了群权限“邀请他人权限”为",
    updateTeamUpdateTeamMode: "更新了群权限“群资料修改权限”为",
    updateTeamMute: "群禁言已",
    onlyTeamOwner: "仅群主",
    teamAll: "所有人",
    closeText: "关闭",
    openText: "开启",
    inviteText: "邀请",
    aliasText: "备注",
    updateAliasSuccessText: "修改备注成功",
    updateAliasFailedText: "修改备注失败",
    sendText: "发送消息",
    noPermission: "您暂无权限操作",

    // conversation-kit
    onDismissTeamText: "群已被解散",
    onRemoveTeamText: "您已被移出群组",
    textMsgText: "文本消息",
    audioMsgText: "音频消息",
    videoMsgText: "视频消息",
    fileMsgText: "文件消息",
    callMsgText: "话单消息",
    geoMsgText: "地理位置消息",
    imgMsgText: "图片消息",
    notiMsgText: "通知消息",
    robotMsgText: "机器消息",
    tipMsgText: "提示消息",
    unknowMsgText: "未知消息",
    deleteSessionText: "删除会话",
    muteSessionText: "开启免打扰",
    unmuteSessionText: "取消免打扰",
    deleteStickTopText: "取消置顶",
    addStickTopText: "置顶消息",

    // contact-kit
    teamListTitle: "我的群组",
    friendListTitle: "我的好友",
    blackListTitle: "黑名单",
    msgListTitle: "消息中心",
    blackListDesc: "（你不会收到列表中任何联系人的消息）",
    teamMenuText: "我的群组",
    friendMenuText: "我的好友",
    blackMenuText: "黑名单",
    msgMenuText: "消息中心",
    acceptedText: "已同意该申请",
    acceptFailedText: "同意该申请失败",
    rejectedText: "已拒绝该申请",
    rejectFailedText: "拒绝该申请失败",
    getApplyMsgFailedText: "获取消息失败",

    // search-kit
    addFriendText: "添加好友",
    createTeamText: "创建群组",
    joinTeamText: "加入群组",
    beRemoveTeamText: "被移出群组",
    addButtonText: "添加",
    addSuccessText: "添加成功",
    addFailedText: "添加失败",
    createButtonText: "创建",
    createTeamSuccessText: "创建群组成功",
    createTeamFailedText: "创建群组失败",
    chatButtonText: "去聊天",
    getRelationFailedText: "获取关系失败",
    accountNotMatchText: "查无此账号",
    teamIdNotMatchText: "查无此群号",
    searchButtonText: "查找",
    searchTeamPlaceholder: "请输入群名称",
    teamTitle: "群名称",
    teamAvatarText: "群头像",
    addTeamMemberText: "添加成员",
    searchEmptyText: "您暂未添加好友和群组",
    searchNoResText: "暂无搜索结果",
    searchFriendTitle: "好友",
    searchTeamTitle: "群组",
    notSupportJoinText: "讨论组无法直接加入，请联系管理员拉您入群",

    // chat-kit
    sendToText: "发送给",
    sendUsageText: "（按enter直接发送，shift+enter换行）",
    sendEmptyText: "不能发送空白消息",
    teamMutePlaceholder: "当前群主设置为禁言",
    enterTeamText: "进入了群组",
    leaveTeamText: "离开了群组",
    teamMuteText: "群禁言",
    muteAllTeamSuccessText: "开启全员禁言成功",
    unmuteAllTeamSuccessText: "结束全员禁言成功",
    muteAllTeamFailedText: "开启全员禁言失败",
    unmuteAllTeamFailedText: "结束全员禁言失败",
    updateTeamSuccessText: "修改成功",
    updateTeamFailedText: "修改失败",
    leaveTeamSuccessText: "已成功退出此群",
    leaveTeamFailedText: "退出此群失败",
    dismissTeamSuccessText: "群解散成功",
    dismissTeamFailedText: "群解散失败",
    addTeamMemberSuccessText: "添加成员成功",
    addTeamMemberFailedText: "添加成员失败",
    addTeamMemberConfirmText: "请选择要添加的群成员",
    removeTeamMemberText: "移除成员",
    removeTeamMemberConfirmText: "是否确认移除该成员？",
    removeTeamMemberSuccessText: "移除成员成功",
    removeTeamMemberFailedText: "移除成员失败",
    teamTitleConfirmText: "群名称不能为空",
    teamAvatarConfirmText: "群头像不能为空",
    teamIdText: "群ID",
    teamSignText: "群介绍",
    teamTitlePlaceholder: "请输入群名称",
    teamSignPlaceholder: "请输入内容",
    teamOwnerText: "群主",
    teamManagerText: "管理员",
    teamManagerLimitText: "仅管理员可修改群资料",
    teamMemberText: "群成员",
    teamInfoText: "群资料",
    teamPowerText: "权限设置",
    dismissTeamText: "解散群组",
    dismissTeamConfirmText: "是否确认解散该群组",
    leaveTeamTitle: "离开群组",
    leaveTeamConfirmText: "是否确认退出该群组",
    personUnit: "人",
    leaveTeamButtonText: "删除并退出",
    sendMsgFailedText: "消息发送失败",
    getHistoryMsgFailedText: "获取历史消息失败",
    sendBlackFailedText: "您已被对方拉入黑名单",
    recallMessageText: "撤回了一条消息",
    reeditText: "重新编辑",
    addChatMemberText: "添加聊天成员",
    chatHistoryText: "聊天记录",
    noMoreText: "没有更多消息了",
    receiveText: "您收到了新消息",
    strangerNotiText: "当前不是您的好友，请注意保护个人隐私安全。",
    teamInviteModeText: "仅管理员可邀请他人",
    nickInTeamText: "我在群里的昵称",
    editNickInTeamText: "编辑我在群里的昵称",
    replyText: "回复",
    unreadText: "未读",
    readText: "已读",
    // emoji 不能随便填，要用固定 key
    Laugh: "[大笑]",
    Happy: "[开心]",
    Sexy: "[色]",
    Cool: "[酷]",
    Mischievous: "[奸笑]",
    Kiss: "[亲]",
    Spit: "[伸舌头]",
    Squint: "[眯眼]",
    Cute: "[可爱]",
    Grimace: "[鬼脸]",
    Snicker: "[偷笑]",
    Joy: "[喜悦]",
    Ecstasy: "[狂喜]",
    Surprise: "[惊讶]",
    Tears: "[流泪]",
    Sweat: "[流汗]",
    Angle: "[天使]",
    Funny: "[笑哭]",
    Awkward: "[尴尬]",
    Thrill: "[惊恐]",
    Cry: "[大哭]",
    Fretting: "[烦躁]",
    Terrorist: "[恐怖]",
    Halo: "[两眼冒星]",
    Shame: "[害羞]",
    Sleep: "[睡着]",
    Tired: "[冒星]",
    Mask: "[口罩]",
    ok: "[ok]",
    AllRight: "[好吧]",
    Despise: "[鄙视]",
    Uncomfortable: "[难受]",
    Disdain: "[不屑]",
    ill: "[不舒服]",
    Mad: "[愤怒]",
    Ghost: "[鬼怪]",
    Angry: "[发怒]",
    Unhappy: "[不高兴]",
    Frown: "[皱眉]",
    Broken: "[心碎]",
    Beckoning: "[心动]",
    Ok: "[好的]",
    Low: "[低级]",
    Nice: "[赞]",
    Applause: "[鼓掌]",
    GoodJob: "[给力]",
    Hit: "[打你]",
    Please: "[阿弥陀佛]",
    Bye: "[拜拜]",
    First: "[第一]",
    Fist: "[拳头]",
    GiveMeFive: "[手掌]",
    Knife: "[剪刀]",
    Hi: "[招手]",
    No: "[不要]",
    Hold: "[举着]",
    Think: "[思考]",
    Pig: "[猪头]",
    NoListen: "[不听]",
    NoLook: "[不看]",
    NoWords: "[不说]",
    Monkey: "[猴子]",
    Bomb: "[炸弹]",
    Cloud: "[筋斗云]",
    Rocket: "[火箭]",
    Ambulance: "[救护车]",
    Poop: "[便便]",
}
const en = {
    // common
    saveText: 'Save',
    setText: 'Settings',
    saveSuccessText: 'Settings saved',
    saveFailedText: 'Failed to save Settings',
    addFriendSuccessText: 'Friend added',
    applyFriendSuccessText: 'Friend request sent',
    addFriendFailedText: 'Failed to add friend',
    applyFriendFailedText: 'Failed to send friend request',
    okText: 'OK',
    cancelText: 'Cancel',
    deleteText: 'Delete',
    recallText: 'Recall',
    forwardText: 'Forward',
    forwardFailedText: 'Forward failed',
    sendBtnText: 'send',
    replyText: 'Reply',
    commentText: 'comment',
    recentSessionText: 'Recent session',
    you: 'you',
    deleteFriendText: 'Delete friend',
    confirmDeleteText: 'Delete friend',
    confirmDeleteFriendText: 'Are you sure you want to delete the friend?',
    deleteFriendSuccessText: 'Friend deleted',
    deleteFriendFailedText: 'Failed to delete friend',
    blackText: 'Blocked',
    removeBlackText: 'Remove from blocklist',
    blackSuccessText: 'Blocked',
    blackFailedText: 'Failed to block user',
    removeBlackSuccessText: 'Unblocked',
    removeBlackFailedText: 'Failed to unblock user',
    maxSelectedText: 'A maximum of {} friends can be selected',
    selectedText: 'Selected',
    friendsText: 'Friends',
    strangerText: 'stranger(s)',
    emailErrorText: 'Invalid email address',
    uploadLimitText: 'A maximum size of {} MB is allowed for upload',
    uploadLimitUnit: 'M',
    uploadImgFailedText: 'Upload failed',
    accountText: 'Account',
    nickText: 'Nickname',
    genderText: 'Gender',
    phoneText: 'Phone',
    emailText: 'Email',
    signText: 'Bio',
    accountPlaceholder: 'Enter your account',
    teamIdPlaceholder: 'Please enter the group ID',
    nickPlaceholder: 'Enter your nickname',
    genderPlaceholder: 'Select your gender',
    phonePlaceholder: 'Enter mobile phone number',
    emailPlaceholder: 'Enter email address',
    signPlaceholder: 'Enter your bio',
    searchInputPlaceholder: 'Search for friends or groups',
    searchTeamMemberPlaceholder: 'Search for team member',
    searchText: 'search',
    man: 'Male',
    woman: 'Female',
    unknow: 'Prefer not to Answer',
    welcomeText: 'Welcome to CommsEase Messenger',
    notSupportMessageText: 'The type of message is not supported',
    applyTeamText: 'Join',
    applyTeamSuccessText: 'Apply team success',
    rejectText: 'Reject',
    acceptText: 'Accept',
    inviteTeamText: 'Invite',
    applyFriendText: 'Add',
    acceptResultText: 'Accepted',
    rejectResultText: 'Rejected',
    beRejectResultText: 'Friend request rejected',
    passResultText: 'Friend request accepted',
    rejectTeamInviteText: 'declined group invitation',
    updateTeamAvatar: 'Updated group avatar',
    updateTeamName: 'The group name was updated as',
    updateTeamIntro: 'Updated the group introduction',
    updateTeamInviteMode: 'Updated the "Invite permission" to',
    updateTeamUpdateTeamMode: 'Updated the "Edit permission" to​',
    updateAllowAt: 'Updated the "@everyone permission" to',
    updateTeamMute: 'group ban',
    onlyTeamOwner: 'Only the owner',
    teamAll: 'Everyone',
    closeText: '',
    openText: 'Open',
    inviteText: 'Invite',
    aliasText: 'Remark',
    updateAliasSuccessText: 'Remarks modified',
    updateAliasFailedText: 'Failed to modify remark',
    sendText: 'Send',
    noPermission: 'No permission',
    callDurationText: 'call duration',
    callCancelText: 'canceled',
    callRejectedText: 'rejected',
    callTimeoutText: 'timeout',
    callBusyText: 'busy line',

    // conversation-kit
    onDismissTeamText: 'Group dismissed',
    onRemoveTeamText: 'You were removed',
    textMsgText: 'Text message',
    audioMsgText: 'Audio message',
    videoMsgText: 'Video message',
    fileMsgText: 'File message',
    callMsgText: 'CDR message',
    geoMsgText: 'Location message',
    imgMsgText: 'Image message',
    notiMsgText: 'Notification',
    robotMsgText: 'Chatbot message',
    tipMsgText: 'Tip',
    unknowMsgText: 'Unknown message',
    deleteSessionText: 'Delete',
    muteSessionText: 'Enable Do-Not-Disturb',
    unmuteSessionText: 'Disable Do-Not-Disturb',
    deleteStickTopText: 'Unpin',
    addStickTopText: 'Pin',
    beMentioned: '[You were mentioned]',

    // contact-kit
    teamListTitle: 'Groups',
    friendListTitle: 'Friends',
    blackListTitle: 'Blocklist',
    msgListTitle: 'Message Center',
    blackListDesc:
        '（You will not receive messages from any contacts in the list）',
    teamMenuText: 'Groups',
    friendMenuText: 'Friends',
    blackMenuText: 'Blocklist',
    msgMenuText: 'Message Center',
    acceptedText: 'Request approved',
    acceptFailedText: 'Failed to approve the request',
    rejectedText: 'Request rejected',
    rejectFailedText: 'Failed to reject the request',
    getApplyMsgFailedText: 'Failed to get the messsage',

    // search-kit
    addFriendText: 'Add friend',
    createTeamText: 'Create group',
    joinTeamText: 'Join group',
    beRemoveTeamText: 'Removed from the group',
    addButtonText: 'Add',
    addSuccessText: 'Added',
    addFailedText: 'Adding friend failed',
    createButtonText: 'Create',
    createTeamSuccessText: 'Group created',
    createTeamFailedText: 'Failed to create the group',
    chatButtonText: 'Chat',
    getRelationFailedText: 'Failed to get relationship',
    accountNotMatchText: 'No account found',
    teamIdNotMatchText: 'No such group number found',
    searchButtonText: 'Search',
    searchTeamPlaceholder: 'Enter a group name',
    teamTitle: 'Group name',
    teamAvatarText: 'Group avatar',
    addTeamMemberText: 'Add member',
    searchEmptyText: 'You have not added friends or groups yet',
    searchNoResText: 'No search results yet',
    searchFriendTitle: 'Friends',
    searchTeamTitle: 'Groups',
    notSupportJoinText:
        'The discussion group cannot be joined directly, please contact the administrator to add you to the group',

    // chat-kit
    sendToText: 'To',
    sendUsageText:
        '（Send a message by pressing Enter and break a line using Shift+ Enter）',
    sendEmptyText: 'Unable to send an empty message',
    teamMutePlaceholder: 'The group owner has muted the group',
    enterTeamText: 'joined the group chat',
    leaveTeamText: 'left the group chat',
    teamMuteText: 'Mute group',
    muteAllTeamSuccessText: 'Mute all',
    unmuteAllTeamSuccessText: 'Unmute all',
    muteAllTeamFailedText: 'Failed to mute all',
    unmuteAllTeamFailedText: 'Failed to unmute all members',
    updateTeamSuccessText: 'Edit succeeded',
    updateTeamFailedText: 'Edit failed',
    leaveTeamSuccessText: 'You have left the group',
    leaveTeamFailedText: 'Failed to leave the group',
    dismissTeamSuccessText: 'Group dismissed',
    dismissTeamFailedText: 'Failed to dismiss the group',
    addTeamMemberSuccessText: 'Member added',
    addTeamMemberFailedText: 'Failed to add member',
    addTeamMemberConfirmText: 'Select group members',
    newGroupOwnerText: 'become the new group owner',
    removeTeamMemberText: 'Remove',
    removeTeamMemberConfirmText: 'Whether to remove the member？',
    removeTeamMemberSuccessText: 'Member removed',
    removeTeamMemberFailedText: 'Failed to remove member',
    teamTitleConfirmText: 'Group name is required',
    teamAvatarConfirmText: 'Group avatar is required',
    teamIdText: 'Group ID',
    teamSignText: 'Introduction',
    teamTitlePlaceholder: 'Enter group name',
    teamSignPlaceholder: 'Enter a description',
    teamOwnerText: 'Group owner',
    teamManagerText: 'Admin',
    teamManagerLimitText: 'Only admins can edit group information',
    teamMemberText: 'Group member',
    teamInfoText: 'Group information',
    teamPowerText: 'Permissions',
    dismissTeamText: 'Dismiss group',
    transferOwnerText: 'transfer group owner',
    transferTeamFailedText: 'transfer group owner failed',
    transferTeamSuccessText: 'transfer group owner success',
    transferOwnerConfirmText:
        'Are you sure you want to transfer the group ownership',
    transferToText: 'transfer to',
    dismissTeamConfirmText: 'Are you sure you want to dismiss the group',
    leaveTeamTitle: 'Leave group',
    leaveTeamConfirmText: 'Are you sure you want to leave the group',
    personUnit: 'person',
    leaveTeamButtonText: 'Delete and leave',
    sendMsgFailedText: 'Failed to send the message',
    getHistoryMsgFailedText: 'Failed to get the message history',
    sendBlackFailedText: 'You were added to blocklist',
    recallMessageText: 'A message was recalled',
    reeditText: 'Re-edit',
    addChatMemberText: 'Add member',
    chatHistoryText: 'Chat history',
    noMoreText: 'No more messages',
    receiveText: 'You have a new message',
    strangerNotiText:
        'You are chatting with a non-friend. Be discreet about sharing your personal information.',
    teamInviteModeText: 'Only administrators can invite other users',
    nickInTeamText: 'Nickname',
    editNickInTeamText: 'Edit',
    unreadText: 'unread',
    readText: 'read',
    allReadText: 'All read',
    amap: 'Amap',
    txmap: 'Tencent map',
    bdmap: 'Baidu map',
    customMsgText: 'Custom message',
    joinTeamSuccessText: 'Join group success',
    teamManagerEditText: 'Member management',
    teamManagerEmptyText: 'No group management personnel yet',
    teamOwnerAndManagerText: 'Group owner and administrator',
    updateTeamManagerSuccessText: 'Group administrator modified',
    updateTeamManagerFailText: 'Failed to modify group administrator',
    userNotInTeam: 'Member is no longer in the group',
    teamAtModeText: 'Who can @everyone',
    beAddTeamManagersText: 'You were added as an administrator',
    beRemoveTeamManagersText: 'You were removed as an administrator',
    recallReplyMessageText: 'The message has been recalled or deleted',
    updateMyMemberNickSuccess: 'Update my group nickname success',
    updateMyMemberNickFailed: 'Update my group nickname failed',
    updateBitConfigMaskSuccess: 'Update group message Do-Not-Disturb success',
    updateBitConfigMaskFailed: 'Update group message Do-Not-Disturb failed',
    onlineText: '[Online]',
    offlineText: '(Offline)',
    // emoji 不能随便填，要用固定 key
    Laugh: '[Laugh]',
    Happy: '[Happy]',
    Sexy: '[Sexy]',
    Cool: '[Cool]',
    Mischievous: '[Mischievous]',
    Kiss: '[Kiss]',
    Spit: '[Spit]',
    Squint: '[Squint]',
    Cute: '[Cute]',
    Grimace: '[Grimace]',
    Snicker: '[Snicker]',
    Joy: '[Joy]',
    Ecstasy: '[Ecstasy]',
    Surprise: '[Surprise]',
    Tears: '[Tears]',
    Sweat: '[Sweat]',
    Angle: '[Angle]',
    Funny: '[Funny]',
    Awkward: '[Awkward]',
    Thrill: '[Thrill]',
    Cry: '[Cry]',
    Fretting: '[Fretting]',
    Terrorist: '[Terrorist]',
    Halo: '[Halo]',
    Shame: '[Shame]',
    Sleep: '[Sleep]',
    Tired: '[Tired]',
    Mask: '[Mask]',
    ok: '[ok]',
    AllRight: '[All right]',
    Despise: '[Despise]',
    Uncomfortable: '[Uncomfortable]',
    Disdain: '[Disdain]',
    ill: '[ill]',
    Mad: '[Mad]',
    Ghost: '[Ghost]',
    Angry: '[Angry]',
    Unhappy: '[Unhappy]',
    Frown: '[Frown]',
    Broken: '[Broken]',
    Beckoning: '[Beckoning]',
    Ok: '[Ok]',
    Low: '[Low]',
    Nice: '[Nice]',
    Applause: '[Applause]',
    GoodJob: '[Good job]',
    Hit: '[Hit]',
    Please: '[Please]',
    Bye: '[Bye]',
    First: '[First]',
    Fist: '[Fist]',
    GiveMeFive: '[Give me five]',
    Knife: '[Knife]',
    Hi: '[Hi]',
    No: '[No]',
    Hold: '[Hold]',
    Think: '[Think]',
    Pig: '[Pig]',
    NoListen: '[No listen]',
    NoLook: '[No look]',
    NoWords: '[No words]',
    Monkey: '[Monkey]',
    Bomb: '[Bomb]',
    Cloud: '[Cloud]',
    Rocket: '[Rocket]',
    Ambulance: '[Ambulance]',
    Poop: '[Poop]',
};
const ko = {
    // common
    saveText: '저장',
    setText: '설정',
    saveSuccessText: '저장성공',
    saveFailedText: '저장실패',
    addFriendSuccessText: '친구추가 성공',
    applyFriendSuccessText: '친구추가 신청 성공',
    addFriendFailedText: '친구추가 실패',
    applyFriendFailedText: '친구추가 신청 실패',
    okText: '확인',
    cancelText: '취소',
    deleteText: '삭제',
    recallText: '취소',
    you: '고객님',
    deleteFriendText: '친구삭제',
    confirmDeleteText: '삭제 하시겠습니까？',
    confirmDeleteFriendText: '친구삭제 하시겠습니까？',
    deleteFriendSuccessText: '친구삭제 성공',
    deleteFriendFailedText: '친구삭제 실패',
    blackText: '친구차단',
    removeBlackText: '차단 해지',
    blackSuccessText: '차단 성공',
    blackFailedText: '차단 실패',
    removeBlackSuccessText: '차단해지 성공',
    removeBlackFailedText: '차단해지 실패',
    maxSelectedText: '최대 선택 범위',
    selectedText: '선택',
    friendsText: '명 친구',
    strangerText: '명 낯선 친구' ,
    emailErrorText: '메일주소 격식이 맞지 않습니다',
    uploadLimitText: '이미지 파일 크기 최대 지원',
    uploadLimitUnit: 'M',
    uploadImgFailedText: '이미지 업로드 실패',
    accountText: '계정',
    nickText: 'ID',
    genderText: '성별',
    phoneText: '휴대폰',
    emailText: '메일',
    signText: '서명',
    accountPlaceholder: '계정을 입력 하십시오',
    teamIdPlaceholder: '그룹명을 입력 하십시오' ,
    nickPlaceholder: 'ID를 입력 하십시오',
    genderPlaceholder: '성별을 입력 하십시오',
    phonePlaceholder: '휴대폰 번호를 입력 하십시오',
    emailPlaceholder: '메일 주소를 입력 하십시오',
    signPlaceholder: '서명을 입력 하십오',
    searchInputPlaceholder: '친구&구룹 검색',
    man :'남',
    woman: '여',
    unknow: '알수 없음',
    welcomeText: '오신것을 환영 합니다.',
    notSupportMessageText: '메세지를 지원하지 않습니다',
    applyTeamText: '그룹가입 신청',
    rejectText: '거절',
    acceptText: '동의',
    inviteTeamText: '그룹가입을 초대 합니다',
    applyFriendText: '친구추가',
    acceptResultText: '동의',
    rejectResultText:  '거절',
    beRejectResultText: '친구신청 거절',
    passResultText: '친구신청 수락',
    rejectTeamInviteText: '그룹초대 거절',
    updateTeamAvatar: '프로필 이미지 변경',
    updateTeamName: '그룹명 변경',
    updateTeamIntro: '그룹소개 업로드',
    updateTeamInviteMode: '그룹권한 변경"친구 초대 권한"을' ,
    updateTeamUpdateTeamMode: '그룹권한 변경 "그룹자료 수정 권한"을'  ,
    updateTeamMute: '그룹 금지 언어',
    onlyTeamOwner: '그룹장 만',
    teamAll: '모든사람',
    closeText: '차단',
    openText: '열림',
    inviteText: '초대',
    aliasText: '비고',
    updateAliasSuccessText: '비고 수정성공',
    updateAliasFailedText: '비고 수정실퍠',
    sendText: '메세지 발송',
    noPermission: '실행권한이 없습니다',

    //  conversation-kit
    onDismissTeamText: '그룹 해산 되였습니다',
    onRemoveTeamText: '그룹 탈퇴 되였습니다',
    textMsgText: '문자 메세지',
    audioMsgText: '음성 메세지',
    videoMsgText: '영상 메세지',
    fileMsgText: '파일 메세지',
    callMsgText: '음성 메세지',
    geoMsgText: '위치 메세지',
    imgMsgText: '이미지 메세지',
    notiMsgText: '메세지 알림',
    robotMsgText: '로봇 메세지',
    tipMsgText: '메세지 알림',
    unknowMsgText: '알수없는 메세지',
    deleteSessionText: '대화 삭제',
    muteSessionText: '알림 차단',
    unmuteSessionText: '알림 차단 해지',
    deleteStickTopText: '상위 노출 취소',
    addStickTopText: '상위 메세지',

    //  contact-kit
    teamListTitle: '나의그룹',
    friendListTitle: '나의친구',
    blackListTitle: '차단친구',
    msgListTitle: '메세지',
    blackListDesc: '（목록에 있는 연락처의 메시지를 받을수 없습니다）',
    teamMenuText: '나의그룹',
    friendMenuText: '나의친구',
    blackMenuText: '차단친구',
    msgMenuText: '메세지',
    acceptedText: '신청수락 하였습니다.',
    acceptFailedText: '신청수락 실패 하였습니다',
    rejectedText: '신청거절 하였습니다',
    rejectFailedText: '신청거절 실패 하였습니다',
    getApplyMsgFailedText: '메세지 수락 실패',

    //  search-kit
    addFriendText: '친구추가',
    createTeamText: '그룹만들기',
    joinTeamText: '그룹가입',
    beRemoveTeamText: '그룹탈퇴 되였습니다',
    addButtonText: '추가',
    addSuccessText: '추가성공',
    addFailedText: '추가 실패',
    createButtonText: '만들기',
    createTeamSuccessText: '그룹만들기 성공',
    createTeamFailedText: '그룹만들기 실패',
    chatButtonText: '챙팅하기',
    getRelationFailedText: '관계 가져오기 실패',
    accountNotMatchText: '존재하지 않는 계정',
    teamIdNotMatchText:  '존재하지 않는 그룹',
    searchButtonText: '검색',
    searchTeamPlaceholder: '그룹명을 입력 하십시오',
    teamTitle: '그룹명',
    teamAvatarText: '그룹이미지',
    addTeamMemberText: '회원추가',
    searchEmptyText: '친구 혹은 그룹을 추가하지 않았습니다',

    searchNoResText: '검색 결과가 없습니다',
    searchFriendTitle: '친구',
    searchTeamTitle: '구룹',
    notSupportJoinText: '채팅그룹에 직접 가입할 수 없습니다. 관리자에게 연락하여 그룹에 가입하십시오.',

    //  chat-kit
    sendToText: '발송',
    sendUsageText: '（enter클릭 바로 보내기, shift+enter 줄 바꿈）',
    sendEmptyText: '빈 메시지를 보낼 수 없습니다',
    teamMutePlaceholder: '현재 그룹장이 채팅을 금지 하였습니다',
    enterTeamText: '그룹에 가입하였습니다',
    leaveTeamText: '그룹을 탈퇴 하였습니다',
    teamMuteText: '그룹 채팅 금지',
    muteAllTeamSuccessText: '모든 회원 채팅금지 성공',
    unmuteAllTeamSuccessText: '모든 회원 채팅금지 완료',
    muteAllTeamFailedText: '모든 회원 채팅금지 실패' ,
    unmuteAllTeamFailedText: '모든 회원 채팅금지 완료 실패' ,
    updateTeamSuccessText: '수정성공',
    updateTeamFailedText: '수정실패',
    leaveTeamSuccessText: '그룹 탈퇴를 성공 하였습니다',
    leaveTeamFailedText: '그룹 탈퇴를 실패 하였습니다',
    dismissTeamSuccessText: '그룹 해산 성공',
    dismissTeamFailedText: '그룹 해산 실패',
    addTeamMemberSuccessText: '회원추가 성공',
    addTeamMemberFailedText: '회원추가 실패',
    addTeamMemberConfirmText: '추가할 회원을 선택 하십시오',
    removeTeamMemberText: '회원 탈퇴',
    removeTeamMemberConfirmText: '회원을 탈퇴 시키겠습니까？',
    removeTeamMemberSuccessText: '회원탈퇴 성공',
    removeTeamMemberFailedText: '회원탈퇴 실패',
    teamTitleConfirmText: '그룹명을 공란으로 할수 없습니다',
    teamAvatarConfirmText: '그룹 이미지를 공란으로 할수 없습니다',
    teamIdText: '그룹ID',
    teamSignText: '그룹소개',
    teamTitlePlaceholder: '그룹명을 입력 하십시오',
    teamSignPlaceholder: '내용을 입력 하십시오',
    teamOwnerText: '그룹장',
    teamManagerText: '관리자',
    teamManagerLimitText: '그룹자료를 관리자만 수정 할수 있습니다',
    teamMemberText: '그룹회원',
    teamInfoText: '그룹자료',
    teamPowerText: '권한설정',
    dismissTeamText: '그룹해산',
    dismissTeamConfirmText: '그룹 해산 하시겠습니까?',
    leaveTeamTitle: '그룹 탈퇴하기',
    leaveTeamConfirmText: '그룹 탈퇴 확인',
    personUnit: '인',
    leaveTeamButtonText: '삭제 및 탈퇴',
    sendMsgFailedText: '메세지 발송 실패',
    getHistoryMsgFailedText: '과거기록 메시지를 가져오는데 실패했습니다',
    sendBlackFailedText: '상대방에 의해 블랙리스트에 올랐습니다',
    recallMessageText: '메세지를 철회 하였습니다',
    reeditText: '다시편집',
    addChatMemberText: '채팅회원 추가',
    chatHistoryText: '채팅기록',
    noMoreText: '더이상 메세지가 없습니다',
    receiveText: '새 메세지 도착 하였습니다',
    strangerNotiText: '현재 고객님의 친구가 아니니, 개인프라이버시보호에 주의하시기 바랍니다',
    teamInviteModeText: '관리자만 다른 사람을 초대할 수 있습니다',
    nickInTeamText: '그룹방 나의 ID',
    editNickInTeamText: '그룹방 ID 편집',
    replyText: '회신',
    unreadText: '읽지 않음',
    readText: '읽음',
    // emoji 不能随便填，要用固定 key
    Laugh: "[大笑]",
    Happy: "[开心]",
    Sexy: "[色]",
    Cool: "[酷]",
    Mischievous: "[奸笑]",
    Kiss: "[亲]",
    Spit: "[伸舌头]",
    Squint: "[眯眼]",
    Cute: "[可爱]",
    Grimace: "[鬼脸]",
    Snicker: "[偷笑]",
    Joy: "[喜悦]",
    Ecstasy: "[狂喜]",
    Surprise: "[惊讶]",
    Tears: "[流泪]",
    Sweat: "[流汗]",
    Angle: "[天使]",
    Funny: "[笑哭]",
    Awkward: "[尴尬]",
    Thrill: "[惊恐]",
    Cry: "[大哭]",
    Fretting: "[烦躁]",
    Terrorist: "[恐怖]",
    Halo: "[两眼冒星]",
    Shame: "[害羞]",
    Sleep: "[睡着]",
    Tired: "[冒星]",
    Mask: "[口罩]",
    ok: "[ok]",
    AllRight: "[好吧]",
    Despise: "[鄙视]",
    Uncomfortable: "[难受]",
    Disdain: "[不屑]",
    ill: "[不舒服]",
    Mad: "[愤怒]",
    Ghost: "[鬼怪]",
    Angry: "[发怒]",
    Unhappy: "[不高兴]",
    Frown: "[皱眉]",
    Broken: "[心碎]",
    Beckoning: "[心动]",
    Ok: "[好的]",
    Low: "[低级]",
    Nice: "[赞]",
    Applause: "[鼓掌]",
    GoodJob: "[给力]",
    Hit: "[打你]",
    Please: "[阿弥陀佛]",
    Bye: "[拜拜]",
    First: "[第一]",
    Fist: "[拳头]",
    GiveMeFive: "[手掌]",
    Knife: "[剪刀]",
    Hi: "[招手]",
    No: "[不要]",
    Hold: "[举着]",
    Think: "[思考]",
    Pig: "[猪头]",
    NoListen: "[不听]",
    NoLook: "[不看]",
    NoWords: "[不说]",
    Monkey: "[猴子]",
    Bomb: "[炸弹]",
    Cloud: "[筋斗云]",
    Rocket: "[火箭]",
    Ambulance: "[救护车]",
    Poop: "[便便]",
}
export default {
    zh_CN,
    en,
    ko,
}
