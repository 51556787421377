/**
* zh_CN
*/
export default {
    bannerName: '快捷交易中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    buyAmount: '购买数量',
    rujinAmount: '入金数量',
    tradeRate: '汇率',
    paymentAmount: '支付金额',
    rujinjine: '入金金额',
    tradeFee: '手续费',
    patternofPayment: '请选择支付方式',
    sellersChoice: '选择卖家',
    sellersSearch: '搜索卖家',
    countDown: '倒计时',
    orderNo: '订单号',
    remittanceAmount: '支付金额',
    clientProceedsWay: '卖家收款方式',
    name: '姓名',
    bankAccount: '银行账号',
    bankName:'银行名',
    WeChat: '微信支付',
    Alipay: '支付宝支付',
    getQr: '查看收款码',
    buyNow: '立即购买',
    cancelBtn: '取消',
    scheduleStatus: '进行状态',
    sellName: '交易对象',
    buyUser: '入金人',
    remittanceNow: '已汇款 (汇款好点击)',
    contactPlatform: '联系平台',
    subAppeal: '提交申诉',
    toPay: '已付款（付款好点击）',
    openIm: '联系卖家',
    verifyProceeds: '确认收款',
    cancelOrder: '取消订单',
    WECHATPAY: '微信支付',
    ALIPAY: '支付宝',
    BANK_CARD: '银行转账',
    rules: {
        payTypeId: '请选择支付方式',
        searchKey: '请选择卖家',
        appealDesc: '请输入申诉信息'
    },
    message: {
        searchUser:'没有匹配到卖家',
        success: '操作成功',
        mkOrder: '下单成功！请准备给卖家汇款。',
        orderCancel: '订单已取消',
    },
    confirm: {
        hint: '提示',
        info: '确认已支付',
        cancelInfo: '取消当前订单',
        rejectInfo: '拒绝当前订单',
        confirmInfo: '确认已汇款',
        confirmBtn: '付款后点击',
        cancelBtn: '未支付',
        confirm: '确定',
        cancel: '取消',
    }
}