<template>
  <div class="table-footer">
    <el-form :model="searchData" inline>
      <el-form-item prop="name">
        <el-input
          :placeholder="$t('transfer.content.searchInput')"
          class="searchInput"
          clearable
          v-model="searchData.name"
          id="name"
          size="mini"
          @clear='backSeachName'
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="backSeachName">{{$t('transfer.content.searchBtn')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "search",
  components: {},
  data() {
    return {
      searchData: {
        name: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    backSeachName() {
        this.$emit('backSeachName',this.searchData.name)
    }
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.table-footer {
  margin-top: 1.66667vw;
  text-align: right;
}
::v-deep .el-form-item__content{
    height: 2.29167vw !important;
}
::v-deep .searchInput .el-input__inner {
  height: 2.29167vw !important;
  background: #ededed;
  text-align: center;
}
</style>
