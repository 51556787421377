/**
* zh_CN
*/
export default {
    bannerName: {
        shortcutOrder:'快捷交易订单',
        pendingOrder:'购买/出售申请订单',
    },
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    buyBtn:'购买',
    sellBtn:'出售',
    tabelBtns:{
        all:'全部',
        audit:'审核中',
        timeOut:'已取消',
        arbitration:'仲裁中',
        reject:'已驳回',
        finished:'已完成',
    },
    orderNo:'订单号',
    status:'状态',
    buyPrice:'支付金额',
    sellPrice:'收款金额',
    tradeRate:'汇率',
    buyAmount:'购买数量',
    sellAmount:'出售数量',
    tradingObject:'交易对象',
    createdTime:'时间',
    options:'操作',
    detail:'详情',
}