/**
* ko
*/
export default {
    bannerName: {
        shortcutOrder: '빠른거래 내역',
        pendingOrder: '구매/판매신청 내역',
    },
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    buyBtn: '구매',
    sellBtn: '판매',
    tabelBtns: {
        all: '전부',
        audit: '진행중',
        timeOut: '취소',
        arbitration: '중재중',
        reject: '거절',
        finished: '완성',
    },
    orderNo: '주문번호',
    status: '상태',
    buyPrice: '지불금액',
    sellPrice: '수금금액',
    tradeRate: '환율',
    buyAmount: '구매수량',
    sellAmount: '판매수량',
    tradingObject: '거래대상',
    createdTime: '시간',
    options: '실행',
    detail: '조회',
}