/**
* ko
*/
export default {
    bannerName: '회원가입',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    phoneRegister: '휴대폰번호로 가입',
    emailRegister: '메일로 가입',
    mustFillIn: '기본설정',
    setPsd: '비밀번호 설정',
    userAgreement: '이용약관',
    signInBtn: '회원가입',
    formLabel: {
        countryCode: '국적',
        loginName: '사용자ID',
        phoneNumber: '휴대폰번호',
        mail: '메일',
        phoneVerificationCode: '휴대폰 인증코드',
        mailVerificationCode: '메일 인증코드',
        sendCode: '인증코드 발송',
        newCode: '재발송',
        password: '로그인 비밀번호',
        notarizePassword: '로그인 비밀번호 확인',
        tradePassword: '거래 비밀번호',
        notarizeTradePassword: '거래 비밀번호 확인',
        inviteId: '요청코드',
        userAgreementBtn: '이용약관',
        userAgreementInfo: ' 확인후 동의',
    },
    rules: {
        countryCode: '국적선택',
        loginName: '사용자ID를 입력 하십시오',
        phoneNumber: '휴대폰번호를 입력 하십시오',
        mail: '메일주소를 입력 하십시오',
        verificationCode: '인증코드를 입력 하십시오',
        password: '로그인 비밀번호를 입력 하십시오',
        verifyPassword: '로그인 비밀번호 확인 하십시오',
        verifyPasswordError: '로그인 비밀번호가 일치하지 않습니다.',
        tradePassword: '거래 비밀번호를 입력 하십시오',
        verifyTradePassword: '거래 비밀번호를 확인 하십시오',
        verifyTradePasswordError: '거래 비밀번호가 일치하지 않습니다.',
        inviteId: '요청코드를 입력 하십시오',
        userAgreement: '먼저 이용약관 동의',
    },
    success: '실행성공'
};
