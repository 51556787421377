<template>
  <div class="body">
    <el-dropdown
      @visible-change="visibleChange"
      trigger="click"
      class="dropdown"
    >
      <span class="el-dropdown-link">
        <i class="el-icon-arrow-down" ref="el-icon-arrow-down" />
        <img
          v-if="dropdownData.src"
          :src="dropdownData.src"
          alt=""
          class="dropdownImg"
        />{{ dropdownData.countryCode }}
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          class="el-dropdown-link"
          v-for="item in phoneInternation"
          :key="item.id"
          @click.native="dropdownItemClick(item)"
          ><img :src="item.src" alt="" class="dropdownImg" />{{
            item.label
          }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import obj from '@/utils/common'
export default {
  name: "",
  components: {},
  data() {
    return {
      dropdownData: {
        src: "",
        label: "",
      },
      phoneInternation: [],
    };
  },
  created() {
    this.phoneInternation = obj.nationality
    this.dropdownData = this.phoneInternation[0]
  },
  mounted() {},
  methods: {
    //dropdown 点击项记录
    dropdownItemClick(row) {
      this.dropdownData = row;
      this.$emit("phoneBack", row.countryCode);
    },
    //dropdown icon 翻转
    visibleChange(val) {
      this.dropdownType = val;
      this.$refs["el-icon-arrow-down"].style.transition = ".3s";
      if (val) {
        this.$refs["el-icon-arrow-down"].style.transform = "rotate(180deg)";
      } else {
        this.$refs["el-icon-arrow-down"].style.transform = "rotate(0deg)";
      }
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.body {
  cursor: pointer;
  font-weight: bold;
  .dropdown {
    width: 80px;
  }
}
.el-dropdown-link {
  display: flex;
  align-items: center;
}
.dropdownImg {
  width: 24px;
  height: 24px;
  margin-right: 0.5vw;
}
.el-icon-arrow-down {
  margin-right: 0.5vw;
}
</style>
