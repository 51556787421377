<template>
	<div>
		<SmallBanners :title="$t('transfer.bannerName')" :info="$t('transfer.bannerInfo')" />
		<div class="content">
			<div class="content-head">
				<div class="content-head-title">
					<span class="content-head-title-name">&nbsp;{{ $t("transfer.header.title") }}&nbsp;:&nbsp;</span>
					<span class="content-head-title-value">{{
            userInfo.balanceShow
          }}</span>
					<span class="content-head-title-type">USDT</span>
				</div>
				<div class="content-head-info">
					&nbsp;*&nbsp;{{ $t("transfer.header.info") }}
				</div>
				<el-form :model="formData" :rules="rules" ref="formData" class="formData">
					<el-form-item prop="address" ref="" class="formData-item border">
						<el-input placeholder="" v-model="formData.address" class="content-form-area-box-input">
							<span slot="prefix">{{ $t("transfer.header.form.address") }}:</span>
						</el-input>
					</el-form-item>
					<el-form-item prop="amount" ref="" class="formData-item border">
						<el-input placeholder="" type="number" v-model="formData.amount"
							class="content-form-area-box-input formData-item-input">
							<!-- <template slot="prepend"
                >{{ $t("transfer.header.form.amount") }}:</template
              > -->
							<!-- <template slot="append"> USDT </template> -->
							<span slot="prefix">{{ $t("transfer.header.form.amount") }}:</span>
							<span slot="suffix">USDT</span>
						</el-input>
					</el-form-item>
				</el-form>
				<div class="content-head-btns">
					<el-button type="primary" class="btn content-head-btns-once"
						@click="onceTransfer">{{ $t("transfer.header.btns.onceTransfer") }}</el-button>
					<el-button type="info" @click="tabelListChange(2)"
						class="btn content-head-btns-batch">{{ $t("transfer.header.btns.batch") }}</el-button>
				</div>
				<div class="content-head-btns">
					<el-button type="primary" @click="tabelListChange(1)" plain
						class="btn content-head-btns-listBtn">{{ $t("transfer.header.btns.friend") }}</el-button>
				</div>
			</div>
			<div class="tabel">
				<span class="tabelName">
					<span>
						<span v-if="transferType == 1">{{
              $t("transfer.header.btns.friend")
            }}</span>
						<span v-if="transferType == 2">{{
              $t("transfer.header.btns.batch")
            }}</span>
					</span>
				</span>
				<div class="tabel-title" v-if="transferType != 0">
					<el-button type="info" v-if="transferType == 1" plain class="tabel-title-btn"
						@click="openDialog('AddDialog')">{{ $t("transfer.content.addFriend") }}</el-button>
					<el-button type="info" v-if="transferType == 2" plain class="tabel-title-btn"
						@click="openDialog('AddBatchDialog')">{{ $t("transfer.content.addTransfer") }}</el-button>
				</div>

				<el-form :model="formTable" ref="tableForm">
					<el-table :data="formTable.tableData" ref="table" v-loading="loading">
						<el-table-column show-overflow-tooltip :key="Math.random()"
							:label="$t('transfer.content.tabel.type')" v-if="transferType == 0" align="center">
							<template slot-scope="scope">
								<div class="tabel-item">
									<span class="tabel-item-bold">
                    {{$t(`transfer.content.convey.${scope.row.type}`)}}
									</span>
								</div>

							</template>
						</el-table-column>
            <el-table-column show-overflow-tooltip :key="Math.random()"
                             :label="$t('transfer.content.tabel.amount')" v-if="transferType == 0" align="center">
              <template slot-scope="scope">
                <div class="tabel-item">
									<span class="tabel-item-bold">
										{{ scope.row.amountShow }}
									</span>
                </div>
              </template>
            </el-table-column>
						<el-table-column show-overflow-tooltip :key="Math.random()"
							:label="$t('transfer.content.tabel.friendName')" v-if="transferType != 0" align="left">
							<template slot-scope="scope">
								<div class="tabel-item color">
									<el-radio v-model="radio" :label="scope.row.id"
										v-if="transferType == 1">{{ scope.row.friendName }}</el-radio>
									<span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                      scope.row.friendName
                    }}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="400px" show-overflow-tooltip :key="Math.random()" prop="address"
							:label="$t('transfer.content.tabel.address')" align="center">
							<template slot-scope="scope">
								<div class="tabel-item color">
									{{ scope.row.address ? scope.row.address : "&nbsp;" }}
								</div>
							</template>
						</el-table-column>
						<el-table-column show-overflow-tooltip :key="Math.random()"
							:label="$t('transfer.content.tabel.amount')" v-if="transferType == 2" align="center">
							<template slot-scope="scope">
								<div class="tabel-item color">
									<el-form-item :rules="rules.amount" :prop="'tableData.' + scope.$index + '.amount'">
										<el-input v-model="formTable.tableData[scope.$index].amount"
											placeholder=""></el-input>
									</el-form-item>
								</div>
							</template>
						</el-table-column>
            <el-table-column show-overflow-tooltip :key="Math.random()" prop="createTime"
                             :label="$t('transfer.content.tabel.friendName')" v-if="transferType == 0" align="center">
              <template slot-scope="scope">
                <div class="tabel-item color">
                  {{ scope.row.userName }}
                </div>
              </template>
            </el-table-column>
						<el-table-column show-overflow-tooltip :key="Math.random()" prop="createTime"
							:label="$t('transfer.content.tabel.createTime')" v-if="transferType == 0" align="center">
							<template slot-scope="scope">
								<div class="tabel-item color">
									{{ timeCheck(scope.row.timestamp) }}
								</div>
							</template>
						</el-table-column>
						<el-table-column :key="Math.random()" :label="$t('transfer.content.tabel.options')"
							v-if="transferType != 0" align="center" width="180">
							<template slot-scope="scope">
								<div class="tabel-item">
									<el-button type="primary"
										@click="deleteFriend(scope.row,transferType==1?true:false)"
										class="btn">{{ $t("transfer.content.tabel.delete") }}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-form>
				<Search @backSeachName="backSeachName" v-if="transferType == 1" />
				<div class="tabelFootet" v-if="transferType == 2">
					<el-button type="primary" class="btn tabelFootet-btn"
						@click="onceBatch()">{{ $t("transfer.content.footerBtn") }}</el-button>
				</div>
				<Pagination :page="page" :size="size" :total="total" @callbackPage="callbackPage"
					v-if="transferType != 2" />
			</div>
		</div>
		<AddDialog ref="AddDialog" @dialogBack="dialogBack" />
		<AddBatchDialog ref="AddBatchDialog" @dialogBack="dialogBack" />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
	</div>
</template>

<script>
	import Vue from "vue";
	import {
		assetInfo
	} from "@/api/estate";
	import {
		conveyTransfer,
		orderList,
		relationshipList,
		deleteRelationship,
		conveyBatchTransfer,
	} from "@/api/transfer";
	import Search from "./components/search.vue";
	import AddDialog from "./components/addDialog.vue";
	import AddBatchDialog from "./components/addBatchDialog.vue";
	import moment from "moment";
	export default {
		name: "",
		components: {
			Search,
			AddDialog,
			AddBatchDialog
		},
		data() {
			return {
				loading: false,
				transferType: 0, // 0：历史，1：好友列表，2：批量传送
				radio: "",
				userInfo: {},
				formData: {
					address: "",
					amount: "",
				},
				rules: {
					address: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
					amount: [{
						required: true,
						message: "不能为空",
						trigger: "blur"
					}],
				},
				searchName: "",
				page: 1,
				size: 10,
				total: 0,
				formTable: {
					tableData: [],
				},
				verifyRed: false,
			};
		},
		watch: {
			radio(newval, oldval) {
				if (newval && this.transferType == 1) {
					let item = this.formTable.tableData.find((i) => {
						return i.id == newval;
					});
					this.formData.address = item.address;
				} else {
					this.formData.address = "";
				}
			},
		},
		created() {
			this.getUserInfo();
			this.init();
		},
		methods: {
			init() {
				this.formTable.tableData = [];
				if (this.transferType == 0) {
					let data = {
						page: this.page,
						pageSize: this.size,
					};
					this.loading = true;
					orderList(data).then((res) => {
						if (res.code == 200) {
							this.$nextTick(() => {
								this.formTable.tableData = res.data.list;
								this.page = res.data.page;
								this.size = res.data.pageSize;
								this.total = res.data.total;
								this.loading = false;
							});
						}
					});
				}
				if (this.transferType == 1) {
					let data = new FormData();
					data.append("page", this.page);
					data.append("pageSize", this.size);
					if (this.searchName) {
						data.append("name", this.searchName);
					}
					relationshipList(data).then((res) => {
						if (res.code == 200) {
							res.data.list.forEach((i) => {
								this.formTable.tableData.push({
									...i,
									radio: false,
									address: i.friendAddress,
								});
							});
							this.page = res.data.pageNum;
							this.size = res.data.pageSize;
							this.total = res.data.total;
						}
					});
				}
				this.$forceUpdate();
			},
			// 立即传送
			onceTransfer() {
				this.$refs["formData"].validate((valid) => {
					if (valid) {
						conveyTransfer(this.formData).then((res) => {
							if (res.code == 200) {
								this.formData = {
									address: "",
									amount: "",
								};
                this.$tooltipPop.open(null, true, {
                  type: "success",
                  info: [this.$t("transfer.success")],
                });
								this.getUserInfo();
								this.init();
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			//打开打开弹窗
			openDialog(refName) {
				let list = [];
				if (this.transferType == 2) {
					this.formTable.tableData.forEach((i) => {
						if (i.checkbox) {
							list.push(i);
						}
					});
				}
				this.$refs[refName].opendialog(this.userInfo, list);
			},
      tooltipBack(falg, isCancel) {
        console.log('isCancel',falg)
        console.log('isCancel',isCancel)
      },
			//弹窗返回
			dialogBack(list, isInit) {
				if (isInit) {
					this.init();
				} else {
					console.log('this.formTable.tableData', this.formTable.tableData)
					console.log('this.formTable.tableData', list)
					this.formTable.tableData = list;
					this.$set(this.formTable, 'tableData', list)
				}
			},
			//搜索返回
			backSeachName(name) {
				this.searchName = name;
				this.init();
			},
			//删除
			deleteFriend(row, isDelete) {
				if (isDelete) {
					let data = new FormData();
					data.append("address", row.friendAddress);
					deleteRelationship(data).then((res) => {
						if (res.code == 200) {
							this.init();
						}
					});
				} else {
					let list = this.formTable.tableData
					let ind = list.map(i => i.id).indexOf(row.id)
					list.splice(ind, 1)
					this.$set(this.formTable, 'tableData', list)
				}
			},
			// 立即批量传送
			async onceBatch() {
				this.$refs["tableForm"].validate(async (valid) => {
					if (valid) {
						this.verifyRed = false;
						let list = [];
						await this.formTable.tableData.forEach((i) => {
							if (!i.amount) {
								this.verifyRed = true;
							}
							list.push({
								address: i.address,
								amount: i.amount,
							});
						});
						await conveyBatchTransfer({
							conveyList: list
						}).then((res) => {
							if (res.code == 200) {
								this.$tooltipPop.open(null, true, {
									type: "success",
									info: [this.$t("transfer.success")],
								});
								this.transferType == 0;
								this.init();
								this.getUserInfo();
							}
						});
					} else {}
				});
			},
			// 查询个人资产
			getUserInfo() {
				assetInfo().then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
					}
				});
			},
			//分页返回
			callbackPage({
				page,
				size
			}) {
				this.page = page;
				this.size = size;
				this.init();
			},
			// tabel状态切换
			tabelListChange(val) {
				if (this.transferType == val) {
					this.transferType = 0;
				} else {
					this.transferType = val;
				}
				this.page = 1;
				this.size = 10;
				this.init();
			},
		},
		beforeUpdate() {
			this.$nextTick(() => {
				this.$refs["table"].doLayout();
			});
		},

		computed: {
			timeCheck() {
				return (time) => {
					return moment(time).format("YYYY-MM-DD H:mm:ss");
				};
			},
		},
	};
</script>
<style scoped lang='scss'>
	.content {
		margin-bottom: 5vw;
		margin-top: -2vw;

		.content-head {
			width: 100%;
			margin-bottom: 3.541667vw;

			.content-head-title {
				color: #019393;
				font-size: 36px;
				margin-bottom: 1.71875vw;

				.content-head-title-value {
					font-size: 48px;
					font-weight: bold;
					margin-right: 0.5vw;
				}
			}

			.content-head-info {
				color: #cccccc;
			}

			.formData {
				width: 100%;
				margin-top: 0.5vw;

				.formData-item {
					width: 100%;
					border-radius: 5px;
				}

				.formData-itemBorder {
					border: 1px solid #dcdfe6;
				}

				.formData-itemBorder:hover {
					border: 1px solid #019393;
				}
			}

			.content-head-btns {
				display: flex;
				justify-content: space-between;
				margin-bottom: 1.14583vw;

				.content-head-btns-once {
					width: 36.82292vw;
				}

				.content-head-btns-batch {
					width: 24.73958vw;
					background: #000000;
					border: 1px solid #313131;
				}

				.content-head-btns-batch:hover {
					background: rgba(112, 112, 112, 0.98);
				}

				.content-head-btns-batch:active {
					background: #313131 !important;
				}

				.content-head-btns-listBtn {
					width: 100%;
					color: #019393;
					background: #ffffff;
					border: 1px solid #019393;
				}

				.content-head-btns-listBtn:hover {
					border: 1px solid #00c2c2;
				}

				.content-head-btns-listBtn:active {
					border: 1px solid #019393;
				}
			}
		}

		.tabel {
			margin-top: 6.04166vw;
			position: relative;

			.tabelName {
				font-size: 1.3vw;
				font-weight: bold;
				position: absolute;
				top: 10px;
			}

			.tabel-title {
				margin-bottom: 0.67708vw;
				text-align: right;

				.tabel-title-btn {
					width: 17.23958vw;
				}
			}

			.tabel-item {
				width: 100%;
				height: 40px;
				color: #019393;
				line-height: 40px;

				.tabel-item-bold {
					font-weight: bold;
				}

				.btn {
					width: 140px;
				}

				.verifyRed {
					border: 1px solid red;
				}
			}

			.color {
				color: #313131 !important;
			}
		}

		.tabelFootet {
			text-align: center;

			.btn {
				width: 24.73958vw;
				margin-top: 4.73958vw;
				background: #000000;
				border: 1px solid #313131;
				color: #ffffff;
			}

			.btn:hover {
				background: rgba(112, 112, 112, 0.98);
			}

			.btn:active {
				background: #313131;
			}
		}
	}

	// --------------------
	::v-deep .el-form-item__error {
		visibility: hidden;
	}

	//右侧插槽
	// ::v-deep .el-input-group__append {
	//   width: 12vw !important;
	//   color: #313131 !important;
	//   background: #ededed !important;
	//   box-sizing: border-box !important;
	//   text-align: right !important;
	//   border: none !important;
	// }
	::v-deep .el-input__suffix {
		display: flex;
		align-items: center;
		color: #000000;
		margin-right: 10px;
	}

	::v-deep .el-input__prefix {
		display: flex;
		align-items: center;
		color: #000000;
		margin-left: 10px;
	}

	// 左侧插槽
	// ::v-deep .el-input-group__prepend {
	//   width: 4vw !important;
	//   text-align: center !important;
	//   background: #ededed;
	//   color: #000000 !important;
	//   border: none !important;
	// }
	::v-deep .el-input__inner {
		width: 100% !important;
		background: #ededed;
		border: 1px solid #ededed;
		padding-left: 90px;
	}

	// 修改按钮样式 ↓
	::v-deep .tabel-title-btn {
		background: #ffffff;
		border: 1px solid #313131 !important;
		color: #313131 !important;
	}

	::v-deep .tabel-title-btn:active {
		border: 1px solid #000000 !important;
	}

	::v-deep .tabel-title-btn:hover {
		background: rgba(231, 231, 231, 0.98);
		color: #000000 !important;
	}

	// --------------------
	// 修改头部样式
	::v-deep .el-table .el-table__header th {
		background: #019393;
		color: #ffffff !important;
		box-sizing: border-box;
	}

	::v-deep .el-table__header {
		border-radius: 5px !important;
		overflow: hidden !important;
	}

	// 修改title背景
	::v-deep .el-table .el-table__header th .cell {
		background: #019393;
		text-align: center;
		transform: translateX(-0.5vw);
	}

	// 修改row样式
	::v-deep .el-table .cell {
		width: calc(100%);
		display: flex;
		align-items: center;
		border-radius: 5px;
		background: #ededed;
		height: 100% !important;
		padding: 0;
	}

	::v-deep .cell .el-input__inner {
		padding-left: 10px;
	}

	v-deep .el-table__row .cell {
		line-height: 2vw;
	}

	::v-deep .formData-item-input input::-webkit-outer-spin-button,
	::v-deep .formData-item-input input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep .formData-item-input input[type="number"] {
		-moz-appearance: textfield;
	}

	::v-deep .selectCurrency {

		.el-select__caret,
		.el-input__icon,
		.el-icon-arrow-up,
		.el-input__inner {
			cursor: pointer !important;
			width: 100% !important;
		}
	}

	::v-deep .selectBefore .el-icon-arrow-up:before {
		content: none !important;
	}
</style>
