<template>
  <div class="content">
    <div class="content-head">
      <div class="content-head-btnbox">{{ $t("clientSell.tradeTye") }}</div>
    </div>
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      class="formData"
      label-position="left"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="price" ref="elFormItem1" class="formData-item">
        <el-input
          placeholder=""
          v-model="formData.price"
          :controls="false"
          :type="formData.computeMode == 2 ? 'text' : 'number'"
          ref="elFormItem1Input"
          :disabled="formData.computeMode == 2 ? true : false"
          class="formData-item-input"
          @input="inputRate"
        >
          <template slot="prepend">{{ $t("clientSell.price") }}</template>
          <template slot="append">
            <el-select
              ref="currencyType"
              v-model="formData.currencyCode"
              placeholder=""
              class="selectCurrency"
              @change="
                (val) => selectionEcho(val, 'currencyType', currencyTypes)
              "
            >
              <el-option
                v-for="item in currencyTypes"
                :key="item.id"
                :value="item.currency"
              >
                <img
                  :src="item.src"
                  style="
                    width: 24px !important;
                    height: 24px !important;
                    vertical-align: text-bottom;
                    transform: translateY(15%);
                  "
                />
                {{ item.currency }}
              </el-option>
            </el-select>
          </template>
        </el-input>
      </el-form-item>
      <div class="rotation" @click="rotateFun">
        <img src="@/assets/transaction/rotate.png" ref="rotation" alt="" />
      </div>
      <el-form-item prop="quantity" ref="elFormItem2" class="formData-item">
        <!-- <span class="formData-item-tip">{{ $t("clientBuy.possessor") }}</span> -->
        <el-input
          placeholder=""
          v-model="formData.quantity"
          :type="formData.computeMode == 1 ? 'text' : 'number'"
          ref="elFormItem2Input"
          :disabled="formData.computeMode == 1 ? true : false"
          class="formData-item-input"
          @input="inputRate"
        >
          <template slot="prepend">{{ $t("clientSell.quantity") }}</template>
          <template slot="append">
            <el-select
              disabled
              ref="currencyText"
              v-model="formData.currencyText"
              placeholder=""
              class="selectBefore"
              @change="(val) => selectionEcho(val, 'currencyText', currency)"
            >
              <el-option
                v-for="item in currency"
                :key="item.id"
                :value="item.label"
              >
                <img
                  :src="item.src"
                  style="
                    width: 24px !important;
                    height: 24px !important;
                    vertical-align: text-bottom;
                    transform: translateY(15%);
                  "
                />
                {{ item.label }}
              </el-option>
            </el-select>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="footerBtn">
      <el-button type="info" @click="verifyToPath()" class="subBtn">{{
        $t("clientSell.sellBtn")
      }}</el-button>
    </div>
    <PageInfoTXT :infoList="infoList" />
  </div>
</template>

<script>
import { getRate, checkSell, clientShopInfo, sell } from "@/api/client";
import obj from "@/utils/common.js";
export default {
  name: "",
  components: {},
  data() {
    const formDataCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        this.formData.price = "";
        this.formData.quantity = "";
        return callback(new Error(this.$t(`clientSell.rules.` + rule.field)));
      }
    };
    return {
      rotateType: false,
      shopId: null,
      userInfo: null,
      formData: {
        tradeTye: 4, // 3：买、4：卖
        computeMode: 1, //计算方式，1：按金额计算，2：按数量计算
        currencyCode: this.$store.state.common.i18n == "zh_CN" || localStorage.getItem('lang') == "zh_CN"? "CNY" : "KRW",
        inputAmount: "", //输入得数
        payTypeId: "", // 收付款方式  买入为支付方式ID,卖出为收款方式ID
        searchKey: "", // 商家名称 模糊搜索对应的商家
        //
        price: undefined, // 此值不上传 仅在当前作用域使用
        quantity: undefined, // 此值不上传 仅在当前作用域使用
        currencyText: "USDT", // 此值不上传 仅在当前作用域使用
      },
      rotateFunTime: false,
      rules: {
        price: [{ required: true, validator: formDataCheck, trigger: "blur" }],
        quantity: [
          { required: true, validator: formDataCheck, trigger: "blur" },
        ],
      },
      infoList: this.$t("clientSell.warnInfo"),
      currencyTypes: obj.nationality,
      currency: obj.currency,
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["formData"].resetFields();
      this.infoList = this.$t("clientSell.warnInfo");
      if (newVal == "zh_CN") {
        this.formData.currencyCode = "CNY";
      } else {
        this.formData.currencyCode = "KRW";
      }
      this.selectionEcho(
        this.formData.currencyCode,
        "currencyType",
        this.currencyTypes
      );
      this.$forceUpdate()
    },
  },
  created() {
    this.shopId = this.$route.params.shopId;
    clientShopInfo({ shopId: this.shopId }).then((res) => {
      if (res.code == 200) {
        this.userInfo = res.data;
      }
    });
  },
  mounted() {
    this.selectionEcho(
      this.formData.currencyCode,
      "currencyType",
      this.currencyTypes
    );
    this.selectionEcho(
      this.formData.currencyText,
      "currencyText",
      this.currency
    );
  },
  methods: {
    inputRate() {
      // 根据状态判断  从哪里取值
      if (this.formData.computeMode == 1 && this.formData.price) {
        this.formData.inputAmount = this.formData.price.replaceAll(",", "");
      }
      if (this.formData.computeMode == 2 && this.formData.quantity) {
        this.formData.inputAmount = this.formData.quantity.replaceAll(",", "");
      }
      if (!this.formData.inputAmount || this.formData.inputAmount == "") return;
      let data = {
        shopId: this.shopId,
        tradeTye: this.formData.tradeTye,
        computeMode: this.formData.computeMode,
        currencyCode: this.formData.currencyCode,
        inputAmount: this.formData.inputAmount,
      };
      getRate(data).then((res) => {
        if (res.code == 200) {
          if (this.formData.computeMode == 1) {
            this.formData.quantity = this.utils.numberFormatting(
              res.data.outputAmount
            );
          } else {
            this.formData.price = this.utils.numberFormatting(
              res.data.outputAmount
            );
          }
          this.$refs["formData"].validate((valid) => {});
        }
      });
    },
    // 跳转页面
    verifyToPath() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let data = {
            shopId: this.shopId,
            orderNo: this.userInfo.orderNo,
            tradeTye: this.formData.tradeTye,
            computeMode: this.formData.computeMode,
            currencyCode: this.formData.currencyCode,
            inputAmount: this.formData.inputAmount,
          };
          // 出金前见检查
          checkSell(data).then((res) => {
            if (res.code == 200) {
              sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
              sessionStorage.setItem("formData", JSON.stringify(this.formData));
              sessionStorage.setItem("checkResData", JSON.stringify(res.data));
              this.$emit("pageChange", 2);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 设置下拉选回显图片
    selectionEcho(val, refName, list) {
      let dom = this.$refs[refName].$el.children[0].children[0];
      this.utils.selectionEcho(val, dom, list);
      // 切换币种重新查询换算值
      if (this.formData.inputAmount) {
        this.inputRate();
      }
    },
    // 输入数据格式化
    valueCheck() {
      if (this.formData.computeMode == 1) {
        this.formData.computeMode = 2;
        if (this.formData.quantity) {
          this.formData.quantity = this.formData.quantity.replaceAll(",", "");
        }
        if (this.formData.price) {
          this.formData.price = this.utils.numberFormatting(
            this.formData.price
          );
        }
      } else {
        this.formData.computeMode = 1;
        if (this.formData.price) {
          this.formData.price = this.formData.price.replaceAll(",", "");
        }
        if (this.formData.quantity) {
          this.formData.quantity = this.utils.numberFormatting(
            this.formData.quantity
          );
        }
      }
    },
    //调转动画
    rotateFun() {
      if (this.rotateFunTime) {
        return;
      }
      this.valueCheck();
      // 交互动画
      this.rotateFunTime = true;
      let time = setTimeout(() => {
        clearTimeout(time);
        this.rotateFunTime = false;
      }, 800);
      this.$nextTick(() => {
        this.rotateType = !this.rotateType;
        let rotationDom = this.$refs["rotation"];
        rotationDom.style.animation = "rotate .6s normal";
        let time = setTimeout(() => {
          rotationDom.style.animation = "none";
          clearTimeout(time);
        }, 800);

        // 输入框调转
        let item1 = this.$refs["elFormItem1"];
        let itemStyle1 = item1.$el.style;
        itemStyle1.transition = ".6s";
        if (this.rotateType) {
          itemStyle1.top = "calc(100% - 40px)";
        } else {
          itemStyle1.top = "calc(0px)";
        }

        let item2 = this.$refs["elFormItem2"];
        let itemStyle2 = item2.$el.style;
        itemStyle2.transition = ".6s";
        if (this.rotateType) {
          itemStyle2.bottom = "calc(100% - 40px)";
        } else {
          itemStyle2.bottom = "calc(0px)";
        }
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            this.inputRate();
          } else {
            return false;
          }
        });
      });
    },
  },

  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6.77083vw;
  .content-head {
    width: 100%;
    margin-bottom: 3.541667vw;
    .content-head-btnbox {
      width: 100px;
      height: 40px;
      line-height: 40px;
      background: #019393;
      color: #ffffff;
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .formData {
    width: 100%;
    height: 8vw;
    min-height: 150px;
    position: relative;
    .formData-item-tip {
      position: absolute;
      top: -30px;
      left: 30px;
      color: #019393;
    }
    .rotation {
      position: absolute;
      top: calc(50% - 1.5vw);
      left: calc(50% - 1.5vw);
      width: 2.5vw;
      height: 2.5vw;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      img {
        width: 2.5vw;
        height: 2.5vw;
        animation-name: rotate;
      }
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }
    .formData-item {
      width: 100%;
      .formData-item-input {
        .append {
          padding: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .formData-item:first-child {
      position: absolute;
      top: 0;
      left: 0;
    }
    .formData-item:last-child {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .subBtn {
    margin-top: 5.72917vw;
    width: 100%;
    color: #ffffff !important;
  }
  .subBtn:active {
    background: #313131 !important;
  }
}
.footerBtn {
  display: flex;
  justify-content: center;
  .subBtn {
    margin-top: 3vw;
    width: 100%;
    color: #ffffff;
  }
  .subBtn:active {
    background: #313131 !important;
  }
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
::v-deep .subBtn {
  background: #313131 !important;
}
::v-deep .subBtn:hover {
  background: rgba(110, 110, 110, 0.98) !important;
}
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
::v-deep .el-form-item__error {
  visibility: hidden !important;
}
//修改input样式
::v-deep .el-input-group__append {
  width: 12vw !important;
  box-sizing: border-box !important;
  .el-select {
    line-height: 40px !important;
    .el-input__suffix {
      line-height: 100%;
    }
  }
}
::v-deep .el-input-group__prepend {
  width: 7.76042vw;
  text-align: center;
  border-right: none;
}
::v-deep .el-input__inner {
  width: 100% !important;
}
::v-deep .formData-item-input input::-webkit-outer-spin-button,
::v-deep .formData-item-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep .formData-item-input input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep .selectCurrency {
  .el-select__caret,
  .el-input__icon,
  .el-icon-arrow-up,
  .el-input__inner {
    cursor: pointer !important;
    width: 100% !important;
  }
}
::v-deep .selectBefore .el-icon-arrow-up:before {
  content: none !important;
}
</style>
