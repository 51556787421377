/**
* en
*/
export default {
    bannerName: 'Cash center',
    bannerInfo: 'Safe, Fast, Convenient',
    tradeTye: 'withdraw',
    price: 'Withdrawal amount',
    quantity: 'Out amount',
    sellBtn: 'Immediate withdrawal',
    cancelBntn: 'cancel',
    Step1: {
        label: 'Step1 : Please confirm the quantity and amount of funds disbursed',
        tradeAmount: 'Out amount:',
        currencyAmount: 'Withdrawal amount:',
        orderNo: 'Order number:'
    },
    Step2: {
        label: 'Step2 : Please enter the ID or name used by the drawer',
    },
    Step3: {
        label: 'Step3 : Please enter the Umarket wallet address',
        placeholder:'Please click "Export Address" to automatically generate an address'
    },
    exportAddress: 'Export Address',
    agreement: {
        txt1: 'User Agreement',
        txt2: 'Please confirm and click agree.',
    },
    tooltipList: {
        txt1: 'The withdrawal application has been completed.',
        txt2: "Please wait for the merchant's review.",
    },
    rules: {
        price: 'Please enter the selling price',
        quantity: 'Please enter the sales quantity',
        userName: 'Please enter the ID or name used by the drawer',
        address: 'Please enter the Umarket wallet address',
        checkbox: 'Please confirm the user agreement and click agree',
    },
    message: {
        agreement: 'Please confirm the user agreement and click agree'
    },
    warnInfo: [
        {
            title: "matters needing attention",
            values: [
                `1. Please complete the sale application after the withdrawal application, and the platform will automatically match the buyer.`,
                `2. Register the Umarket account first and apply for withdrawal`,
                `3. During the withdrawal process, click "Export wallet address" to appear on the Umarket login page. Log in and automatically generate the wallet address.`,
            ],
        },
    ]
};