<template>
  <div class="content">
    <div class="content-head">
      <div class="content-head-title">
        <div class="content-head-title-label">
          {{ $t("clientBuy.countDown") }}
        </div>
        <div class="content-head-title-date">{{ countDown }}</div>
      </div>
      <div class="content-head-info">
        <div class="content-head-info-item">
          <span class="content-head-info-item-name"
            >{{ $t("clientBuy.orderNo") }}:</span
          >
          <span class="content-head-info-item-info"
            >#{{ clientData.orderNo }}</span
          >
        </div>
        <div class="content-head-info-item">
          <span class="content-head-info-item-name"
            >{{ $t("clientBuy.quantity") }}:</span
          >
          <span class="content-head-info-item-info color">{{
            orderData.orderAmountShow
          }}</span>
          <span class="content-head-info-item-flag">USDT</span>
        </div>
      </div>
    </div>
    <div class="content-counterparty">
      <div class="content-counterparty-title">
        {{ $t("clientBuy.sellInfo") }}
      </div>
      <div class="content-counterparty-data">
        <div class="content-counterparty-data-form">
          <div class="content-counterparty-data-form-item">
            <div class="label">
              <span>{{ $t("clientBuy.payeeUser") }}:</span>
            </div>
            <div class="value">
              {{ orderData.bankUserName }}
            </div>
          </div>
          <div class="content-counterparty-data-form-item">
            <div class="label">
              <span>{{ $t("clientBuy.price") }}:</span>
            </div>
            <div class="value">
              {{ orderData.currencyAmountShow }}{{ orderData.symbol }}
            </div>
          </div>
          <div class="content-counterparty-data-form-item">
            <template>
              <div
                v-if="orderData.payTypeId != 3 && orderData.payTypeId != 4"
                class="label"
              >
                <span>{{ $t("clientBuy.bank") }}:&nbsp;&nbsp;</span>
              </div>
              <div v-else class="label">
                <span>{{ $t("clientBuy.payType") }}:&nbsp;&nbsp;</span>
              </div>
            </template>
            <template>
              <div v-if="orderData.payTypeId == 3" class="value">
                <span>{{ $t("clientBuy.WeChat") }}&nbsp;&nbsp;</span>
              </div>
              <div v-if="orderData.payTypeId == 4" class="value">
                <span>{{ $t("clientBuy.Alipay") }}&nbsp;&nbsp;</span>
              </div>
              <div
                v-if="orderData.payTypeId != 3 && orderData.payTypeId != 4"
                class="value"
              >
                {{ orderData.bankName }}
              </div>
            </template>
          </div>
          <div
            class="content-counterparty-data-form-item"
            v-if="orderData.orderType != 0 && orderData.orderType != 2"
          >
            <div
              v-if="orderData.payTypeId != 3 && orderData.payTypeId != 4"
              class="label"
            >
              <span>{{ $t("clientBuy.bankNo") }}:&nbsp;&nbsp;</span>
            </div>
            <div v-if="orderData.payTypeId == 3" class="label">
              <span>{{ $t("clientBuy.WeChat") }}:&nbsp;&nbsp;</span>
            </div>
            <div v-if="orderData.payTypeId == 4" class="label">
              <span>{{ $t("clientBuy.Alipay") }}:&nbsp;&nbsp;</span>
            </div>
            <div
              v-if="orderData.payTypeId != 3 && orderData.payTypeId != 4"
              class="value"
            >
              {{ orderData.bankNumber }}
            </div>
            <div class="getQR value" @click="getQR" v-else>
              {{ $t("transactionBuy.getQr") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" class="footer-left btn" @click="submit">{{
        $t("clientBuy.remittance")
      }}</el-button>
      <el-button
        type="info"
        class="contactPlatform infoBtn"
        @click="cancelOrder()"
        >{{ $t("clientBuy.canelOrder") }}</el-button
      >
    </div>
    <div class="footer">
      <el-button
          type="info"
          class="contactPlatform infoBtn"
          @click="openIM(orderData.serviceAccId)"
      >{{ $t("transactionBuy.contactPlatform") }}</el-button
      >
      <!-- 联系卖家 -->
      <el-button
          type="primary"
          class="openIm"
          plain
          @click="openIM(orderData.accId)"
      >{{ $t(`transactionBuy.openIm`) }}</el-button
      >
    </div>
    <QR ref="QR" />
    <PageInfoTXT :infoList="infoList" />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import { orderInfo, paid, cancel } from "@/api/client";
export default {
  name: "",
  components: {},
  data() {
    return {
      orderData: {
        orderAmountShow: "",
        orderAmountShow: "",
      },
      clientData: {
        orderNo: "",
      },
      countDown: "00:00", // 倒计时
      infoList: this.$t("clientBuy.warnInfo3"),
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.infoList = this.$t("clientBuy.warnInfo3");
    },
  },
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }
    if (sessionStorage.getItem("checkResData")) {
      this.checkResData = JSON.parse(sessionStorage.getItem("checkResData"));
    }
    if (sessionStorage.getItem("clientData")) {
      this.clientData = JSON.parse(sessionStorage.getItem("clientData"));
    }
    this.init();
  },
  methods: {
    openIM(id) {
      this.$store.dispatch("common/setIM", { bool: null, accId: id });
    },
    init() {
      orderInfo({ orderNo: this.clientData.orderNo }).then((res) => {
        if (res.code == 200) {
          this.orderData = res.data;
          this.countDownFun();
        }
      });
    },
    // 倒计时
    countDownFun() {
      this.countDownTime = setInterval(() => {
        var endTime = this.orderData.createTimestamp + 2 * 60 * 60 * 1000;
        var newTime = new Date().getTime();
        var diffTime = (endTime - newTime) / 1000;
        var honur = parseInt((diffTime / 60 / 60) % 24);
        if (honur < 10) {
          honur = "0" + honur;
        }
        var min = parseInt((diffTime / 60) % 60);
        if (min < 10) {
          min = "0" + min;
        }
        var sen = parseInt(diffTime % 60);
        if (sen < 10) {
          sen = "0" + sen;
        }
        if (honur <= 0 && min <= 0 && sen <= 0) {
          this.countDown = "00:00:00";
          clearInterval(this.countDownTime);
        } else {
          this.countDown = honur + ":" + min + ":" + sen;
        }
      }, 1000);
    },
    // 确认付款
    submit() {
      this.$refs['TooltipPop'].openDialog('submit', false, {
        type: "affirm",
        info: [this.$t("clientBuy.tooltipList.txt1"),
        this.$t("clientBuy.tooltipList.txt2"),],
      });
    },
    // 取消订单
    cancelOrder() {
      this.$refs['TooltipPop'].openDialog('cancelOrder', false, {
        type: "affirm",
        info: [this.$t("clientBuy.confirm.hint")],
      });
    },
    // 获取二维码
    getQR() {
      let data = {
        type: 1,
        url: this.orderData.bankName,
        amountShow: this.orderData.currencyAmountShow,
        symbol: this.orderData.symbol,
      };
      this.$refs["QR"].openDialog(data);
    },
    // 提示组件返回
    tooltipBack(flag, isCancel) {
      if (isCancel) return;
      if (flag == "cancelOrder") {
        let data = {
          orderNo: this.clientData.orderNo,
        };
        cancel(data).then((res) => {
          if (res.code == 200) {
            clearInterval(this.countDownTime);
            this.countDown = "00:00:00";
            // this.init()
          }
        });
      }
      if (flag == "submit") {
        let data = {
          orderNo: this.clientData.orderNo,
        };
        paid(data).then((res) => {
          if (res.code == 200) {
            this.$emit("pageChange", 1);
          }
        });
      }
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6vw;
  .content-head {
    width: 100%;
    height: 9.11458vw;
    background: #eeeeee;
    padding: 1vw;
    box-sizing: border-box;
    .content-head-title {
      display: flex;
      justify-content: flex-start;
      .content-head-title-label {
        font-size: 1.97917vw;
        font-weight: bold;
        color: #019393;
        margin-right: 1vw;
      }
      .content-head-title-date {
        font-size: 2.34375vw;
      }
    }
    .content-head-info {
      display: flex;
      justify-content: flex-start;
      margin-top: 1.5625vw;
      .content-head-info-item {
        width: 15.625vw;
        height: 40px;
        line-height: 40px;
        background: #019393;
        color: #ffffff;
        margin-right: 1vw;
        border-radius: 5px;
        padding-left: 1vw;
        box-sizing: border-box;
        display: flex;
        font-size: 0.9375vw;
        span {
          margin-right: 0.5vw;
        }
        .color {
          color: #ffff00;
        }
      }
    }
  }
  .content-counterparty {
    margin-top: 2.1875vw;
    .content-counterparty-title {
      font-size: 1vw;
      color: #019393;
      font-weight: bold;
      padding-left: 1vw;
    }
    .content-counterparty-data {
      width: 100%;
      margin-top: 1vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    .content-counterparty-data-form {
      width: 100%;
      margin-right: 1vw;
      .content-counterparty-data-form-item {
        width: 100%;
        height: 2.5vw;
        background: #f8f8f8;
        padding: 0.5vw 1vw;
        box-sizing: border-box;
        font-weight: bold;
        color: #7d7d7d;
        margin-top: 1vw;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: start;
        align-items: center;
        div {
          font-weight: 500;
        }
        .label {
          width: 7.5vw;
          margin-right: 1vw;
        }
        .value {
          font-size: 0.9375vw;
          color: #7d7d7d;
        }
        .getQR {
          cursor: pointer;
        }
        .getQR:hover {
          color: #007c7c;
        }
      }
      .content-counterparty-data-form-item:first-child {
        margin-top: 0;
      }
    }
  }
  .footer {
    margin-top: 1.92708vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-left {
      width: calc(65%);
    }
    &-right {
      width: 35%;
    }
    &-right:active {
      background: #f8f8f8 !important;
    }
  }
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
.footer {
  margin-top: 1.92708vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .footer-left {
    width: calc(70%);
  }
  .footer-right {
    width: 30%;
  }
  .openIm {
    width: calc(50%);
  }
  .contactPlatform {
    flex: 1;
    background: #313131;
    color: #ffffff;
  }
}
</style>
