<template>
  <div class="content">
    <div class="content-head">
      <div class="content-head-btnbox">
        <div class="content-head-type">
          <el-button
            type="primary"
            class="content-head-button"
            :plain="orderType != 1"
            @click="transactionTypeChange('1')"
            >{{ $t("transactionOrder.buyBtn") }}</el-button
          >
        </div>
        <div class="content-head-btnbox">
          <el-button
            type="primary"
            class="content-head-button"
            :plain="orderType != 2"
            @click="transactionTypeChange('2')"
            >{{ $t("transactionOrder.sellBtn") }}</el-button
          >
        </div>
      </div>
      <div class="content-head-dataType">
        <el-tag
          type="info"
          class="tag"
          :class="orderStatus === null ? 'tagFlag' : ''"
          @click="orderStatusChack(null)"
          >{{ $t("transactionOrder.tabelBtns.all") }}</el-tag
        >
        <el-tag
          type="info"
          class="tag"
          @click="orderStatusChack(orderType == 1 ? 2 : 0)"
          :class="orderStatus === 2 || orderStatus === 0 ? 'tagFlag' : ''"
          >{{ $t("transactionOrder.tabelBtns.audit") }}</el-tag
        >
        <el-tag
          type="info"
          class="tag"
          @click="orderStatusChack(1)"
          :class="orderStatus === 1 ? 'tagFlag' : ''"
          >{{ $t("transactionOrder.tabelBtns.timeOut") }}</el-tag
        >
        <el-tag
          type="info"
          class="tag"
          @click="orderStatusChack(4)"
          :class="orderStatus === 4 ? 'tagFlag' : ''"
          >{{ $t("transactionOrder.tabelBtns.arbitration") }}</el-tag
        >
        <el-tag
          type="info"
          class="tag"
          @click="orderStatusChack(6)"
          :class="orderStatus === 6 ? 'tagFlag' : ''"
          >{{ $t("transactionOrder.tabelBtns.reject") }}</el-tag
        >
        <el-tag
          type="info"
          class="tag"
          @click="orderStatusChack(3)"
          :class="orderStatus === 3 ? 'tagFlag' : ''"
          >{{ $t("transactionOrder.tabelBtns.finished") }}</el-tag
        >
      </div>
    </div>
    <div class="tabel">
      <el-table :data="tabelList" ref="table" v-loading="loading">
        <el-table-column
          show-overflow-tooltip
          prop="id"
          :label="$t('transactionOrder.orderNo')"
          align="center"
        />
        <el-table-column
          show-overflow-tooltip
          prop="orderStatus"
          :label="$t('transactionOrder.status')"
          align="center"
        >
          <template slot-scope="scope">
            <div class="table-item">
              <img
                src="@/assets/transactionOrder/underReview.png"
                class="tanel-falg"
                v-if="scope.row.orderStatus == 0 || scope.row.orderStatus == 2"
              />
              <img
                src="@/assets/transactionOrder/reject.png"
                class="tanel-falg"
                v-if="scope.row.orderStatus == 1 || scope.row.orderStatus == 6"
              />
              <img
                src="@/assets/transactionOrder/finish.png"
                v-if="scope.row.orderStatus == 3"
                class="tanel-falg"
              />
              <img
                src="@/assets/transactionOrder/arbitration.png"
                v-if="scope.row.orderStatus == 4"
                class="tanel-falg"
              />
              <span>
                {{ $t(`orderStatus.${scope.row.orderStatusDict}`) }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="currencyAmountShow"
          :label="
            orderType == 1
              ? $t('transactionOrder.buyPrice')
              : $t('transactionOrder.sellPrice')
          "
          align="center"
        >
          <template slot-scope="scope">
            <span class="tabel-money">
              {{ scope.row.currencyAmountShow }}{{ scope.row.symbol }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="tradeRate"
          :label="$t('transactionOrder.tradeRate')"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.priceShow }}USDT/{{ scope.row.symbol }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="orderAmountShow"
          :label="
            orderType == 1
              ? $t('transactionOrder.buyAmount')
              : $t('transactionOrder.sellAmount')
          "
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.orderAmountShow }}USDT
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="otherSideName"
          :label="$t('transactionOrder.tradingObject')"
          align="center"
        />
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          :label="$t('orderManagement.content.tabel.creationTime')"
          align="center"
        >
          <template slot-scope="scope">
            {{ timeCheck(scope.row.createTimestamp) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('transactionOrder.options')"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="tabelOptionBtn"
              @click="toDetail(scope.row)"
              size="mini"
              >{{ $t("transactionOrder.detail") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <SearchDialog @popoverSearch="popoverSearch" />
      <Pagination
        :page="page"
        :size="size"
        :total="total"
        @callbackPage="callbackPage"
      />
    </div>
  </div>
</template>

<script>
import { orderList } from "@/api/transactionOrder";
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      loading: false,
      orderType: 1,
      orderStatus: null,
      page: 1,
      size: 10,
      total: 0,
      visible: true,
      tabelList: [],
      shortcutOrder: {
        1: 1,
        2: 2,
      },
      pendingOrder: {
        1: 5,
        2: 6,
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init(orderStatus = null, id, beginDate, endDate, currencyId) {
      let path = this.$route.path.substring(1);
      let data = {
        orderType: this[path][this.orderType],
        orderStatus: orderStatus,
        id: id,
        beginDate: beginDate,
        endDate: endDate,
        symbol: currencyId,
        page: this.page,
        pageSize: this.size,
      };
      // this.loading = true;
      orderList(data).then((res) => {
        if (res.code == 200) {
          this.tabelList = res.data.list;
          this.page = res.data.pageNum;
          this.size = res.data.pageSize;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    // 查询pop回调
    popoverSearch({ start, end, orderNo, name }) {
      this.init(null, orderNo, start, end, name);
    },
    //查看详情
    toDetail(row) {
      let data = {
        query: {
          orderNo: row.id,
        },
      };
      if (this.orderType == 1) {
        data.path = "/transactionBuyTwo";
      } else {
        data.path = "/transactionSellTwo";
      }
      this.$router.push(data);
    },
    //切换买卖状态
    transactionTypeChange(type) {
      this.orderType = type;
      this.page = 1;
      this.size = 10;
      this.init();
    },
    //切换买卖状态
    orderStatusChack(type) {
      console.log("orderStatusChack");
      this.orderStatus = type;
      this.page = 1;
      this.size = 10;
      this.init(this.orderStatus);
    },
    // 分页回调
    callbackPage({ page, size, total }) {
      this.page = page;
      this.size = size;
      this.init();
    },
  },
  computed: {
    timeCheck() {
      return (time) => {
        return moment(time).format("YYYY-MM-DD H:mm");
      };
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 8.4375vw;
  .content-head {
    width: 100%;
    .content-head-btnbox {
      display: flex;
      justify-content: flex-start;
      margin-right: 1vw;
      margin-bottom: 1vw;
      .content-head-button {
        width: 8.85417vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1vw;
      }
    }
    .content-head-dataType {
      .tag {
        margin-right: 1vw;
        color: #5a5a5a;
        background: #ffffff;
        cursor: pointer;
      }
      .tag:hover {
        background: #ffffff;
      }
      .tagFlag {
        color: #019393;
        background: #ffffff;
        border: 1px solid #019393;
      }
    }
  }
  .tabel {
    margin-top: 1vw;
    .table-item {
      width: 100%;
      height: 100%;
    }
    .tabel-money {
      color: #019393;
      font-weight: bold;
    }
    .tanel-falg {
      width: 1.25vw;
      height: 1.25vw;
      transform: translateY(-10%);
    }
  }
}
::v-deep .is-plain {
  background: #ffffff !important;
}
::v-deep .is-plain:hover {
  color: #019393;
}
::v-deep .el-input__inner {
  background: #e6e6e6 !important;
}
// --------------------
// 修改头部样式
::v-deep .el-table .el-table__header th {
  background: #019393 !important;
  color: #ffffff !important;
}
::v-deep .el-table__header {
  border-radius: 0.26042vw !important;
  overflow: hidden !important;
}
</style>
