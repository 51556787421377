/**
* ko
*/
export default {
  PARAMETER_ERROR: '매개변수 오류',
  SEND_CODE_FREQUENT: '인증코드 발송 빈번',
  LOGIN_NAME_HAS_BEEN_REGISTERED: '사용자 ID 이미 존재합니다',
  PHONE_NUMBER_HAS_BEEN_REGISTERED: '이미 등록된 휴대폰번호 입니다',
  EMAIL_HAS_BEEN_REGISTERED: '이미 등록된 메일주소 입니다',
  INVALID_INVITE_CODE: '요청코드가 잘못 되였습니다',
  VALIDATE_CODE_ERROR: '인증코드가 잘못 되였습니다',
  INVALID_VERIFICATION_CODE: '인증코드가 잘못 되였습니다',
  LOGIN_NAME_OR_PASSWORD_ERROR: '아이디 혹은 비밀번호 오류',
  PHONE_NUMBER_ERROR: '휴대폰번호 오류',
  EMAIL_ERROR: '메일 오류',
  CURRENCY_CODE_ERROR: '화페코드 오류',
  NEED_AUTH_PASS_FIRST_ERROR: '본인인증 신청 하시고<br/>관리자 심사를 심사를 기다려 주십시오.',
  NEED_BIND_BANK_CARD_AND_APPROVE: '은행계좌 등록, 관리자 심사 통과후 거래를 진행할수 있습니다',
  ORDER_MIN_NUM_ERROR: '최소 거래량  초과하였습니다',
  ORDER_MAX_NUM_ERROR: '최대 거래량을 초과하였습니다',
  ORDER_STATUS_CANNOT_BE_OPERATE: '주문상태가  실행을 허용하지 않습니다',
  NO_OPERATION_PERMISSION: '실행권한이 없습니다',
  SHOP_ID_NOT_EXIST: '존재하지 않는 업체',
  CLIENT_NO_SELLER_ERROR: '서비스를 제공할 수 있는 판매자가 없습니다',
  CLIENT_NO_BUYER_ERROR: '서비스를 제공할 수 있는 구매자가 없습니다',
  ORDER_NO_EXPIRE: '주문번호가 종료되었습니다',
  ORDER_NO_ERROR: '주문번호가 존재하지 않습니다',
  SHOP_BALANCE_NOT_ENOUGH: '업체 잔액부족',
  INVALID_UMARKET_ADDRESS: ' umarket 주소 정확하지 않습니다.',
  NO_REGISTER: '비회원',
  SYSTEM_CONFIGURATION_ERROR: '시스템설정 오류',
  BAD_REQUEST: '잘못된 요청',
  READDRESS_INVALID: '충전주소 오류',
  ADDRESS_INVALID: '무효주소',
  PHONE_NUMBER_HAS_ALREADY_BEEN_LINKED_TO_AN_ACCOUNT: '휴대폰번호가 이미 다른 계정에 연동되었습니다',
  VERIFICATION_CODE_ERROR: '인증코드 오류',
  EMAIL_HAS_ALREADY_BEEN_LINKED_TO_AN_ACCOUNT: '메일이 이미 다른 계정에 연동 되였습니다',
  CERTIFICATION_IN_PROGRESS: '본인인증 심사중',
  PASSED: '본인인증 완료',
  ADDRESS_DOES_NOT_EXIST: '주소가 존재 하지 않음',
  REPEATEDLY_ADDING_FRIENDS: '주소를 중복 사용 할수 없습니다',
  OLD_PASSWORD_ERROR: '기존 로그인비밀번호 오류',
  OLD_TRADE_PASSWORD_ERROR: '기존 거래 비밀번호 오류',
  BALANCE_NOT_ENOUGH: '잔액부족',
  USER_NOT_PRESENT: '계정이 존재하지 않습니다',
  TOKEN_ERROR: '로그인 시간초과',
  TOKEN_ERROR: '거래 비밀번호 오류',
  INVALID_ADDRESS: '주소 정확하지 않습니다.',
  ONLY_ADD_UMARKET_MEMBER_ADDRESS: 'Umarket 주소만 사용가능 합니다',
  BALANCE_NOT_ENOUGH: '잔액부족',
  USER_NOT_PRESENT: '존재하지 않는 사용자',
  TRADE_PASSWORD_ERROR: '거래 비밀번호가 정확하지 않습니다',
  NOT_AGENT: '대리상 사용자가 아닙니다',
  ACCOUNT_HAS_BEEN_FROZEN: "계정이 동결 되였습니다.  관리자에게 문의 하십시오."
};
