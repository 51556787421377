import api from '@/utils/request'
// 获取用户的朋友列表
export function relationshipList(data) {
    return api.post('/api/v1/relationship/list', data)
}
// 添加好友
export function addRelationship(data) {
    return api.post('/api/v1/relationship/add', data)
}
// 删除好友（可以批量删除）
export function deleteRelationship(data) {
    return api.post('/api/v1/relationship/remove', data)
}
// 单个传送
export function conveyTransfer(data) {
    return api.post('/api/v1/convey/transfer', data)
}
// 批量传送
export function conveyBatchTransfer(data) {
    return api.post('/api/v1/convey/batchTransfer', data)
}
// 传送历史
export function orderList(data) {
    return api.post('/api/v1/convey/history',data)
}