/**
* ko
*/
export default {
    bannerName: '출금 센터',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    tradeTye: '출금',
    price: '출금금액',
    quantity: '출금수량',
    sellBtn: '출금하기',
    cancelBntn: '취소',
    Step1: {
        label: 'Step1 : 출금금액과 수량을 확인 하십시오',
        tradeAmount: '출금수량:',
        currencyAmount: '출금금액:',
        orderNo: '주분번호:'
    },
    Step2: {
        label: 'Step2 :출금인 ID 혹은 성명을 입력 하십시오 ',
    },
    Step3: {
        label: 'Step3 : Umarket지갑주소를 입력 하십시오',
        placeholder:'“주소 불러오기"를 클릭하시면 주소가 자동으로 생성 됩니다.'
    },
    exportAddress: '주소 불러오기',
    agreement: {
        txt1: '이용약관',
        txt2: ' 확인후 동의',
    },
    tooltipList: {
        txt1: '출금신청 완료',
        txt2: '업체의 심사를 기다려 주십시오.',
    },
    rules: {
        price: '      ',
        quantity: '판매수량을 입력 하십시오',
        userName: '출금인ID 혹은 성명을 입력 하십시오',
        address: 'Umarket지갑주소를 입력 하십시오',
        checkbox: '이용약관 확인후 동의',
    },
    message: {
        agreement: '이용약관 확인후 동의',
    },
    warnInfo: [
        {
            title: "주의사항",
            values: [
                `1. 출금신청 후 판매신청을 완료해 주시면 플랫폼에서 구매자를 자동으로 매칭해 드립니다.`,
                `2. Umarket 회원가입후  출금신청 하십시오.  `,
                `3. 출금과정에서 '지갑주소 불러오기'를 클릭하시면  Umarket 로그인 페이지가 나타나고 로그인을 하면 지갑주소가 자동으로 생성됩니다.`,
            ]
        }
    ]
};
