/**
* zh_CN
*/
export default {
    KOREA:'韩国',
    CHINA:'中国',
    EU:'欧盟',
    BRITAIN:'英国',
    JAPAN:'日本',
    MALAYSIA:'马来西亚',
    PHILIPPINES:'菲律宾',
    THAILAND:'泰国',
    VIETNAM:'越南',
  };