<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      v-el-drag-dialog
      top="8%"
      :modal="false"
      append-to-body
      show-close
      modal-append-to-body
      :close-on-click-modal="false"
      :before-close="cancel"
      width="960px"
      z-index="10"
    >
      <div class="content">
        <IM ref="IM" @back="cancel" v-if="dialogVisible" :accId="accId" />
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import elDragDialog from "@/utils/directive/el-dragDialog";
import IM from "./IM.vue";
export default {
  name: "App",
  components: {
    IM,
  },
  directives: {
    elDragDialog,
  },

  data() {
    return {
      dialogVisible: false,
      accId: "",
    };
  },
  watch: {
    "$store.state.common.IM"(newVal, oldVal) {
      this.dialogVisible = newVal;
      if (
        this.$store.state.common.imData &&
        this.$store.state.common.imData.accId
      ) {
        this.accId = this.$store.state.common.imData.accId;
      }
    },
  },
  mounted() {},
  methods: {
    cancel(bool) {
      const store = window.__xkit_store__.store;
      store.uiStore.selectedSession = "p2p-d383fa42a47d9d5282ddc2f6355ffc32";
      this.$store.dispatch("common/setIM", { bool: false, accId: null });
    },
  },
};
</script>
<style lang='scss' scoped>
.content {
  width: 960px;
  height: 540px;
}
::v-deep .el-dialog {
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.3);
}
::v-deep .el-dialog__headerbtn {
  top: 2px !important;
  right: 5px !important;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
  box-sizing: border-box !important;
}
</style>
