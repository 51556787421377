<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="40vw"
      :before-close="handleClose"
    >
      <div class="title">{{ $t(`estate.rechargeDialog.title`) }}</div>
      <div class="content">
        <div class="content-title">
          {{ $t(`estate.rechargeDialog.chainId`) }}
        </div>
        <el-select
            v-model="formData.chainId"
            filterable
            placeholder=""
            style="width: 100%"
        >
          <!--                <el-option label="Umarket" value="4" />-->
          <el-option label="BNB Smart Chain(BEP20)" value="2" />
          <el-option label="Ethereum(ERC20)" value="1" />
          <el-option label="Polygon" value="3" />
        </el-select>
        <div class="content-title" style="padding-top: 20px">
          {{ $t(`estate.rechargeDialog.rechargeAddress`) }}
        </div>
        <div class="content-path">
          <div class="content-path-text">
            {{ parentInfo.address }}
          </div>
          <div class="content-path-copy" @click="copy(parentInfo.address)">
            <img src="@/assets/estate/copy.png" alt="" />
          </div>
        </div>
        <div class="content-QRcode">
          <vue-qr
            :text="parentInfo.address"
            :margin="0"
            colorDark="#333"
            colorLight="#fff"
            :logoScale="0.3"
            :size="200"
            style="vertical-align: middle"
          />
        </div>
        <div class="content-info">
          <div class="content-info-title">
            <span class="mr">*</span>
            <span>{{ $t(`estate.rechargeDialog.warnTitle`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.rechargeDialog.warninfo1`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.rechargeDialog.warninfo2`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.rechargeDialog.warninfo3`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.rechargeDialog.warninfo4`) }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { copyText } from "@/utils/copy.js";
import vueQr from "vue-qr";
export default {
  name: "rechargeDialog",
  components: {
    vueQr,
  },
  data() {
    return {
      dialogVisible: false,
      parentInfo: {
        address: "",
      },
      formData: {
        chainId: "2"
      }
    };
  },
  methods: {
    copy(text) {
      copyText(text);
    },
    opendialog(data) {
      this.parentInfo = data;
      this.dialogVisible = !this.dialogVisible;
    },
    handleClose(done) {
      done();
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  color: #019393;
  font-size: 1.25vw;
  font-weight: bold;
}
.content {
  .content-title {
    font-weight: bold;
    margin-bottom: 0.36458vw;
  }
  .content-path {
    height: 2.08333vw;
    background: #e6e6e6;
    border-radius: 0.26042vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vw;
    box-sizing: border-box;
    .content-path-text {
      font-size: 0.9375vw;
      color: #000000;
    }
    .content-path-copy {
      width: 1.25vw;
      height: 1.25vw;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content-QRcode {
    margin-top: 0.83333vw;
    height: 8.59375vw;
    text-align: center;
    cursor: pointer;
    img {
      width: 8.59375vw;
      height: 8.59375vw;
    }
  }
  .content-info {
    margin-top: 0.6vw;
    .mr {
      margin-right: 0.4vw;
    }
    .content-info-title {
      font-size: 0.72917vw;
      font-weight: bold;
      margin-bottom: 0.2vw;
    }
    .content-info-info {
      font-size: 0.72917vw;
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
