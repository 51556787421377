<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="41vw"
      :before-close="handleClose"
      class="roll-dialog"
    >
      <div class="title">{{ $t(`transfer.addFriendDialog.title`) }}</div>
      <div class="formData">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          label-width="0"
        >
          <el-form-item label="" prop="friendName">
            <el-input
              placeholder=""
              v-model="formData.friendName"
              class="content-form-area-box-input"
            >
              <template slot="prepend"
                >{{ $t(`transfer.addFriendDialog.friendName`) }}:</template
              >
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="friendAddress">
            <el-input
              placeholder=""
              v-model="formData.friendAddress"
              class="content-form-area-box-input"
            >
              <template slot="prepend"
                >{{ $t(`transfer.addFriendDialog.friendAddress`) }}:</template
              >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" class="dialog-footer" @click="subData">{{
        $t(`transfer.addFriendDialog.subBtn`)
      }}</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { addRelationship } from "@/api/transfer";
export default {
  name: "addDialog",
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        return callback(
          new Error(this.$t("transfer.addFriendDialog.rules." + rule.field))
        );
      }
    };
    return {
      dialogVisible: false,
      formData: {
        friendName: "",
        friendAddress: "",
      },
      rules: {
        friendName: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        friendAddress: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    subData() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          addRelationship(this.formData).then((res) => {
            if (res.code == 200) {
              this.$tooltipPop.open(null, true, {
                type: "success",
                info: [this.$t("transfer.success")],
              });
              let time = setTimeout(() => {
                clearTimeout(time);
                this.resetData();
                this.$emit("dialogBack", [this.formData], true);
              }, 3000);
            }
          });
        } else {
          return false;
        }
      });
    },
    opendialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    handleClose(done) {
      done();
      this.resetData();
    },
    resetData() {
      this.$refs["formData"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  color: #019393;
  font-size: 1.30208vw;
  font-weight: bold;
}
.formData {
  width: 100%;
  margin-top: 2.70833vw;
  .formData-item {
    width: 100%;
    border-radius: 5px;
  }
  .formData-itemBorder {
    border: 1px solid #dcdfe6;
  }
  .formData-itemBorder:hover {
    border: 1px solid #019393;
  }
}
.dialog-footer {
  margin-top: 1vw;
  width: 100%;
  margin: 2.08333vw 0;
}
// --------------------
// 左侧插槽
::v-deep .el-input-group__prepend {
  width: 4vw !important;
  border: none !important;
  text-align: center !important;
  background: #ededed;
  color: #000000 !important;
}
::v-deep .el-input__inner {
  width: 100% !important;
  background: #ededed;
  border: none;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
