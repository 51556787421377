<template>
  <div>
    <el-dialog
      v-loading="loading"
      :visible.sync="dialogVisible"
      top="8%"
      width="700px"
      :append-to-body="true"
      :show-close="false"
      center
      destroy-on-close
      :before-close="handleClose"
    >
      <div class="contentBox-header">
        <img src="@/assets/brands.png" alt="" />
<!--        <span class="contentBox-header-name">Umarket {{$t('logInAndResetPasswords.logInBtn')}}</span>-->
        <span class="contentBox-header-name">Umarket</span>
      </div>
      <div class="contentBox-form">
        <el-form
          :model="formData"
          :rules="logInRules"
          ref="formData"
          label-width="0px"
        >
          <el-form-item prop="loginName">
<!--            {{$t('logInAndResetPasswords.noAccount')}}-->
            <el-input
              :placeholder="$t('logInAndResetPasswords.loginName')"
              v-model="formData.loginName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              show-password
              :placeholder="$t('logInAndResetPasswords.password')"
              v-model="formData.password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="validateCode">
            <el-input
              :placeholder="$t('logInAndResetPasswords.validateCode')"
              class="contentBox-form-input"
              v-model="formData.validateCode"
            ></el-input>
            <img
              src=""
              ref="captchaImage"
              class="contentBox-form-codeImg"
              @click="getCodeImgUrl"
              alt=""
            />
          </el-form-item>
          <div class="interval" />
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" style="width: 500px;margin: 0 auto 100px;">
        <el-button type="primary" class="sub" @click="formDataFun"
          >{{$t('logInAndResetPasswords.logInBtn')}}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { login } from "@/api/login";
import IM from "@/utils/IM";
export default {
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        return callback(
          new Error(this.$t("logInAndResetPasswords.logInRules." + rule.field))
        );
      }
    };
    return {
      dialogType: "",
      loading: false,
      dialogVisible: false,
      formData: {
        loginName: "",
        password: "",
        validateCode: "",
      },
      logInRules: {
        loginName: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        validateCode: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$nextTick(() => {
        if (this.$refs["formData"]) {
          this.$refs["formData"].resetFields();
        }
      });
    },
  },
  mounted() {
    this.keydown(this);
  },
  methods: {
    openDialog(type) {
      this.getCodeImgUrl();
      this.dialogVisible = true;
      this.dialogType = type;
    },
    // 获取图片验证码
    getCodeImgUrl() {
      let time = setTimeout(() => {
        let url = "/api/v1/captcha/captchaImage?type=math&s=" + Math.random();
        this.$refs["captchaImage"].src = url;
        clearTimeout(time);
      }, 1);
    },
    // 登录提交
    formDataFun() {
      this.$nextTick(() => {
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            this.loading = true;
            let data = this.formData;
            login(data).then((res) => {
              if (res.code == 200) {
                this.$store.dispatch("userInfo/setUserInfo", res.data);
                if (res.data.certState == 0 || res.data.certState == 3) {
                  sessionStorage.setItem("logIn", "true");
                }
                IM.createIm('init');
                this.$emit("logInBack", this.dialogType);
                this.loading = false;
                this.handleClose();
              } else {
                this.loading = false;
              }
            });
          } else {
            return false;
          }
        });
      });
    },
    keydown() {
      let that = this;
      //所有键盘按下触发
      document.addEventListener(
        "keydown",
        function (e) {
          var event = e || window.event; // e:非IE浏览器使用，window.event是IE浏览器使用
          if (event.keyCode == 13) {
            that.formDataFun();
          }
        },
        false
      );
    },
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$refs["formData"].resetFields();
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.contentBox-form {
  justify-content: space-between;
  width: 500px;
  margin: 0 auto;
  .contentBox-form-input {
    width: 75%;
  }
  .contentBox-form-sendCode {
    width: calc(25% - 8px);
    margin-left: 8px;
    text-align: center;
  }
  .contentBox-form-codeImg {
    width: calc(25% - 8px);
    height: 36px;
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
  }
}
.sub {
  width: 100%;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}

::v-deep .el-dialog{
  background: #eaecf0;
  border-radius: 1.5625vw;
}

.contentBox-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.92708vw;
  padding-top: 80px;
  img {
    width: 4vw;
    height: 4vw;
    margin-right: 0.88542vw;
  }
  .contentBox-header-name {
    font-size: 52px;
    font-weight: bold;
    color: #000000;
  }
}
</style>
