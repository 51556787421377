import api from '@/utils/request'
// 获取商家信息
export function clientShopInfo(data) {
    return api.post('/api/v1/client/shopInfo', data)
}
// 支付通道交易兑换数量查询
export function getRate(data) {
    return api.post('/api/v1/client/rate', data)
}
// 入金前check
export function checkBuy(data) {
    return api.post('/api/v1/client/checkBuy', data)
}
// 立即入金
export function buy(data) {
    return api.post('/api/v1/client/buy', data)
}
// 入金确认已付款
export function paid(data) {
    return api.post('/api/v1/client/paid', data)
}
// 入金取消订单
export function cancel(data) {
    return api.post('/api/v1/client/cancel', data)
}
// 出金前check
export function checkSell(data) {
    return api.post('/api/v1/client/checkSell', data)
}
// 立即出金
export function sell(data) {
    return api.post('/api/v1/client/sell', data)
}
// 支付方式下拉选列表
export function payBankTypeList() {
    return api.get('/api/v1/user/personalCenter/payBankTypeList')
}
// 根据orderNo查询订单信息
export function orderInfo(data) {
    return api.post('/api/v1/client/orderInfo',data)
}