<template>
  <div class='fullscreen-block'>
    <iframe ref="kycIframe" class='fullscreen' allow='camera'></iframe>
  </div>
</template>

<script>
const KYC_TARGET_ORIGIN = "https://kyc.useb.co.kr";
const KYC_URL = "https://kyc.useb.co.kr/auth";
// const params =  { customer_id: '2', id: 'demoUser', key: 'demoUser0000!' };
const params =  { customer_id: 122, id: 'Tu6URp35xh', key: 'N5zJ5PW9FBduD@4' };
export default {
  name: "authenticationKR",
  components: {},
  mounted() {
    console.log('this.$refs.kycIframe',this.$refs.kycIframe)
    let that = this
    this.$refs.kycIframe.onload = ()=> {
      let encodedParams = btoa(encodeURIComponent(JSON.stringify(params)));
      this.$refs.kycIframe.contentWindow.postMessage(encodedParams, KYC_TARGET_ORIGIN);
      this.$refs.kycIframe.onload = null
    }
    this.$refs.kycIframe.src = KYC_URL

    window.addEventListener("message",(e)=> {
      try {
        const decodedData = decodeURIComponent(atob(e.data));
        console.log('decoded', decodedData);
        const json = JSON.parse(decodedData);
        console.log("json",json)
        if (json.result === 'success') {
          console.log('success')
        } else if (json.result === 'failed') {
          console.log('failed')
        } else if (json.result === 'complete') {
          console.log('complete')
        } else if (json.result === 'close') {
          console.log('close')
          that.$router.back()
        } else {
          // invalid result
        }
      }catch (error) {
        console.log('wrong data', error);
      }
    })
  },
  data() {
    return {

    }
  },
};
</script>
<style scoped lang='scss'>
.fullscreen-block {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0 19vw;
  box-sizing: border-box;
}
.fullscreen {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
