import api from '@/utils/request'
// 查询银行卡
export function payBankList() {
    return api.get('/api/v1/user/personalCenter/payBank/list')
}
// 支付方式下拉选列表
export function payBankTypeList() {
    return api.get('/api/v1/user/personalCenter/payBankTypeList')
}
// 个人中心信息查询
export function myInfo() {
    return api.get('/api/v1/user/myInfo')
}
// 重置登录密码
export function passwordLogin(data) {
    return api.post('/api/v1/user/personalCenter/password/login',data)
}
// 重置交易密码
export function passwordTransaction(data) {
    return api.post('/api/v1/user/personalCenter/password/transaction',data)
}
// 绑定邮箱
export function setEmail(data) {
    return api.post('/api/v1/user/personalCenter/email',data)
}
// 绑定手机号码
export function setPhone(data) {
    return api.post('/api/v1/user/personalCenter/phone',data)
}
// 绑定手机号码
export function realNameAuthentication(data) {
    return api.post('/api/v1/user/personalCenter/realNameAuthentication',data)
}
// 添加银行卡
export function payBankAdd(data) {
    return api.post('/api/v1/user/personalCenter/payBank/add',data)
}
// 支付状态切换
export function updateState(data) {
    return api.post('/api/v1/user/personalCenter/payBank/updateState',data)
}
// 银行卡修改
export function payBankUpdate(data) {
    return api.post('/api/v1/user/personalCenter/payBank/update',data)
}
// 银行卡删除
export function payBankDelete(data) {
    return api.post('/api/v1/user/personalCenter/payBank/delete',data)
}