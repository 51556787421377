<template>
  <div>
    <SmallBanners :title="$t('clientBuy.bannerName')" :info="$t('clientBuy.bannerInfo')" />
    <Buy1 v-if="pageType == 1" @pageChange="pageChange" />
    <Buy2 v-if="pageType == 2" @pageChange="pageChange" />
    <Buy3 v-if="pageType == 3" @pageChange="pageChange" />
  </div>
</template>

<script>
import Buy1 from "./buy1.vue";
import Buy2 from "./buy2.vue";
import Buy3 from "./buy3.vue";
export default {
  components: { Buy1, Buy2, Buy3 },
  data() {
    return {
      pageType: 1
    };
  },
  methods: {
    pageChange(type) {
      this.pageType = type;
    },
  },
  destroyed() {
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("checkResData");
  }
};
</script>

<style>
</style>