/**
* en
*/
export default {
    bannerName: 'Registered Member',
    bannerInfo: 'Safe, Fast, Convenient',
    phoneRegister: 'Mobile',
    emailRegister: 'Email',
    mustFillIn: 'Please enter a required field',
    setPsd: 'Set password',
    userAgreement: 'User Agreement',
    signInBtn: 'Register',
    formLabel: {
        countryCode: 'Nationality',
        loginName: 'User ID',
        phoneNumber: 'Mobile',
        mail: 'Email',
        phoneVerificationCode: 'Code',
        mailVerificationCode: 'Code',
        sendCode: 'Send code',
        newCode: 'Resend',
        password: 'Login password',
        notarizePassword: 'Confirm login password',
        tradePassword: 'Transaction password',
        notarizeTradePassword: 'Confirm transaction password',
        inviteId: 'Invitation code ',
        userAgreementBtn: 'User Agreement',
        userAgreementInfo: 'Please confirm and click agree',
    },
    rules: {
        countryCode: 'Please select nationality',
        loginName: 'Please enter the user ID',
        phoneNumber: 'Please enter your phone number',
        mail: 'Please enter your email address',
        verificationCode: 'Please enter the verification code',
        password: 'Please enter your login password',
        verifyPassword: 'Please confirm the login password',
        verifyPasswordError: 'Inconsistent login passwords',
        tradePassword: 'Please enter the transaction password',
        verifyTradePassword: 'Please confirm the transaction password',
        verifyTradePasswordError: 'Inconsistent transaction passwords',
        inviteId: 'Please enter the invitation code',
        userAgreement: 'Please agree to the user agreement first',
    },
    success: 'Operation successful'
};