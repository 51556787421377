<template>
  <div class="content">
    <el-form :model="formData" :rules="rules" ref="formData" class="formData">
      <el-form-item class="formData-item">
        <div class="formData-item-label">{{ $t("clientBuy.Step1.label") }}</div>
        <div class="formData-item-value">
          <div class="formData-item-value-item">
            <div class="formData-item-value-item-name">
              {{ $t("clientBuy.Step1.tradeAmount") }}
            </div>
            <div class="formData-item-value-item-value">
              {{ utils.numberFormatting(checkResData.tradeAmount) + "USDT" }}
            </div>
          </div>
          <div class="formData-item-value-item">
            <div class="formData-item-value-item-name">
              {{ $t("clientBuy.Step1.currencyAmount") }}
            </div>
            <div class="formData-item-value-item-value">
              {{ utils.numberFormatting(checkResData.currencyAmount)
              }}{{ checkResData.symbol }}
            </div>
          </div>
          <div class="formData-item-value-item">
            <div class="formData-item-value-item-name">
              {{ $t("clientBuy.Step1.orderNo") }}
            </div>
            <div class="formData-item-value-item-value">
              {{ checkResData.orderNo }}
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="userName" class="formData-item">
        <div class="formData-item-label">{{ $t("clientBuy.Step2.label") }}</div>
        <el-input v-model="formData.userName"></el-input>
      </el-form-item>
<!--      <el-form-item prop="tel" class="formData-item">
        <div class="formData-item-label">{{ $t("clientBuy.Step3.label") }}</div>
        <el-input v-model="formData.tel" class="formData-item-input">
          <template slot="prepend">
            <PhoneInternation @phoneBack="phoneBack" />
          </template>
        </el-input>
      </el-form-item>-->
      <el-form-item class="formData-item">
        <div class="formData-item-option">
          <el-checkbox
            v-model="formData.checkbox"
            size="medium"
            class="formData-item-checkbox"
          />
          <span
            @click="toAgreement(e)"
            class="formData-item-describe formData-item-agreement"
            >{{ $t("clientBuy.agreement.txt1") }}</span
          >
          <span class="formData-item-describe">{{
            $t("clientBuy.agreement.txt2")
          }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="footerBox">
      <el-button type="info" class="sub" @click="submit">{{
        $t("clientBuy.subBtn")
      }}</el-button
      ><el-button type="primary" plain class="cancel" @click="goBack">{{
        $t("clientBuy.cancel")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { buy } from "@/api/client";
export default {
  name: "",
  components: {},
  data() {
    const formDataCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        this.formData.price = "";
        this.formData.quantity = "";
        return callback(new Error(this.$t(`clientBuy.rules.` + rule.field)));
      }
    };
    return {
      shopId: "",
      userInfo: {},
      checkResData: {
        currencyAmount: "",
        tradeAmount: "",
      },
      shopId: "",
      formData: {
        userName: "",
        tel: "",
        checkbox: false,
        countryCode: "82",
      },
      rules: {
        userName: [
          {
            required: true,
            validator: formDataCheck,
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            validator: formDataCheck,
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "$store.state.common.i18n"(newVal, oldVal) {
      this.$refs["formData"].resetFields();
    },
  },
  created() {},
  mounted() {
    this.shopId = this.$route.params.shopId;
    this.formData = {
      ...JSON.parse(sessionStorage.getItem("formData")),
      userName: "",
      address: "",
      checkbox: "",
      countryCode: "82",
    };
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }
    if (sessionStorage.getItem("checkResData")) {
      this.checkResData = JSON.parse(sessionStorage.getItem("checkResData"));
    }
  },
  methods: {
    toAgreement() {
      window.open("/agreement", "_blank");
    },
    phoneBack(val) {
      this.formData.countryCode = val ? val : this.formData.countryCode;
    },
    goBack() {
      this.$emit("pageChange", 1);
    },
    submit() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (!this.formData.checkbox) {
            this.$tooltipPop.open(null, true, {
              type: "error",
              info: [this.$t(`clientBuy.message.agreement`)],
            });
            return;
          }
          let data = {
            shopId: this.shopId,
            orderNo: this.userInfo.orderNo,
            tradeTye: this.formData.tradeTye,
            computeMode: this.formData.computeMode,
            currencyCode: this.formData.currencyCode,
            inputAmount: this.formData.inputAmount,
            userName: this.formData.userName,
            tel: this.formData.countryCode + "-" + this.formData.tel,
            payTypeId: this.formData.payTypeId,
          };
          buy(data).then((res) => {
            if (res.code == 200) {
              let data = {
                orderNo: this.checkResData.orderNo,
                amount: this.checkResData.tradeAmount,
                isClient: true,
                orderType: "1",
              };
              sessionStorage.setItem("clientData", JSON.stringify(data));
              this.$emit("pageChange", 3);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6.77083vw;
  .formData {
    .formData-item {
      .formData-item-label {
        width: 31.66667vw;
        height: 2.08333vw;
        background: #019393;
        font-size: 0.9375vw;
        display: flex;
        align-items: center;
        color: #ffffff;
        padding: 0.5vw 1vw;
        border-radius: 5px 5px 5px 5px;
        margin-bottom: 1.35417vw;
        box-sizing: border-box;
      }
      .formData-item-value {
        .formData-item-value-item {
          width: 100%;
          font-size: 0.9375vw;
          line-height: 1vw;
          margin-bottom: 1vw;
          display: flex;
          justify-content: flex-start;
          padding-left: 1vw;
          .formData-item-value-item-name {
            width: 85px;
            display: flex;
            justify-content: space-between;
            margin-right: 1vw;
          }
          .formData-item-value-item-value {
            font-size: 1vw;
            font-weight: bold;
          }
        }
      }
      .formData-item-option {
        width: 100%;
        .formData-item-checkbox {
          margin-right: 18px;
        }
        .formData-item-describe {
          font-size: 0.9375vw;
        }
        .formData-item-agreement {
          cursor: pointer;
          border-bottom: 1px solid #cccccc;
        }
        .formData-item-agreement:hover {
          color: #019393;
          border-bottom: 1px solid #019393;
        }
      }
    }
  }
  .footerBox {
    display: flex;
    justify-content: space-between;
    .sub {
      width: 42.03125vw;
      margin-right: 1vw;
      background: #000000;
    }
    .sub:hover {
      background: rgba(112, 112, 112, 0.98);
    }
    .sub:active {
      background: #313131 !important;
    }
    .cancel {
      width: 19.42708vw;
      color: #000000;
      background: #ffffff !important;
      border: 1px solid #313131 !important;
    }
    .cancel:active {
      background: rgba(231, 231, 231, 0.98) !important;
      border: 1px solid #3d3d3d;
    }
  }
}
::v-deep .el-input-group__prepend {
  background: #f8f8f8;
  width: 60px;
  border: none;
  padding-right: 0.25vw;
}
::v-deep .el-input__inner {
  background: #f8f8f8;
  border: none;
}
</style>
