<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :title='$t("transactionSell.appealDescLabel")'
      :before-close="handleClose"
    >
      <el-form :model="subData" :rules="rules" ref="subData">
        <el-form-item label="" prop="appealDesc">
          <el-input
            type="textarea"
            :placeholder="$t('transaction.placeholder')"
            v-model="subData.appealDesc"
            show-word-limit
            :autosize="{ minRows: 6, maxRows: 6 }"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{
          $t("transactionSell.cancel")
        }}</el-button>
        <el-button type="primary" @click="subappeal">{{
          $t("transactionSell.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const formDataCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error(this.$t("transactionSell.rules." + rule.field)));
      }
    };
    return {
      dialogVisible: false,
      subData: {
        appealDesc: "",
      },
      rules: {
        appealDesc: [
          { required: true, validator: formDataCheck, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    subappeal() {
      this.$refs["subData"].validate((valid) => {
        if (valid) {
          this.$emit("appealBack", this.subData);
          this.handleClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.$refs["subData"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>