<template>
  <div>
    <el-upload
      action=""
      list-type="picture-card"
      :auto-upload="false"
      :limit="limitCountImg"
      :class="{ uploadIconOne: showImg, uploadIcon: noneUploadImg }"
      :file-list="fileList"
      :on-change="uploadImgChange"
      accept=".jpg,.jpeg"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>

          <span
            class="el-upload-list__item-delete"
            @click="handleDeleteImgRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="imgDialog">
      <img width="100%" :src="imageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { upload } from "@/api/common";
import {baseURL} from "@/utils/baseURL";
import { uuid } from "vue-uuid";
export default {
  name: "uploadImg",
  components: {},
  props: {
    limit: {
      default: () => {
        return 9999;
      },
    },
    list: {
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      imageUrl: "",
      showImg: true, //默认展示
      noneUploadImg: false, //
      fileList: [], //文件传输流
      needfile: {}, //需要上传的文件，最后上传的文件是存储在这里
      limitCountImg: this.limit, //设置的第几张图片隐藏上传标志
      imgDialog: false, //图片弹框
    };
  },
  mounted() {
    this.$nextTick(() => {
      let arr = [];
      this.list.forEach((i) => {
        arr.push({
          name: i,
          url: baseURL + i,
          uid: uuid.v1(),
        });
      });
      this.$set(this, "fileList", arr);
      this.noneUploadImg = this.fileList.length >= this.limitCountImg;
    });
  },
  methods: {
    handlePictureCardPreview(file) {
      this.imageUrl = file.url;
      this.imgDialog = true;
    },
    // 判断数量，是否显示上传icon
    uploadImgChange(file, fileList) {
      this.needfile = file.raw;
      this.noneUploadImg = fileList.length >= this.limitCountImg;
      this.fileList = fileList;
      this.imageUrl = file.raw; // 将上传的图片数据存放在form对象的image属性中
      // 上传服务器
      let data = new FormData();
      data.append("file", file.raw);
      upload(data).then((res) => {
        if (res.code == 200) {
          this.callBack(res.data, file, "success", res.data);
        }
      });
    },
    // 删除图片，判断数量，是否显示icon
    handleDeleteImgRemove(file) {
      this.fileList = [];
      this.needfile = {};
      this.noneUploadImg = false;
      this.callBack(this.fileList, file, "remove");
    },
    callBack(fileList, file, type, resUrl) {
      this.$emit("backUpload", {
        fileList: fileList,
        file: file,
        type: type,
        resUrl,
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.uploadIcon ::v-deep .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
