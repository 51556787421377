import api from '@/utils/request'
// 获取个人资产
export function assetInfo() {
    return api.get('/api/v1/asset/info')
}
// 资产变化列表
export function assetChangelist(data) {
    return api.post('/api/v1/asset/changelist',data)
}
// 提现申请
export function withdraw(data) {
    return api.post('/api/v1/asset/withdraw',data)
}
// 提现记录
export function withdrawList(data) {
    return api.post('/api/v1/asset/withdrawList',data)
}
// 充值记录
export function rechargeList(data) {
    return api.post('/api/v1/asset/rechargeList',data)
}