/**
* zh_CN
*/
export default {
    bannerName: '代理商中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    header: {
        invite:'邀请用户数量',
        allEarnings:'总收益',
        monthEarnings:'月收益',
        dayEarnings:'昨日收益',
    },
    tabel:{
        loginName:'账号ID',
        transactionAmount:'交易金额',
        earningsAmount:'收益金额',
        createTime:'注册时间',
    }
}