/**
* zh_CN
*/
export default {
    bannerName: '快捷交易中心',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    sellAmount: '出售数量',
    chujinAmount: '出金数量',
    tradeRate: '汇率',
    proceedsAmount: '收款金额',
    chujinjine: '出金金额',
    tradeFee: '手续费',
    selectPaymentTerm: '选择收款方式',
    payTypeName: '收款方式',
    name: '姓名',
    bankAccount: '银行账号',
    bankName:'银行名',
    WeChat: '微信支付',
    Alipay: '支付宝支付',
    getQr: '查看收款码',
    selectBuyer: '选择买家',
    searchBuyer: '搜索买家',
    transactionPwd: '交易密码',
    sellNow: '立即出售',
    orderNo: '订单号',
    scheduleStatus: '进行状态',
    sellName: '交易对象',
    sellUser: '出金人',
    sellAddress: '出金地址',
    cancelOrder: '取消订单',
    rejectOrder: '拒绝出金',
    verifyProceeds: '确认收款',
    chujin: '确认出金',
    toPay: '已付款（付款好点击）',
    subAppeal: '提交申诉',
    cancelBtn: '取消',
    contactPlatform: '联系平台',
    openIm: '联系买家',
    appealDescLabel: '申诉原因',
    confirmBtn: '确定',
    cancel: '取消',
    rules: {
        payTypeId: '请选择收款方式',
        searchKey: '请选择买家',
        tradePassword: '请输入交易密码',
        appealDesc: '请输入申诉信息'
    },
    message: {
        searchUser:'没有匹配到买家',
        success: '操作成功',
        mkOrder: '下单成功！请等待买家汇款。',
        orderCancel: '订单已取消',
    },
    confirm: {
        hint: '提示',
        cancelInfo: '取消当前订单',
        rejectInfo: '拒绝当前订单',
        confirmInfo: '确认收款',
        confirm: '确定',
        cancel: '取消',
    }
}