/**
* en
*/
export default {
  banner: {
    title: 'USDT P2P trading platform',
    info: 'Safe, Fast, Convenient',
  },
  tableTitle: "Today's exchange rate",
  tableLabel: {
    symbol: 'Currency types',
    tradingUnit: 'Trading unit',
    buyingPrice: 'Purchase price',
    sellingPrice: 'Selling price',
  },
  reasonTitle: 'Reasons for choosing Umarket',
  reasonItem: {
    item1: 'Safe, Fast, Convenient',
    item2: 'View deposit and withdrawal functions at any time',
    item3: 'Safe, fast, and convenient transfer and withdrawal',
  },
  confirm: {
    authenticationTitle: 'Tip',
    authenticationInfo: 'There is currently no authentication information. Do you want to proceed with authentication?',
    confirmBtn: 'Confirm',
    cancelBtn: 'Cancel',
    authToSell: "Please verify your identity before trading"
  }
};
