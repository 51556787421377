<template>
  <div>
    <SmallBanners :title="$t('transactionOrder.bannerName.shortcutOrder')" :info="$t('transactionOrder.bannerInfo')"/>
    <Orders />
  </div>
</template>

<script>
import Orders from "./index.vue";
export default {
  name: "",
  components: { Orders },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style scoped lang='scss'>
</style>
