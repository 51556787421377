/**
* ko
*/
export default {
    bannerName: '빠른거래 센터',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    buyBtn: '구매 ',
    buyPrice: '구매금액',
    buyAmount: '구매수량',
    buyAmountPlaceholder: '1회 구매 범위 최소10usdt ~ 최대7000usdt',
    sellBtn: '판매',
    sellPrice: '판매금액',
    sellAmount: '판매수량',
    subBuybtn: '구매하기',
    subSellbtn: '판매하기',
    placeholder: '내용을 입력 하십시오.',
    rules: {
        buyPrice: '  ',
        buyAmount: '구매수량을 입력 하십시오',
        sellPrice: '     ',
        sellAmount: '판매수량을 입력 하십시오',
        type: '계좌유형 선택 하십시오'
    },
    buyWarnInfo: [
        {
            title: "주의사항 ",
            values: [
                `1. 매번 거래는 플랫폼이 자동으로 판매자를 매칭 합니다 ,판매자의 계좌정보도 매번 다를수가 있으니 판매자 계좌정보에 따라 송금하시길 바랍니다。`,
                `2. 이용자가 USDT 구매 요청 동시 판매자의 USDT는 동결처리 되므로 안전하게 거래를 할수 있습니다。`,
                `3. 송금시 비고란을 공란으로 하십시오！(예：가상화폐、USDT、비트코인、외환거래 등 절때 금지)。판매자가 거래를 거절할수 있습니다。`,
                `4. 이용자가 계좌정보 오류로 잘못 입금한 금액은 플랫폼에서 책임지지 않습니다。`,
                `5. 반드시 등록된 본인 은행계좌로 구매하셔야 합니다. 그렇지 않으면 구매한 USDT가 동결 되여 찾을수 없습니다。`,
                `6. 운영시간 ：9：00-21：00，운영시간이외 거래는 판매자의 처리가 늦을수 있습니다.。`,
            ],
        },
    ],
    sellWarnInfo: [
        {
            title: "주의사항",
            values: [
                `1. 매번 거래는 플랫폼이 자동으로 구매자를 매칭 합니다 .`,
                `2. 이용자가 USDT 판매 요청 동시 이용자의 USDT는 동결처리 되고 , 수금확인 후 USDT는 구매자 계정으로 입금 됩니다.`,
                `3. 안전거래 보장 시스템이 작동 되므로 판매자와 구매자간 거래를 플랫폼이 안전을 100%보장 합니다.`,
                `4. 운영시간 ：9：00-21：00 운영시간이외 거래는 구매자의 송금 처리가 늦을수 있습니다.`,
            ],
        },
    ],
}