/**
* en
*/
export default {
    bannerName: 'Personal Center',
    bannerInfo: 'Safe, Fast, Convenient',
    header: {
        item1: {
            lebel: 'User',
            info: ''
        },
        item2: {
            lebel: 'Second',
            info: 'Assembly Order (Buy/Sell)'
        },
        item3: {
            lebel: 'Minute',
            info: 'Average release'
        },
        item4: {
            lebel: 'Second',
            info: '1-day order completion'
        },
        item5: {
            lebel: '%',
            info: '1-day completion rate'
        }
    },
    userInfo: {
        title: 'personal information',
        name: 'Name',
        code: 'User ID',
        nationality: 'Country',
        recordDate: 'Registration date',
    },
    accountInfo: {
        title: 'Set up an account',
        password: 'Login password',
        passwordDialog: {
            diaLog: {
                title: 'Change login password',
                oldPassword: 'Original login password',
                newPassword: 'New login password',
                verifyNewPassword: 'Confirm new login password',
                subBtn: 'Amend',
                rules: {
                    oldPassword: 'Please enter the original login password',
                    newPassword: 'Please enter a new login password',
                    verifyNewPassword: 'Please confirm the new login password',
                    verifyNewPasswordError: 'The new password you entered does not match. Please confirm',
                },
            },
        },
        transactionPin: 'Transaction password',
        transactionPinDialog: {
            diaLog: {
                title: 'Change transaction password',
                oldPassword: 'Original transaction password',
                newPassword: 'New transaction password',
                verifyNewPassword: 'Confirm new transaction password',
                subBtn: 'modify',
                rules: {
                    oldPassword: 'Please enter the original login password',
                    newPassword: 'Please enter a new login password',
                    verifyNewPassword: 'Please confirm the new login password',
                    verifyNewPasswordError: 'The new password you entered does not match. Please confirm',
                },
            },
        },
        authentication: {
            label: 'Real name authentication',
            info0: 'Please submit real name authentication documents',
            info2: 'Real name authentication materials have been submitted',
            info3: 'Please submit real name authentication documents',
            info4: 'Certified',
            button0: 'Apply for real name authentication',
            button2: "Please be patient and wait for the administrator's approval",
            button3: 'Apply for real name authentication',
            button4: 'Certified',
        },
        phone: {
            label: 'mobile phone',
            info: 'Verified: ',
            wrong: 'Unbound',
            button: 'Bind phone',
            diaLog: {
                title: 'Bind phone number',
                resetTitle: 'Modify bound phone number',
                phone: 'Mobile phone number',
                code: 'Verification code',
                codeBtn: 'Send code',
                newCodeBtn: 'Send code',
                subBtn: 'Submit',
                rules: {
                    phone: 'Please enter your phone number',
                    code: 'Please enter the verification code',
                }
            },
        },
        mail: {
            label: 'Email',
            info: 'Verified: ',
            wrong: 'Unbound',
            button: 'Bind email',
            diaLog: {
                title: 'Bind email',
                resetTitle: 'Modify bound email',
                email: 'Email address',
                code: 'Verification code',
                codeBtn: 'Send code',
                newCodeBtn: 'Send code',
                subBtn: 'Submit',
                rules: {
                    email: 'Please enter your email address',
                    code: 'Please enter the verification code',
                }
            },
        },
        paymentProcedure: {
            title: 'Choose payment method',
            titleBnt: 'Add bank account',
            label: 'Bank account number',
            paytypeState: 'Unauthenticated',
            paytypeState1: 'Under review',
            paytypeState2: 'Not passed',
            paytypeState3: 'Adopt',
            diaLog: {
                title: 'Add bank account',
                realName: 'Name',
                bankName: 'Bank',
                paytypeId: 'Account type',
                paytypeName: 'Payee',
                paytypeNo: 'Bank account number',
                paytypeNoWECHATPAY: 'WeChat account',
                subBtn: 'Confirm',
                rules: {
                    realName: 'Please enter your name',
                    bankName: 'Please enter your name',
                    paytypeId: 'Please select payment method',
                    paytypeName: 'Please enter the payee',
                    paytypeNo: 'Please enter your bank account number',
                    paytypeNoWECHATPAY: 'Please enter your WeChat account',
                    collectionImg: '请上传收款二维码',
                },
            },
            editDialog: {
                title: 'Modify payment method',
                realName: 'Name',
                paytypeState: 'Account status ',
                paytypeState1: 'Under review',
                paytypeState2: 'Not passed',
                paytypeState3: 'Adopt',
                paytypeId: 'Payment method ',
                paytypeName: 'Payee',
                bankName: 'Bank',
                paytypeNo: 'Bank account number',
                paytypeNoWECHATPAY: 'WeChat account',
                subBtn: 'Confirm',
                deleteBtn: 'Delete',
                rules: {
                    realName: 'Please enter your name',
                    paytypeState: 'Please select the account status',
                    bankName: 'Please enter the bank name',
                    paytypeId: 'Please select payment method',
                    paytypeName: 'Please enter the payee',
                    paytypeNo: 'Please enter your bank account number',
                    paytypeNoWECHATPAY: 'Please enter your WeChat account',
                    collectionImg: 'Please upload the payment QR code',
                },
            }
        },
        confirm: {
            authenticationTitle: 'Tip',
            authenticationInfo1: 'There is currently no authentication information. Do you want to proceed with authentication?',
            authenticationInfo2: 'Certification information review in progress, please wait',
            confirmBtn: 'Confirm',
            cancelBtn: 'Cancel',
        }
    }
};
