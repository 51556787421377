/**
* zh_CN
*/
export default {
  userAgreement: '用户协议',
  privacyProtection: '个人隐私保护政策',
  announcementCenter: '公告中心',
  customerCenter: '客户中心',
  FAQ: '常见问题',
  beginnerGuide: '新手指南',
  announcement: {
    agreement: '用户协议',
    announcement: '公告中心',
    customerCenter: '客户中心',
    FAQ: '常见问题',
    privacyAgreement: '个人隐私保护政策',
    rookieGuide: '新手指南',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    title: '标题',
    userName: '联系人',
    userPhone: '电话',
    userEmail: '邮箱',
    content: '内容',
    sendBtn: '发送',
    message: {
      success: '操作成功',
    },
    rules: {
      title: '请输入标题',
      userName: '请输入联系人',
      userPhone: '请输入电话',
      userEmail: '请输入邮箱',
      content: '请输入内容',
    }
  }
};