<template>
  <!-- 确认弹窗 -->
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    width="25vw"
    :before-close="handleClose"
  >
    <div class="content">
      <img src="@/assets/verify.png" alt="" v-if="parentType == 'affirm'" />
      <img src="@/assets/error.png" alt="" v-if="parentType == 'error'" />
      <img src="@/assets/succee.png" alt="" v-if="parentType == 'success'" />
      <span class="type">
        {{ $t(`common.tooltipPop.${parentType}`) }}
      </span>
      <div class="info" v-if="parentInfo && parentInfo.length > 0">
        <template>
          <div
            v-for="(txt, index) in parentInfo"
            :key="index"
            v-html="txt"
          ></div>
        </template>
      </div>
    </div>
    <span slot="footer" class="footer">
      <el-button type="primary" class="footer-btn" @click="handleSub">{{
        $t("common.tooltipPop.affirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Vue from "vue";
export default {
  name: "tooltipPop",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      flag: null,
      time: null,
      parentType: null,
      parentInfo: null,
      timeOut: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      let tooltipPop = {
        open: this.openDialog,
        sub: this.handleSub,
        canCel: this.handleClose,
      };
      if (!Vue.$tooltipPop) {
        Vue.$tooltipPop = tooltipPop;
        Vue.prototype.$tooltipPop = Vue.$tooltipPop;
      }
    });
  },
  methods: {
    openDialog(flag, time, data) {
      this.flag = null;
      this.time = null;
      this.parentType = null;
      this.parentInfo = null;
      this.timeOut = null;
      this.$nextTick(() => {
        this.flag = flag;
        this.time = time;
        if (data) {
          this.parentType = data.type;
          this.parentInfo = data.info;
        }
        this.dialogVisible = true;
        if (time) {
          this.timeOut = setTimeout(() => {
            this.dialogVisible = false;
            clearTimeout(this.timeOut);
          }, 3000);
        }
      });
    },
    handleSub() {
      this.cllBack("tooltipBack", this.flag, false);
      return true;
    },
    handleClose() {
      this.cllBack("tooltipBack", this.flag, true);
      return false;
    },
    cllBack(funcName, flag, isCancel) {
      this.$nextTick(() => {
        clearTimeout(this.timeOut);
        if (!this.time) {
          this.$emit(funcName, flag, isCancel);
        }
        this.dialogVisible = false;
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  text-align: center;
  img {
    width: 10vw;
    height: 11vw;
  }
  .type {
    font-size: 1.3vw;
    font-weight: bold;
    position: absolute;
    top: 7.3vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .info {
    margin-top: 1.5vw;
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
  }
}
.footer-btn {
  width: 100%;
  margin-bottom: 1vw;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
