/**
* zh_CN
*/
export default {
    // 登录
    loginName: '用户ID',
    password:'登录密码',
    validateCode:'验证码',
    logInBtn: '登录',
    forgetThePassword: '忘记密码',
    // 忘记密码
    phoneReset:'用手机号重置',
    mailReset:'用邮箱重置',
    username: '用户ID',
    countryCode: '国籍',
    phonenumber: '绑定手机号',
    email: '绑定邮箱',
    code:'验证码',
    codeBtn1: '重新发送',
    codeBtn2: '发送验证码',
    newpassword:'新登录密码',
    verifyPassword:'确认新登录密码',
    resetBtn: '重置',
    message: {
      success: '操作成功',
    },
    noAccount: '没有Umarket账号请先注册账号',
    logInRules:{
        // 登录
        loginName:'请输入用户ID',
        password:'请输入登录密码',
        validateCode:'请输入验证码',
        // 忘记密码
        username:'请输入用户ID',
        countryCode: '请选择国籍',
        phonenumber: "请输入绑定手机号码",
        email: "请输入绑定邮箱",
        code: "请输入验证码",
        newpassword: "请输入新密码",
        verifyPassword: "请确认新密码",
        verifyPasswordError: "输入的新密码不一致,请确认",
    }
};
