
<template>
  <div class="footersBody">
    <span class="item" @click="toPath('/agreement')">{{
      $t("footer.userAgreement")
    }}</span>
    <span class="item" @click="toPath('/privacyAgreement')">{{
      $t("footer.privacyProtection")
    }}</span>
    <span class="item" @click="toPath('/announcement')">{{
      $t("footer.announcementCenter")
    }}</span>
    <span class="item" @click="toPath('/customerCenter')">{{
      $t("footer.customerCenter")
    }}</span>
    <span class="item" @click="toPath('/FAQ')">{{ $t("footer.FAQ") }}</span>
    <span class="item" @click="toPath('/rookieGuide')">{{
      $t("footer.beginnerGuide")
    }}</span>
    <br/>
    <span class="item">Copyright ©2018-2024 UMARKET GLOBAL LIMITED. All Rights Reserved.</span>
  </div>
</template>

<script>
export default {
  name: "footers",
  components: {},
  props: {
    // list: Array
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    toPath(path) {
      if (!path) return;
      if (this.$route.path == path) {
        location.reload();
      } else {
        this.$router.push(path);
      }
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.footersBody {
  width: 100vw;
  line-height: 80px;
  color: #9c9c9c;
  background: #313131;
  text-align: center;
  position: relative;
  z-index: 99;
  .item {
    font-size: 14px;
    margin-right: 42px;
    cursor: pointer;
  }
  .item:hover {
    color: #ffffff;
  }
}
</style>
