/**
* ko
*/
export default {
    bannerName: '빠른거래 센터',
    bannerInfo: '자금 안전 신속 편리한 P2P거래  ',
    sellAmount: '판매수량',
    chujinAmount: '출금수량',
    tradeRate: '환율',
    proceedsAmount: '수금금액',
    chujinjine: '출금금액',
    tradeFee: '수수료',
    selectPaymentTerm: '수금방식 선택',
    payTypeName: '수금방식',
    name: '성명',
    bankAccount: '계좌번호',
    bankName:'은행명',
    weChat: 'wechat pay',
    alipay: 'alipay',
    getQr: '결제QR코드 확인',
    selectBuyer: '구매자 선택',
    searchBuyer: '구매자 검색',
    transactionPwd: '거래 비밀번호',
    sellNow: '판매하기',
    orderNo: '주문번호',
    scheduleStatus: '진행상태',
    sellName: '거래대상',
    sellUser: '출금인',
    sellAddress: '출금주소',
    cancelOrder: '주문 취소',
    rejectOrder: '출금거절',
    verifyProceeds: '수금확인',
    chujin: '출금확인',
    toPay: '송금하기',
    subAppeal: '중재요청',
    cancelBtn: '취소',
    contactPlatform: '관리자 연결',
    openIm: '구매자 연결',
    appealDescLabel: '중재요청 이유',
    confirmBtn: '확인',
    cancel: '취소',
    rules: {
        payTypeId: '수금방식 선택',
        searchKey: '구매자 선택',
        tradePassword: '거래 비밀번호를 입력 하십시오',
        appealDesc: '중재 내용을 입력 하십시오'
    },
    message: {
        searchUser:'매칭되는 구매자가 없습니다. ',
        success: '실행성공',
        mkOrder: '주문성공! 구매자의 송금을 기다려주세요.',
        orderCancel: '주문 취소 되였습니다',
    },
    confirm: {
        hint: '알림',
        cancelInfo: '현재 주문 취소',
        rejectInfo: '현재 주문 거절',
        confirmInfo: '수금확인',
        confirm: '확인',
        cancel: '취소',
    }
}