<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="title">
        {{ $t("userInfo.accountInfo.paymentProcedure.editDialog.title") }}
      </div>
      <div class="content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          class="formData"
          label-position="left"
        >
          <el-form-item
            :label="
              $t('userInfo.accountInfo.paymentProcedure.editDialog.realName')
            "
            prop="realName"
          >
            <el-input
              v-model="formData.realName"
              placeholder=""
              disabled
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="
              $t(
                'userInfo.accountInfo.paymentProcedure.editDialog.paytypeState'
              )
            "
            prop="paytypeState"
          >
            <el-input
              v-model="formData.paytypeStateTxt"
              placeholder=""
              disabled
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="
              $t('userInfo.accountInfo.paymentProcedure.editDialog.paytypeId')
            "
            prop="paytypeId"
          >
            <el-select
              v-model="formData.paytypeId"
              placeholder=""
              filterable
              class="formData-input"
              @change="paymentChange"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in patterns"
                :key="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="
              $t('userInfo.accountInfo.paymentProcedure.editDialog.paytypeName')
            "
            prop="paytypeName"
          >
            <el-input
              v-model="formData.paytypeName"
              placeholder=""
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="
              $t('userInfo.accountInfo.paymentProcedure.editDialog.bankName')
            "
            prop="bankName"
            v-if="formData.paytypeId != 3 && formData.paytypeId != 4"
          >
            <el-input
              v-model="formData.bankName"
              placeholder=""
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            :label="
              formData.paytypeId != 3
                ? $t(
                    'userInfo.accountInfo.paymentProcedure.editDialog.paytypeNo'
                  )
                : $t(
                    'userInfo.accountInfo.paymentProcedure.editDialog.paytypeNoWECHATPAY'
                  )
            "
            prop="paytypeNo"
            v-if="formData.paytypeId != 4"
          >
            <el-input
              v-model="formData.paytypeNo"
              placeholder=""
              class="formData-input"
            />
          </el-form-item>
          <el-form-item
            label=""
            ref="fileitem"
            prop="collectionImg"
            v-if="formData.paytypeId == 3 || formData.paytypeId == 4"
          >
            <div class="formData-upload">
              <UploadImg
                :limit="1"
                @backUpload="backUpload"
                :list="[formData.collectionImg]"
              />
            </div>
          </el-form-item>
          <div class="formData-subBtn">
            <div>
              <el-button
                type="primary"
                class="formData-subBtn-btn"
                @click="submitForm"
                >{{
                  $t("userInfo.accountInfo.paymentProcedure.editDialog.subBtn")
                }}</el-button
              >
            </div>
            <div>
              <el-button
                type="danger"
                class="formData-subBtn-btn delete"
                @click="deleteItem"
                >{{
                  $t(
                    "userInfo.accountInfo.paymentProcedure.editDialog.deleteBtn"
                  )
                }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { payBankUpdate, payBankDelete, payBankTypeList } from "@/api/userInfo";
export default {
  name: "",
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (this.formData.paytypeId == 3 && rule.field == "paytypeNo") {
        if (value) {
          callback();
        } else {
          return callback(
            new Error(
              this.$t(
                "userInfo.accountInfo.paymentProcedure.editDialog.rules." +
                  rule.field +
                  "WECHATPAY"
              )
            )
          );
        }
      } else if (rule.field == "collectionImg") {
        if (value && value.length >= 1) {
          callback();
        } else {
          return callback(
            new Error(
              this.$t(
                "userInfo.accountInfo.paymentProcedure.editDialog.rules." +
                  rule.field
              )
            )
          );
        }
      } else {
        if (value) {
          callback();
        } else {
          return callback(
            new Error(
              this.$t(
                "userInfo.accountInfo.paymentProcedure.editDialog.rules." +
                  rule.field
              )
            )
          );
        }
      }
    };
    return {
      dialogVisible: false,
      parentInfo: {},
      formData: {
        realName: "",
        paytypeId: "",
        paytypeName: "",
        bankName: "",
        paytypeNo: "",
        collectionImg: "",
      },
      rules: {
        realName: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        paytypeState: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        bankName: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        paytypeId: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "change",
          },
        ],
        paytypeName: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        paytypeNo: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "blur",
          },
        ],
        collectionImg: [
          {
            required: true,
            validator: formRulesCheck,
            trigger: "change",
          },
        ],
      },
      patterns: [],
    };
  },
  "$store.state.common.i18n"(newVal, oldVal) {
    this.$refs["formData"].resetFields();
    this.patternsChange();
  },
  mounted() {
    this.patternsChange();
  },
  methods: {
    // 上传文件返回
    backUpload({ fileList, file, type }) {
      if (type == "success" || type == "remove") {
        this.$set(this.formData, "collectionImg", fileList);
      }
      this.$refs["formData"].validateField(
        ["collectionImg"],
        (valid, vv) => {}
      );
    },
    paymentChange() {
      this.$nextTick(() => {
        this.$refs["formData"].validate((valid) => {});
      });
    },
    opendialog(userInfo, type, item) {
      this.parentInfo = userInfo;
      this.formData = {
        ...item,
        realName: userInfo.realName,
        paytypeStateTxt: this.$t(
          `userInfo.accountInfo.paymentProcedure.editDialog.paytypeState${item.paytypeState}`
        ),
      };
      this.dialogVisible = !this.dialogVisible;
    },
    submitForm() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          payBankUpdate(this.formData).then((res) => {
            if (res.code == 200) {
              this.$emit("diaLogCallBack", null);
              this.resetData();
            }
          });
        } else {
          return false;
        }
      });
    },
    patternsChange() {
      payBankTypeList().then((res) => {
        if (res.code == 200) {
          let list = [];
          res.data.forEach((item) => {
            list.push({
              label: this.$t(`common.bankList.${item.typeName}`),
              value: item.id,
            });
          });
          this.$set(this, "patterns", list);
        }
      });
    },
    deleteItem() {
      let data = new FormData();
      data.append("id", this.formData.id);
      payBankDelete(data).then((res) => {
        if (res.code == 200) {
          this.$emit("diaLogCallBack", null);
          this.resetData();
        }
      });
    },
    handleClose(done) {
      done();
      this.resetData();
    },
    resetData() {
      this.$refs["formData"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  font-size: 1.30208vw;
  font-weight: bold;
  margin-top: -1.5625vw;
}
.content {
  padding-top: 1vw;
  .formData {
    .formData-input {
      width: calc(100% - 200px);
    }
    .formData-upload {
      height: 152px;
      max-height: 152px;
      overflow: hidden;
    }
    .formData-subBtn {
      width: 100%;
      margin-top: 2.23958vw;
      // margin-bottom: 1.6vw;
      .formData-subBtn-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .delete {
        background: red;
        margin-top: 1vw;
      }
      .delete:hover {
        background: #ff5151;
      }
      .delete:active {
        background: #d90000;
      }
    }
  }
}
::v-deep .el-form-item__label {
  width: 200px !important;
  padding-left: 1vw !important;
  font-weight: bold !important;
  background: #f8f8f8 !important;
}
::v-deep .el-input__inner {
  border: none !important;
  background: #f8f8f8 !important;
}
::v-deep .el-dialog__body {
  // padding-top: 0 !important;
}
</style>
