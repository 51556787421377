/**
* en
*/
export default {
    bannerName: 'Transfer',
    bannerInfo: 'Safe, Fast, Convenient',
    header: {
        title: 'Available amount',
        info: 'Only Umarket addresses can be transmitted',
        form: {
            address: 'Delivery address',
            amount: 'Number of transfers',
        },
        btns: {
            onceTransfer: 'Send Now',
            batch: 'Batch transmission',
            friend: 'Friend list',
        },
    },
    content: {
        addTransfer: 'Add deliveryman',
        addFriend: 'Add friends',
        tabel: {
            type: 'Type',
            amount: 'Number of transfers',
            friendName: 'Name',
            address: 'Wallet address',
            createTime: 'Time',
            options: 'Operation',
            delete: 'Delete',
        },
        searchInput: 'Enter Name',
        searchBtn: 'Search',
        footerBtn: 'Batch transfer immediately',
    },
    addTransferDialog: {
        title: 'Add deliveryman',
        friendName: 'Name',
        friendAddress: 'Wallet address',
        subBtn: 'Add Now',
    },
    addFriendDialog: {
        title: 'Add friends',
        friendName: 'Name',
        friendAddress: 'Wallet address',
        subBtn: 'Add Now',
        rules: {
            friendName: 'Please enter your name',
            friendAddress: 'Please enter the wallet address',
        }
    },
    success: 'Operation successful'
}
