/**
* ko
*/
export default {
    // 로그인
    loginName: '사용자ID',
    password: '로그인 비밀번호',
    validateCode: '인증코드',
    logInBtn: '로그인',
    forgetThePassword: '비밀번호 찾기',
    // 비밀번호 찾기
    phoneReset: '휴대폰번호로 재설정',
    mailReset: '메일로 재설정',
    username: '사용자ID',
    countryCode: '국적',
    phonenumber: '휴대폰번호',
    email: '이메일',
    code: '인증코드',
    codeBtn1: '재발송',
    codeBtn2: '인증코드 발송',
    newpassword: '새 로그인 비밀번호',
    verifyPassword: '새 로그인 비밀번호 확인',
    resetBtn: '재설정',
    noAccount: 'Umarket계정이 없으시면 회원가입부터 하십시오.',
    message: {
      success: '실행성공',
    },
    logInRules: {
        // 로그인
        loginName: '사용자ID 입력 하십시오',
        password: '로그인 비밀번호를 입력 하십시오',
        validateCode: '인증코드를 입력 하십시오',
        // 비밀번호 찾기
        username: '사용자ID 입력 하십시오',
        countryCode: '국적을 선택 하십시오',
        phonenumber: "연동된 휴대폰번호를 입력 하십시오",
        email: "연동된 메일주소를 입력 하십시오",
        code: "인증코드를 입력 하십시오",
        newpassword: "새 비밀번호를 입력 하십시오",
        verifyPassword: "새 비밀번호를 확인 하십시오",
        verifyPasswordError: "새 비밀번호가 일치하지 않습니다.  확인 하십시오",
    }
};
