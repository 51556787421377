<template>
  <div>
    <SmallBanners
      :title="$t('orderManagement.bannerName')"
      :info="$t('orderManagement.bannerInfo')"
    />
    <div class="content">
      <div class="content-head">
        <div class="content-head-btnbox">
          <div class="content-head-type">
            <el-button
              type="primary"
              class="content-head-button"
              :plain="orderType != 3"
              @click="transactionTypeChange('3')"
              >{{ $t("orderManagement.header.btns.buyBtn") }}</el-button
            >
          </div>
          <div class="content-head-type">
            <el-button
              type="primary"
              class="content-head-button"
              :plain="orderType != 4"
              @click="transactionTypeChange('4')"
              >{{ $t("orderManagement.header.btns.sellBtn") }}</el-button
            >
          </div>
        </div>
        <div class="content-head-dataType">
          <el-tag
            type="info"
            class="tag"
            :class="orderStatus === null ? 'tagFlag' : ''"
            @click="orderStatusChack(null)"
            >{{ $t("transactionOrder.tabelBtns.all") }}</el-tag
          >
          <!-- 订单状态不同，审核代表状态不同，orderType == 1：买，已付款等待放币，orderType == 1：卖，订单新建 -->
          <el-tag
            type="info"
            class="tag"
            @click="orderStatusChack(orderType == 1 ? 2 : 0)"
            :class="orderStatus === 2 || orderStatus === 0 ? 'tagFlag' : ''"
            >{{ $t("transactionOrder.tabelBtns.audit") }}</el-tag
          >
          <el-tag
            type="info"
            class="tag"
            @click="orderStatusChack(1)"
            :class="orderStatus === 1 ? 'tagFlag' : ''"
            >{{ $t("transactionOrder.tabelBtns.timeOut") }}</el-tag
          >
          <el-tag
            type="info"
            class="tag"
            @click="orderStatusChack(4)"
            :class="orderStatus === 4 ? 'tagFlag' : ''"
            >{{ $t("transactionOrder.tabelBtns.arbitration") }}</el-tag
          >
          <el-tag
            type="info"
            class="tag"
            @click="orderStatusChack(6)"
            :class="orderStatus === 6 ? 'tagFlag' : ''"
            >{{ $t("transactionOrder.tabelBtns.reject") }}</el-tag
          >
          <el-tag
            type="info"
            class="tag"
            @click="orderStatusChack(3)"
            :class="orderStatus === 3 ? 'tagFlag' : ''"
            >{{ $t("transactionOrder.tabelBtns.finished") }}</el-tag
          >
        </div>
      </div>
      <div class="tabel">
        <el-table :data="tableData" ref="table" v-loading="loading">
          <el-table-column
            show-overflow-tooltip
            prop="id"
            :label="$t('orderManagement.content.tabel.orderNo')"
            align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="status"
            :label="$t('orderManagement.content.tabel.status')"
            align="center"
          >
            <template slot-scope="scope">
              <div class="table-item">
                <img
                  src="@/assets/transactionOrder/underReview.png"
                  class="tanel-falg"
                  v-if="
                    scope.row.orderStatus == 0 || scope.row.orderStatus == 2
                  "
                />
                <img
                  src="@/assets/transactionOrder/reject.png"
                  class="tanel-falg"
                  v-if="
                    scope.row.orderStatus == 1 || scope.row.orderStatus == 6
                  "
                />
                <img
                  src="@/assets/transactionOrder/finish.png"
                  v-if="scope.row.orderStatus == 3"
                  class="tanel-falg"
                />
                <img
                  src="@/assets/transactionOrder/arbitration.png"
                  v-if="scope.row.orderStatus == 4"
                  class="tanel-falg"
                />
                <span>
                  {{ $t(`orderStatus.${scope.row.orderStatusDict}`) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="currencyAmountShow"
            :label="
              orderType == 3
                ? $t('orderManagement.content.tabel.buyAmount')
                : $t('orderManagement.content.tabel.sellAmount')
            "
            align="center"
          >
            <template slot-scope="scope">
              <span class="tabel-money">
                {{ scope.row.currencyAmountShow }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="priceShow"
            :label="$t('orderManagement.content.tabel.tradeRate')"
            align="center"
          >
          <template slot-scope="scope">
            {{scope.row.priceShow}}USDT/{{scope.row.symbol}}
          </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="orderAmountShow"
            :label="
              orderType == 3
                ? $t('orderManagement.content.tabel.buyNum')
                : $t('orderManagement.content.tabel.sellNum')
            "
            align="center"
          >
          <template slot-scope="scope">
            {{scope.row.orderAmountShow}}USDT
          </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="payUserName"
            :label="
              orderType == 3
                ? $t('orderManagement.content.tabel.buyUser')
                : $t('orderManagement.content.tabel.sellUser')
            "
            align="center"
          />
          <el-table-column
            show-overflow-tooltip
            prop="createTime"
            :label="$t('orderManagement.content.tabel.creationTime')"
            align="center"
          >
            <template slot-scope="scope">
              {{ timeCheck(scope.row.createTimestamp) }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            :label="$t('orderManagement.content.tabel.options')"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="tabelOptionBtn"
                @click="toDetail(scope.row)"
                >{{ $t("orderManagement.content.tabel.search") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <SearchDialog @popoverSearch="popoverSearch" />
        <Pagination
          :page="page"
          :size="size"
          :total="total"
          @callbackPage="callbackPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { orderList } from "@/api/transactionOrder";
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      loading: false,
      orderType: 3,
      orderStatus: null,
      page: 1,
      size: 10,
      total: 0,
      visible: true,
      tableData: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init(orderStatus = null, id, beginDate, endDate, currencyId) {
      let data = {
        orderType: this.orderType,
        orderStatus: orderStatus ? orderStatus : this.orderStatus,
        id: id,
        beginDate: beginDate,
        endDate: endDate,
        symbol: currencyId,
        page: this.page,
        pageSize: this.size,
      };
      this.loading = true;
      orderList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.page = res.data.pageNum;
          this.size = res.data.pageSize;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    // 查询pop回调
    popoverSearch({ start, end, orderNo, name }) {
      this.init(null, orderNo, start, end, name);
    },
    //查看详情
    toDetail(row) {
      let data = {
        query: {
          orderNo: row.id,
        },
      };
      if (this.orderType == 3) {
        data.path = "/transactionDetail";
      } else {
        data.path = "/transactionSellTwo";
      }
      this.$router.push(data);
    },
    //切换买卖状态
    transactionTypeChange(type) {
      this.orderType = type;
      this.page = 1;
      this.size = 10;
      this.init();
    },
    //切换买卖状态
    orderStatusChack(type) {
      this.orderStatus = type;
      this.page = 1;
      this.size = 10;
      this.init(this.orderStatus);
    },
    // 分页回调
    callbackPage({ page, size, total }) {
      this.page = page;
      this.size = size;
      this.init();
    },
  },
  computed: {
    timeCheck() {
      return (time) => {
        return moment(time).format("YYYY-MM-DD H:mm");
      };
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 8.4375vw;
  .content-head {
    width: 100%;
    .content-head-btnbox {
      display: flex;
      justify-content: flex-start;
      margin-right: 1vw;
      margin-bottom: 2.03125vw;
      .content-head-button {
        width: 8.85417vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1vw;
      }
    }
    .content-head-dataType {
      .tag {
        margin-right: 1vw;
        color: #5a5a5a;
        background: #ffffff;
        cursor: pointer;
      }
      .tag:hover {
        background: #ffffff;
      }
      .tagFlag {
        color: #019393;
        background: #ffffff;
        border: 1px solid #019393;
      }
    }
  }
  .tabel {
    margin-top: 1.51875vw;
    .table-item {
      width: 100%;
      height: 100%;
    }
    .tabel-money {
      color: #019393;
      font-weight: bold;
    }
    .tanel-falg {
      width: 1.25vw;
      height: 1.25vw;
      transform: translateY(-5%);
    }
  }
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
::v-deep .el-input__inner {
  background: #e6e6e6 !important;
}
// --------------------
// 修改头部样式
::v-deep .el-table .el-table__header th {
  background: #019393 !important;
  color: #ffffff !important;
}
::v-deep .el-table__header {
  border-radius: 0.26042vw !important;
  overflow: hidden !important;
}
</style>
