/**
* zh_CN
*/
export default {
    sellScheduleStatusStart0: 'Waiting for buyer payment',
    buyScheduleStatusStart0: 'Waiting for your payment',
    sellScheduleStatusStart1: 'Order cancelled',
    buyScheduleStatusStart1: 'Order cancelled',
    sellScheduleStatusStart2: 'Buyer has made payment',
    chujinScheduleStatusStart2: 'Waiting for your confirmation of withdrawal',
    buyScheduleStatusStart2: 'Waiting for the seller to deposit coins',
    sellScheduleStatusStart3: 'Order completed',
    buyScheduleStatusStart3: 'Success',
    sellScheduleStatusStart4: 'In appeal',
    buyScheduleStatusStart4: 'In appeal',
    sellScheduleStatusStart5: 'Appeal rejection',
    buyScheduleStatusStart5: 'Appeal rejection',
    sellScheduleStatusStart6: 'Rejected',
    buyScheduleStatusStart6: 'Rejected',
    UNPAID_PAYMENTS:'Receivable',
    NO_PAY:'Unpaid',
    CANCELED:'Canceled',
    PENDING_PAYMENT:'Acknowledge receipt',
    PAID_PAYMENTS:'Pending USDT',
    SUCCESS:'Success',
    IN_APPEAL:'In appeal',
    REJECTED:'Rejected',
    APPLY_WITHDRAW:'Application for withdrawal',
    AWAIT_SEND_COIN: 'Await send coin',
    AWAIT_RECEIVE_COIN: 'Pending USDT',
    APPLY_DEPOSIT: 'Apply for deposit',
}