/**
* en
*/
export default {
    bannerName: 'Purchase and sale application',
    clientBannerName: 'Application for sale',
    bannerInfo: 'Safe, Fast, Convenient',
    buyBtn: 'Purchase application',
    sellBtn: 'Application for sale',
    buyLabelPrice: 'Purchase price',
    sellLabelPrice: 'Selling price',
    buyLabelAmount: 'Number of purchase requests',
    sellLabelAmount: 'Number of sales applications',
    newSellBtn: 'Application for immediate sale',
    newBuyBtn: 'Buy now request',
    switchType: 'Currency types',
    switchTipBuy: 'Please turn on the receiving switch after purchasing the application',
    switchTipSell: 'Please turn on the receiving switch after the sale application',
    switchTip: 'Single switch ON/OFF',
    rules: {
        price: 'Please enter the selling price',
        buyamount: 'Please enter the number of purchase requests',
        sellamount: 'Please enter the number of requests for sale',
    },
    message: {
        success: 'Operation successful',
    },
    confirm: {
        authenticationTitle: 'Tip',
        authenticationInfo: 'Data not saved, do you want to leave?',
        confirmBtn: 'Yes',
        cancelBtn: 'No',
    },
    warnInfo: [
        {
            title: "How to Apply for Purchase/Sale",
            values: [
                `1. If you enter the maximum quantity you want to buy/sell and wait, the platform AI automatic matching program will automatically match buyers and sellers for transactions within the maximum quantity you set.`,
                `2. Apply FOR BUY/SELL MAY BUY USDT AT A LOWER PRICE THAN A QUICK TRADE SELL/BUY AND SELL USDT AT A HIGHER PRICE. Example: The selling price at the exchange rate is KRW 1400 / the buying price is KRW 1300.`
            ],
        },
        {
            title: "Usage",
            values: [
                `1. Only open the purchase button when purchasing 10000 Korean won, sell when selling 10000 Korean won    Just want to open the button to purchase/sell   Both buttons are turned on    `,
                `2. You can purchase/sell at most    possible    Please enter the number of USDTs.`,
            ],
        },

    ],
}
