<template>
  <div class="body">
    <div class="banner">
      <div class="banner-title">{{ title }}</div>
      <div class="banner-info">{{ info }}</div>
      <img src="@/assets/brands.png" class="banner-ime" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "smallBanners",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: () => {
        return "자금 안전 간편 신속하게 누구나 이용 가능";
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style scoped lang='scss'>
.body {
  height: 12.41667vw;
  margin-bottom: 4vw;
}
.banner {
  width: 100vw;
  padding: 2vw 23vw;
  padding-top: 3.8125vw;
  box-sizing: border-box;
  background: linear-gradient(0deg, #f7f7f7, #fafafa);
  position: absolute;
  left: 0;
  .banner-title {
    font-size: 2vw;
    font-weight: bold;
    margin-bottom: 0.8vw;
  }
  .banner-info {
    font-size: 0.8375vw;
  }
  .banner-ime {
    width: 8.3vw;
    height: 9vw;
    position: absolute;
    top: 3.82292vw;
    left: 70vw;
  }
}
</style>
