/**
* ko
*/
export default {
    bannerName: '전송',
    bannerInfo: '자금 안전 신속 편리한 P2P거래 ',
    header: {
        title: '보유금액',
        info: 'Umarket주소만 전송 가능 합니다',
        form: {
            address: '전송주소',
            amount: '전송수량',
        },
        btns: {
            onceTransfer: '전송하기',
            batch: '대량전송',
            friend: '친구목록',
        },
    },
    content: {
        addTransfer: '전송인 추가',
        addFriend: '친구추가',
        tabel: {
            type: '유형',
            amount: '전송수량',
            friendName: '성명',
            address: '지갑주소',
            createTime: '시간',
            options: '실행',
            delete: '삭제',
        },
        convey: {
            CONVEY_OUT: '출금',
            CONVEY_IN: '입금'
        },
        searchInput: '성명 입력 하십시오',
        searchBtn: '검색',
        footerBtn: '대량 전송하기',
    },
    addTransferDialog: {
        title: '전송인 추가',
        friendName: '성명',
        friendAddress: '지갑주소',
        subBtn: '추가하기',
    },
    addFriendDialog: {
        title: '친구추가',
        friendName: '성명',
        friendAddress: '지갑주소',
        subBtn: '추가하기',
        rules: {
            friendName: '성명을 입력 하십시오',
            friendAddress: '지갑주소를 입력 하십시오',
        }
    },
    success: '실행성공'
}
