
const state = {
    userInfo:{
        userType:''
    },// 个人信息
}
const mutations = {
    setUserInfo(state, data) {
        state.userInfo = data
    },
}
const actions = {
    setUserInfo({ commit }, data) {
        commit('setUserInfo', data)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}