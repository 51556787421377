<template>
  <div>
    <el-dialog
      :title="amountShowCheck"
      :visible.sync="dialogVisible"
      width="500px"
      :append-to-body="true"
      :show-close="false"
      center
      destroy-on-close
      :before-close="handleClose"
    >
      <el-image class="image" :src="imgUrl" />
    </el-dialog>
  </div>
</template>

<script>
import {baseURL} from "@/utils/baseURL";
export default {
  data() {
    return {
      dialogVisible: false,
      type: "",
      imgUrl: "",
      amountShow: "",
      symbol: "",
    };
  },
  mounted() {},
  methods: {
    openDialog(data) {
      this.type = data.type;
      this.imgUrl = baseURL + data.url;
      this.amountShow = data.amountShow;
      this.symbol = data.symbol;
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
    },
  },
  computed: {
    amountShowCheck() {
      if (this.type == 1) {
        return (
          this.$t("transactionBuy.paymentAmount") +
          this.amountShow +
          "   " +
          this.symbol
        );
      } else {
        return (
          this.$t("transactionSell.proceedsAmount") +
          this.amountShow +
          "   " +
          this.symbol
        );
      }
    },
  },
};
</script>

<style>
.image {
  width: 100%;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>