import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// 引入组件
import loginHome from '@/views/loginHome'
import signIn from '@/views/signIn'
import logIn from '@/views/loginHome/logIn'
import resetPasswords from '@/views/loginHome/resetPasswords'
import estate from '@/views/estate'
import userInfo from '@/views/userInfo'
import authentication from '@/views/userInfo/authentication'
import authenticationKR from '@/views/userInfo/authenticationKR'
import transaction from '@/views/transaction'
import buyOne from '@/views/transaction/buy/buyOne'
import buyTwo from '@/views/transaction/buy/buyTwo'
import buyDetail from '@/views/transaction/buy/buyDetail'
import sellOne from '@/views/transaction/sell/sellOne'
import sellTwo from '@/views/transaction/sell/sellTwo'
import pendingOrders from '@/views/pendingOrders'
import shortcutOrder from '@/views/transactionOrder/shortcutOrder'
import pendingOrder from '@/views/transactionOrder/pendingOrder'
import transfer from '@/views/transfer'
import orderManagement from '@/views/orderManagement'
import agency from '@/views/agency'
import agreement from '@/views/communal/agreement'
import privacyAgreement from '@/views/communal/privacyAgreement'
import announcement from '@/views/communal/announcement'
import customerCenter from '@/views/communal/customerCenter'
import FAQ from '@/views/communal/FAQ'
import rookieGuide from '@/views/communal/rookieGuide'
import clientSell from '@/views/client/sell'
import clientBuy from '@/views/client/buy'
// import IM from '@/views/IM'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/IM',
  //   name: '客服',
  //   meta: {
  //     keepAlive: false
  //   },
  //   component: IM
  // },
  {
    path: '/',
    name: '首页',
    meta: {
      keepAlive: false
    },
    component: loginHome
  },
  {
    path: '/signIn',
    name: '注册会员',
    meta: {
      keepAlive: false
    },
    component: signIn
  },
  {
    path: '/logIn',
    name: '登录',
    meta: {
      keepAlive: false
    },
    component: logIn
  },
  {
    path: '/resetPasswords',
    name: '重置密码',
    meta: {
      keepAlive: false
    },
    component: resetPasswords
  },
  {
    path: '/estate',
    name: '资产',
    meta: {
      keepAlive: false
    },
    component: estate
  },
  {
    path: '/userInfo',
    name: '个人中心',
    meta: {
      keepAlive: false
    },
    component: userInfo
  },
  {
    path: '/authentication',
    name: '实名认证',
    meta: {
      keepAlive: false
    },
    component: authentication
  },
  {
    path: '/authenticationKR',
    name: '实名认证',
    meta: {
      keepAlive: false
    },
    component: authenticationKR
  },
  {
    path: '/transaction',
    name: '快捷交易中心',
    meta: {
      keepAlive: false
    },
    component: transaction,
    // children: []
  },
  {
    path: '/transactionBuyOne',
    name: '买家1',
    meta: {
      keepAlive: false
    },
    component: buyOne
  },
  {
    path: '/transactionBuyTwo',
    name: '买家2',
    meta: {
      keepAlive: false
    },
    component: buyTwo
  },
  {
    path: '/transactionDetail',
    name: '买家订单详情',
    meta: {
      keepAlive: false
    },
    component: buyDetail
  },
  {
    path: '/transactionSellOne',
    name: '卖家1',
    meta: {
      keepAlive: false
    },
    component: sellOne
  },
  {
    path: '/transactionSellTwo',
    name: '卖家订单详情',
    meta: {
      keepAlive: false
    },
    component: sellTwo
  },
  {
    path: '/pendingOrders',
    name: '购买/出售申请',
    meta: {
      keepAlive: false
    },
    component: pendingOrders
  },
  {
    path: '/shortcutOrder',
    name: '快捷交易',
    meta: {
      keepAlive: false
    },
    component: shortcutOrder
  },
  {
    path: '/pendingOrder',
    name: '挂单交易',
    meta: {
      keepAlive: false
    },
    component: pendingOrder
  },
  {
    path: '/transfer',
    name: '传送',
    meta: {
      keepAlive: false
    },
    component: transfer
  },
  {
    path: '/orderManagement',
    name: '出入金管理',
    meta: {
      keepAlive: false
    },
    component: orderManagement
  },
  {
    path: '/agency',
    name: '代理商中心',
    meta: {
      keepAlive: false
    },
    component: agency
  },
  {
    path: '/agreement',
    name: '用户协议',
    meta: {
      keepAlive: false
    },
    component: agreement
  },
  {
    path: '/privacyAgreement',
    name: '个人隐私保护政策',
    meta: {
      keepAlive: false
    },
    component: privacyAgreement
  },
  {
    path: '/announcement',
    name: '公告中心',
    meta: {
      keepAlive: false
    },
    component: announcement
  },
  {
    path: '/customerCenter',
    name: '客户中心',
    meta: {
      keepAlive: false
    },
    component: customerCenter
  },
  {
    path: '/FAQ',
    name: '常见问题',
    meta: {
      keepAlive: false
    },
    component: FAQ
  },
  {
    path: '/rookieGuide',
    name: '新手指南',
    meta: {
      keepAlive: false
    },
    component: rookieGuide
  },
  {
    path: '/client/sell/:shopId',
    name: '出金',
    meta: {
      keepAlive: false
    },
    component: clientSell
  },
  {
    path: '/client/buy/:shopId',
    name: '入金',
    meta: {
      keepAlive: false
    },
    component: clientBuy
  },
  //404页面捕获
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
