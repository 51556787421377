/**
* zh_CN
*/
export default {
  srartDate: "开始日期",
  endDate: "结束日期",
  orderNo: "订单号",
  currencyName: "货币名",
  searchBtn: "查询",
  canselBtn: "取消",
  changeType: '请选择资产类型'
};