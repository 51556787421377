<template>
  <div>
    <SmallBanners
      :title="$t('transactionBuy.bannerName')"
      :info="$t('transactionBuy.bannerInfo')"
    />
    <div class="content">
      <div class="content-head">
        <div class="content-head-title">
          <span class="content-head-title-name">{{
            $t("transactionBuy.buyAmount")
          }}</span>
          <span class="content-head-title-num">{{
            utils.numberFormatting(formData.quantity)
          }}</span>
          <span class="content-head-title-type">USDT</span>
        </div>
        <div class="content-head-info">
          <div class="content-head-info-item">
            <span class="content-head-info-item-name"
              >{{ $t("transactionBuy.tradeRate") }}:</span
            >
            <span class="content-head-info-item-num">{{
              utils.numberFormatting(checkResData.tradeRate)
            }}</span>
            <span class="content-head-info-item-type">USDT</span>
          </div>
          <div class="content-head-info-item">
            <span class="content-head-info-item-name"
              >{{ $t("transactionBuy.paymentAmount") }}:</span
            >
            <span class="content-head-info-item-num">{{
              utils.numberFormatting(formData.price)
            }}</span>
            <span class="content-head-info-item-type">{{
              checkResData.symbol
            }}</span>
          </div>
          <div class="content-head-info-item">
            <span class="content-head-info-item-name"
              >{{ $t("transactionBuy.tradeFee") }}:</span
            >
            <span class="content-head-info-item-num">{{
              utils.numberFormatting(checkResData.tradeFee)
            }}</span>
            <span class="content-head-info-item-type">USDT</span>
          </div>
        </div>
      </div>
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        class="formData"
        label-position="left"
        :hide-required-asterisk="true"
      >
        <el-row :gutter="24">
          <el-col :span="4">
            <div class="el-form-item__label">
              {{ $t("transactionBuy.patternofPayment") }}
            </div>
          </el-col>
          <el-col :span="14">
            <el-form-item prop="payTypeId" class="formData-item">
              <el-select
                v-model="formData.payTypeId"
                placeholder=""
                class="formData-item-input"
                @change="searchLoginNames"
              >
                <el-option
                  :label="item.label"
                  :value="item.id"
                  v-for="item in checkResData.payTypeList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="4">
            <div class="el-form-item__label">
              {{ $t("transactionBuy.sellersChoice") }}
            </div>
          </el-col>
          <el-col :span="14">
            <el-form-item prop="searchKey" class="formData-item">
              <el-select
                v-model="formData.searchKey"
                placeholder=""
                class="formData-item-input"
              >
                <el-option
                  :label="item.loginName"
                  :value="item.userId"
                  v-for="item in checkResData.acceptorInfoList"
                  :key="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="14">
            <el-form-item prop="" label="" class="formData-item">
              <div class="formData-itemBox">
                <el-input
                  v-model="formData.searchName"
                  class="formData-item-input"
                />
                <el-button
                  type="info"
                  class="formData-item-btn"
                  @click="searchLoginNames"
                  >{{ $t("transactionBuy.sellersSearch") }}</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer">
        <el-button type="primary" class="footer-left btn" @click="buyNow">{{
          $t("transactionBuy.buyNow")
        }}</el-button>
        <el-button
          type="primary"
          class="footer-right btn"
          plain
          @click="goBack()"
          >{{ $t("transactionBuy.cancelBtn") }}</el-button
        >
      </div>
    </div>
    <TooltipPop ref="TooltipPop" @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import { checkState, searchAcceptor, mkOrder } from "@/api/transaction";
export default {
  name: "",
  components: {},
  data() {
    const formDataCheck = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error(this.$t("transactionBuy.rules." + rule.field)));
      }
    };
    return {
      orderNo: "",
      formData: {
        price: "",
        quantity: "",
        payTypeId: "",
        searchKey: "",
        tradePassword: "",
        searchName: "", // 仅在当前页面使用
      },
      checkResData: {
        tradeRate: "",
        payTypeList: [], //可选收款方式
        acceptorInfoList: [], //可选购买方
      },
      rules: {
        payTypeId: [
          { required: true, validator: formDataCheck, trigger: "change" },
        ],
        searchKey: [
          { required: true, validator: formDataCheck, trigger: "change" },
        ],
      },
    };
  },
  destroyed() {
    sessionStorage.removeItem("orderData");
    sessionStorage.removeItem("checkResData");
  },
  created() {
    // this.$on("tooltipback", (flag, isCanCel) => {
    //     console.log("on监听参数====", flag, isCanCel); //['string',false,{name:'vue'}]
    //   });
  },
  mounted() {
    this.$nextTick(() => {
      this.formData = JSON.parse(sessionStorage.getItem("orderData"));
      // 替换字符串
      this.formData = {
        ...this.formData,
        price: this.formData.price.replaceAll(",", ""),
        quantity: this.formData.quantity.replaceAll(",", ""),
        inputAmount: this.formData.inputAmount.replaceAll(",", ""),
      };
      this.checkResData = JSON.parse(sessionStorage.getItem("checkResData"));
      let list = [];
      this.checkResData.payTypeList.forEach((i) => {
        list.push({
          ...i,
          label: this.$t("transactionBuy." + i.shortName),
        });
      });
      this.$set(this.checkResData, "payTypeList", list);
      if (!this.formData.payTypeId) {
        this.formData.payTypeId = this.checkResData.payTypeList[0].id;
      }
      if (!this.formData.searchKey) {
        this.formData.searchKey = this.checkResData.acceptorInfoList[0]?.userId;
        this.searchLoginNames();
      }
      // this.init();
    });
  },
  methods: {
    searchLoginNames() {
      let data = {
        tradeTye: this.formData.tradeTye,
        computeMode: this.formData.computeMode,
        currencyCode: this.formData.currencyCode,
        inputAmount: this.formData.inputAmount,
        payTypeId: this.formData.payTypeId,
        searchKey: this.formData.searchName,
      };
      searchAcceptor(data).then((res) => {
        if (res.code == 200) {
          if (res.data.acceptorInfoList.length < 1) {
            this.formData.searchKey = "";
            this.checkResData.acceptorInfoList = [];
            this.$tooltipPop.open(null, true, {
              type: "error",
              info: [this.$t("transactionBuy.message.searchUser")],
            });
          } else {
            this.checkResData.acceptorInfoList = res.data.acceptorInfoList;
            this.formData.searchKey =
              this.checkResData.acceptorInfoList[0].userId;
          }
          this.$forceUpdate();
        }
      });
    },
    init() {
      let data = {
        tradeTye: this.formData.tradeTye,
        computeMode: this.formData.computeMode,
        currencyCode: this.formData.currencyCode,
        inputAmount: this.formData.inputAmount,
      };
      checkState(data).then((res) => {
        if (res.code == 200) {
          this.checkResData = res.data;
        } else {
          this.$router.go(-1);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    buyNow() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let subData = {
            tradeTye: this.formData.tradeTye,
            computeMode: this.formData.computeMode,
            currencyCode: this.formData.currencyCode,
            inputAmount: this.formData.inputAmount,
            payTypeId: this.formData.payTypeId,
            searchKey: this.formData.searchName,
            tradePassword: this.formData.tradePassword,
            acceptorId: this.formData.searchKey,
          };
          mkOrder(subData).then((res) => {
            if (res.code == 200) {
              this.$refs['TooltipPop'].openDialog(null, false, {
                type: "success",
                info: [this.$t("transactionBuy.message.mkOrder")],
              });
              this.orderNo = res.data;
            }
          });
        } else {
          return false;
        }
      });
    },
    tooltipBack(flag, isCancel) {
      let data = {
        path: "/transactionBuyTwo",
        query: {
          orderNo: this.orderNo,
        },
      };
      this.$router.push(data);
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6vw;
  .content-head {
    width: 100%;
    height: 9.16667vw;
    background: #eeeeee;
    padding: 1.35417vw;
    box-sizing: border-box;
    margin-bottom: 2.44792vw;
    .content-head-title {
      font-size: 1.82292vw;
      .content-head-title-name {
        font-weight: bold;
        color: #019393;
        margin-right: 1vw;
      }
      .content-head-title-num {
        font-size: 2.08333vw;
        font-weight: bold;
      }
    }
  }
  .content-head-info {
    margin-top: 1.5vw;
    display: flex;
    justify-content: space-between;
    .content-head-info-item {
      width: 32%;
      height: 40px;
      font-size: 0.9375vw;
      border-radius: 5px;
      background: #019393;
      display: flex;
      align-items: center;
      padding: 0.9375vw 1.40625vw;
      box-sizing: border-box;
      color: #ffffff;
      span {
        margin-right: 5px;
      }
      &-name {
        font-weight: bold;
      }
      &-num {
        color: #ffff00;
      }
    }
  }
  .formData {
    .formData-item {
      width: 100%;
      .formData-itemBox {
        width: 100%;
        display: flex;
        justify-content: start;
      }
      .formData-item-input {
        width: calc(100% - 12vw);
      }
      .formData-item-btn {
        width: 9.3vw;
        margin-left: 1vw;
        background: #313131 !important;
      }
      .formData-item-btn:hover {
        background: #656565 !important;
      }
      .formData-item-btn:active {
        background: #313131 !important;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-left {
      width: calc(65%);
    }
    &-right {
      width: 35%;
    }
    &-right:active {
      background: #f8f8f8 !important;
    }
  }
}
::v-deep .el-form-item__label {
  width: 100%;
  background: #f8f8f8;
  margin-right: 1vw !important;
  padding-left: 1vw !important;
}
::v-deep .el-row {
  margin: 0px !important;
}
::v-deep .el-col {
  padding-left: 0px !important;
}
::v-deep .el-input__inner {
  border: none !important;
  background: #f8f8f8 !important;
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
</style>
