/**
* en
*/
import im from './en/IM'
import requestCode from "./en/requestCode";
import common from "./en/common";
import orderStatus from "./en/orderStatus";
import headers from "./en/components/headers";
import headeTooltip from "./en/components/headeTooltip";
import footer from "./en/components/footer";
import searchDialog from "./en/components/searchDialog";
import signIn from "./en/signIn";
import loginHome from "./en/loginHome";
import logInAndResetPasswords from "./en/loginHome/logInAndResetPasswords";
import userInfo from "./en/userInfo";
import authentication from "./en/userInfo/authentication";
import nationality from "./en/nationality";
import estate from "./en/estate";
import transfer from "./en/transfer";
import agency from "./en/agency";
import orderManagement from "./en/orderManagement";
import transaction from "./en/transaction";
import transactionSell from "./en/transaction/sell";
import transactionBuy from "./en/transaction/buy";
import pendingOrders from "./en/pendingOrders";
import transactionOrder from "./en/transactionOrder";
import clientBuy from './en/client/buy'
import clientSell from './en/client/sell'
export default {
  im,
  requestCode,
  common,
  orderStatus,
  headers,
  headeTooltip,
  footer,
  searchDialog,
  signIn,
  loginHome,
  logInAndResetPasswords,
  userInfo,
  authentication,
  nationality,
  estate,
  transfer,
  agency,
  orderManagement,
  transaction,
  transactionSell,
  transactionBuy,
  pendingOrders,
  transactionOrder,
  clientBuy,
  clientSell,
};