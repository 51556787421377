/**
* en
*/
export default {
    bannerName: {
        shortcutOrder: 'Quick transaction order',
        pendingOrder: 'Hanging order transaction order',
    },
    bannerInfo: 'Safe, Fast, Convenient',
    buyBtn: 'Buy',
    sellBtn: 'Sell',
    tabelBtns: {
        all: 'whole',
        audit: 'Under review',
        timeOut: 'Timed out',
        arbitration: 'In arbitration',
        reject: 'Rejected',
        finished: 'Success',
    },
    orderNo: 'Order number',
    status: 'State',
    buyPrice: 'Payment amount',
    sellPrice: 'Amount Collected ',
    tradeRate: 'Exchange rate',
    buyAmount: 'Purchase quantity',
    sellAmount: 'Sales quantity',
    tradingObject: 'Trading partner',
    createdTime: 'Time',
    options: 'Operation',
    detail: 'Details',
}