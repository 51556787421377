/**
* en
*/
export default {
  KOREA:'Korea',
  CHINA:'China',
  EU:'EU',
  BRITAIN:'Britain',
  JAPAN:'Japan',
  MALAYSIA:'Malaysia',
  PHILIPPINES:'Philippines',
  THAILAND:'Thailand',
  VIETNAM:'Vietnam',
};