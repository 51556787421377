/**
* zh_CN
*/
export default {
    bannerName: '购买/出售申请',
    clientBannerName: '出售申请',
    bannerInfo: '资金安全, 快捷, 方便 随时交易',
    buyBtn: '购买申请',
    sellBtn: '出售申请',
    buyLabelPrice: '购买价格',
    sellLabelPrice: '出售价格',
    buyLabelAmount: '购买申请数量',
    sellLabelAmount: '出售申请数量',
    newSellBtn: '立即出售申请',
    newBuyBtn: '立即购买申请',
    switchType: '货币种类',
    switchTipBuy: '购买申请后请打开接单开关',
    switchTipSell: '出售申请后请打开接单开关',
    switchTip: '接单开关 ON/OFF',
    rules: {
        price: '请输入出售价格',
        buyamount: '请输入购买申请数量',
        sellamount: '请输入出售申请数量',
    },
    message: {
        success: '操作成功',
    },
    confirm: {
        authenticationTitle: '提示',
        authenticationInfo: '数据未保存，是否离开?',
        confirmBtn: '是',
        cancelBtn: '否',
    },
    warnInfo: [
        {
            title: "购买/出售申请方法",
            values: [
                `1. 如果您输入您想要购买/出售的最大数量，平台AI自动配对程序就会自动配对您设定的最大数量以内的交易。`,
                `2. 购买/出售申请可以以低于快速交易/购买的价格购买USDT，并以更高的价格出售USDT。例如:汇率行情抛售价格1400韩元/买进价格1300韩元。`
            ],
        },
        {
            title: "温馨提示",
            values: [
                `1. 购买一万韩元时，购买按钮ON;出售万韩元时，出售按钮ON;购买/出售全部需要时，两个按钮ON `,
                `2. 请输入您最大可购买/出售的USDT数量。`,
            ],
        },

    ],
}
