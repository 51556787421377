/**
* en
*/
export default {
  bannerName: 'Cash order',
  bannerInfo: 'Safe, Fast, Convenient',
  header:{
    inviterUserNumber:'Number of invited users',
    totalRevenue:'Total revenue',
    monthlyIncome:'Monthly income',
    btns:{
      buyBtn:'Deposit',
      sellBtn:'Withdrawal of funds',
    }
  },
  content:{
    tabelBtns:{
      all:'whole',
      buyAamount:'Investing in gold',
      sellamount:'Application for withdrawal',
      overtime:'Timed out',
      arbitration:'In arbitration',
      reject:'Rejected',
      accomplish:'Success',
    },
    tabel:{
      orderNo:'Order number',
      status:'State',
      buyAmount:'Payment amount',
      sellAmount:'Withdrawal amount',
      buyUser:'Buyer',
      sellUser:'Cashier',
      tradeRate:'exchange rate',
      buyNum:'Deposit amount',
      sellNum:'Out amount',
      transactionObject:'Trading partner',
      creationTime:'Time',
      options:'Operation',
      search:'Check',
    },
  }
};