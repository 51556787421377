<template>
  <div>
    <SmallBanners
      :title="$t('transactionSell.bannerName')"
      :info="$t('transactionSell.bannerInfo')"
    />
    <div class="content">
      <div class="content-head">
        <div class="content-head-title">
          <span class="content-head-title-name">{{
            orderData.isJieDanRen
              ? $t("transactionSell.sellAmount")
              : orderData.orderType == 4
              ? $t("transactionSell.chujinAmount")
              : $t("transactionSell.sellAmount")
          }}</span>
          <span class="content-head-title-amount"
            >{{ orderData.orderAmountShow
            }}<span class="content-head-title-amount-symbol"> USDT</span></span
          >
        </div>
        <div class="content-head-info">
          <div class="content-head-info-item">
            <span class="content-head-info-item-name"
              >{{ $t("transactionSell.orderNo") }}:</span
            >
            <span class="content-head-info-item-info">#{{ orderData.id }}</span>
          </div>
        </div>
      </div>
      <div class="content-paymentInfo">
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            {{ $t("transactionSell.tradeRate") }}:
          </div>
          <div class="content-paymentInfo-item-value">
            {{ orderData.priceShow }}USDT/{{ orderData.symbol }}
          </div>
        </div>
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            {{
              orderData.isJieDanRen
                ? $t("transactionSell.proceedsAmount")
                : orderData.orderType == 4
                ? $t("transactionSell.chujinjine")
                : $t("transactionSell.proceedsAmount")
            }}:
          </div>
          <div class="content-paymentInfo-item-value">
            {{ orderData.currencyAmountShow }}{{ orderData.symbol }}
          </div>
        </div>
        <div class="content-paymentInfo-item">
          <div class="content-paymentInfo-item-name">
            {{ $t("transactionSell.tradeFee") }}:
          </div>
          <div class="content-paymentInfo-item-value">
            {{ orderData.feeShow }}USDT
          </div>
        </div>
        <div
          class="content-paymentInfo-item"
          v-if="orderData.orderStatus != 1 && orderData.orderType != 4"
        >
          <div class="content-paymentInfo-item-name">
            {{ $t("transactionSell.payTypeName") }}:
          </div>
          <div class="content-paymentInfo-item-value">
            <div class="content-paymentInfo-item-value-reminder">
              <div class="content-paymentInfo-item-reminder-value">
                <div class="content-paymentInfo-item-reminder-value-item">
                  {{ $t("common.minBankList." + orderData.payTypeName) }}
                </div>
                <div class="content-paymentInfo-item-reminder-value-item">
                  <div
                    class="content-paymentInfo-item-reminder-value-item-name"
                  >
                    {{ $t("transactionSell.bankName") }}:
                  </div>
                  <div
                    class="content-paymentInfo-item-reminder-value-item-value"
                  >
                    {{ orderData.bankName }}
                  </div>
                </div>
                <div class="content-paymentInfo-item-reminder-value-item">
                  <div
                    class="content-paymentInfo-item-reminder-value-item-name"
                  >
                    {{
                      $t(
                        orderData.payTypeId == 3
                          ? "transactionSell.WeChat"
                          : orderData.payTypeId == 4
                          ? "transactionSell.Alipay"
                          : "transactionSell.bankAccount"
                      )
                    }}:
                  </div>
                  <div
                    class="content-paymentInfo-item-reminder-value-item-value"
                  >
                    <span
                      v-if="
                        orderData.payTypeId != 3 && orderData.payTypeId != 4
                      "
                      >{{ orderData.bankNumber }}</span
                    >
                    <span
                      class="content-paymentInfo-item-reminder-value-item-getImg"
                      @click="getQR"
                      v-else
                      >{{ $t("transactionSell.getQr") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="orderData.orderType == 2">
          <div class="content-paymentInfo-item">
            <div class="content-paymentInfo-item-name">
              {{ $t("transactionSell.sellName") }}:
            </div>
            <div class="content-paymentInfo-item-value">
              {{ orderData.otherSideName }}
            </div>
          </div>
        </template>
        <template v-if="orderData.orderType == 4 && !orderData.isJieDanRen">
          <div class="content-paymentInfo-item">
            <div class="content-paymentInfo-item-name">
              {{ $t("transactionSell.sellUser") }}:
            </div>
            <div class="content-paymentInfo-item-value">
              {{ orderData.payUserName }}
            </div>
          </div>
          <div class="content-paymentInfo-item">
            <div class="content-paymentInfo-item-name">
              {{ $t("transactionSell.sellAddress") }}:
            </div>
            <div class="content-paymentInfo-item-value">
              {{ orderData.receiveAddress }}
            </div>
          </div>
        </template>
        <div class="content-paymentInfo-item styleNone">
          <div class="content-paymentInfo-item-reminder">
            <span class="name"
              >{{ $t("transactionSell.scheduleStatus") }}:&nbsp;</span
            >
            <span
              class="value"
              v-if="orderData.orderType == 4 && orderData.orderStatus == 2"
              >{{
                $t(
                  `orderStatus.chujinScheduleStatusStart${orderData.orderStatus}`
                )
              }}</span
            >
            <span class="value" v-else>{{
              $t(`orderStatus.sellScheduleStatusStart${orderData.orderStatus}`)
            }}</span>
          </div>
        </div>
      </div>
      <!-- -----------------------------是发起人---------------------- -->
      <template v-if="!orderData.isJieDanRen">
        <template v-if="orderData.orderStatus == 0">
          <div class="footer">
            <!-- 提交申诉 -->
            <el-button
              type="primary"
              class="footer-left btn"
              @click="openAppeal()"
              >{{ $t("transactionSell.subAppeal") }}</el-button
            >
            <!-- 取消订单 -->
            <el-button
              type="info"
              @click="cancelOrder()"
              class="footer-right infoBtn"
              >{{ $t("transactionSell.cancelOrder") }}</el-button
            >
          </div>
        </template>
        <template v-if="orderData.orderStatus == 2">
          <template v-if="orderData.orderType == 2">
            <div class="footer">
              <!-- 确认收款 | 确认出金 -->
              <el-button
                type="primary"
                class="footer-left btn"
                @click="confirmReceipt(orderData.orderStatus)"
                >{{
                  orderData.orderType == 4
                    ? $t("transactionSell.chujin")
                    : $t("transactionSell.verifyProceeds")
                }}</el-button
              >
              <!-- 提交申诉 -->
              <el-button
                type="info"
                class="footer-right infoBtn"
                @click="openAppeal()"
                >{{ $t("transactionSell.subAppeal") }}</el-button
              >
            </div>
          </template>
          <template v-if="orderData.orderType == 4">
            <div class="footer">
              <!-- 确认出金 -->
              <el-button
                type="primary"
                class="footer-left btn"
                @click="confirmReceipt(orderData.orderStatus)"
                >{{
                  orderData.orderType == 4
                    ? $t("transactionSell.chujin")
                    : $t("transactionSell.verifyProceeds")
                }}</el-button
              >
              <!-- 拒绝出金 -->
              <el-button
                type="primary"
                @click="rejectOrder()"
                plain
                class="footer-right"
                >{{ $t("transactionSell.rejectOrder") }}</el-button
              >
            </div>
          </template>
        </template>
        <!-- ----------------------联系平台---------------------------- -->
        <div class="footer">
          <!-- 联系平台 -->
          <el-button
            type="info"
            @click="openIM(orderData.serviceAccId)"
            class="contactPlatform infoBtn"
            >{{ $t("transactionSell.contactPlatform") }}</el-button
          >
          <!-- 联系买家 -->
          <el-button
            v-if="orderData.orderType == 2"
            type="primary"
            class="openIm"
            plain
            @click="openIM(orderData.accId)"
            >{{ $t(`transactionSell.openIm`) }}</el-button
          >
        </div>
      </template>
      <!-- -----------------------------是接单人---------------------- -->
      <template v-else>
        <template v-if="orderData.orderStatus == 0">
          <div class="footer">
            <!-- 已付款（付款好点击） -->
            <!-- <el-button
              type="primary"
              class="footer-left btn"
              @click="forceUpdate()"
              >{{ $t("transactionSell.toPay") }}</el-button
            > -->
            <!-- 取消订单 -->
            <el-button
              type="primary"
              @click="cancelOrder()"
              class="footer-left"
              >{{ $t("transactionSell.cancelOrder") }}</el-button
            >
            <!-- 联系买家 -->
            <el-button
              type="primary"
              @click="openIM(orderData.accId)"
              class="footer-right"
              plain
              >{{ $t(`transactionSell.openIm`) }}</el-button
            >
          </div>
        </template>
        <template v-if="orderData.orderStatus == 2">
          <div class="footer">
            <!-- 确认收款 | 确认出金 -->
            <el-button
              type="primary"
              class="footer-left btn"
              @click="confirmReceipt(orderData.orderStatus)"
              >{{
                orderData.orderType == 4
                  ? $t("transactionSell.chujin")
                  : $t("transactionSell.verifyProceeds")
              }}</el-button
            >
            <!-- 提交申诉 -->
            <el-button
              type="info"
              class="footer-right infoBtn"
              @click="openAppeal()"
              >{{ $t(`transactionSell.subAppeal`) }}</el-button
            >
          </div>
        </template>
        <!-- ----------------------联系平台---------------------------- -->
        <div class="footer">
          <el-button
            type="info"
            @click="openIM(orderData.serviceAccId)"
            class="contactPlatform infoBtn"
            >{{ $t("transactionSell.contactPlatform") }}</el-button
          >
          <!-- 联系买家 -->
          <el-button
            type="primary"
            class="openIm"
            plain
            @click="openIM(orderData.accId)"
            v-if="orderData.orderStatus != 0"
            >{{ $t(`transactionSell.openIm`) }}</el-button
          >
        </div>
      </template>
    </div>
    <QR ref="QR" />
    <AppealDialog ref="AppealDialog" @appealBack="appealBack" />
    <TooltipPop ref='TooltipPop' @tooltipBack="tooltipBack" />
  </div>
</template>

<script>
import {
  orderInfo,
  cancel,
  confirmReceipt,
  appeal,
  reject,
} from "@/api/transaction";
import AppealDialog from "@/views/transaction/components/appealDialog.vue";
export default {
  name: "",
  components: { AppealDialog },
  data() {
    return {
      orderData: {},
      orderNo: "",
    };
  },
  watch: {},
  created() {
    this.orderNo = this.$route.query.orderNo;
    this.init();
  },
  methods: {
    tooltipBack(flag, isCancel) {
      if (isCancel) return;
      if (flag == "confirmReceipt") {
        let data = {
          orderId: this.orderData.id,
        };
        confirmReceipt(data).then((res) => {
          if (res.code == 200) {
            // this.$tooltipPop.open(null, true, {
            //   type: "success",
            //   info: [this.$t("transactionSell.message.success")],
            // });
            this.init();
          }
        });
      }
      if (flag == "cancelOrder") {
        let data = {
          orderId: this.orderData.id,
        };
        cancel(data).then((res) => {
          if (res.code == 200) {
            this.init();
            this.$tooltipPop.open(null, true, {
              type: "success",
              info: [this.$t("transactionSell.message.orderCancel")],
            });
          }
        });
      }
      if (flag == "rejectOrder") {
        let data = {
          orderId: this.orderData.id,
        };
        reject(data).then((res) => {
          if (res.code == 200) {
            this.init();
            this.$tooltipPop.open(null, true, {
              type: "success",
              info: [this.$t("transactionSell.message.success")],
            });
          }
        });
      }
    },
    init() {
      let data = {
        orderNo: this.orderNo,
      };
      orderInfo(data).then((res) => {
        if (res.code == 200) {
          this.orderData = {
            ...res.data,
          };
        }
      });
    },
    // 已付款（付款好点击） 刷新当前页面
    forceUpdate() {
      this.$router.go(0);
    },
    // 确认收款
    confirmReceipt(status) {
      this.$refs['TooltipPop'].openDialog('confirmReceipt', false, {
        type: "affirm",
        info: [
        this.orderData.orderType == 4
          ? this.$t("transactionSell.chujin")
          : this.$t("transactionSell.confirm.confirmInfo"),
      ],
      });
    },
    // 取消订单
    cancelOrder() {
      this.$refs['TooltipPop'].openDialog('cancelOrder', false, {
        type: "affirm",
        info: [this.$t("transactionSell.confirm.cancelInfo")],
      });
    },
    // 拒绝订单
    rejectOrder() {
      this.$refs['TooltipPop'].openDialog('rejectOrder', false, {
        type: "affirm",
        info: [this.$t("transactionSell.confirm.rejectInfo")],
      });
    },
    getQR() {
      let data = {
        type: 2,
        url: this.orderData.bankName,
        amountShow: this.orderData.currencyAmountShow,
        symbol: this.orderData.symbol,
      };
      this.$refs["QR"].openDialog(data);
    },
    // 打开申诉
    openAppeal() {
      this.$refs["AppealDialog"].openDialog();
    },
    // 申诉返回
    appealBack(value) {
      let data = {
        orderId: this.orderData.id,
        appealDesc: value.appealDesc,
      };
      appeal(data).then((res) => {
        if (res.code == 200) {
          this.init();
        }
      });
    },
    openIM(id) {
      this.$store.dispatch("common/setIM", { bool: null, accId: id });
    },
  },
};
</script>
<style scoped lang='scss'>
.content {
  padding-bottom: 6vw;
  .content-head {
    width: 100%;
    background: #eeeeee;
    padding: 1.35417vw;
    box-sizing: border-box;
    margin-bottom: 2.44792vw;
    .content-head-title {
      height: 2vw;
      display: flex;
      align-items: center;
      font-size: 1.82292vw;
      .content-head-title-name {
        font-weight: bold;
        color: #019393;
        margin-right: 1vw;
      }
      .content-head-title-amount {
        font-size: 2.34375vw;
        .content-head-title-amount-symbol {
          font-size: 1.5vw;
        }
      }
    }
    .content-head-info {
      display: flex;
      justify-content: flex-start;
      margin-top: 1.2vw;
      .content-head-info-item {
        width: 15.625vw;
        height: 40px;
        line-height: 40px;
        background: #019393;
        color: #ffffff;
        margin-right: 1vw;
        border-radius: 5px;
        padding-left: 1vw;
        box-sizing: border-box;
        display: flex;
        font-size: 0.9375vw;
        span {
          margin-right: 0.5vw;
        }
        .color {
          color: #ffff00;
        }
      }
    }
  }
  .content-paymentInfo {
    .content-paymentInfo-item {
      height: 2.08333vw;
      min-height: 40px;
      line-height: 2.08333vw;
      padding-left: 1vw;
      margin-bottom: 1vw;
      font-size: 14px;
      color: #7d7d7d;
      border-radius: 5px;
      box-sizing: border-box;
      background: #f8f8f8;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .content-paymentInfo-item-name {
        width: calc(5vw + 2px);
        display: flex;
        justify-content: space-between;
        margin-right: 1vw;
        font-weight: bold;
        div {
          display: inline-block;
          width: 23%;
        }
        .col {
          width: 33.3%;
          text-align: center;
        }
        .col:first-child {
          text-align: left;
        }
        .col:last-child {
          text-align: right;
        }
      }
      .content-paymentInfo-item-value {
        width: calc(100% - 7vw);
      }
      .content-paymentInfo-item-value-reminder {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .content-paymentInfo-item-reminder-value {
          flex: 1;
          background: #f8f8f8;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .content-paymentInfo-item-reminder-value-item {
            display: flex;
            justify-content: flex-start;
            margin-right: 2vw;
            .content-paymentInfo-item-reminder-value-item-name {
              font-weight: bold;
              margin-right: 0.5vw;
            }
            .content-paymentInfo-item-reminder-value-item-getImg:hover {
              color: #007c7c;
            }
          }
          .content-paymentInfo-item-reminder-value-item {
            cursor: pointer;
          }
        }
      }
    }
    .styleNone {
      background: none;
    }
    .content-paymentInfo-item:last-child {
      color: #313131;
    }
  }
  .footer {
    margin-top: 1.92708vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .footer-left {
      width: calc(70%);
    }
    .footer-right {
      width: 30%;
    }
    .openIm {
      width: calc(50%);
    }
    .contactPlatform {
      flex: 1;
      background: #313131;
      color: #ffffff;
    }
  }
  .infoBtn {
    background: #313131;
    color: #ffffff;
  }
}
::v-deep .infoBtn:hover {
  background: #808080 !important;
}
::v-deep .infoBtn:active {
  background: #313131 !important;
}
::v-deep .is-plain {
  background: #ffffff !important;
  color: #019393 !important;
}
</style>
